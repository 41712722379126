export const SET_LABEL = "SET_LABEL";
export const RESET_LABEL = "RESET_LABEL";

export const setLabel = (label) => (dispatch) => {
  dispatch({
    type: SET_LABEL,
    label,
  });
};
export const resetLabel = () => (dispatch) => {
  dispatch({
    type: RESET_LABEL,
  });
};
