import React from "react";
import { Row, Col, Button } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import Texty from "rc-texty";
import "./index.scss";

export default function Cart(props) {
  const calculateTotal = () => {
    let total = 0;
    props.cart.map((item) => {
      if (item.product_details) total = total + item.quantity * item.price;
    });
    return total;
  };
  const { cart } = props;
  let total = 0;

  return (
    <div className="cart-container cart-border">
      <Row className="justify-content-lg-between p-3">
        <Col span={6}>
          <Texty mode={"smooth"} style={{ fontWeight: "600" }}>
            Product
          </Texty>
        </Col>
        <Col span={6}>
          <Texty mode={"smooth"} style={{ fontWeight: "600" }}>
            Price
          </Texty>
        </Col>
        <Col span={6}>
          <Texty mode={"smooth"} style={{ fontWeight: "600" }}>
            Quantity
          </Texty>
        </Col>
        <Col span={6}>
          <Texty mode={"smooth"} style={{ fontWeight: "600" }}>
            Total
          </Texty>
        </Col>
      </Row>
      <Row className="justify-content-lg-between p-3">
        {cart.map((item, index) => {
          if (item.product_details) {
            total = total + item.quantity * item.price;
            return (
              <>
                <Col span={6}>
                  <img
                    src={item.product_details.product_media[0]._value}
                    style={{
                      width: 40,
                      height: 30,
                      borderRadius: 10,
                      marginRight: 10,
                    }}
                  />
                  {item.product_details.name}
                </Col>
                <Col span={6}>$ {item.price}</Col>
                <Col
                  span={6}
                  style={{
                    alignItems:
                      window.screen.width > 768 ? "center" : undefined,
                    display: "flex",
                  }}
                >
                  <MinusOutlined
                    onClick={() => {
                      if (cart[index].quantity > 1) {
                        const arr = [...cart];
                        arr[index].quantity = arr[index].quantity - 1;
                        arr[index].total = arr[index].total - arr[index].price;
                        props.setCart(arr);
                      }
                    }}
                    style={{
                      fontSize: 15,
                      cursor: "pointer",
                    }}
                  />
                  <span className="ml-2 mr-2 font-weight-bold">
                    {item.quantity}
                  </span>
                  <PlusOutlined
                    onClick={() => {
                      const arr = [...cart];
                      arr[index].quantity = arr[index].quantity + 1;
                      arr[index].total = arr[index].price + arr[index].total;
                      props.setCart(arr);
                    }}
                    style={{ fontSize: 15, cursor: "pointer" }}
                  />
                </Col>
                <Col span={6}>$ {item.quantity * item.price}</Col>
              </>
            );
          }
        })}
      </Row>
      <div className="d-flex justify-content-center mt-2">
        <Row className="w-100">
          <Texty mode={"smooth"} style={{ fontWeight: "600", marginLeft: 15 }}>
            Total:
          </Texty>
          <span>$ {calculateTotal()}</span>
        </Row>
      </div>
      <Button
        className="checkout-btn mt-3 mb-2"
        onClick={() => props.nextStep()}
      >
        Proceed To Delivery
      </Button>
    </div>
  );
}
