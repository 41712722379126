import {
  ADD_HOTSPOT,
  ADD_HOTSPOT_LABEL,
  SET_IMAGE,
  SET_DESC,
  SET_ACTIVE_HOTSPOT,
  UPDATE_HOTSPOT,
  RESET_HOTSPOT,
  SET_PANO_VIEW,
  SET_CURRENT_PANO,
  DELETE_HOTSPOT,
  ADD_AUDIO_HOTSPOT,
  DELETE_AUDIO_HOTSPOT,
  RESET_AUDIO_HOTSPOT,
} from "../../actions/hotspot/hotspot";

const hotspotReducer = (
  state = {
    label: "",
    desc: "",
    image: "",
    hotspots: [],
    audioHotspots: [],
    ActiveHotspot: "",
  },
  action
) => {
  switch (action.type) {
    case ADD_HOTSPOT:
      return Object.assign({}, state, {
        hotspots: [...state.hotspots, action.hotspot],
      });
    case ADD_AUDIO_HOTSPOT:
      return Object.assign({}, state, {
        audioHotspots: [...state.audioHotspots, action.hotspot],
      });
    case UPDATE_HOTSPOT:
      const index = state.hotspots.findIndex((x) => x.hid === action.hid);
      state.hotspots[index] = action.hotspot;
    case ADD_HOTSPOT_LABEL:
      return Object.assign({}, state, {
        label: action.hotspotlabel,
      });
    case DELETE_HOTSPOT:
      const newHotspots = state.hotspots.filter(
        (x) => x.hotspotID !== action.id
      );
      state.hotspots = newHotspots;
    case DELETE_AUDIO_HOTSPOT:
      const newAudioHotspots = state.audioHotspots.filter(
        (x) => x.hotspotID !== action.id
      );
      state.audioHotspots = newAudioHotspots;
    case SET_IMAGE:
      return Object.assign({}, state, {
        image: action.image,
      });
    case SET_DESC:
      return Object.assign({}, state, {
        desc: action.desc,
      });
    case SET_ACTIVE_HOTSPOT:
      return Object.assign({}, state, {
        ActiveHotspot: action.hotspot,
      });
    case RESET_HOTSPOT:
      return Object.assign({}, state, {
        hotspots: [],
      });
    case RESET_AUDIO_HOTSPOT:
      return Object.assign({}, state, {
        audioHotspots: [],
      });
    case SET_PANO_VIEW:
      return Object.assign({}, state, {
        panoView: action.panoView,
      });
    case SET_CURRENT_PANO:
      return Object.assign({}, state, {
        currentPano: action.currentPano,
      });
    default:
      return state;
  }
};

export default hotspotReducer;
