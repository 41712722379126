import React, { useState, useEffect } from "react";
import Home from "../Screens/Home";
import "./index.scss";
import {
  CommentOutlined,
  ShareAltOutlined,
  HeartFilled,
  HeartOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faImages, faStar } from "@fortawesome/free-solid-svg-icons";
import { Tooltip, Modal, List, Comment, Input, Button, message } from "antd";
import { BASE_URL_IMAGE, BASE_URL_WEBSITE } from "../constants/URL";
import errors from "../constants/FormErrors";
import Avatar from "antd/lib/avatar/avatar";
import moment from "moment";
import { connect } from "react-redux";
import Api from "../Api Wrapper/Api";
import CopyToClipboard from "react-copy-to-clipboard";
import { token } from "../LocalForage/localForage";

const { TextArea } = Input;

function Post(props) {
  const imgPath = process.env.PUBLIC_URL + "/images/";
  const CommentList = ({ comments }) => (
    <List
      dataSource={comments}
      itemLayout="horizontal"
      renderItem={(props) => {
        return (
          <Comment
            author={props.commenter}
            content={props.text}
            avatar={
              props.User.profilePicture
                ? BASE_URL_IMAGE + props.User.profilePicture
                : `${imgPath}empty-profile-image.jpg`
            }
            datetime={moment(props.createdAt).fromNow()}
          />
        );
      }}
    />
  );
  const ext = props.image.split(".", 2);
  const [isShareModal, setIsShareModal] = useState(false);
  const [commentModal, setCommentModal] = useState(false);
  const [comments, setComments] = useState(props.Comments);
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState("");
  const [likesCounter, setLikesCounter] = useState(props.numberOfLikes);
  const [isLiked, setIsLiked] = useState(props.isLiked);
  const [likedProfPic, setLikedProfPic] = useState([]);

  const unAuthorizedUser = () => {
    if (!props.isAuthenticated) {
      message.error(errors.unAuthorizedUserCheck);
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = () => {
    if (unAuthorizedUser()) {
      if (!value) {
        return;
      }
      setSubmitting(true);
      Api.post(`/comment/add`, {
        image_id: props.id,
        commenter: props.commentUser,
        text: value,
      })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            setComments(res.data.Comments);
            setValue("");
            setSubmitting(false);
            setIsLiked(true);
            Api.post(`/notification/add?uid=${props.postUserID}`, {
              action: "commented",
              post_id: props.postID,
              isTour: props.isTour,
            }).then((res) => {
              console.log(res);
            });
          }
        })
        .catch((err) => {
          message.error("Error posting your comment, please try again later.");
          setSubmitting(false);
          console.log(err);
        });
    }
  };
  const createLike = async () => {
    if (unAuthorizedUser()) {
      Api.post(
        `/like/add`,
        {
          uid: props.postUserID,
          image_id: props.id,
        },
        { headers: { token: await token.getItem("@token") } }
      ).then((response) => {
        if (response.status === 200) {
          if (response.data === "RemovedLike") {
            setIsLiked(false);
            setLikesCounter(likesCounter - 1);
          } else {
            setIsLiked(true);
            Api.post(`/notification/add?uid=${props.postUserID}`, {
              action: "liked",
              post_id: props.postID,
              isTour: props.isTour,
            }).then((res) => {
              console.log(res);
            });
            setLikesCounter(likesCounter + 1);
          }
        }
      });
    }
  };
  useEffect(() => {
    props.LikedUserProfPic &&
      props.Likes &&
      setLikedProfPic(
        props.Likes.map((like) =>
          like.User.profilePicture === null
            ? undefined
            : like.User.profilePicture
        ).filter((like) => like !== undefined)
      );
  }, []);
  return (
    <div className="d-flex flex-column w-100 mt-4">
      {!props.profile ? (
        <div className="ml-3 flex-row mb-3">
          <div className="bottom-container-likes w-100">
            <div className="d-flex ">
              {/* <img
                className="avatar-post"
                alt=""
                // src={,BASE_URL_WEBSITE + profile}
              /> */}
              <Avatar size={window.screen.width > 1100 ? 50 : 35}>
                {props.userName.charAt(0)}
              </Avatar>
              <span className="ml-2 d-flex flex-column ">
                <span className="lg-fs-16 sm-fs-13">{props.userName}</span>
                <span className="gray-color lg-fs-12 sm-fs-9">
                  {props.time}
                </span>
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className="ml-3 flex-row mb-3">
          <div className="bottom-container-likes w-100">
            <div className="d-flex ">
              <img
                className="sm-w-35 sm-h-35 sm-br-17 lg-w-50 lg-h-50 lg-br-25"
                alt=""
                src={BASE_URL_IMAGE + props.profile}
              />
              <span className="ml-2 d-flex flex-column ">
                <span className="lg-fs-16 sm-fs-13">{props.userName}</span>
                <span className="gray-color lg-fs-12 sm-fs-9">
                  {props.time}
                </span>
              </span>
            </div>
          </div>
        </div>
      )}
      {ext[1] === "png" || ext[1] === "jpeg" || ext[1] === "jpg" ? (
        <div className="position-relative">
          <img
            style={{ cursor: "pointer" }}
            onClick={() => {
              props.handleClick();
            }}
            className="image-container sm-display-none"
            alt=""
            src={BASE_URL_IMAGE + props.image}
          />
          <img
            style={{ cursor: "pointer" }}
            onClick={() => {
              props.handleClick();
            }}
            className="sm-image-container lg-display-none"
            alt=""
            src={BASE_URL_IMAGE + props.image}
          />
          {props.isFeatured && (
            <div className="post-banner-feature">
              <Tooltip title="Featured Image">
                <div className="post-banner-featured-card">Featured</div>
              </Tooltip>
            </div>
          )}
          {props.isPurchaseable && (
            <div className="post-banner">
              <Tooltip title="Purchaseable">
                <div className="post-banner-card">For Sale</div>
              </Tooltip>
            </div>
          )}
          {props.isTour !== undefined && (
            <div className="post-banner">
              {!props.isTour
                ? !props.isPurchaseable && (
                    <Tooltip title="Single Image">
                      <div className="post-banner-card">Photo</div>
                    </Tooltip>
                  )
                : !props.isPurchaseable && (
                    <Tooltip title="Tour">
                      <div className="post-banner-card">Tour</div>
                    </Tooltip>
                  )}
            </div>
          )}
          <Tooltip title="Frostie VR">
            <img
              alt=""
              src="/logo192.png"
              className="post-logo-banner"
              style={{ backgroundColor: "transparent" }}
            />
          </Tooltip>
        </div>
      ) : (
        <div className="position-relative">
          <video
            style={{ cursor: "pointer" }}
            onClick={() => {
              props.handleClick();
            }}
            alt=""
            src={BASE_URL_IMAGE + props.image}
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              width: "90%",
              height: window.screen.width > 1480 ? "50%" : "100px",
            }}
          />
          {props.isFeatured && (
            <div className="post-banner-feature">
              <Tooltip title="Featured Image">
                <div className="post-banner-featured-card">Featured</div>
              </Tooltip>
            </div>
          )}
          {props.isPurchaseable && (
            <div className="post-banner">
              <Tooltip title="Purchaseable">
                <div className="post-banner-card">For Sale</div>
              </Tooltip>
            </div>
          )}
          {!props.isPurchaseable && (
            <div className="post-banner">
              <Tooltip title="Video">
                <div className="post-banner-card">Video</div>
              </Tooltip>
            </div>
          )}
          <Tooltip title="Frostie VR">
            <img
              alt=""
              src="/logo192.png"
              className="post-logo-banner"
              style={{ backgroundColor: "transparent" }}
            />
          </Tooltip>
        </div>
      )}
      {!props.boughtImage && (
        <div style={{ width: "93%" }} className="m-3">
          <p className="post-name">{props.postName}</p>
          <div className="lg-post-card-footer sm-post-card-footer">
            <div className="likes-circle-container  d-flex justify-content-between align-items-center">
              {/* <div className="circles-avatar-likes" />
            <div className="circles-avatar-likes-proceeding" />
            <div className="circles-avatar-likes-proceeding" />
            <div className="circles-avatar-likes-proceeding" /> */}
              {/* {!props.isLiked && (
              <HeartFilled
                style={{
                  fontSize: 25,
                  marginLeft: 5,
                  color: "red",
                }}
              />
            )} */}
              <div className="d-flex align-items-center">
                {props.LikedUserProfPic &&
                  likedProfPic.length > 0 &&
                  likedProfPic.map(
                    (imagePth, index) =>
                      index < 3 && (
                        <img
                          className="liked_pro_pic"
                          alt=""
                          src={BASE_URL_IMAGE + imagePth}
                        />
                      )
                  )}
                {/* {!isLiked ? (
                      <HeartOutlined
                        className="like-btn display-none-in-large-display"
                        onClick={() => {
                          if (props.user.userID !== props.postUserID) {
                            createLike();
                          }
                        }}
                      />
                    ) : (
                      <HeartFilled
                        className="like-btn-filled display-none-in-large-display"
                        onClick={() => {
                          if (props.user.userID !== props.postUserID) {
                            createLike();
                          }
                        }}
                      />
                    )}
               ) : !isLiked ? (
                 <HeartOutlined
                   className="like-btn"
                   onClick={() => {
                     if (props.user.userID !== props.postUserID) {
                       createLike();
                     }
                   }}
                 />
               ) : (
                 <HeartFilled
                   className="like-btn-filled"
                   onClick={() => {
                     if (props.user.userID !== props.postUserID) {
                       createLike();
                     }
                   }}
                 />
               )} */}

                <span className="post-ml-10 like-counter">
                  {likesCounter} likes
                </span>
              </div>
              {/* </div>
            <div className="d-flex align-items-center"> */}
              <div>
                <ShareAltOutlined
                  onClick={() => setIsShareModal(true)}
                  className="share-icon d-flex  align-items-center"
                />
                <Modal
                  visible={isShareModal}
                  title="Share Link"
                  onOk={() => setIsShareModal(false)}
                  onCancel={() => setIsShareModal(false)}
                  footer={null}
                >
                  <div>
                    <p>Please use the following image URL to share:</p>
                    <CopyToClipboard
                      text={props.shareUrl}
                      onCopy={() => message.success("Copied to clipboard!!")}
                    >
                      <p style={{ color: "#4a90e2", cursor: "pointer" }}>
                        {props.shareUrl}
                      </p>
                    </CopyToClipboard>
                  </div>
                </Modal>
              </div>
              {props.numberOfView > -1 && (
                <div className="d-flex align-items-center ml-1 mr-2">
                  <EyeOutlined className="comment-icon" />
                  <span className="ml-2 sm-fs-9">{props.numberOfView}</span>
                </div>
              )}
              <Modal
                visible={commentModal}
                title="Add Comment"
                footer={null}
                onOk={() => setCommentModal(false)}
                onCancel={() => setCommentModal(false)}
              >
                {comments.length > 0 && <CommentList comments={comments} />}
                <Comment
                  content={
                    <div>
                      <TextArea
                        rows={4}
                        onChange={(event) => setValue(event.target.value)}
                        value={value}
                      />
                      <Button
                        htmlType="submit"
                        className="mt-2"
                        loading={submitting}
                        onClick={() => handleSubmit()}
                        type="primary"
                      >
                        Add Comment
                      </Button>
                    </div>
                  }
                />
              </Modal>
              <div className="sm-comment-cont">
                <CommentOutlined
                  onClick={() => setCommentModal(true)}
                  className="comment-icon"
                />
                <span className="ml-1 sm-fs-9">{props.numberOfComments}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    isVisitor: state.posts.isVisitor,
    isAuthenticated: state.auth.isAuthenticated,
  };
};
export default connect(mapStateToProps)(Post);
