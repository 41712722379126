import { SET_LABEL, RESET_LABEL } from "../actions/textEditor/editor";

const label = (
  state = {
    label: "",
  },
  action
) => {
  switch (action.type) {
    case SET_LABEL:
      return Object.assign({}, state, {
        label: action.label,
      });
    case RESET_LABEL:
      return Object.assign({}, state, {
        label: "",
      });
    default:
      return state;
  }
};
export default label;
