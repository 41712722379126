import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Empty, Modal, Steps, message, Spin } from "antd";
import Texty from "rc-texty";
import "./index.scss";
import ProductApi from "../../Api Wrapper/ProductApi";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";
const { Step } = Steps;

function index(props) {
  const [trackingId, setTrackingId] = useState();
  const [loading, setLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState();
  const [billDetail, setBillDetail] = useState();

  const getOrderDetails = (orderId) => {
    setLoading(true);
    const orderDetailForm = new FormData();
    orderDetailForm.append("customer_id", props.user.ecommerce_user_id);
    orderDetailForm.append("tracking_id", trackingId);
    ProductApi.post("/customer/order/orderDetailsByTrackingId", orderDetailForm)
      .then((res) => {
        console.log("details");
        console.log(res.data.data.order_items);
        setOrderDetails(res.data.data.order_items);
        setBillDetail(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        message.error("somthing went wrong");
      });
  };
  return (
    <div>
      <Texty
        mode={"smooth"}
        style={{
          fontSize: 30,
          fontWeight: "bold",
          marginTop: 30,
          marginLeft: 500,
        }}
      >
        Track Your Order
      </Texty>
      <div
        className="input-group mb-5 mt-5"
        style={{ opacity: 0.9, width: "50%", marginLeft: "25%" }}
      >
        <div className="input-group-prepend">
          <div className="input-group-text">
            <SearchOutlined style={{ color: "gray" }} />
          </div>
        </div>
        <input
          type="number"
          className="form-control"
          id="inlineFormInputGroup"
          placeholder="enter tracking id"
          onChange={(e) => {
            setTrackingId(e.target.value);
          }}
        />
        <div className="input-group-append">
          <button
            onClick={() => {
              getOrderDetails();
            }}
            className="btn btn-warning border gray-button"
          >
            Search
          </button>
        </div>
      </div>
      {loading ? (
        <Spin
          style={{ marginLeft: "50%", marginBottom: "20%", marginTop: 20 }}
        />
      ) : !orderDetails ? (
        <Empty
          className="mb-5"
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 60,
          }}
          description={<span>No Order Found</span>}
        />
      ) : (
        <div className="w-50 mb-5" style={{ marginLeft: "25%" }}>
          <Row className="mt-1 justify-content-between">
            <Col span={8} className="justify-content-center d-flex">
              <Texty
                mode={"smooth"}
                style={{ fontWeight: "600", fontSize: 16 }}
              >
                Placed At
              </Texty>
            </Col>
            <Col span={8} className="justify-content-center d-flex">
              <Texty
                mode={"smooth"}
                style={{ fontWeight: "600", fontSize: 16 }}
              >
                Price
              </Texty>
            </Col>
            <Col span={8} className="justify-content-center d-flex">
              <Texty
                mode={"smooth"}
                style={{ fontWeight: "600", fontSize: 16 }}
              >
                Quantity
              </Texty>
            </Col>
          </Row>
          {orderDetails &&
            orderDetails.map((item) => (
              <Row className="mt-1 justify-content-between">
                <Col span={8} className="justify-content-center d-flex">
                  <span>{item.created_at}</span>
                </Col>
                <Col span={8} className="justify-content-center d-flex">
                  <span>{item.price_per_unit}$</span>
                </Col>
                <Col span={8} className="justify-content-center d-flex">
                  <span>{item.quantity}</span>
                </Col>
              </Row>
            ))}
          <hr />
          <Texty
            mode={"smooth"}
            style={{ fontWeight: "600", fontSize: 16, marginBottom: 10 }}
          >
            Status:
          </Texty>
          <Steps size="small" current={1}>
            <Step title="Order Placed" />
            <Step title="In Progress" />
            <Step title="Waiting" />
          </Steps>
          <hr />
          <div>
            <Texty
              mode={"smooth"}
              style={{ fontWeight: "600", fontSize: 16, marginBottom: 10 }}
            >
              Total Summary:
            </Texty>
            <Row className="w-50 d-flex justify-content-between">
              <Texty
                mode={"smooth"}
                style={{ fontWeight: "400", fontSize: 15 }}
              >
                SubTotal:
              </Texty>
              <span>${billDetail ? billDetail.sub_total : 0}</span>
            </Row>
            <Row className="w-50 d-flex justify-content-between">
              <Texty
                mode={"smooth"}
                style={{ fontWeight: "400", fontSize: 15 }}
              >
                Shipping Fee:
              </Texty>
              <span>${billDetail ? billDetail.total_shipping_cost : 0}</span>
            </Row>
            <Row className="w-50 d-flex justify-content-between">
              <Texty
                mode={"smooth"}
                style={{ fontWeight: "400", fontSize: 15 }}
              >
                Tax:
              </Texty>
              <span>${billDetail ? billDetail.tax : 0}</span>
            </Row>
            <Row className="w-50 d-flex justify-content-between">
              <Texty
                mode={"smooth"}
                style={{ fontWeight: "400", fontSize: 15 }}
              >
                Discount:
              </Texty>
              <span>{billDetail ? billDetail.discount : 0}%</span>
            </Row>
            <hr />
            <Row className="w-50 d-flex justify-content-between">
              <Texty
                mode={"smooth"}
                style={{ fontWeight: "400", fontSize: 15 }}
              >
                Total:
              </Texty>
              <span>${billDetail ? billDetail.total : 0}</span>
            </Row>
          </div>
        </div>
      )}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    cart: state.cart.cart,
  };
};

export default connect(mapStateToProps)(index);
