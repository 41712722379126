import React, { useEffect } from "react";
import { useState } from "react";
import "../Screens/EditProfile/index.scss";
import apiClient from "../Api Wrapper/Api";
import { connect } from "react-redux";
import { Spin } from "antd";
import { currentUser } from "../LocalForage/localForage";

function ProfileEdit(props) {
  const [username, setUsername] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [url, setUrl] = useState("");
  const [bio, setBio] = useState("");
  const [loading, setLoading] = useState(true);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    apiClient
      .get("users/get/profile")
      .then((response) => {
        if (response && response.status === 200) {
          setUsername(response.data.username);
          setDisplayName(response.data.display_name);
          setEmail(response.data.email);
          setUrl(response.data.website_url);
          setBio(response.data.bio);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  const handleClick = () => {
    apiClient
      .put(`users/updateProfile?uid=${props.user.userID}`, {
        email: email,
        username: username,
        display_name: displayName,
        website_url: url,
        bio: bio,
      })
      .then(async (response) => {
        if (response && response.status === 200) {
          props.updateUserName({
            displayName: response.data.display_name,
            email: response.data.email,
            userID: props.user.userID,
            username: response.data.username,
            profilePicture: response.data.profilePicture,
            ecommerce_user_id: response.data.ecommerce_user_id,
          });
          props.updateUserProfile(response.data);
          await currentUser.setItem("@currentUser", {
            displayName: response.data.display_name,
            email: response.data.email,
            userID: props.user.userID,
            username: response.data.username,
            profilePicture: response.data.profilePicture,
            ecommerce_user_id: response.data.ecommerce_user_id,
          });
          setShowSuccessMessage(true);
          props.router.push({
            pathname: `/edit-profile/${response.data.username}`,
          });
        }
      })
      .catch((error) => console.log(error));
  };
  return (
    <div className="mt-5">
      {loading ? (
        <Spin
          style={{
            width: "100%",
            padding: 50,
          }}
        />
      ) : (
        <form className="sm-widht-90 sm-align-center">
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="inputEmail4">User Name</label>
              <input
                value={username}
                type="text"
                className="form-control round-form"
                id="inputEmail4"
                placeholder=""
                onChange={(e) => setUsername(e.target.value)}
              />
              <p className="helptext">
                Changing username will change the Public URL
              </p>
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="inputEmail4">Display Name</label>
              <input
                value={displayName}
                type="text"
                className="form-control round-form"
                id="inputEmail4"
                placeholder=""
                onChange={(e) => setDisplayName(e.target.value)}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="inputEmail4">Email</label>
              <input
                value={email}
                type="email"
                className="form-control round-form"
                id="inputEmail4"
                placeholder=""
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="inputPassword4">Website URL</label>
              <input
                value={url}
                type="text"
                className="form-control round-form"
                id="inputPassword4"
                placeholder=""
                onChange={(e) => setUrl(e.target.value)}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="exampleFormControlTextarea1">
              Bio <span className="helptext">(max 200 chracters)</span>
            </label>

            <textarea
              value={bio}
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              maxLength={200}
              onChange={(e) => setBio(e.target.value)}
            ></textarea>
          </div>
          {showSuccessMessage && (
            <div
              style={{
                color: "green",
                display: "flex",
                justifyContent: "center",
                marginBottom: "15px",
              }}
            >
              We've successfully updated your profile!
            </div>
          )}
          <button
            style={{ width: 260, marginBottom: "10px" }}
            type="submit"
            className="btn btn-primary center-block"
            onClick={() => handleClick()}
          >
            Update Profile
          </button>
        </form>
      )}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};
// const mapDispatchToProps = {
//   updateUserData,
// };

export default connect(mapStateToProps, null)(ProfileEdit);
