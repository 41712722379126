import React, { useState } from "react";
import {
  faSearch,
  faThumbsUp,
  faComment,
  faEye,
  faShare,
  faRoad,
} from "@fortawesome/free-solid-svg-icons";
import {
  Avatar,
  Tooltip,
  Modal,
  message,
  List,
  Input,
  Button,
  Comment,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import "./MainCSS.css";
import { BASE_URL_IMAGE } from "../constants/URL";
import CopyToClipboard from "react-copy-to-clipboard";
import { connect } from "react-redux";
import errors from "../constants/FormErrors";
import Api from "../Api Wrapper/Api";
import { token } from "../LocalForage/localForage";
const { TextArea } = Input;

function ExploreCard(props) {
  const ext = props.image.split(".", 2);
  const [isShareModal, setIsShareModal] = useState(false);
  const [commentModal, setCommentModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const imgPath = process.env.PUBLIC_URL + "/images/";
  const [value, setValue] = useState("");
  const [comments, setComments] = useState(props.comments);
  const [likesCounter, setLikesCounter] = useState(props.likeCount);

  const IconAndCount = (props) => {
    return (
      <div onClick={() => props.onClick && props.onClick()}>
        <FontAwesomeIcon
          icon={props.icon}
          className="lg-fs-16 sm-fs-11 mr-1"
          style={{ color: "gray" }}
        />
        {props.count && (
          <span className="p-1 lg-fs-16 sm-fs-11">{props.count}</span>
        )}
      </div>
    );
  };
  const CommentList = ({ comments }) => (
    <List
      dataSource={comments}
      itemLayout="horizontal"
      renderItem={(props) => {
        return (
          <Comment
            author={props.commenter}
            content={props.text}
            avatar={
              props.User.profilePicture
                ? BASE_URL_IMAGE + props.User.profilePicture
                : `${imgPath}empty-profile-image.jpg`
            }
            datetime={moment(props.createdAt).fromNow()}
          />
        );
      }}
    />
  );
  const unAuthorizedUser = () => {
    if (!props.isAuthenticated) {
      message.error(errors.unAuthorizedUserCheck);
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = () => {
    if (unAuthorizedUser()) {
      if (!value) {
        return;
      }
      setSubmitting(true);
      Api.post(`/comment/add`, {
        image_id: props.image_id,
        commenter: props.commentUser,
        text: value,
      })
        .then((res) => {
          console.log(res.data);
          if (res.status === 200) {
            setComments(res.data.Comments);
            setValue("");
            setSubmitting(false);
          } else {
            message.error(
              "Error posting your comment, please try again later."
            );
          }
        })
        .catch((err) => {
          message.error("Error posting your comment, please try again later.");
          setSubmitting(false);
          console.log(err);
        });
    }
  };
  const createLike = async () => {
    if (unAuthorizedUser()) {
      Api.post(
        `/like/add`,
        {
          uid: props.postUserID,
          image_id: props.image_id,
        },
        { headers: { token: await token.getItem("@token") } }
      )
        .then((response) => {
          if (response.status === 200) {
            if (response.data === "RemovedLike") {
              message.error("Removed your like, click again to like this post");
              setLikesCounter(likesCounter - 1);
            } else {
              setLikesCounter(likesCounter + 1);
            }
          }
        })
        .catch((err) => {
          message.error("Error occured, please try again later.");
          setSubmitting(false);
          console.log(err);
        });
    }
  };
  return (
    <div className="card d-inline-block mt-4 mb-4 zoom explore-post-card">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            marginRight: "10px",
            display: "flex",
          }}
        >
          {props.profilePicture ? (
            <div>
              <img
                src={BASE_URL_IMAGE + props.profilePicture}
                className="sm-w-35 sm-h-35 sm-br-17 lg-w-50 lg-h-50 lg-br-25"
              />
            </div>
          ) : (
            <div>
              <Avatar
                size={window.screen.width > 768 ? 50 : 35}
                icon={<UserOutlined />}
              />
            </div>
          )}
          <div style={{ marginLeft: "10px", textAlign: "left" }}>
            <p style={{ margin: 0 }} className="lg-fs-16 sm-fs-13">
              {props.userName ? props.userName : ""}
            </p>
            <p style={{ margin: 0 }} className="gray-color lg-fs-12 sm-fs-9">
              {moment(props.timeStamp).fromNow()}
            </p>
          </div>
        </div>
        <div
          className="my-card-image"
          onClick={() => props.onClick()}
          data-content={props.photoName}
        >
          {ext[1] === "png" || ext[1] === "jpeg" || ext[1] === "jpg" ? (
            <>
              <img
                className="explore-card-img-top"
                src={BASE_URL_IMAGE + props.image}
                alt="Card cap"
              />
              <div className="post-banner">
                <Tooltip title="Frostie VR">
                  <img
                    alt=""
                    src="/logo192.png"
                    style={{
                      backgroundColor: "transparent",
                      padding: "5px",
                      width: window.screen.width > 768 ? "100px" : "40px",
                      fontSize: "15px",
                      borderTopRightRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      borderBottomLeftRadius: "4px",
                    }}
                  />
                </Tooltip>
              </div>
            </>
          ) : (
            <>
              <video
                className="explore-card-img-top small-screen-width"
                src={BASE_URL_IMAGE + props.image}
                alt="Card cap"
              />
              <div className="post-banner">
                <Tooltip title="Frostie VR">
                  <img
                    alt=""
                    src="/logo192.png"
                    style={{
                      backgroundColor: "transparent",
                      padding: "5px",
                      width: window.screen.width > 768 ? "100px" : "40px",
                      fontSize: "15px",
                      borderTopRightRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      borderBottomLeftRadius: "4px",
                    }}
                  />
                </Tooltip>
              </div>
            </>
          )}
        </div>
        <div className="card-body" className="icon-panel">
          <div
            style={{
              width: "85%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            className="d-flex justify-content-between"
          >
            <IconAndCount
              icon={faThumbsUp}
              count={likesCounter}
              onClick={() => {
                if (props.logedInUser !== props.postUserID) {
                  createLike();
                }
              }}
            />
            <IconAndCount
              icon={faComment}
              count={comments.length}
              onClick={() => setCommentModal(true)}
            />
            <IconAndCount icon={faEye} count={props.viewCount} />
            <IconAndCount
              icon={faShare}
              onClick={() => setIsShareModal(true)}
            />
            {/* Comment Modal */}
            <Modal
              visible={commentModal}
              title="Add Comment"
              footer={null}
              onOk={() => setCommentModal(false)}
              onCancel={() => setCommentModal(false)}
            >
              {comments.length > 0 && <CommentList comments={comments} />}
              <Comment
                content={
                  <div>
                    <TextArea
                      rows={4}
                      onChange={(event) => setValue(event.target.value)}
                      value={value}
                    />
                    <Button
                      htmlType="submit"
                      className="mt-2"
                      loading={submitting}
                      onClick={() => handleSubmit()}
                      type="primary"
                    >
                      Add Comment
                    </Button>
                  </div>
                }
              />
            </Modal>

            {/* Share Modal */}
            <Modal
              visible={isShareModal}
              title="Share Link"
              onOk={() => setIsShareModal(false)}
              onCancel={() => setIsShareModal(false)}
              footer={null}
            >
              <div>
                <p>Please use the following image URL to share:</p>
                <CopyToClipboard
                  text={props.shareUrl}
                  onCopy={() => message.success("Copied to clipboard!!")}
                >
                  <p style={{ color: "#4a90e2", cursor: "pointer" }}>
                    {props.shareUrl}
                  </p>
                </CopyToClipboard>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps, null)(ExploreCard);
