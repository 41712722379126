export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

const requestLogin = () => ({
  type: LOGIN_REQUEST,
  isFetching: true,
  isAuthenticated: false,
  token: "",
  user: {},
  error: "",
});

const receiveLogin = (token, user) => {
  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    token: token,
    user: user,
    error: "",
  };
};

const errorLogin = (error) => ({
  type: LOGIN_FAILURE,
  isFetching: false,
  isAuthenticated: false,
  token: "",
  user: {},
  error,
});

export const login = (token, name) => (dispatch) => {
  dispatch(receiveLogin(token, name));
};
export const loginError = (error) => (dispatch) => {
  dispatch(errorLogin(error));
};
