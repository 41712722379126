import React, { Component } from "react";
import AppNav from "../../../components/appNavBar";
import Footer from "../../../components/footer";
import SideBar from "../../../components/sideBar";
import { connect } from "react-redux";
import jwt_decode from "jwt-decode";

import { loginError, login } from "../../../js/actions/auth/login";
import { token, currentUser } from "../../../LocalForage/localForage";
import apiClient from "../../../Api Wrapper/Api";

class App extends Component {
  async componentDidMount() {
    const userToken = await token.getItem("@token");
    const user = await currentUser.getItem("@currentUser");
    if (userToken) {
      var decodedToken = jwt_decode(userToken);
      var dateNow = new Date();
      if (decodedToken.exp * 1000 < dateNow.getTime()) {
        await token.setItem("@token", "");
        await currentUser.setItem("@currentUser", "");
        this.props.loginError("authentication failed");
      } else {
        apiClient.setHeader("token", userToken);
        this.props.login(userToken, user);
      }
    } else {
      this.props.loginError("authentication failed");
    }
  }
  render() {
    return (
      <div>
        <AppNav {...this.props} />
        <SideBar {...this.props} />
        <div
          onClick={() => {
            document.getElementById("mySidenav").style.width = "0";
          }}
        >
          {this.props.children}
        </div>
        <Footer {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    token: state.auth.token,
  };
};
const mapDispatchToProps = {
  loginError,
  login,
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
