import React, { useEffect, useState, useReducer } from "react";
import "./index.scss";
import HomeProfile from "../../CustomComponents/HomeProfile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import Activity from "../../components/Activity";
import { connect } from "react-redux";
import apiClient from "../../Api Wrapper/Api";
import { getUserFollows } from "../../js/actions/Posts/posts.actions";
import { Col, Row } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import { BASE_URL_IMAGE } from "../../constants/URL";
import Zoom from "react-reveal/Zoom";
import Slide from "react-reveal/Slide";
import Roll from "react-reveal/Roll";

function Home(props) {
  const [click, setClick] = useState(false);
  const [whoToFollow, setWhoToFollow] = useState([]);
  const [activity, setActivity] = useState([]);

  const LoadAcitivty = () => {
    apiClient
      .get(`/comment/activity?uid=${props.user.userID}`)
      .then((response) => {
        if (response && response.status === 200) {
          setActivity(response.data);
        } else {
        }
      });
  };

  const LoadWhoToFollow = () => {
    apiClient
      .get(`/follower/whoToFollow?uid=${props.user.userID}`)
      .then((response) => {
        if (response && response.status === 200) {
          setWhoToFollow(response.data);
        } else {
        }
      });
  };

  useEffect(() => {
    LoadAcitivty();
    LoadWhoToFollow();
  }, []);

  const WhoToFollow = ({ user }) => (
    <div className="activity_container">
      <div>
        {user.profilePicture ? (
          <img
            className="activity_image"
            alt=""
            src={BASE_URL_IMAGE + user.profilePicture}
            onClick={() =>
              props.router.push(`/profile/photos/${user.username}`)
            }
          />
        ) : (
          <Avatar className="activity_image" size={50}>
            {user.display_name.charAt(0)}
          </Avatar>
        )}
      </div>
      <div className="activity_innerConatiner">
        <p className="m-0" style={{ fontSize: 15 }}>
          {user.display_name}
        </p>
        <button
          className="follow-button"
          onClick={() =>
            apiClient
              .post(`/follower/followed`, {
                uid: props.user.userID,
                followed: user.id,
              })
              .then((response) => {
                LoadWhoToFollow();
                props.getUserFollows(props.user.userID);
                apiClient
                  .post(`/notification/add?uid=${user.id}`, {
                    action: "followed",
                  })
                  .then((res) => {
                    console.log(res);
                  });
              })
              .catch((error) => console.log("Error during following " + error))
          }
        >
          + Follow
        </button>
      </div>
    </div>
  );
  return (
    <Row className="home-container">
      <Col className="home-profile" span={6}>
        <Roll left>
          <HomeProfile
            userType={props.userProfile ? props.userProfile.userType : ""}
            displayName={
              props.userPhotos.length === 0
                ? undefined
                : props.userPhotos.display_name
            }
            name={
              props.userPhotos.length === 0
                ? undefined
                : props.userPhotos.username
            }
            views={
              props.userPhotos.length === 0 ? 0 : props.userPhotos.profileViews
            }
            photos={
              props.userPhotos.length === 0
                ? 0
                : props.userPhotos.Posts.length + props.userPhotos.Tours.length
            }
            followers={
              props.userFollowers.length === 0
                ? 0
                : props.userFollowers.user_followed_by.length
            }
            following={
              props.userFollows.length === 0
                ? 0
                : props.userFollows.user_followed.length
            }
            image={props.userProfile ? props.userProfile.profilePicture : ""}
            setClick={setClick}
            router={props.router}
            isVisitor={props.isVisitor}
            userID={props.user.userID}
          />
        </Roll>
        {!props.isVisitor && activity.length > 0 && (
          <Slide bottom>
            <Activity className="home-profile" activity={activity} />
          </Slide>
        )}
        {props.isVisitor && activity.length > 0 && (
          <Slide bottom>
            <Activity className="home-profile" activity={activity} />
          </Slide>
        )}
      </Col>
      <div className="home-profile-none">
        <HomeProfile
          mobView={true}
          displayName={
            props.userPhotos.length === 0
              ? undefined
              : props.userPhotos.display_name
          }
          name={
            props.userPhotos.length === 0
              ? undefined
              : props.userPhotos.username
          }
          views={
            props.userPhotos.length === 0 ? 0 : props.userPhotos.profileViews
          }
          photos={
            props.userPhotos.length === 0
              ? 0
              : props.userPhotos.Posts.length + props.userPhotos.Tours.length
          }
          image={props.userProfile ? props.userProfile.profilePicture : ""}
          setClick={setClick}
          router={props.router}
          isVisitor={props.isVisitor}
          userID={props.user.userID}
        />
      </div>
      <div className="timeline">{props.children}</div>
      <Col className="home-profile" span={6}>
        <Slide right>
          {!props.isVisitor && (
            <>
              <div className="ml-5 d-flex flex-column">
                {props.userProfile && (
                  <div className="userinfo_card_shadow">
                    {props.timeline ? (
                      <FontAwesomeIcon
                        icon={faPen}
                        style={{
                          position: "absolute",
                          top: "13px",
                          right: "16px",
                        }}
                        onClick={() =>
                          props.router.push(
                            `/edit-profile/${props.user.username}`
                          )
                        }
                      />
                    ) : undefined}
                    {props.userProfile.username && (
                      <div className="mt-3 text_container">
                        <p className="  font-weight-bold">Username:</p>
                        <p
                          style={{
                            marginLeft: 6,
                            fontSize: "17px",
                            marginTop: "-10px",
                          }}
                        >
                          {props.userProfile.username}
                        </p>
                      </div>
                    )}{" "}
                    {props.userProfile.email && (
                      <div className="text_container">
                        <p className="  font-weight-bold">Email:</p>
                        <p
                          style={{
                            marginLeft: 6,
                            fontSize: "17px",
                            marginTop: "-10px",
                          }}
                        >
                          {props.userProfile.email}
                        </p>
                      </div>
                    )}
                    {props.userProfile.bio && (
                      <div className="text_container">
                        <p className="  font-weight-bold">Bio:</p>
                        <p
                          style={{
                            marginLeft: 6,
                            fontSize: "17px",
                            marginTop: "-10px",
                          }}
                        >
                          {props.userProfile.bio}
                        </p>
                      </div>
                    )}
                    {props.userProfile.website_url && (
                      <div className="text_container">
                        <p className="  font-weight-bold">Website Url:</p>
                        <p
                          style={{
                            marginLeft: 6,
                            fontSize: "17px",
                            marginTop: "-10px",
                          }}
                        >
                          {props.userProfile.website_url}
                        </p>
                      </div>
                    )}
                  </div>
                )}
                {whoToFollow.length > 0 ? (
                  <>
                    <div style={{}}>
                      <p className="font-weight-bold">Who To Follow</p>
                    </div>

                    {whoToFollow.length > 0 &&
                      whoToFollow.map((user, index) => (
                        <WhoToFollow key={user.id} user={user} />
                      ))}
                  </>
                ) : undefined}
              </div>
            </>
          )}
        </Slide>
      </Col>
    </Row>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    isVisitor: state.posts.isVisitor,
    userPhotos: state.posts.isVisitor
      ? state.followerProfile.userPhotos
      : state.posts.userPhotos,
    userFollowers: state.posts.isVisitor
      ? state.followerProfile.userFollowers
      : state.posts.userFollowers,
    userFollows: state.posts.isVisitor
      ? state.followerProfile.userFollows
      : state.posts.userFollows,
    isVisitor: state.posts.isVisitor,
    userProfile: state.auth.userProfile,
  };
};
const mapDispatchToProps = {
  getUserFollows,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
