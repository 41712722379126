export const applyFilters = (name, intensity = 50) => {
  switch (name) {
    case "NONE":
      return {};
      break;
    case "COOLER":
      const sepia = intensity * 0.5;
      const saturate = intensity * 1.5;
      return {
        filter: `hue-rotate(180deg) sepia(${sepia}%) contrast(100%) saturate(${saturate}%) hue-rotate(180deg)`,
      };
      break;
    case "BLOOM":
      let brightness = (2 * (intensity + 100)) / 5;
      let saturation = 3 * intensity;
      return { filter: `brightness(${brightness}%) saturate(${saturation}%)` };
      break;
    case "GREYSCALE":
      return { filter: `grayscale(${intensity}%)` };
      break;
    case "BLUR":
      let blur = intensity / 10;

      return { filter: `blur(${blur}px)` };
      break;
    case "WATER":
      let effect = intensity / 400;
      return {
        filter: `brightness(1.3) invert(0.17) saturate(2.6) sepia(${effect}) url('#squiggly-1')`,
      };
      break;

    default:
      return {};
      break;
  }
};

// mixBlendMode: "luminosity",
// filter: "contrast(1.25)",
// WebkitFilter: "contrast(1.25)",
// overflow: "hidden",
// , //greyscale filter effect
// filter: , //bloom filter
// filter:
//
