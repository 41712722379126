import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faTelegram,
} from "@fortawesome/free-brands-svg-icons";
import "./MainCSS.css";
import { render } from "@testing-library/react";
import { useState } from "react";
import { connect } from "react-redux";
import { token, currentUser } from "../LocalForage/localForage";

import { logout } from "../js/actions/auth/logout";

function closeNav() {
  document.getElementById("mySidenav").style.width = "0";
  // document.getElementById("main").style.marginLeft = "0";
  // document.body.style.backgroundColor = "white";
}

function SideBar(props) {
  return (
    <div id="mySidenav" className="sidenav">
      <a
        className="side-link text-uppercase font-weight-bold closebtn"
        onClick={closeNav}
      >
        <FontAwesomeIcon icon={faTimes} />
        <span className="ml-2 orange-color">Close Menu</span>
      </a>
      {props.isAuthenticated && (
        <>
          <div
            onClick={() => {
              props.router.replace("/home");
              closeNav();
            }}
          >
            <a className="side-link">
              <i
                className="fa fa-fw fa-home d-inline"
                style={{ fontSize: "20px" }}
              />
              <span className="ml-2 text-uppercase font-weight-bold">Feed</span>
            </a>
          </div>
          <div>
            <a className="side-link">
              <i
                className="fa fa-fw fa-bell d-inline"
                style={{ fontSize: "20px" }}
              />
              <span className="ml-2 text-uppercase font-weight-bold">
                Notification
              </span>
            </a>
          </div>

          <div>
            <a style={{ cursor: "default" }} className="side-link">
              <i
                className="fa fa-fw fa-upload d-inline"
                style={{ fontSize: "20px" }}
              />
              <span className="ml-2 text-uppercase font-weight-bold">
                Upload
              </span>
            </a>
            <ul>
              <li
                className="inner-list-text"
                onClick={() => {
                  props.router.replace("/create-tour");
                  closeNav();
                }}
              >
                Create Tour
              </li>
              <li
                className="pt-0 inner-list-text"
                onClick={() => {
                  props.router.replace("/single-image/post");
                  closeNav();
                }}
              >
                Create Image
              </li>
            </ul>
          </div>
          <div>
            <a
              onClick={() => {
                props.router.replace(`/profile/photos/${props.user.username}`);
                closeNav();
              }}
              className="side-link"
            >
              <i
                className="fa fa-fw fa-user-circle d-inline"
                style={{ fontSize: "20px" }}
              />
              <span className="ml-2 text-uppercase font-weight-bold">
                Profile
              </span>
            </a>

            <ul className="list-unstyled">
              <li
                onClick={() => {
                  props.router.replace(
                    `/profile/photos/${props.user.username}`
                  );
                  closeNav();
                }}
                className="inner-list-text"
              >
                My Posts
              </li>
              {/* <li
                onClick={() => {
                  props.router.replace(`/profile/tours/${props.user.username}`);
                  closeNav();
                }}
                className="inner-list-text pt-0"
              >
                My Tours
              </li> */}
              <li
                onClick={() => {
                  props.router.replace(
                    `/profile/LikedPhotos/${props.user.username}`
                  );
                  closeNav();
                }}
                className="inner-list-text pt-0"
              >
                Liked Posts
              </li>
              <li
                onClick={() => {
                  props.router.replace(
                    `/profile/BoughtImages/${props.user.username}`
                  );
                  closeNav();
                }}
                className="inner-list-text pt-0"
              >
                Bought Images
              </li>
              <li
                onClick={() => {
                  props.router.replace(
                    `/profile/BoughtTours/${props.user.username}`
                  );
                  closeNav();
                }}
                className="inner-list-text pt-0"
              >
                Bought Tours
              </li>
            </ul>
          </div>
          <div className="display-none-in-large-display">
            <a
              className="side-link"
              onClick={() => {
                props.router.push("/cart");
                closeNav();
              }}
            >
              <i
                className="fa fa-fw fa-shopping-cart d-inline"
                style={{ fontSize: "20px" }}
              />
              <span className="ml-2 text-uppercase font-weight-bold">Cart</span>
            </a>
          </div>
          <div
            onClick={() => {
              props.router.replace(`/edit-profile/${props.user.username}`);
              closeNav();
            }}
          >
            <a className="side-link">
              <i
                className="fa fa-fw fa-cog d-inline"
                style={{ fontSize: "20px" }}
              />
              <span className="ml-2 text-uppercase font-weight-bold">
                Setting
              </span>
            </a>
          </div>
        </>
      )}

      <div>
        <a className="side-link">
          <i
            className="fa fa-fw fa-bookmark d-inline"
            style={{ fontSize: "20px" }}
          />
          <span
            onClick={() => {
              props.router.replace("/explore/photos");
              closeNav();
            }}
            className="ml-2 text-uppercase font-weight-bold"
          >
            Explore
          </span>
        </a>

        <ul className="list-unstyled">
          <li
            className="inner-list-text"
            onClick={() => {
              props.router.replace("/explore/photos");
              closeNav();
            }}
          >
            Featured Photos
          </li>
          <li
            onClick={() => {
              props.router.replace("/explore/tour");
              closeNav();
            }}
            className="inner-list-text pt-0"
          >
            Featured Tours
          </li>
          <li
            className="inner-list-text pt-0"
            onClick={() => {
              props.router.replace("/explore/likes");
              closeNav();
            }}
          >
            Most Liked
          </li>
          {/* <li className="inner-list-text pt-0">Collections</li> */}
        </ul>
      </div>

      <div>
        <a className="side-link">
          <i
            className="fa fa-fw fa-exclamation-circle d-inline"
            style={{ fontSize: "20px" }}
          />
          <span
            onClick={() => {
              props.router.replace("/");
              closeNav();
            }}
            className="ml-2 text-uppercase font-weight-bold"
          >
            About
          </span>
        </a>

        <ul className="list-unstyled">
          <li
            className="inner-list-text"
            style={{ cursor: "pointer" }}
            onClick={() => {
              props.router.push(`/froastie-vr-pro`);
              closeNav();
            }}
          >
            Frostie <span className="badge badge-warning">PRO</span>
          </li>
          <li
            style={{ cursor: "pointer" }}
            onClick={() => {
              props.router.push(`/pricing`);
              closeNav();
            }}
            className="inner-list-text pt-0"
          >
            Pricing
          </li>
          <li
            style={{ cursor: "pointer" }}
            onClick={() => {
              props.router.push(`/blog`);
              closeNav();
            }}
            className="inner-list-text pt-0"
          >
            Blog
          </li>
        </ul>
      </div>
      <a
        className="side-link"
        onClick={() => {
          props.router.push(`/support`);
          closeNav();
        }}
      >
        <i
          className="fa fa-fw fa-heart d-inline"
          style={{ fontSize: "20px" }}
        />
        <span className="ml-2 text-uppercase font-weight-bold">Support</span>
      </a>
      {props.isAuthenticated && (
        <div>
          <a className="side-link">
            <i
              className="fa fa-fw fa-edit d-inline"
              style={{ fontSize: "20px" }}
            />
            <span
              onClick={async () => {
                await token.setItem("@token", "");
                await currentUser.setItem("@currentUser", "");
                props.logout();
                closeNav();
              }}
              className="ml-2 text-uppercase font-weight-bold"
            >
              SIGN OUT
            </span>
          </a>
        </div>
      )}
      {!props.isAuthenticated && (
        <div style={{ marginLeft: -20 }} className="text-center">
          <div className="mt-4">
            <button
              onClick={() => {
                props.router.replace("/register");
                closeNav();
              }}
              className="orange-button"
            >
              <span style={{ color: "#fff" }}>REGISTER</span>
            </button>
            <br />
            <button
              style={{ marginTop: 10 }}
              onClick={() => {
                props.router.replace("/login");
                closeNav();
              }}
              className="orange-button"
            >
              <span style={{ color: "#fff" }}>SIGN-IN</span>
            </button>
          </div>
        </div>
      )}
      <div className="w-100 text-center mb-3 mt-4">
        <img
          alt="logo"
          src="/logo192.png"
          style={{
            width: 200,
            height: 50,
          }}
        />
      </div>
      <div className="w-100 text-center" style={{ marginLeft: -20 }}>
        <a className="brand-links ml-3" href="">
          <FontAwesomeIcon icon={faFacebookF} />
        </a>
        <a className="brand-links ml-3" href="">
          <FontAwesomeIcon icon={faTwitter} />
        </a>
        <a className="brand-links ml-3" href="">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a className="brand-links ml-3" href="">
          <FontAwesomeIcon icon={faTelegram} />
        </a>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

const mapDispatchToProps = {
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
