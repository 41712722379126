export const SET_IMAGES = "SET_IMAGES";
export const DELETE_IMAGE = "DELETE_IMAGE";
export const RESET_IMAGES = "RESET_IMAGES";
export const SET_NAME = "SET_NAME";
export const SET_DESC = "SET_DESC";
export const SET_TOUR = "SET_TOUR";

export const setImages = (image) => (dispatch) => {
  dispatch({
    type: SET_IMAGES,
    image,
  });
};
export const deleteImage = (image) => (dispatch) => {
  dispatch({
    type: DELETE_IMAGE,
    image,
  });
};
export const resetImages = () => (dispatch) => {
  dispatch({
    type: RESET_IMAGES,
  });
};
export const setName = (name) => (dispatch) => {
  dispatch({
    type: SET_NAME,
    name,
  });
};
export const setDesc = (desc) => (dispatch) => {
  dispatch({
    type: SET_DESC,
    desc,
  });
};
export const setTour = (tour) => (dispatch) => {
  dispatch({
    type: SET_TOUR,
    tour,
  });
};
