import React from "react";
import Post from "../../../CustomComponents/Post";
import "./index.scss";
import QueueAnim from "rc-queue-anim";
import moment from "moment";
import Texty from "rc-texty";
import { connect } from "react-redux";
import { Empty, Spin, Button } from "antd";
import { BASE_URL_WEBSITE } from "../../../constants/URL";

function TabPhotosILike(props) {
  // console.log(props.posts);
  return (
    <div className="main-container">
      {!props.isVisitor && (
        <Texty
          type="left"
          mode={"smooth"}
          style={{
            fontSize: 30,
            fontWeight: "bold",
            marginTop: 30,
            textAlign: "center",
          }}
        >
          LIKED POSTS
        </Texty>
      )}
      <QueueAnim
        style={{
          display: "flex",
          // width: "100%",

          flexGrow: "3",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
        className="queue-simple"
      >
        {!props.posts ? (
          <Spin
            style={{
              width: "100%",
              padding: 50,
            }}
          />
        ) : (
          <React.Fragment>
            {props.posts.length > 0 || props.tours.length > 0 ? (
              <>
                {props.posts.map((item) =>
                  item.Posts.map((post) => (
                    <div className="lg-post-w sm-post-w mt-2">
                      <Post
                        key={post.id}
                        id={post.id}
                        postID={item.id}
                        postName={post.description}
                        commentUser={props.displayName}
                        postUserID={post.uid}
                        userID={props.userID}
                        numberOfComments={post.Image.Comments.length}
                        Comments={post.Image.Comments}
                        userName={item.displayName}
                        numberOfLikes={post.Image.Likes.length}
                        Likes={post.Image.Likes}
                        numberOfView={post.Image.Views.length}
                        time={moment(post.createdAt).fromNow()}
                        handleClick={() =>
                          props.router.push({
                            pathname: `/post-view/id-${post.id}`,
                          })
                        }
                        profile={item.profilePicture}
                        isTour={false}
                        userName={item.display_name}
                        image={post.Image.image}
                        isPurchaseable={post.Image.isPurchaseable}
                        isFeatured={post.featuredTill === "0" ? false : true}
                        isLiked={true}
                        LikedUserProfPic={true}
                        shareUrl={BASE_URL_WEBSITE + `/post-view/id-${post.id}`}
                      />
                    </div>
                  ))
                )}
                {props.tours &&
                  props.tours.map((item) =>
                    item.Posts.map((post) => (
                      <div className="lg-post-w sm-post-w mt-2">
                        <Post
                          key={post.id}
                          id={post.id}
                          postID={item.id}
                          postName={post.description}
                          userID={props.userID}
                          numberOfComments={
                            post.Tour.Tour_images[0].Comments.length
                          }
                          Comments={post.Tour.Tour_images[0].Comments}
                          commentUser={props.displayName}
                          numberOfLikes={post.Tour.Tour_images[0].Likes.length}
                          Likes={post.Tour.Tour_images[0].Likes}
                          numberOfView={post.Tour.Tour_images[0].Views.length}
                          userName={props.displayName}
                          time={moment(post.createdAt).fromNow()}
                          handleClick={() =>
                            props.router.push({
                              pathname: `/tour-view/tid-${post.id}/${post.uid}`,
                            })
                          }
                          profile={item.profilePicture}
                          isTour={true}
                          userName={item.display_name}
                          image={post.Tour.Tour_images[0].image}
                          isPurchaseable={post.Tour.isPurchaseable}
                          isFeatured={post.featuredTill === "0" ? false : true}
                          isLiked={true}
                          LikedUserProfPic={true}
                        />
                      </div>
                    ))
                  )}
              </>
            ) : (
              <React.Fragment>
                {props.username === props.routeParams.name ? (
                  <div
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <Empty
                      image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                      imageStyle={{
                        height: 60,
                      }}
                      description={
                        <span>There are no photos that you have liked</span>
                      }
                    >
                      <Button
                        type="primary"
                        onClick={() => props.router.push("/explore/photos")}
                      >
                        Post Now
                      </Button>
                    </Empty>
                  </div>
                ) : (
                  <div
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <Empty
                      image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                      imageStyle={{
                        height: 60,
                      }}
                      description={<span>This User Has No Liked Posts</span>}
                    ></Empty>
                  </div>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </QueueAnim>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userID: state.auth.user.userID,
    username: state.auth.user.username,
    isVisitor: state.posts.isVisitor,
  };
};

export default connect(mapStateToProps)(TabPhotosILike);
