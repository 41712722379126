import React, { useEffect, useState } from "react";
import {
  StarOutlined,
  HeartOutlined,
  ShareAltOutlined,
  FolderAddOutlined,
  HeartFilled,
  CreditCardOutlined,
  UserAddOutlined,
  DownOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,
  faVrCardboard,
  faBinoculars,
  faExpand,
  faGlobeAsia,
  faCompass,
  faCircle,
  faImage,
  faVolumeMute,
  faVolumeUp,
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { TextureLoader, Vector3 } from "three";

import moment from "moment";
import CopyToClipboard from "react-copy-to-clipboard";

import VideoPanorama from "../../Panolen/panorama/VideoPanorama";

import { applyFilters } from "../../Helpers/filters";
import {
  Comment,
  Avatar,
  Form,
  Button,
  List,
  Input,
  Spin,
  message,
  Select,
  InputNumber,
  Radio,
  Carousel,
  Row,
  Col,
  Slider,
  Menu,
  Dropdown,
  Popconfirm,
  Tooltip,
} from "antd";

const { TextArea } = Input;
import { token } from "../../LocalForage/localForage";

import "./index.scss";
import apiClient from "../../Api Wrapper/Api";
import ProductApi from "../../Api Wrapper/ProductApi";
import { BASE_URL_IMAGE } from "../../constants/URL";
import errors from "../../constants/FormErrors";
import Viewer from "../../Panolen/viewer/Viewer";
import ImagePanorama from "../../Panolen/panorama/ImagePanorama";
import Infospot from "../../Panolen/infospot/Infospot";
import Modal from "antd/lib/modal/Modal";
import LittlePlanet from "../../Panolen/panorama/ImageLittlePlanet";
import { BASE_URL_WEBSITE } from "../../constants/URL";
import { CONTROLS, MODES } from "../../Panolen/Constants";
import productImage from "../../Images/product.jpg";
import productImage1 from "../../Images/product1.jpg";
import { addToCart } from "../../js/actions/cart/cart";
import { faMendeley } from "@fortawesome/free-brands-svg-icons";

class PostView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      value: "",
      submitting: false,
      post: "",
      current: "",
      loading: true,
      isLiked: false,
      likesCounter: 0,
      viewsCounter: 0,
      isFollowed: false,
      isTour: false,
      isFeatured: false,
      isShareModal: false,
      loadingPanaroma: true,
      allScenes: [],
      mode: "photo",
      numberOfMonths: 1,
      cardNum: "4242424242424242",
      expDateMonth: 2,
      expDateYear: 2022,
      cvc: 314,
      currentMode: MODES.NORMAL,
      currentControl: CONTROLS.ORBIT,
      showProductModal: false,
      narratorAudioLevel: 1,
      backgroundMusicLevel: 1,
      productDescription: "",
      productName: "",
      productPrice: 0,
      productQuantity: 0,
      isCollapse: true,
      imgPath: `${process.env.PUBLIC_URL}/images/`,
      isMuteAdded: false,
      isFullscreen: false,
      isFirstAudioMuted: false,
      isSecondAudioMuted: false,
      isBought: false,
      Show_Public_Url: false,
      featurePaymentModal: false,
      featuringImage: false,
      featureMonths: 1,
      featureCardNum: "4242424242424242",
      featureExpDateMonth: 2,
      featureExpDateYear: 2022,
      featureCVC: 314,
      addToTourModal: false,
      userTours: [],
    };
  }
  componentDidMount() {
    var str = this.props.router.params.id;
    var st = str.split("-", 2);
    this.requestNewPostData(st[1], this.props.router.params.uid);
    document.addEventListener("keydown", this.escFunction);
  }
  escFunction = (event) => {
    if (event.keyCode === 27 || event.keyCode === 4) {
      if (this.myViewer.getContainer().exitFullscreen) {
        this.myViewer.getContainer().exitFullscreen();
      }
      if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      }
      if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
      this.setState({ isFullscreen: false });
    }
  };
  createLike = async () => {
    if (this.unAuthorizedUser()) {
      const { post } = this.state;
      apiClient
        .post(
          `/like/add`,
          {
            image_id: post.Image.id,
            uid: post.uid,
          },
          { headers: { token: await token.getItem("@token") } }
        )
        .then((response) => {
          if (response.status === 200) {
            if (response.data === "RemovedLike") {
              this.setState({
                isLiked: false,
                likesCounter: this.state.likesCounter - 1,
              });
            } else {
              this.setState({
                isLiked: true,
                likesCounter: this.state.likesCounter + 1,
              });
              apiClient
                .post(`/notification/add?uid=${post.uid}`, {
                  action: "liked",
                  post_id: post.id,
                  isTour: false,
                })
                .then((res) => {
                  console.log(res);
                });
            }
          }
        });
    }
  };
  handleSubmit = () => {
    if (this.unAuthorizedUser()) {
      const { value, post } = this.state;
      if (!value) {
        return;
      }
      this.setState({ submitting: true });
      apiClient
        .post(`/comment/add`, {
          image_id: post.Image.id,
          commenter: this.props.user.displayName,
          text: value,
        })
        .then((response) => {
          this.setState({
            comments: response.data.Comments,
            value: "",
            submitting: false,
          });
          apiClient
            .post(`/notification/add?uid=${post.uid}`, {
              action: "commented",
              post_id: post.id,
              isTour: false,
            })
            .then((res) => {
              console.log(res);
            });
        })
        .catch((error) => {
          message.error("Error posting your comment, please try again later.");
          this.setState({
            submitting: false,
          });
        });
    }
  };

  componentWillUnmount() {
    if (this.audioPlayer) {
      this.audioPlayer.pause();
      this.audioPlayer.removeEventListener("ended", () =>
        this.setState({ playingAudio: false })
      );
    }
    if (this.narratorAudioPlayer) {
      this.narratorAudioPlayer.pause();
      this.narratorAudioPlayer.removeEventListener("ended", () =>
        this.setState({ playingNarratorAudio: false })
      );
    }
  }

  requestNewPostData = async (id, uid) => {
    // this.setState({ isLoading: true });
    await apiClient
      .get(`/post/findPost?post_id=${id}`, {
        headers: { token: await token.getItem("@token") },
      })
      .then((response) => {
        console.log("response");
        console.log(response.data);
        if (response && response.status === 200) {
          const Post = response.data;
          let viewsCounter = Post.Image.Views.length;
          if (this.props.user.userID !== response.data.uid) {
            console.log("views.data");
            apiClient
              .post(`/view/add`, {
                uid: response.data.uid,
                image_id: response.data.Image.id,
                views: response.data.Image.Views.length,
              })
              .then((views) => {
                if (views.data !== "Already Viewed") {
                  this.setState({ viewsCounter: viewsCounter + 1 });
                }
              });
          }
          if (Post.Image.audio_one) {
            let audio = new Audio(BASE_URL_IMAGE + Post.Image.audio_one);
            this.audioPlayer = audio;
            audio.play();
            this.setState({ playingAudio: true });
          }
          if (Post.Image.audio_two) {
            let audio = new Audio(BASE_URL_IMAGE + Post.Image.audio_two);
            this.narratorAudioPlayer = audio;
            audio.play();
            this.setState({ playingNarratorAudio: true });
          }
          this.setState({
            post: Post,
            likesCounter: Post.Image.Likes.length,
            viewsCounter: Post.Image.Views.length,
            isFollowed: Post.isFollowed,
            isLiked: Post.isLiked,
            isBought: Post.isBought,
            comments: Post.Image.Comments,
            loading: false,
          });
          if (this.state.firstDone) {
            this.render2ndPanaromas(response.data);
          } else {
            this.renderPanaroma(response.data);
          }
        } else {
          message.error(
            "There was an error fetching the post, please refresh or contact our administrator."
          );
        }
      })
      .catch((err) => {
        message.error(
          "There was an error fetching the post, please refresh or contact our administrator."
        );
      });
    apiClient
      .get("/tour/findTours")
      .then((res) => {
        if (res.status === 200) {
          this.setState({ userTours: res.data.Posts });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  shiftingPanorama = (item, infospot, uid) => {
    infospot.unlockHoverElement();
    this.props.router.push({
      pathname: `/post-view/id-${item.hotspot_with}`,
    });
    this.setState({ firstDone: true });
    this.requestNewPostData(item.hotspot_with, uid);
  };

  render2ndPanaromas = (post) => {
    const viewer = this.myViewer;
    const ext = post.Image.image.split(".", 2);
    if (ext[1] === "png" || ext[1] === "jpeg" || ext[1] === "jpg") {
      const panorama = new ImagePanorama(BASE_URL_IMAGE + post.Image.image);
      const littlePlanet = new LittlePlanet(BASE_URL_IMAGE + post.Image.image);
      viewer.add(panorama);
      viewer.add(littlePlanet);
      this.myPanaroma = panorama;
      this.myTinyPlanet = littlePlanet;
      panorama.addEventListener("enter-fade-start", function () {
        viewer.tweenControlCenter(
          new Vector3(
            post.Image.initialViewX,
            post.Image.initialViewY,
            post.Image.initialViewZ
          ),
          0
        );
      });
    } else if (
      ext[1] === "mp4" ||
      ext[1] === "mkv" ||
      ext[1] === "gif" ||
      ext[1] === "webm"
    ) {
      if (this.state.isMuteAdded) {
        this.setState({ isMuteAdded: false });
      } else {
        this.setState({ isMuteAdded: true });
      }
      const panorama = new VideoPanorama(BASE_URL_IMAGE + post.Image.image, {
        autoplay: true,
        muted: false,
      });
      viewer.add(panorama);
      this.myPanaroma = panorama;
      panorama.addEventListener("enter-fade-start", function () {
        viewer.tweenControlCenter(
          new Vector3(
            post.Image.initialViewX,
            post.Image.initialViewY,
            post.Image.initialViewZ
          ),
          0
        );
      });
    }
    // const littlePlanet = new LittlePlanet(BASE_URL_IMAGE + post.Image.image);
    // viewer.add(littlePlanet);

    // this.myPanaroma = panaroma;
    // this.myLittlePlanet = littlePlanet;

    post.hotspot_post.map((item) => {
      let infoSpot = new Infospot(item.scale * 6, BASE_URL_IMAGE + item.image);
      infoSpot.position.set(item.x, item.y, item.z);
      infoSpot.material.rotation = (Math.PI / 180) * item.rotation;
      infoSpot.material.opacity = item.opacity / 100;
      const div = document.createElement("div");
      div.style =
        item.label.split("style", 2)[1] +
        "padding-left: 10px; padding-right: 10px; border-radius: 10px; margin-bottom: 15px;";
      div.innerHTML = `<div>${item.label.split("style", 2)[0]}</div>`;

      infoSpot.addHoverElement(div);
      infoSpot.lockHoverElement();
      infoSpot.addEventListener("hover", (e) => infoSpot.lockHoverElement());
      infoSpot.addEventListener("hoverenter", (e) =>
        infoSpot.lockHoverElement()
      );
      infoSpot.addEventListener("hoverleave", (e) =>
        infoSpot.lockHoverElement()
      );
      if (item.audio) {
        infoSpot.addEventListener("click", (e) => {
          let audio = new Audio(BASE_URL_IMAGE + item.audio);
          audio.play();
        });
      }
      if (item.action === "No action") {
        // Show hover label only
      } else if (item.action === "Go to another post") {
        infoSpot.addEventListener("click", (e) =>
          this.shiftingPanorama(item, infoSpot)
        );
      } else if (item.action === "Open URL") {
        // Open the url for the user
        infoSpot.addEventListener("click", (e) => {
          window.open(item.url, "_blank");
        });
      } else if (item.action === "Open Card") {
        infoSpot.addEventListener("click", (e) => {
          this.setState({ activeHotspot: item, showModal: true });
        });
      } else if (item.action === "Sell Product") {
        ProductApi.post(
          `http://therentalsapp.com/rentmoebackend/api/general/product-details?product_id=${item.product_id}`
        )
          .then((res) => {
            this.setState({
              productDetails: res.data.data,
              productImages: res.data.data.product_media,
              productDescription: res.data.data.description,
              productName: res.data.data.name,
              productPrice: res.data.data.price[0].price,
              productQuantity: res.data.data.quantity,
            });
          })
          .catch((err) => {});
        infoSpot.addEventListener("click", (e) => {
          this.setState({ activeHotspot: item, showProductModal: true });
        });
      }
      this.myPanaroma.add(infoSpot);
    });
    viewer.setPanorama(this.myPanaroma);
    this.setState({ loadingPanaroma: false });
    if (this.state.isMuteAdded) {
      var controlItemCube = {
        style: {
          backgroundImageColor: "red",
          backgroundImage:
            "url(https://cdn1.bbcode0.com/uploads/2021/4/4/31d399a01caf44c898a6381a26e42495-full.png)",
          // "url(https://cdn1.bbcode0.com/uploads/2021/4/4/c8ca2162e114ec3eff76d4cda9113877-full.png)",
        },

        onTap: () => this.muteVideoFunction(),
      };
      this.myViewer.appendControlItem(controlItemCube);
    }
  };
  renderPanaroma = (post) => {
    const viewer = new Viewer({
      container: this.panaromaref,
      autoHideInfospot: false,
      cameraFov: 90,
      // controlBar: false,
      controlButtons: ["video", "fullscreen"],
    });

    this.myViewer = viewer;
    console.log(post);
    const ext = post.Image.image.split(".", 2);
    if (ext[1] === "png" || ext[1] === "jpeg" || ext[1] === "jpg") {
      const panorama = new ImagePanorama(BASE_URL_IMAGE + post.Image.image);
      const littlePlanet = new LittlePlanet(BASE_URL_IMAGE + post.Image.image);

      viewer.add(panorama);
      viewer.add(littlePlanet);
      this.myPanaroma = panorama;
      this.myTinyPlanet = littlePlanet;

      panorama.addEventListener("enter-fade-start", function () {
        viewer.tweenControlCenter(
          new Vector3(
            post.Image.initialViewX,
            post.Image.initialViewY,
            post.Image.initialViewZ
          ),
          0
        );
      });
    } else if (
      ext[1] === "mp4" ||
      ext[1] === "mkv" ||
      ext[1] === "gif" ||
      ext[1] === "webm"
    ) {
      this.setState({ isMuteAdded: true });

      const panorama = new VideoPanorama(BASE_URL_IMAGE + post.Image.image, {
        autoplay: true,
        muted: false,
      });
      viewer.add(panorama);
      this.myPanaroma = panorama;
      panorama.addEventListener("enter-fade-start", function () {
        viewer.tweenControlCenter(
          new Vector3(
            post.Image.initialViewX,
            post.Image.initialViewY,
            post.Image.initialViewZ
          ),
          0
        );
      });
    }

    // const littlePlanet = new LittlePlanet(BASE_URL_IMAGE + post.Image.image);

    // viewer.add(littlePlanet);

    // this.myLittlePlanet = littlePlanet;

    post.hotspot_post.map((item) => {
      let infoSpot = new Infospot(
        item.scale * 6,
        BASE_URL_IMAGE + item.image,
        true
      );
      infoSpot.position.set(item.x, item.y, item.z);
      const div = document.createElement("div");
      div.style =
        item.label.split("style", 2)[1] +
        "padding-left: 10px; padding-right: 10px; border-radius: 10px; margin-bottom: 15px;";
      div.innerHTML = `<div>${item.label.split("style", 2)[0]}</div>`;

      infoSpot.addHoverElement(div);

      infoSpot.lockHoverElement();
      infoSpot.addEventListener("hover", (e) => infoSpot.lockHoverElement());
      infoSpot.addEventListener("hoverenter", (e) =>
        infoSpot.lockHoverElement()
      );
      infoSpot.addEventListener("hoverleave", (e) =>
        infoSpot.lockHoverElement()
      );
      infoSpot.material.rotation = (Math.PI / 180) * item.rotation;
      infoSpot.material.opacity = item.opacity / 100;
      if (item.audio) {
        infoSpot.addEventListener("click", (e) => {
          let audio = new Audio(BASE_URL_IMAGE + item.audio);
          audio.play();
        });
      }
      if (item.action === "No action") {
        // Show hover label only
      } else if (item.action === "Go to another post") {
        infoSpot.addEventListener("click", (e) =>
          this.shiftingPanorama(item, infoSpot, item.PostUid)
        );
      } else if (item.action === "Open URL") {
        // Open the url for the user
        infoSpot.addEventListener("click", (e) => {
          window.open(item.url, "_blank");
        });
      } else if (item.action === "Open Card") {
        infoSpot.addEventListener("click", (e) => {
          this.setState({ activeHotspot: item, showModal: true });
        });
      } else if (item.action === "Sell Product") {
        ProductApi.post(
          `http://therentalsapp.com/rentmoebackend/api/general/product-details?product_id=${item.product_id}`
        )
          .then((res) => {
            this.setState({
              productDetails: res.data.data,
              productImages: res.data.data.product_media,
              productDescription: res.data.data.description,
              productName: res.data.data.name,
              productPrice: res.data.data.price[0].price,
              productQuantity: res.data.data.quantity,
            });
          })
          .catch((err) => {});
        infoSpot.addEventListener("click", (e) => {
          this.setState({ activeHotspot: item, showProductModal: true });
        });
      }
      this.myPanaroma.add(infoSpot);
    });
    if (this.state.isMuteAdded) {
      var controlItemCube = {
        style: {
          // width: "20px",
          // height: "20px",
          // backgroundColor: "red",

          // color: "red",
          // fontFamily: "FontAwesome",
          // content: "\f005",
          backgroundImage:
            "url(https://cdn1.bbcode0.com/uploads/2021/4/4/c8ca2162e114ec3eff76d4cda9113877-full.png)",
          // "url(https://cdn1.bbcode0.com/uploads/2021/4/4/31d399a01caf44c898a6381a26e42495-full.png)",
        },

        onTap: () => this.muteVideoFunction(),
      };
      this.myViewer.appendControlItem(controlItemCube);
      this.setState({ isMuteAdded: false });
    }
    this.setState({ loadingPanaroma: false });
  };
  // getHotspots = (hotspots) => {
  //   let hotspotsArray = [];
  //   hotspots.forEach((item) => {
  //     const hotspotInfo = item;
  //     hotspotsArray.push({
  //       pitch: hotspotInfo.pitch,
  //       yaw: hotspotInfo.yaw,
  //       text: hotspotInfo.text,
  //       type: hotspotInfo.type === "url" ? "info" : hotspotInfo.type,
  //       cssClass:
  //         hotspotInfo.type === "info"
  //           ? undefined
  //           : hotspotInfo.type + "-hotspot",
  //       clickHandlerFunc: () => {
  //         if (hotspotInfo.type === "hotspot") {
  //           this.props.router.replace({
  //             pathname: `/post-view/id-${hotspotInfo.hotspot_with}`,
  //           });
  //           requestNewPostData(hotspotInfo.hotspot_with);
  //         }
  //       },
  //     });
  //   });
  //   return hotspotsArray;
  // };
  addToTour = (tour_id, image_id) => {
    this.setState({ addToTourModal: false });
    apiClient
      .put(`/tour/image/add?tour_id=${tour_id}&image_id=${image_id}`)
      .then((res) => {
        if (res.status === 200) {
          message.success("post added to tour successfully");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  muteVideoFunction = () => {
    if (this.myPanaroma.isVideoMuted()) {
      this.myPanaroma.unmuteVideo();
    } else {
      this.myPanaroma.muteVideo();
    }
  };
  unAuthorizedUser = () => {
    if (!this.props.isAuthenticated) {
      message.error(errors.unAuthorizedUserCheck);
      return false;
    } else {
      return true;
    }
  };
  unFollow = () => {
    if (this.unAuthorizedUser()) {
      apiClient
        .post(
          `/follower/unfollow?uid=${this.props.user.userID}&followed=${this.state.post.uid}`
        )
        .then((response) => {
          this.setState({
            isFollowed: !this.state.isFollowed,
          });
        })
        .catch((error) => {});
    }
  };
  Follow = () => {
    if (this.unAuthorizedUser()) {
      apiClient
        .post(`/follower/followed`, {
          uid: this.props.user.userID,
          followed: this.state.post.uid,
        })
        .then((response) => {
          this.setState({
            isFollowed: !this.state.isFollowed,
          });
          apiClient
            .post(`/notification/add?uid=${this.state.post.uid}`, {
              action: "followed",
            })
            .then((res) => {
              console.log(res);
            });
        })
        .catch((error) => {
          message.error(
            "There is an error unfollowing the user, please try again later."
          );
        });
    }
  };

  handleFeaturing = () => {
    this.setState({ featuringImage: true });
    const {
      featureCVC,
      featureExpDateMonth,
      featureExpDateYear,
      featureCardNum,
      post,
      featureMonths,
    } = this.state;
    if (!this.state.featureCardNum) {
      message.error("Please enter your credit card number");
      return;
    }
    if (!this.state.featureExpDateMonth) {
      message.error("Please select expiration month.");
      return;
    }
    if (!this.state.featureExpDateYear) {
      message.error("Please select expiration year.");
      return;
    }
    if (!this.state.featureCVC && this.state.cvc.featureCVC !== 3) {
      message.error("Please enter a valid CVC.");
      return;
    }

    apiClient
      .post(`users/card/token`, {
        number: featureCardNum,
        exp_month: featureExpDateMonth,
        exp_year: featureExpDateYear,
        cvc: featureCVC,
      })
      .then((response) => {
        if (response && response.status === 200) {
          apiClient
            .post(`users/card/charges/deduct`, {
              amount: 10 * featureMonths,
              token_id: response.data,
            })
            .then((tokenResponse) => {
              if (tokenResponse && tokenResponse.status === 200) {
                apiClient
                  .post(`/post/featured/add?post_id=${this.state.post.id}`, {
                    charge_token: tokenResponse.data.id,
                    amount: 10 * featureMonths,
                    featuredTill: featureMonths,
                  })
                  .then((response) => {
                    this.setState({
                      featuringImage: false,
                      featurePaymentModal: false,
                    });
                    message.success("Your Post is featured successfully");
                    window.location.reload();
                  })
                  .catch((error) => {
                    message.error(
                      "We were unable to complete the transaction, please try again later."
                    );
                  });
              } else {
                message.error(
                  "We were unable to complete the transaction, please try again later."
                );
              }
            })
            .catch((error) => {
              message.error(
                "We were unable to complete the transaction, please try again later."
              );
            });
        } else {
          message.error(
            "We were unable to complete the transaction, please check your credit card details."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleClick = () => {
    this.setState({ buyingImage: true });
    const {
      cvc,
      expDateMonth,
      expDateYear,
      cardNum,
      post,
      numberOfMonths,
    } = this.state;
    if (!this.state.cardNum) {
      message.error("Please enter your credit card number");
      return;
    }
    if (!this.state.expDateMonth) {
      message.error("Please select expiration month.");
      return;
    }
    if (!this.state.expDateYear) {
      message.error("Please select expiration year.");
      return;
    }
    if (!this.state.cvc && this.state.cvc.length !== 3) {
      message.error("Please enter a valid CVC.");
      return;
    }
    apiClient
      .post(`users/card/token`, {
        number: cardNum,
        exp_month: expDateMonth,
        exp_year: expDateYear,
        cvc: cvc,
      })
      .then((response) => {
        if (response && response.status === 200) {
          apiClient
            .post(`users/card/charges/deduct`, {
              amount: post.Image.purchasePrice * numberOfMonths,
              token_id: response.data,
            })
            .then((tokenResponse) => {
              if (tokenResponse && tokenResponse.status === 200) {
                apiClient
                  .post(
                    `purchase/image/add?image_id=${this.state.post.Image.id}`,
                    {
                      charge_token: tokenResponse.data.id,
                      amount: post.Image.purchasePrice * numberOfMonths,
                      boughtTill: moment().add(this.state.numberOfMonths, "M"),
                    }
                  )
                  .then((response) => {
                    this.setState({ imageBought: true, buyingImage: false });
                  })
                  .catch((error) => {
                    message.error(
                      "We were unable to complete the transaction, please try again later."
                    );
                  });
              } else {
                message.error(
                  "We were unable to complete the transaction, please try again later."
                );
              }
            })
            .catch((error) => {
              message.error(
                "We were unable to complete the transaction, please try again later."
              );
            });
        } else {
          message.error(
            "We were unable to complete the transaction, please check your credit card details."
          );
        }
      })
      .catch((error) => {});
  };

  render() {
    const CommentList = ({ comments }) => (
      <List
        dataSource={comments}
        itemLayout="horizontal"
        renderItem={(props) => {
          return (
            <Comment
              author={props.commenter}
              content={props.text}
              avatar={
                props.User.profilePicture
                  ? BASE_URL_IMAGE + props.User.profilePicture
                  : `${this.state.imgPath}empty-profile-image.jpg`
              }
              datetime={moment(props.createdAt).fromNow()}
            />
          );
        }}
      />
    );
    const {
      post,
      isFeatured,
      isFollowed,
      isLiked,
      likesCounter,
      loading,
      viewsCounter,
      value,
      comments,
      submitting,
      loadingPanaroma,
    } = this.state;

    const ModeMenu = () => (
      <Menu>
        <Menu.Item
          onClick={() => {
            this.myViewer.enableEffect(MODES.NORMAL);
            this.setState({ currentMode: MODES.NORMAL });
          }}
          key={MODES.NORMAL}
        >
          Normal
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            this.myViewer.enableEffect(MODES.CARDBOARD);
            this.setState({ currentMode: MODES.CARDBOARD });
          }}
          key={MODES.CARDBOARD}
        >
          Card Board
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            this.myViewer.enableEffect(MODES.STEREO);
            this.setState({ currentMode: MODES.STEREO });
          }}
          key={MODES.STEREO}
        >
          Stereoscopic
        </Menu.Item>
      </Menu>
    );
    const ControlMenu = () => (
      <Menu>
        <Menu.Item
          onClick={() => {
            this.myViewer.enableControl(CONTROLS.ORBIT);
            this.setState({ currentControl: CONTROLS.ORBIT });
          }}
          key={CONTROLS.ORBIT}
        >
          Orbit
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            this.myViewer.enableControl(CONTROLS.DEVICEORIENTATION);
            this.setState({ currentControl: CONTROLS.DEVICEORIENTATION });
          }}
          key={CONTROLS.DEVICEORIENTATION}
        >
          Sensor (VR)
        </Menu.Item>
      </Menu>
    );
    const PanoromaMenu = () => (
      <Menu>
        <Menu.Item
          onClick={() => {
            this.myViewer.setPanorama(this.myPanaroma);
          }}
        >
          Panoroma
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            if (this.myPanaroma.videoElement) {
              message.info("Tiny Planet View Is Not Available For Videos");
            } else {
              this.myViewer.setPanorama(this.myTinyPlanet);
            }
          }}
        >
          Little Planet
        </Menu.Item>
      </Menu>
    );
    return (
      <div
        style={{
          height: window.screen.width > 768 ? "860px" : undefined,
          color: window.screen.width > 768 ? "black" : "white",
        }}
        className="row"
      >
        {loading ? (
          <Spin
            style={{
              width: "100%",
              padding: 50,
            }}
          />
        ) : (
          <React.Fragment>
            <>
              <div
                style={applyFilters(
                  post.Image.filterName,
                  post.Image.intensity
                )}
                className="col-md-9 h-100 m-0 p-0 panoroma-container"
              >
                {loadingPanaroma && <Spin />}
                <div
                  id="myPanoramaPostView"
                  // style={{ width: window.innerWidth, height: window.inne }}
                  ref={(ref) => (this.panaromaref = ref)}
                />
              </div>
              <div className="col-md-3 d-flex align-items-lg-start flex-column side-menu-container-post">
                {isFeatured ? (
                  <button className="btn btn-warning d-flex align-items-center mt-4">
                    <StarOutlined className="mr-1" /> FEATURED
                  </button>
                ) : undefined}
                {this.state.activeHotspot ? (
                  <Modal
                    title="Card"
                    width="100%"
                    visible={this.state.showModal}
                    footer={null}
                    onCancel={() => this.setState({ showModal: false })}
                  >
                    <iframe
                      src={this.state.activeHotspot.cardImageUrl}
                      height={"100%"}
                      width={"100%"}
                    />
                    <p>{this.state.activeHotspot.cardText}</p>
                    <a href={this.state.activeHotspot.cardUrl} target="_blank">
                      {this.state.activeHotspot.cardLinkLabel}
                    </a>
                  </Modal>
                ) : undefined}
                {this.state.productDetails ? (
                  <Modal
                    title="Product"
                    centered={true}
                    width="50%"
                    footer={null}
                    bodyStyle={{ height: 600 }}
                    visible={this.state.showProductModal}
                    onOk={() => this.setState({ showProductModal: false })}
                    onCancel={() => this.setState({ showProductModal: false })}
                  >
                    <Row style={{ height: 500 }}>
                      <Col span={12}>
                        <Carousel
                          ref={(ref) => {
                            this.carosalRef = ref;
                          }}
                          dots={false}
                        >
                          {this.state.productImages.map((item) => {
                            return (
                              <img
                                src={item._value}
                                style={{
                                  height: 100,
                                  width: 100,
                                  borderRadius: 10,
                                  marginRight: 5,
                                  marginBottom: 5,
                                }}
                              />
                            );
                          })}
                        </Carousel>
                        <div className="d-flex flex-row justify-content-center mt-2">
                          {this.state.productImages.map((item, index) => {
                            return (
                              <img
                                src={item._value}
                                className="slider-product"
                                onClick={() => this.carosalRef.goTo(index)}
                                style={{
                                  height: 50,
                                  width: 50,
                                  borderRadius: 5,
                                  marginRight: 5,
                                  marginBottom: 5,
                                }}
                              />
                            );
                          })}
                          {/* <img
                          src={productImage}
                          className="slider-product"
                          onClick={() => this.carosalRef.goTo(0)}
                          style={{
                            height: 50,
                            width: 50,
                            borderRadius: 5,
                            marginRight: 5,
                            marginBottom: 5,
                          }}
                        />
                        <img
                          src={productImage1}
                          onClick={() => this.carosalRef.goTo(1)}
                          className="active-slider-product"
                          style={{
                            height: 50,
                            width: 50,
                            borderRadius: 5,
                            marginRight: 5,
                            marginBottom: 5,
                          }}
                        /> */}
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="product-modal-side-menu">
                          <p
                            className="m-0"
                            style={{ fontWeight: "bold", fontSize: 25 }}
                          >
                            {this.state.productName}
                          </p>
                          <hr className="border-product" />
                          <p
                            className="m-0 mt-2"
                            style={{ fontWeight: "bold", fontSize: 18 }}
                          >
                            ${this.state.productPrice}.00
                          </p>
                          <p
                            className="m-0 mt-2"
                            style={{ fontWeight: "bold" }}
                          >
                            Description:
                          </p>
                          <p className="m-0 w-75">
                            {this.state.productDescription}
                          </p>
                          <div className="d-flex flex-row mt-4 ">
                            <p
                              className="m-0 mr-2"
                              style={{ fontWeight: "bold" }}
                            >
                              Status:
                            </p>
                            <p>
                              {this.state.productQuantity > 0
                                ? " In Stock"
                                : " Out Of Stock"}
                            </p>
                            {/* <InputNumber
                            className="m-0 ml-2"
                            style={{ fontWeight: "bold" }}
                            value={1}
                          /> */}
                          </div>
                          <Button
                            onClick={() => {
                              if (this.state.productQuantity > 0) {
                                console.log(this.state.productDetails);
                                const { productDetails } = this.state;
                                ProductApi.post("general/addToCart", {
                                  customer_id: this.props.user
                                    .ecommerce_user_id,
                                  product_id: productDetails.id,
                                  quantity: 1,
                                  price: productDetails.price[0].price,
                                })
                                  .then((response) => {
                                    this.props.addToCart({
                                      productId: productDetails.added_by,
                                      image: productImage,
                                      name: this.state.productName,
                                      Price: this.state.productPrice,
                                      Quantity: 1,
                                      AvailableQuantity: this.state
                                        .productQuantity,
                                      Total: this.state.productPrice,
                                    });
                                    message.success(
                                      "Added product to the cart succesfully"
                                    );
                                  })
                                  .catch((error) => {
                                    message.success(
                                      "There was a problem adding the product in cart, please try later."
                                    );
                                  });
                              } else {
                                message.error("out of stock");
                              }
                            }}
                            style={{ width: "75%", marginTop: 10 }}
                          >
                            Add to Cart
                          </Button>
                          <Button
                            onClick={() => {
                              if (this.state.productQuantity > 0) {
                                console.log(this.state.productDetails);
                                const { productDetails } = this.state;
                                ProductApi.post("general/addToCart", {
                                  customer_id: this.props.user
                                    .ecommerce_user_id,
                                  product_id: productDetails.id,
                                  quantity: 1,
                                  price: productDetails.price[0].price,
                                })
                                  .then((response) => {
                                    this.props.addToCart({
                                      productId: productDetails.added_by,
                                      image: productImage,
                                      name: this.state.productName,
                                      Price: this.state.productPrice,
                                      Quantity: 1,
                                      AvailableQuantity: this.state
                                        .productQuantity,
                                      Total: this.state.productPrice,
                                    });
                                    this.props.router.push("/cart");
                                  })
                                  .catch((error) => {
                                    message.success(
                                      "There was a problem adding the product in cart, please try later."
                                    );
                                  });
                              } else {
                                message.error("out of stock");
                              }
                            }}
                            style={{ width: "75%", marginTop: 10 }}
                          >
                            Buy Now
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Modal>
                ) : undefined}
                <div className="bottom-controls">
                  <Tooltip title="CardBoard Mode">
                    <FontAwesomeIcon
                      onClick={() => {
                        this.myViewer.enableEffect(MODES.CARDBOARD);
                        this.setState({ currentMode: MODES.CARDBOARD });
                      }}
                      className="controll-btn"
                      icon={faVrCardboard}
                    />
                  </Tooltip>
                  <Tooltip title="StereoScopic Mode">
                    <FontAwesomeIcon
                      onClick={() => {
                        this.myViewer.enableEffect(MODES.STEREO);
                        this.setState({ currentMode: MODES.STEREO });
                      }}
                      className="controll-btn"
                      icon={faBinoculars}
                    />
                  </Tooltip>
                  <Tooltip title="Defaul Mode">
                    <FontAwesomeIcon
                      onClick={() => {
                        this.myViewer.enableEffect(MODES.NORMAL);
                        this.setState({ currentMode: MODES.NORMAL });
                      }}
                      className="controll-btn"
                      icon={faCompass}
                    />
                  </Tooltip>
                  <div style={{ borderRight: "1px solid grey" }}></div>
                  <Tooltip title="Orbit Controll">
                    <FontAwesomeIcon
                      onClick={() => {
                        this.myViewer.enableControl(CONTROLS.ORBIT);
                        this.setState({ currentControl: CONTROLS.ORBIT });
                      }}
                      className="controll-btn"
                      icon={faCircle}
                    />
                  </Tooltip>
                  <Tooltip title="Sensor VR">
                    <FontAwesomeIcon
                      onClick={() => {
                        this.myViewer.enableControl(CONTROLS.DEVICEORIENTATION);
                        this.setState({
                          currentControl: CONTROLS.DEVICEORIENTATION,
                        });
                      }}
                      className="controll-btn"
                      icon={faMendeley}
                    />
                  </Tooltip>
                  <div style={{ borderRight: "1px solid grey" }}></div>
                  <Tooltip title="Panoroma">
                    <FontAwesomeIcon
                      onClick={() => {
                        this.myViewer.setPanorama(this.myPanaroma);
                      }}
                      className="controll-btn"
                      icon={faImage}
                    />
                  </Tooltip>
                  <Tooltip title="Little Planet">
                    <FontAwesomeIcon
                      onClick={() => {
                        if (this.myPanaroma.videoElement) {
                          message.info(
                            "Tiny Planet View Is Not Available For Videos"
                          );
                        } else {
                          this.myViewer.setPanorama(this.myTinyPlanet);
                        }
                      }}
                      className="controll-btn"
                      icon={faGlobeAsia}
                    />
                  </Tooltip>
                  <Tooltip title="Full Screen">
                    <FontAwesomeIcon
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        // if (!this.state.isFullscreen) {
                        if (
                          document.querySelector("#myPanoramaPostView")
                            .requestFullscreen
                        ) {
                          this.myViewer.getContainer().requestFullscreen();
                        }
                        if (
                          document.querySelector("#myPanoramaPostView")
                            .msRequestFullscreen
                        ) {
                          this.myViewer.getContainer().msRequestFullscreen();
                        }
                        if (
                          document.querySelector("#myPanoramaPostView")
                            .mozRequestFullScreen
                        ) {
                          this.myViewer.getContainer().mozRequestFullScreen();
                        }
                        if (
                          document.querySelector("#myPanoramaPostView")
                            .webkitRequestFullscreen
                        ) {
                          this.myViewer
                            .getContainer()
                            .webkitRequestFullscreen(
                              Element.ALLOW_KEYBOARD_INPUT
                            );
                        }
                        this.setState({ isFullscreen: true });
                        // }
                      }}
                      className="controll-btn"
                      icon={faExpand}
                    />
                  </Tooltip>
                </div>
                <div className="mobile-profile">
                  <div className="d-flex">
                    {post.profilePicture ? (
                      <img
                        src={BASE_URL_IMAGE + post.profilePicture}
                        style={{ width: 50, height: 50, borderRadius: 25 }}
                        onClick={() =>
                          this.props.router.push({
                            pathname: `/profile/photos/${post.username}`,
                          })
                        }
                      />
                    ) : (
                      <Avatar
                        size={50}
                        icon={post.display_name.charAt(0).toUpperCase()}
                      />
                    )}
                    <div>
                      <div
                        style={{
                          fontSize: 20,
                          fontWeight: "600",
                          marginLeft: 10,
                        }}
                      >
                        {post.display_name}
                        {/* <Dropdown overlay={menu}>
                      <a
                        className="ant-dropdown-link"
                        onClick={(e) => e.preventDefault()}
                      >
                        <FontAwesomeIcon
                          style={{ marginLeft: 20 }}
                          icon={faEllipsisH}
                        />
                      </a>
                    </Dropdown> */}
                      </div>
                      <span
                        style={{
                          marginLeft: 10,
                        }}
                      >
                        {moment(post.createdAt).fromNow()}
                      </span>
                    </div>
                  </div>
                  <div>
                    {this.props.user &&
                      post.uid !== this.props.user.userID &&
                      (isFollowed ? (
                        <div>
                          <Button
                            htmlType="submit"
                            onClick={this.unFollow}
                            type="primary"
                          >
                            Un Follow
                          </Button>
                        </div>
                      ) : (
                        <div>
                          <Button
                            htmlType="submit"
                            onClick={this.Follow}
                            type="primary"
                          >
                            Follow
                          </Button>
                        </div>
                      ))}
                  </div>
                  {this.props.user.userID === post.uid && (
                    <Tooltip title="Feature Your Post">
                      <StarOutlined
                        onClick={() => {
                          let date = new Date();
                          let month = date.getMonth();
                          let feature = moment(post.featuredTill)
                            .format("L")
                            .split("/")[0];
                          if (
                            parseInt(feature) > parseInt(month) ||
                            post.featuredTill !== "0"
                          ) {
                            message.info("your post is already featured");
                          } else {
                            this.setState({ featurePaymentModal: true });
                          }
                        }}
                        style={{
                          marginLeft: 10,
                          marginTop: 5,
                        }}
                      />
                    </Tooltip>
                  )}
                  {this.props.user.userID === post.uid && (
                    <EditOutlined
                      onClick={() => {
                        const post_id = this.props.routeParams.id.split("-", 2);
                        this.props.router.push(`/single-image/${post_id[1]}`);
                      }}
                      style={{
                        marginLeft: 10,
                        marginTop: 5,
                      }}
                    />
                  )}
                  {this.props.user.userID === post.uid && (
                    <Popconfirm
                      placement="right"
                      title="Are you sure to delete this post?"
                      onConfirm={async () => {
                        apiClient
                          .delete(`/post/delete/?post_id=${post.id}`, {
                            headers: {
                              token: await token.getItem("@token"),
                            },
                          })
                          .then((res) => {
                            if (res.status === 200) {
                              message.success(res.data);
                              this.props.router.push("/explore/photos");
                            } else {
                              message.error("something went wrong");
                            }
                          })
                          .catch((err) => {
                            message.error("something went wrong");
                          });
                      }}
                      // onCancel={cancel}
                      okText="Yes"
                      cancelText="No"
                    >
                      <DeleteOutlined
                        style={{
                          marginLeft: 10,
                          marginTop: 5,
                          color: "red",
                        }}
                      />
                    </Popconfirm>
                  )}
                </div>
                <div className="row mt-1 d-flex align-items-md-center profile-image-container">
                  <div className="web-profile">
                    <div className="d-flex ml-2 w-100">
                      {post.profilePicture ? (
                        <img
                          src={BASE_URL_IMAGE + post.profilePicture}
                          style={{ width: 60, height: 60 }}
                          onClick={() =>
                            this.props.router.push({
                              pathname: `/profile/photos/${post.username}`,
                            })
                          }
                        />
                      ) : (
                        <Avatar
                          size={60}
                          icon={post.display_name.charAt(0).toUpperCase()}
                        />
                      )}
                      <div>
                        <span
                          style={{
                            fontSize: 20,
                            fontWeight: "600",
                            marginLeft: 10,
                          }}
                        >
                          {post.display_name}
                        </span>
                        <div
                          style={{ fontSize: 16 }}
                          className="time-stamp ml-2"
                        >
                          {moment(post.createdAt).fromNow()}
                        </div>
                      </div>
                      {this.props.user.userID === post.uid && (
                        <Tooltip title="Feature Your Post">
                          <StarOutlined
                            onClick={() => {
                              let date = new Date();
                              let month = date.getMonth();
                              let feature = moment(post.featuredTill)
                                .format("L")
                                .split("/")[0];
                              if (
                                parseInt(feature) > parseInt(month) ||
                                post.featuredTill !== "0"
                              ) {
                                message.info("your post is already featured");
                              } else {
                                this.setState({ featurePaymentModal: true });
                              }
                            }}
                            style={{
                              marginLeft: 10,
                              marginTop: 5,
                            }}
                          />
                        </Tooltip>
                      )}
                      {this.props.user.userID === post.uid && (
                        <EditOutlined
                          onClick={() => {
                            const post_id = this.props.routeParams.id.split(
                              "-",
                              2
                            );
                            this.props.router.push(
                              `/single-image/${post_id[1]}`
                            );
                          }}
                          style={{
                            marginLeft: 10,
                            marginTop: 5,
                          }}
                        />
                      )}
                      {this.props.user.userID === post.uid && (
                        <Popconfirm
                          placement="right"
                          title="Are you sure to delete this post?"
                          onConfirm={async () => {
                            apiClient
                              .delete(`/post/delete/?post_id=${post.id}`, {
                                headers: {
                                  token: await token.getItem("@token"),
                                },
                              })
                              .then((res) => {
                                if (res.status === 200) {
                                  message.success(res.data);
                                  this.props.router.push("/explore/photos");
                                } else {
                                  message.error("something went wrong");
                                }
                              })
                              .catch((err) => {
                                message.error("something went wrong");
                              });
                          }}
                          // onCancel={cancel}
                          okText="Yes"
                          cancelText="No"
                        >
                          <DeleteOutlined
                            style={{
                              marginLeft: 10,
                              marginTop: 5,
                              color: "red",
                            }}
                          />
                        </Popconfirm>
                      )}
                      {/* <div className="mode-selector">
                      <Dropdown overlay={menu}>
                        <a
                          className="ant-dropdown-link"
                          onClick={(e) => e.preventDefault()}
                        >
                          <FontAwesomeIcon icon={faEllipsisH} />
                        </a>
                      </Dropdown>
                    </div> */}
                      <div className="ml-5">
                        {this.props.user &&
                          post.uid !== this.props.user.userID &&
                          (isFollowed ? (
                            <div>
                              <Button
                                htmlType="submit"
                                onClick={this.unFollow}
                                type="primary"
                              >
                                Un Follow
                              </Button>
                            </div>
                          ) : (
                            <div>
                              <Button
                                htmlType="submit"
                                onClick={this.Follow}
                                type="primary"
                              >
                                Follow
                              </Button>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="mt-3"
                  style={{ marginLeft: window.screen.width > 768 ? 3 : 20 }}
                >
                  <div className="d-flex justify-content-center align-items-center">
                    {!isLiked ? (
                      <HeartOutlined
                        style={{ fontSize: 24 }}
                        onClick={() => {
                          if (this.props.user.userID !== post.uid)
                            this.createLike();
                        }}
                      />
                    ) : (
                      <HeartFilled
                        style={{ fontSize: 24, color: "red" }}
                        onClick={() => {
                          if (this.props.user.userID !== post.uid)
                            this.createLike();
                        }}
                      />
                    )}

                    <div className="ml-1">
                      {likesCounter ? likesCounter : "0"} Likes
                    </div>
                    <div className="ml-4">
                      {viewsCounter ? viewsCounter : "0"} Views
                    </div>
                    <div
                      onClick={() => this.setState({ isShareModal: true })}
                      className="reverse-display ml-4"
                    >
                      <ShareAltOutlined style={{ fontSize: 25 }} />
                    </div>
                    {this.props.user.userID === post.uid && (
                      <div className="reverse-display">
                        <FolderAddOutlined
                          onClick={() => {
                            this.setState({ addToTourModal: true });
                          }}
                          style={{ fontSize: 25, marginLeft: 34 }}
                        />
                        &ensp;Add To Tour
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-3 display-none" style={{ marginLeft: 3 }}>
                  <div className="d-flex justify-content-center align-items-center">
                    <ShareAltOutlined
                      onClick={() => this.setState({ isShareModal: true })}
                      style={{ fontSize: 25 }}
                    />
                    <span className="mobile-like-counter">&ensp;Share</span>
                    {this.props.user.userID === post.uid && (
                      <>
                        <FolderAddOutlined
                          onClick={() => {
                            this.setState({ addToTourModal: true });
                          }}
                          style={{ fontSize: 25, marginLeft: 34 }}
                        />
                        &ensp;Add To Tour
                      </>
                    )}
                  </div>
                  <Modal
                    visible={this.state.addToTourModal}
                    title="Select Tour"
                    bodyStyle={{
                      backgroundColor: "#1E1E1E",
                      color: "white",
                      padding: 0,
                    }}
                    width="20%"
                    closable={true}
                    footer={null}
                    onOk={() => this.setState({ addToTourModal: false })}
                    onCancel={() => this.setState({ addToTourModal: false })}
                  >
                    <div style={{ width: "100%", paddingTop: 10 }}>
                      {this.state.userTours.length > 0 ? (
                        this.state.userTours.map((item) => (
                          <div
                            onClick={() => {
                              this.addToTour(item.Tour.id, post.Image.id);
                            }}
                            style={{
                              display: "flex",
                              alignSelf: "center",
                              padding: 15,
                              borderBottom: "1px solid black",
                            }}
                            className="tour-hover"
                          >
                            {item.Tour.tour_name}
                            <hr className="m-0" />
                          </div>
                        ))
                      ) : (
                        <span>no tours found</span>
                      )}
                    </div>
                  </Modal>
                </div>
                <Modal
                  visible={this.state.isShareModal}
                  title="Share Link"
                  onOk={() => this.setState({ isShareModal: false })}
                  onCancel={() => this.setState({ isShareModal: false })}
                >
                  <div>
                    <p>Please use the following image URL to share:</p>
                    <CopyToClipboard
                      text={BASE_URL_WEBSITE + this.props.location.pathname}
                      onCopy={() => message.success("Copied to clipboard!!")}
                    >
                      <a href={BASE_URL_WEBSITE + this.props.location.pathname}>
                        {BASE_URL_WEBSITE + this.props.location.pathname}
                      </a>
                    </CopyToClipboard>
                  </div>
                </Modal>
                {/* feature post payment */}
                <Modal
                  visible={this.state.featurePaymentModal}
                  title="Feature Your Image"
                  onOk={() => {
                    this.handleFeaturing();
                  }}
                  onCancel={() => this.setState({ featurePaymentModal: false })}
                >
                  {this.state.featuringImage ? (
                    <>
                      <Spin
                        style={{
                          width: "100%",
                          padding: 50,
                        }}
                      />{" "}
                      Featuring Image ...
                    </>
                  ) : (
                    <div>
                      <span>
                        Feature Price per Month:{" "}
                        <span style={{ fontWeight: "bold" }}>10</span> $
                      </span>
                      <div className="mt-2 mb-1">Enter Number of Months:</div>
                      <InputNumber
                        min={1}
                        value={this.state.featureMonths}
                        placeholder="Enter number of months"
                        style={{ width: "100%" }}
                        onChange={(value) =>
                          this.setState({ featureMonths: value })
                        }
                      />
                      {this.state.numberOfMonths && (
                        <form>
                          <div style={{ marginTop: 20, marginBottom: 20 }}>
                            Your Bill:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {10 * this.state.featureMonths}
                            </span>{" "}
                            $
                          </div>
                          <div class="form-group">
                            <label
                              className="subheadtext mt-2"
                              for="exampleInputEmail1"
                            >
                              Card Number:
                            </label>
                            <input
                              value={this.state.featureCardNum}
                              style={{ fontStyle: "italic" }}
                              type="text"
                              maxLength={12}
                              class="form-control helptext"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              placeholder="ex 1234 4567 8912 3456"
                              onChange={(e) =>
                                this.setState({
                                  featureCardNum: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="d-flex flex-row align-items-center">
                            <div class="form-group">
                              <label
                                className="subheadtext"
                                for="exampleFormControlSelect1"
                              >
                                Expiration Date:
                              </label>
                              <br />
                              <Select
                                onChange={(value) =>
                                  this.setState({ featureExpDateMonth: value })
                                }
                                style={{ width: "100%" }}
                                value={this.state.featureExpDateMonth}
                                placeholder="Select Month"
                              >
                                <Select.Option value={1}>Januray</Select.Option>
                                <Select.Option value={2}>
                                  February
                                </Select.Option>
                                <Select.Option value={3}>March</Select.Option>
                                <Select.Option value={4}>April</Select.Option>
                                <Select.Option value={5}>May</Select.Option>
                                <Select.Option value={6}>June</Select.Option>
                                <Select.Option value={7}>July</Select.Option>
                                <Select.Option value={8}>August</Select.Option>
                                <Select.Option value={9}>
                                  September
                                </Select.Option>
                                <Select.Option value={10}>
                                  October
                                </Select.Option>
                                <Select.Option value={11}>
                                  November
                                </Select.Option>
                                <Select.Option value={12}>
                                  December
                                </Select.Option>
                              </Select>
                            </div>
                            <div class="form-group mt-4 pl-3">
                              <Select
                                onChange={(value) =>
                                  this.setState({ featureExpDateYear: value })
                                }
                                style={{ width: "100%" }}
                                value={this.state.featureExpDateYear}
                                placeholder="Select Year"
                              >
                                <Select.Option value={2021}>2021</Select.Option>
                                <Select.Option value={2022}>2022</Select.Option>
                                <Select.Option value={2023}>2023</Select.Option>
                                <Select.Option value={2024}>2024</Select.Option>
                                <Select.Option value={2025}>2025</Select.Option>
                                <Select.Option value={2026}>2026</Select.Option>
                                <Select.Option value={2027}>2027</Select.Option>
                                <Select.Option value={2028}>2028</Select.Option>
                                <Select.Option value={2029}>2029</Select.Option>
                                <Select.Option value={2030}>2030</Select.Option>
                              </Select>
                            </div>

                            <div class="form-group ml-5">
                              <label
                                className="subheadtext"
                                for="exampleInputEmail1"
                              >
                                CVC:
                              </label>
                              <input
                                value={this.state.featureCVC}
                                style={{ fontStyle: "italic" }}
                                type="text"
                                maxLength={3}
                                class="form-control helptext"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="000"
                                onChange={(e) =>
                                  this.setState({ featureCVC: e.target.value })
                                }
                              />
                            </div>
                            <div className="ml-2 mt-2">
                              <CreditCardOutlined
                                style={{
                                  fontSize: 35,
                                  marginTop: 3,
                                  color: "rgb(202, 202, 202)",
                                }}
                              />
                            </div>
                          </div>
                        </form>
                      )}
                    </div>
                  )}
                </Modal>
                <Modal
                  visible={this.state.paymentModal}
                  title="Buy Image"
                  onOk={() => {
                    if (this.state.imageBought) {
                      this.setState({ paymentModal: false });
                    } else {
                      this.handleClick();
                    }
                  }}
                  onCancel={() => this.setState({ paymentModal: false })}
                >
                  {this.state.imageBought ? (
                    <div>
                      <p>Please use the following image URL to share:</p>
                      <a href={BASE_URL_WEBSITE + this.props.location.pathname}>
                        {BASE_URL_WEBSITE +
                          `/public/post/id-${post.id}/${this.props.user.userID}`}
                      </a>
                    </div>
                  ) : this.state.buyingImage ? (
                    <>
                      <Spin
                        style={{
                          width: "100%",
                          padding: 50,
                        }}
                      />{" "}
                      Buying Image ...
                    </>
                  ) : (
                    <div>
                      <span>
                        Image Price per Month:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {post.Image.purchasePrice}
                        </span>{" "}
                        $
                      </span>
                      <div className="mt-2 mb-1">Enter Number of Months:</div>
                      <InputNumber
                        min={1}
                        value={this.state.numberOfMonths}
                        placeholder="Enter number of months"
                        style={{ width: "100%" }}
                        onChange={(value) =>
                          this.setState({ numberOfMonths: value })
                        }
                      />
                      {this.state.numberOfMonths && (
                        <form>
                          <div style={{ marginTop: 20, marginBottom: 20 }}>
                            Your Bill:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {post.Image.purchasePrice *
                                this.state.numberOfMonths}
                            </span>{" "}
                            $
                          </div>
                          <div class="form-group">
                            <label
                              className="subheadtext mt-2"
                              for="exampleInputEmail1"
                            >
                              Card Number:
                            </label>
                            <input
                              value={this.state.cardNum}
                              style={{ fontStyle: "italic" }}
                              type="text"
                              maxLength={12}
                              class="form-control helptext"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              placeholder="ex 1234 4567 8912 3456"
                              onChange={(e) =>
                                this.setState({ cardNum: e.target.value })
                              }
                            />
                          </div>
                          <div className="d-flex flex-row align-items-center">
                            <div class="form-group">
                              <label
                                className="subheadtext"
                                for="exampleFormControlSelect1"
                              >
                                Expiration Date:
                              </label>
                              <br />
                              <Select
                                onChange={(value) =>
                                  this.setState({ expDateMonth: value })
                                }
                                style={{ width: "100%" }}
                                value={this.state.expDateMonth}
                                placeholder="Select Month"
                              >
                                <Select.Option value={1}>Januray</Select.Option>
                                <Select.Option value={2}>
                                  February
                                </Select.Option>
                                <Select.Option value={3}>March</Select.Option>
                                <Select.Option value={4}>April</Select.Option>
                                <Select.Option value={5}>May</Select.Option>
                                <Select.Option value={6}>June</Select.Option>
                                <Select.Option value={7}>July</Select.Option>
                                <Select.Option value={8}>August</Select.Option>
                                <Select.Option value={9}>
                                  September
                                </Select.Option>
                                <Select.Option value={10}>
                                  October
                                </Select.Option>
                                <Select.Option value={11}>
                                  November
                                </Select.Option>
                                <Select.Option value={12}>
                                  December
                                </Select.Option>
                              </Select>
                            </div>
                            <div class="form-group mt-4 pl-3">
                              <Select
                                onChange={(value) =>
                                  this.setState({ expDateYear: value })
                                }
                                style={{ width: "100%" }}
                                value={this.state.expDateYear}
                                placeholder="Select Year"
                              >
                                <Select.Option value={2021}>2021</Select.Option>
                                <Select.Option value={2022}>2022</Select.Option>
                                <Select.Option value={2023}>2023</Select.Option>
                                <Select.Option value={2024}>2024</Select.Option>
                                <Select.Option value={2025}>2025</Select.Option>
                                <Select.Option value={2026}>2026</Select.Option>
                                <Select.Option value={2027}>2027</Select.Option>
                                <Select.Option value={2028}>2028</Select.Option>
                                <Select.Option value={2029}>2029</Select.Option>
                                <Select.Option value={2030}>2030</Select.Option>
                              </Select>
                            </div>

                            <div class="form-group ml-5">
                              <label
                                className="subheadtext"
                                for="exampleInputEmail1"
                              >
                                CVC:
                              </label>
                              <input
                                value={this.state.cvc}
                                style={{ fontStyle: "italic" }}
                                type="text"
                                maxLength={3}
                                class="form-control helptext"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="000"
                                onChange={(e) =>
                                  this.setState({ cvc: e.target.value })
                                }
                              />
                            </div>
                            <div className="ml-2 mt-2">
                              <CreditCardOutlined
                                style={{
                                  fontSize: 35,
                                  marginTop: 3,
                                  color: "rgb(202, 202, 202)",
                                }}
                              />
                            </div>
                          </div>
                        </form>
                      )}
                    </div>
                  )}
                </Modal>
                <div style={{ marginTop: 20, width: "100%" }}>
                  {this.state.isBought ? (
                    <Button
                      type="primary"
                      className="mt-2 mb-2"
                      onClick={() => this.setState({ Show_Public_Url: true })}
                    >
                      See Public URL
                    </Button>
                  ) : (
                    post.Image.isPurchaseable &&
                    this.props.user.userID !== this.state.post.uid && (
                      <Button
                        type="primary"
                        className="mt-2 mb-2"
                        onClick={() => this.setState({ paymentModal: true })}
                      >
                        Buy Image
                      </Button>
                    )
                  )}
                  {this.state.Show_Public_Url && (
                    <div>
                      <p>Please use the following image URL to share:</p>
                      <a href={BASE_URL_WEBSITE + this.props.location.pathname}>
                        {BASE_URL_WEBSITE +
                          `/public/post/id-${post.id}/${this.props.user.userID}`}
                      </a>
                    </div>
                  )}
                  <div className="post-name-conatiner">
                    <p
                      style={{
                        fontSize: 20,
                        fontWeight: "600",
                        margin: 0,
                      }}
                    >
                      Post Name:
                    </p>
                    <p style={{ fontSize: 18, margin: 0, marginLeft: 10 }}>
                      {post.name}
                    </p>
                  </div>

                  {post.description && (
                    <div
                      style={{ borderTop: "solid 1px black" }}
                      className="post-description-container"
                    >
                      <p style={{ fontSize: 20, fontWeight: "600", margin: 0 }}>
                        Post Description:
                      </p>
                      <span style={{ fontSize: 18, margin: 0 }}>
                        {post.description}
                      </span>
                    </div>
                  )}
                  <hr className="m-2 mode-selector-post " />
                  <div className="mode-selector-post ">
                    <Dropdown overlay={ModeMenu}>
                      <a
                        className="ant-dropdown-link mr-2"
                        onClick={(e) => e.preventDefault()}
                      >
                        Modes
                      </a>
                    </Dropdown>
                    <Dropdown overlay={ControlMenu}>
                      <a
                        className="ant-dropdown-link mr-2"
                        onClick={(e) => e.preventDefault()}
                      >
                        Controlls
                      </a>
                    </Dropdown>
                    <Dropdown overlay={PanoromaMenu}>
                      <a
                        className="ant-dropdown-link"
                        onClick={(e) => e.preventDefault()}
                      >
                        Panoroma
                      </a>
                    </Dropdown>
                  </div>
                  {post.Image.audio_one && (
                    <div className="margin-mobile">
                      <hr className="m-2" />
                      <p
                        style={{
                          fontSize: 20,
                          fontWeight: "600",
                          marginBottom: 10,
                        }}
                      >
                        Audio:
                      </p>
                    </div>
                  )}
                  {post.Image.audio_one ? (
                    <div className="margin-mobile">
                      <Button
                        onClick={() => {
                          if (this.state.playingAudio) {
                            this.audioPlayer.pause();
                            this.setState({ playingAudio: false });
                          } else {
                            this.audioPlayer.play();
                            this.setState({ playingAudio: true });
                          }
                        }}
                      >
                        {this.state.playingAudio ? "Pause " : "Play "}{" "}
                        Background Music
                      </Button>
                      <FontAwesomeIcon
                        onClick={() => {
                          this.audioPlayer.muted = !this.state
                            .isFirstAudioMuted;
                          this.setState({
                            isFirstAudioMuted: !this.state.isFirstAudioMuted,
                          });
                        }}
                        style={{ marginLeft: 10, cursor: "pointer" }}
                        icon={
                          !this.state.isFirstAudioMuted
                            ? faVolumeUp
                            : faVolumeMute
                        }
                      />
                      <Row gutter={10}>
                        <Col span={18}>
                          <Slider
                            min={0}
                            max={100}
                            defaultValue={100}
                            onChange={(value) => {
                              const volume = value / 100;
                              this.setState({ backgroundMusicLevel: volume });
                              this.audioPlayer.volume = volume;
                            }}
                          />
                        </Col>
                        <Col span={6}>
                          <span style={{ fontSize: 15 }}>
                            {this.state.backgroundMusicLevel * 100} %
                          </span>
                        </Col>
                      </Row>
                    </div>
                  ) : undefined}
                  {post.Image.audio_two ? (
                    <div className="margin-mobile">
                      <Button
                        className="mt-2"
                        onClick={() => {
                          if (this.state.playingNarratorAudio) {
                            this.narratorAudioPlayer.pause();
                            this.setState({ playingNarratorAudio: false });
                          } else {
                            this.narratorAudioPlayer.play();
                            this.setState({ playingNarratorAudio: true });
                          }
                        }}
                      >
                        {this.state.playingNarratorAudio ? "Pause " : "Play "}{" "}
                        Narrator
                      </Button>
                      <FontAwesomeIcon
                        onClick={() => {
                          this.narratorAudioPlayer.muted = !this.state
                            .isSecondAudioMuted;
                          this.setState({
                            isSecondAudioMuted: !this.state.isSecondAudioMuted,
                          });
                        }}
                        style={{ marginLeft: 10, cursor: "pointer" }}
                        icon={
                          !this.state.isSecondAudioMuted
                            ? faVolumeUp
                            : faVolumeMute
                        }
                      />
                      <Row gutter={10}>
                        <Col span={18}>
                          <Slider
                            min={0}
                            max={100}
                            defaultValue={100}
                            onChange={(value) => {
                              const volume = value / 100;
                              this.setState({ narratorAudioLevel: volume });
                              this.narratorAudioPlayer.volume = volume;
                            }}
                          />
                        </Col>
                        <Col span={6}>
                          <span style={{ fontSize: 15 }}>
                            {this.state.narratorAudioLevel * 100} %
                          </span>
                        </Col>
                      </Row>
                    </div>
                  ) : undefined}
                </div>
                <div
                  className="margin-mobile"
                  style={{
                    marginTop: 10,
                    borderTop: "solid 1px lightgrey",
                    width: "90%",
                  }}
                >
                  <div className="mt-2 mb-2">{comments.length} Comments</div>
                  {comments.length > 0 && <CommentList comments={comments} />}
                  <Comment
                    content={
                      <div>
                        <TextArea
                          rows={4}
                          onChange={(event) =>
                            this.setState({ value: event.target.value })
                          }
                          value={value}
                        />
                        <Button
                          htmlType="submit"
                          className="mt-2"
                          loading={submitting}
                          onClick={() => this.handleSubmit()}
                          type="primary"
                        >
                          Add Comment
                        </Button>
                      </div>
                    }
                  />
                </div>
              </div>
            </>
          </React.Fragment>
        )}
      </div>
    );
  }
}
const mapDispatchToProps = {
  addToCart,
};
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
    filterName: state.editor.filterName,
    intensity: state.editor.intensity,
    pitchLimit: state.editor.pitchLimit,
    zoomLimit: state.editor.zoomLimit,
    privacy: state.editor.privacy,
    description: state.editor.description,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PostView);
