import React, { useState } from "react";
import {
  Col,
  Row,
  Upload,
  message,
  Button,
  Spin,
  Dropdown,
  Menu,
  Space,
  Modal,
  InputNumber,
  Input,
} from "antd";
import {
  InboxOutlined,
  UploadOutlined,
  DownOutlined,
  CloseOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import Base64ToFile from "../../Helpers/Base64ToFile";
import { connect } from "react-redux";
import errors from "../../constants/FormErrors";
import Api from "../../Api Wrapper/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import {
  setImages,
  deleteImage,
  resetImages,
  setName,
  setDesc,
  setTour,
} from "../../js/actions/tour/tour";
import "./index.scss";
import ValidateImageorVideo from "../../Helpers/Validator";

const { Dragger } = Upload;
function CreateTour(props) {
  const [activeTab, setActiveTab] = useState("post");
  const [isImage, setIsImage] = useState(false);
  const [audioFile, setAudioFile] = useState("");
  const [privacy, setPrivacy] = useState("Public");
  const [transition, setTransition] = useState("Crossfade");
  const [postingTour, setPostingTour] = useState(false);
  const [isWalkThrough, setisWalkThrough] = useState(false);
  const [isClickAnywhere, setisClickAnywhere] = useState(false);
  const [isPurchaseable, setIsPurchaseable] = useState(false);
  const [isBuyModalVisible, setIsBuyModalVisible] = useState(false);
  const [isBuyModalSecVisible, setIsBuyModalSecVisible] = useState(false);
  const [purchasePrice, setPurchasePrice] = useState(0);
  const [AccountNumber, setAccountNumber] = useState(0);
  const [AccountTitle, setAccountTitle] = useState("");

  const onSubmit = async () => {
    if (props.name === "") {
      messages(errors.PostNameCheck);
    } else if (props.images.length < 1) {
      messages(errors.TourImageCheck);
    } else {
      setPostingTour(true);
      let files = [];
      const form = new FormData();
      props.images.forEach((e) => {
        const fileExt = e.split(";", 2);
        const extention = fileExt[0].split("/", 2);
        files = [
          ...files,
          Base64ToFile(e, "test" + e.length + `.${extention[1]}`),
        ];
      });

      form.append("tour_name", props.name);
      form.append("uid", props.user.userID);
      form.append("tour_description", props.description);
      form.append("initialView", 0);
      form.append("isPurchaseable", isPurchaseable);
      form.append("purchasePrice", purchasePrice);
      form.append("user_acc_num", AccountNumber);
      form.append("user_acc_title", AccountTitle);
      form.append("walk_through", isWalkThrough);
      form.append("transition", transition);
      form.append("clickAnyWhere", isClickAnywhere);
      form.append("privacy", privacy);
      form.append("audio", audioFile);
      files.forEach((e) => {
        form.append("images", e);
      });

      await Api.post(`/tour/add`, form).then((res) => {
        if (res.status === 200) {
          setPostingTour(false);
          props.resetImages();
          props.router.push(`/EditTour/${res.data.post.id}`);
        } else {
          setPostingTour(false);
          message.error(
            "There was a problem uploading your tour, please try again later."
          );
        }
      });
    }
  };

  const getBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  var i = 0;
  const handlePreview = async (file, info) => {
    let preview = "";
    if (!file.url && !file.preview) {
      i = i + 1;
      if (i <= 6) {
        if (
          ValidateImageorVideo(file.name) === "image" ||
          ValidateImageorVideo(file.name) === "video"
        ) {
          preview = await getBase64(file);
          props.setImages(preview);
          setIsImage(true);
        } else {
          messages(errors.ValidImageorVideoExt);
        }
      } else {
        messages(errors.TourImageMax);
        props.resetImages();
        setIsImage(false);
      }
    }
  };
  const messages = (checkMessage) => {
    message.info({
      content: checkMessage,
      style: {
        marginTop: 100,
      },
    });
  };
  const menu = () => {
    return (
      <Menu
        style={{ backgroundColor: "rgb(248, 248, 248)" }}
        onClick={(e) => {
          setPrivacy(e.key);
        }}
      >
        <Menu.Item style={{ color: "black" }} key={"Public"}>
          Public
          <br />
          <em style={{ fontSize: 12, color: "#757575" }}>
            Accessible to everyone
          </em>
          <hr className="m-0" style={{ borderWidth: 3 }} />
        </Menu.Item>
        <Menu.Item disabled style={{ color: "black" }} key={"Unlisted"}>
          Unlisted{" "}
          <em
            style={{
              color: "white",
              backgroundColor: "rgb(129, 209, 53)",
              padding: 3,
              fontSize: 12,
              borderRadius: 5,
            }}
          >
            PRO
          </em>
          <br />
          <em style={{ fontSize: 12, color: "#757575" }}>
            {" "}
            Access via direct link
          </em>
          <hr className="m-0" style={{ borderWidth: 3 }} />
        </Menu.Item>
        <Menu.Item
          disabled
          style={{ color: "black" }}
          key={"Password Protected"}
        >
          Password Protected{" "}
          <em
            style={{
              color: "white",
              backgroundColor: "rgb(129, 209, 53)",
              padding: 3,
              fontSize: 12,
              borderRadius: 5,
            }}
          >
            Buisness
          </em>
          <br />
          <em style={{ fontSize: 12, color: "#757575" }}>
            {" "}
            Access with a password
          </em>
          <hr className="m-0" style={{ borderWidth: 3 }} />
        </Menu.Item>
        <Menu.Item disabled style={{ color: "black" }} key={"Private"}>
          Private{" "}
          <em
            style={{
              color: "white",
              backgroundColor: "rgb(129, 209, 53)",
              padding: 3,
              fontSize: 12,
              borderRadius: 5,
            }}
          >
            PRO
          </em>
          <br />
          <em style={{ fontSize: 12, color: "#757575" }}>
            {" "}
            Access only to you{" "}
          </em>
          <hr className="m-0" style={{ borderWidth: 3 }} />
        </Menu.Item>
      </Menu>
    );
  };
  const trasitionTypes = () => {
    return (
      <Menu
        style={{ backgroundColor: "rgb(248, 248, 248)" }}
        onClick={(e) => {
          setTransition(e.key);
        }}
      >
        <Menu.Item style={{ color: "black" }} key={"Crossfade"}>
          Crossfade
        </Menu.Item>
        <Menu.Item style={{ color: "black" }} key={"Walki-in"}>
          Walki-in
        </Menu.Item>
        <Menu.Item style={{ color: "black" }} key={"Radial fade"}>
          Radial fade
        </Menu.Item>
        <Menu.Item style={{ color: "black" }} key={"Vertivle Wipe"}>
          Verticle Wipe
        </Menu.Item>
        <Menu.Item style={{ color: "black" }} key={"Dot effect"}>
          Dot effect
        </Menu.Item>
        <Menu.Item style={{ color: "black" }} key={"Curtain Effect"}>
          Curtain Effect
        </Menu.Item>
      </Menu>
    );
  };
  return (
    <div className="m-0 mt-2 pr-5 pl-5 pb-0">
      <div className="mt-4">
        <div className="d-flex flex-row">
          <p
            className={
              activeTab === "post" ? "tour-tabs-selected" : "tour-tabs"
            }
            onClick={() => setActiveTab("post")}
          >
            Posts
          </p>
          <p
            className={
              activeTab === "settings" ? "tour-tabs-selected" : "tour-tabs"
            }
            onClick={() => setActiveTab("settings")}
          >
            Tour settings
          </p>
        </div>
        <hr className="m-0" style={{ backgroundColor: "#757575" }} />
        {activeTab === "post" ? (
          <div className="mt-5">
            {isImage ? (
              <div className="d-flex flex-row p-3 justify-content-center position-relative mb-5">
                {props.images.map((item, index) => {
                  const isImages = item.split("/", 2);
                  return isImages[0] === "data:image" ? (
                    <div key={index}>
                      <FontAwesomeIcon
                        onClick={() => {
                          props.deleteImage(item);
                          if (props.images.length <= 1) {
                            setIsImage(false);
                          }
                        }}
                        icon={faTimesCircle}
                        className="image-cross"
                      />
                      <img className="tour-images" src={item} />
                    </div>
                  ) : (
                    <div key={index}>
                      <FontAwesomeIcon
                        onClick={() => {
                          props.deleteImage(item);
                          if (props.images.length <= 1) {
                            setIsImage(false);
                          }
                        }}
                        icon={faTimesCircle}
                        className="image-cross"
                      />
                      <video className="tour-images" src={item} />
                    </div>
                  );
                })}
              </div>
            ) : (
              <Dragger
                className="mb-5"
                beforeUpload={() => false}
                style={{ backgroundColor: "transparent" }}
                onChange={(info) => handlePreview(info.file, info)}
                multiple={true}
                maxCount={6}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p style={{ color: "black" }} className="ant-upload-text ">
                  Click or drag Image/Video to this area to upload
                </p>
                <p style={{ color: "black" }} className="ant-upload-hint">
                  You can upload max 6 files per batch. For bigger tours, upload
                  in rounds & use 'Add to existing Tour's
                </p>
              </Dragger>
            )}
          </div>
        ) : (
          <div className="mt-3 mb-3">
            <div className="setting-container">
              <div>
                <p className="setting-label">Privacy</p>
                <Space wrap>
                  <Dropdown overlay={menu()}>
                    <Button
                      style={{
                        backgroundColor: "rgb(129, 209, 53)",
                        border: "none",
                        color: "white",
                        width: 170,
                        textAlign: "start",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {privacy} <DownOutlined />
                    </Button>
                  </Dropdown>
                </Space>
              </div>
              <div className="vr" />
              <div>
                <p className="setting-label">Trasition Type</p>
                <Space wrap>
                  <Dropdown overlay={trasitionTypes()}>
                    <Button
                      style={{
                        backgroundColor: "white",
                        color: "black",
                        width: 170,
                        textAlign: "start",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {transition} <DownOutlined />
                    </Button>
                  </Dropdown>
                </Space>
              </div>
              <div className="vr" />
              <div>
                <p className="setting-label">
                  WalkThrough Mode{" "}
                  <em
                    style={{
                      color: "white",
                      backgroundColor: "rgb(129, 209, 53)",
                      padding: 3,
                      fontSize: 12,
                      fontWeight: "bold",
                      borderRadius: 5,
                    }}
                  >
                    PRO
                  </em>
                </p>
                <div
                  // style={{ pointerEvents: "none" }}
                  onClick={() => setisWalkThrough(!isWalkThrough)}
                  className={!isWalkThrough ? "off-btn" : "on-btn"}
                >
                  {isWalkThrough ? (
                    <>
                      {" "}
                      <CheckOutlined
                        style={{
                          fontSize: 12,
                          fontWeight: "bold",
                          marginRight: 3,
                        }}
                      />
                      <em style={{ fontSize: 12 }}>ON</em>
                    </>
                  ) : (
                    <>
                      {" "}
                      <CloseOutlined style={{ fontSize: 8, marginRight: 3 }} />
                      <em style={{ fontSize: 12 }}>OFF</em>
                    </>
                  )}
                </div>
              </div>
              <div className="vr" />
              <div>
                <p className="setting-label">For Sale?</p>
                {isPurchaseable && (
                  <>
                    <span
                      style={{
                        color: "grey",
                        fontSize: 15,
                      }}
                    >
                      Sale Price Per Month ({purchasePrice} $)
                    </span>
                    <br />
                    <span
                      style={{
                        cursor: "pointer",
                        color: "#4a90e2",
                        textDecorationLine: "underline",
                        fontSize: 15,
                      }}
                      onClick={() => {
                        setIsBuyModalSecVisible(true);
                      }}
                    >
                      Edit Details
                    </span>
                  </>
                )}
                <div
                  onClick={() => {
                    setIsPurchaseable(!isPurchaseable);
                    setIsBuyModalVisible(true);
                  }}
                  className={!isPurchaseable ? "off-btn" : "on-btn"}
                >
                  {isPurchaseable ? (
                    <>
                      {" "}
                      <CheckOutlined
                        style={{
                          fontSize: 12,
                          fontWeight: "bold",
                          marginRight: 3,
                        }}
                      />
                      <em style={{ fontSize: 12 }}>Yes</em>
                    </>
                  ) : (
                    <>
                      {" "}
                      <CloseOutlined style={{ fontSize: 8, marginRight: 3 }} />
                      <em style={{ fontSize: 12 }}>No</em>
                    </>
                  )}
                </div>
              </div>
              <Modal
                title="Terms and conditions"
                width="40%"
                visible={isBuyModalVisible}
                centered={true}
                closable={false}
                okText="Accept"
                bodyStyle={{
                  padding: 0,
                  paddingLeft: 10,
                }}
                onOk={() => {
                  setIsBuyModalVisible(false);
                  setIsBuyModalSecVisible(true);
                }}
                onCancel={() => {
                  setIsBuyModalVisible(false);
                  setPurchasePrice(0);
                  setAccountNumber(0);
                  setAccountTitle("");
                  setIsPurchaseable(false);
                }}
              >
                <div className="ml-2">
                  <ul>
                    <li>
                      Contrary to popular belief, Lorem Ipsum is not simply
                      random text.
                    </li>
                    <li>
                      Contrary to popular belief, Lorem Ipsum is not simply
                      random text.
                    </li>
                    <li>
                      Contrary to popular belief, Lorem Ipsum is not simply
                      random text.
                    </li>
                    <li>
                      Contrary to popular belief, Lorem Ipsum is not simply
                      random text.
                    </li>
                    <li>
                      Contrary to popular belief, Lorem Ipsum is not simply
                      random text.
                    </li>
                  </ul>
                </div>
              </Modal>
              <Modal
                title="Sale Tour"
                width="40%"
                visible={isBuyModalSecVisible}
                centered={true}
                closable={false}
                okText="Save"
                bodyStyle={{
                  padding: 0,
                  paddingLeft: 10,
                }}
                onOk={() => {
                  if (purchasePrice === 0) {
                    messages(errors.ImagePurchaseCheck);
                  } else {
                    setIsBuyModalSecVisible(false);
                  }
                }}
                onCancel={() => {
                  setIsBuyModalSecVisible(false);
                  setPurchasePrice(0);
                  setAccountNumber(0);
                  setAccountTitle("");
                  setIsPurchaseable(false);
                }}
              >
                <div className="ml-2">
                  <br />
                  <span style={{ fontSize: 15, fontWeight: "bold" }}>
                    Sale Price Per Month (USD $)
                  </span>
                  <br />
                  <InputNumber
                    style={{ width: "50%", marginBottom: 10 }}
                    placeholder="Picture price per month"
                    value={purchasePrice}
                    onChange={(event) => setPurchasePrice(event)}
                  />
                  <br />
                  <span style={{ fontSize: 15, fontWeight: "bold" }}>
                    Account Number
                  </span>
                  <br />
                  <InputNumber
                    style={{ width: "50%", marginBottom: 10 }}
                    placeholder="Account Number"
                    value={AccountNumber}
                    onChange={(event) => setAccountNumber(event)}
                  />
                  <br />
                  <span style={{ fontSize: 15, fontWeight: "bold" }}>
                    Account Title
                  </span>
                  <br />
                  <Input
                    style={{ width: "50%", marginBottom: 10 }}
                    placeholder="Account Title"
                    value={AccountTitle}
                    onChange={(value) => {
                      setAccountTitle(value.target.value);
                    }}
                  />
                </div>
              </Modal>
            </div>
          </div>
        )}
      </div>
      <hr />
      <Row className="mb-5 pb-5">
        <Col span={10}>
          <input
            onChange={(e) => props.setName(e.target.value)}
            placeholder="Tour Name"
            className="tour-name-text-field pl-2"
          />
          <textarea
            onChange={(e) => props.setDesc(e.target.value)}
            placeholder="Tour description (Optional)"
            className="tour-name-text-field pl-2 pt-2 h-100"
          />
        </Col>
        <Col span={14} className="d-flex justify-content-end">
          <Row gutter={10}>
            <Col>
              <button
                onClick={() => onSubmit()}
                className="btn btn-outline-warning button-outline"
              >
                {postingTour ? (
                  <Spin style={{ color: "grey", width: "100%", padding: 50 }} />
                ) : (
                  "POST"
                )}
              </button>
            </Col>
            <Col>
              <button className="btn btn-outline-warning button-outline">
                CANCEL
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
const mapDispatchToProps = {
  setImages,
  resetImages,
  deleteImage,
  setName,
  setDesc,
  setTour,
};

const mapStateToProps = (state) => {
  return {
    images: state.tour.images,
    name: state.tour.name,
    description: state.tour.description,
    user: state.auth.user,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateTour);
