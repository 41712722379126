import { ADD_TO_CART } from "../actions/cart/cart";

const cart = (
  state = {
    cart: [],
  },
  action
) => {
  switch (action.type) {
    case ADD_TO_CART:
      return Object.assign({}, state, {
        cart: [...state.cart, action.product],
      });
    default:
      return state;
  }
};
export default cart;
