import React, { useState } from "react";
import "../Screens/Home/index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faPen } from "@fortawesome/free-solid-svg-icons";
import { Upload, Avatar, message, Tooltip } from "antd";
import { UserOutlined } from "@ant-design/icons";
import Api from "../Api Wrapper/Api";
import { BASE_URL_IMAGE } from "../constants/URL";
import Base64ToFile from "../Helpers/Base64ToFile";

export default function HomeProfile(props) {
  const imgPath = process.env.PUBLIC_URL + "/images/";
  const [profilePic, setProfilePic] = useState();
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleProfilePic = async (file, info) => {
    const { status } = info.file;
    let preview = "";
    if (!file.url && !file.preview && status === "error") {
      preview = await getBase64(file.originFileObj);
      const ImageFile = Base64ToFile(preview);
      const form = new FormData();
      form.append("image", ImageFile);
      await Api.put(`/users/profilePic?uid=${props.userID}`, form)
        .then((res) => {
          if (res.status === 200) {
            message.success("Profile Picture Updated");
            window.location.reload();
          } else {
            message.error("something went wrong");
          }
        })
        .catch((err) => {
          console.log(err);
        });
      setProfilePic(preview);
    }
  };

  return (
    <div className="profile_container">
      <div
        className={`profile_image text-center ${
          props.userType ? "mb-3" : undefined
        }`}
      >
        {profilePic ? (
          <img className="profile_placeholder" alt="" src={profilePic} />
        ) : (
          <React.Fragment>
            {props.image ? (
              <img
                className="profile_placeholder"
                alt=""
                src={BASE_URL_IMAGE + props.image}
              />
            ) : (
              <img
                className="profile_placeholder"
                alt=""
                src={`${imgPath}empty-profile-image.jpg`}
              />
            )}
          </React.Fragment>
        )}

        <div className="camera">
          {!props.isVisitor && (
            <Upload
              multiple={false}
              showUploadList={false}
              onChange={async (info) => {
                handleProfilePic(info.file, info);
              }}
            >
              <FontAwesomeIcon
                icon={faCamera}
                style={{ color: "black", fontSize: 20 }}
              />
            </Upload>
          )}
        </div>
      </div>
      <div className="profile_shadow">
        {/* <div className="center-block border-profile mt-4 mb-5"></div> */}
        {props.mobView && (
          <FontAwesomeIcon
            icon={faPen}
            style={{
              cursor: "pointer",
              marginTop: "10px",
              marginLeft: "170px",
            }}
            onClick={() => props.router.push(`/edit-profile/${props.name}`)}
          />
        )}
        <div
          onClick={() =>
            props.mobView && props.router.push(`/profile/photos/${props.name}`)
          }
        >
          <Tooltip placement="right" title={props.displayName}>
            <p className="text-center profile_name">
              {props.displayName
                ? props.displayName.length > 8
                  ? props.displayName.slice(" ", 8)
                  : props.displayName.charAt(0).toUpperCase() +
                    props.displayName.slice(1)
                : undefined}
            </p>
          </Tooltip>
        </div>
        {props.userType && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              alignSelf: "center",
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <span
              style={{
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 5,
                paddingBottom: 5,
                backgroundColor: "#28b62c",
                fontSize: 15,
                color: "#fff",
                borderRadius: 10,
              }}
            >
              {props.userType}
            </span>
          </div>
        )}
        <div>
          <p className="dark_text text-center m-0 sm-pb-10">
            {props.views ? props.views : 0} Views
          </p>
        </div>
        {!props.mobView && (
          <>
            <div className="row p-3">
              <div
                className="col-md-4 pointer-button"
                onClick={() =>
                  props.router.push(`/profile/photos/${props.name}`)
                }
              >
                <p className="text-center font-weight-bold m-0">
                  {props.photos}
                </p>
                <p className="dark_text text-center">Photos</p>
              </div>
              <div
                className="col-md-4 pointer-button"
                onClick={() => props.router.push(`/followers/${props.name}`)}
              >
                <p className="text-center font-weight-bold m-0">
                  {props.followers}
                </p>
                <p className="dark_text text-center">Followers</p>
              </div>
              <div
                onClick={() => props.router.push(`/following/${props.name}`)}
                className="col-md-4 pointer-button"
              >
                <p className="text-center font-weight-bold m-0">
                  {props.following}
                </p>
                <p className="dark_text text-center">Following</p>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
