import { create } from "apisauce";
import { BASE_URL } from "../constants/URL";
import { token } from "../LocalForage/localForage";

const apiClient = create({
  baseURL: BASE_URL,
});
const setTokenHeader = async () => {
  const t = await token.getItem("@token");
  apiClient.setHeaders({ token: t });
};
// TODO: THESE ARE SOME
setTokenHeader();
export default apiClient;
