import { ImageLoader } from "./ImageLoader.js";
import * as THREE from "three";

/**
 * @module TextureLoader
 * @description Texture loader based on {@link https://github.com/mrdoob/three.js/blob/master/src/loaders/TextureLoader.js}
 */
const TextureLoader = {
  /**
   * Load image texture
   * @example PANOLENS.TextureLoader.load( IMAGE_URL )
   * @method load
   * @param  {string}   url        - An image url
   * @param  {function} onLoad     - On load callback
   * @param  {function} onProgress - In progress callback
   * @param  {function} onError    - On error callback
   * @return {THREE.Texture}   	 - Image texture
   */
  load: function (url, onLoad = () => {}, onProgress, onError) {
    var texture = new THREE.Texture();

    ImageLoader.load(
      url,
      function (image) {
        texture.image = image;

        // JPEGs can't have an alpha channel, so memory can be saved by storing them as RGB.
        const isJPEG =
          url.search(/\.(jpg|jpeg)$/) > 0 ||
          url.search(/^data\:image\/jpeg/) === 0;

        texture.format = isJPEG ? THREE.RGBFormat : THREE.RGBAFormat;
        texture.needsUpdate = true;

        onLoad(texture);
      },
      onProgress,
      onError
    );

    return texture;
  },
};

export { TextureLoader };
