import React from "react";
import { Provider } from "react-redux";
import { hashHistory, IndexRoute, Route, Router } from "react-router";
import { routerMiddleware, syncHistoryWithStore } from "react-router-redux";
import { applyMiddleware, createStore } from "redux";
import ReduxThunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

import "./css/reset.css";
import "./css/font.css";
import "./App.scss";
import allReducers from "./js/reducers/index";

import App from "./js/containers/Wrapper/App";
import AppPrivate from "./js/containers/Wrapper/AppPrivate";

import About from "./components/about";
import Register from "./components/register";
import Login from "./components/login";
import Support from "./components/support";
import Pricing from "./components/pricing";
import Explore from "./components/explore";
import Blog from "./components/blog";
import FroastiePro from "./Screens/FrostieVRPro";

import Timeline from "./Screens/Timeline";
import Followers from "./Screens/Followers";
import Following from "./Screens/Following";
import EditProfile from "./Screens/EditProfile";
import Photos from "./Screens/Photos";
import CreateTour from "./Screens/CreateTour";
import SingleImage from "./Screens/SingleImage";
import PostView from "./Screens/PostView";
import TourView from "./Screens/TourView";
import EditTour from "./Screens/EditTour";
import PublicPostView from "./Screens/PublicPostView";
import Page404 from "./Screens/404 Screen";
import Cart from "./Screens/Cart";
import Order from "./Screens/Orders";
import PublicTourView from "./Screens/PublicTourView";
import TrackOrder from "./Screens/TrackOrder";
import Notification from "./Screens/Notification";

const routingMiddleware = routerMiddleware(hashHistory);

const store = createStore(
  allReducers,
  composeWithDevTools(applyMiddleware(ReduxThunk, routingMiddleware))
);

const history = syncHistoryWithStore(hashHistory, store);

const Root = () => (
  <Provider store={store}>
    <Router history={history}>
      <Route path="/" component={App}>
        <IndexRoute component={About} />
        <Route path="/register" component={Register} />
        <Route path="/login" component={Login} />
        <Route path="/support" component={Support} />
        <Route path="/pricing" component={Pricing} />
        <Route path="/explore/:key" component={Explore} />
        <Route path="/blog" component={Blog} />
        <Route path="/post-view/:id" component={PostView} />
        <Route path="/tour-view/:id/:uid" component={TourView} />
        <Route path="/profile/:key/:name" component={Photos} />
        <Route path="/post-view/public/:id/:uid" component={PostView} />
      </Route>
      <Route path="/home" component={AppPrivate}>
        <IndexRoute component={Timeline} />
        <Route path="/froastie-vr-pro" component={FroastiePro} />
        <Route path="/followers/:name" component={Followers} />
        <Route path="/profile/:key/:name" component={Photos} />
        <Route path="/edit-profile/:name" component={EditProfile} />
        <Route path="/following/:name" component={Following} />
        <Route path="/create-tour" component={CreateTour} />
        <Route path="/single-image/:id" component={SingleImage} />
        <Route path="/EditTour/:id" component={EditTour} />
        <Route path="/post-view/:id" component={PostView} />
        <Route path="/tour-view/:id/:uid" component={TourView} />
        <Route path="/cart" component={Cart} />
        <Route path="/order" component={Order} />
        <Route path="/tracker" component={TrackOrder} />
        <Route path="/notification" component={Notification} />
      </Route>
      <Route path="/public/post/:id/:uid" component={PublicPostView} />
      <Route path="/public/tour/:id/:uid" component={PublicTourView} />
      <Route path="*" exact={true} component={Page404} />
    </Router>
  </Provider>
);
export default Root;
