export const ADD_TO_CART = "ADD_TO_CART";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
// export const ADD_TO_CART = "ADD_TO_CART";

export const addToCart = (product) => (dispatch) => {
  dispatch({
    type: ADD_TO_CART,
    product,
  });
};
