import React, { useState } from "react";

import apiClient from "../Api Wrapper/Api";
import { connect } from "react-redux";

function ProfileChangePassword(props) {
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleClick = () => {
    apiClient
      .put(`users/updatePass?uid=${props.user.userID}`, {
        oldPassword: oldPass,
        newPassword: newPass,
      })
      .then((response) => {
        if (response && response.data) {
          if (response && response.status === 200) {
            setShowSuccessMessage(true);
            // props.router.push(`/profile/photos/${this.user.username}`);
          } else {
          }
        } else {
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="mt-5 sm-widht-90 sm-align-center">
      <div>
        <h2 className="sm-fs-20">Change Password:</h2>
        <div className="form-group sm-mt-40">
          <label className="subheadtext sm-fs-15" htmlFor="exampleInputEmail1">
            Old Password:
          </label>
          <input
            style={{ fontStyle: "italic", borderRadius: 25 }}
            type="password"
            className="form-control  helptext lg-widht-470 sm-widht-260-px sm-align-center"
            // id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="***************"
            value={oldPass}
            onChange={(e) => setOldPass(e.target.value)}
          />
          <p
            style={{ fontStyle: "italic" }}
            className="helptext sm-fs-15 lg-fs-20 lg-widht-600 sm-widht-260-px sm-align-center"
          >
            If your signed in with Facebook and have no password, leave this
            field blank.
          </p>
        </div>
        <div className="form-group sm-mt-40">
          <label className="subheadtext" htmlFor="exampleInputEmail1">
            New Password:
          </label>
          <input
            style={{ fontStyle: "italic", borderRadius: 25 }}
            type="password"
            className="form-control  helptext lg-widht-470 sm-widht-260-px sm-align-center"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            value={newPass}
            onChange={(e) => setNewPass(e.target.value)}
          />
        </div>
      </div>
      {showSuccessMessage && (
        <div style={{ color: "green" }}>
          We've successfully updated your password!
        </div>
      )}
      <button
        style={{ marginBottom: "10px" }}
        type="submit"
        className="btn btn-primary mt-4 ml-4 lg-widht-100 sm-widht-260"
        onClick={handleClick}
      >
        Save Changes
      </button>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};
export default connect(mapStateToProps)(ProfileChangePassword);
