import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Empty, Modal, Steps, message, Spin } from "antd";
import Texty from "rc-texty";
import "./index.scss";
import ProductApi from "../../Api Wrapper/ProductApi";
import moment from "moment";

const { Step } = Steps;
function index(props) {
  const [allOrders, setallOrders] = useState([]);
  const [orderDetail, setOrderDetail] = useState([]);
  const [billDetail, setBillDetail] = useState();
  const [loading, setLoading] = useState(true);
  const [detailModal, setdetailModal] = useState(false);

  useEffect(() => {
    setLoading(true);
    ProductApi.get(
      `/customer/order/customerOrders?customer_id=${props.user.ecommerce_user_id}`
    )
      .then((response) => {
        console.log(response.data);
        setallOrders(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        message.error("somthing went wrong");
      });
  }, []);

  const getOrderDetails = (orderId) => {
    const orderDetailForm = new FormData();
    orderDetailForm.append("customer_id", props.user.ecommerce_user_id);
    orderDetailForm.append("order_id", orderId);
    ProductApi.post("customer/order/orderDetails", orderDetailForm)
      .then((res) => {
        console.log("details");
        console.log(res.data.data);
        setOrderDetail(res.data.data.order_items);
        setBillDetail(res.data.data);
        setdetailModal(true);
      })
      .catch((err) => {
        message.error("somthing went wrong");
      });
  };
  return (
    <div>
      <Texty
        mode={"smooth"}
        style={{
          fontSize: 30,
          fontWeight: "bold",
          marginTop: 30,
          marginLeft: 500,
        }}
      >
        YOUR ORDERS
      </Texty>
      {loading ? (
        <Spin
          style={{ marginLeft: "50%", marginBottom: "20%", marginTop: 20 }}
        />
      ) : allOrders.length < 1 ? (
        <Empty
          className="mb-5"
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 60,
          }}
          description={<span>No Orders Placed Yet</span>}
        ></Empty>
      ) : (
        <>
          <div
            className="cart-border"
            style={{
              marginLeft: "25%",
              marginTop: 20,
              marginBottom: 80,
              width: "50%",
            }}
          >
            <Row className="w-100 d-flex justify-content-lg-between  p-2">
              <Col span={6} className="p-3 justify-content-center d-flex">
                <Texty mode={"smooth"} style={{ fontWeight: "600" }}>
                  Placed At
                </Texty>
              </Col>

              <Col span={6} className="p-3 justify-content-center d-flex">
                <Texty mode={"smooth"} style={{ fontWeight: "600" }}>
                  Tracking#
                </Texty>
              </Col>
              <Col span={6} className="p-3 justify-content-center d-flex">
                <Texty mode={"smooth"} style={{ fontWeight: "600" }}>
                  Status
                </Texty>
              </Col>
              <Col span={6} className="p-3 justify-content-center d-flex">
                <Texty mode={"smooth"} style={{ fontWeight: "600" }}>
                  Action
                </Texty>
              </Col>
            </Row>
            {allOrders.map((item) => (
              <Row className="w-100 d-flex justify-content-lg-between  p-3">
                <Col span={6} className="p-1 justify-content-center d-flex">
                  {moment(item.created_at).format("LLL")}
                </Col>
                <Col span={6} className="p-1 justify-content-center d-flex">
                  {item.tracking_id}
                </Col>
                <Col span={6} className="p-1 justify-content-center d-flex">
                  Pending
                </Col>
                <Col span={6} className="p-1 justify-content-center d-flex">
                  <a
                    onClick={() => {
                      getOrderDetails(item.order_items[0].order_id);
                    }}
                    style={{
                      color: "#4a90e2",
                      textDecorationLine: "underline",
                    }}
                  >
                    Manage Order
                  </a>
                </Col>
              </Row>
            ))}
          </div>
        </>
      )}

      <Modal
        title="Order Details"
        centered={true}
        width="50%"
        footer={null}
        // bodyStyle={{ height: 500 }}
        visible={detailModal}
        onOk={() => setdetailModal(false)}
        onCancel={() => setdetailModal(false)}
      >
        <Row className="mt-1 justify-content-between">
          <Col span={8} className="justify-content-center d-flex">
            <Texty mode={"smooth"} style={{ fontWeight: "600", fontSize: 16 }}>
              Product
            </Texty>
          </Col>
          <Col span={8} className="justify-content-center d-flex">
            <Texty mode={"smooth"} style={{ fontWeight: "600", fontSize: 16 }}>
              Price
            </Texty>
          </Col>
          <Col span={8} className="justify-content-center d-flex">
            <Texty mode={"smooth"} style={{ fontWeight: "600", fontSize: 16 }}>
              Quantity
            </Texty>
          </Col>
        </Row>
        {orderDetail &&
          orderDetail.map((item) => {
            let product = JSON.parse(item.item_detail);
            console.log("******product.name");
            console.log(product);
            console.log("******product.name");
            return (
              <Row className="mt-1 justify-content-between">
                <Col span={8} className="justify-content-center d-flex">
                  <span>HeadPhone</span>
                </Col>
                <Col span={8} className="justify-content-center d-flex">
                  <span>{item.price_per_unit}$</span>
                </Col>
                <Col span={8} className="justify-content-center d-flex">
                  <span>{item.quantity}</span>
                </Col>
              </Row>
            );
          })}
        <hr />
        <Texty
          mode={"smooth"}
          style={{ fontWeight: "600", fontSize: 16, marginBottom: 10 }}
        >
          Status:
        </Texty>
        <Steps size="small" current={1}>
          <Step title="Order Placed" />
          <Step title="In Progress" />
          <Step title="Waiting" />
        </Steps>
        <hr />
        <div>
          <Texty
            mode={"smooth"}
            style={{ fontWeight: "600", fontSize: 16, marginBottom: 10 }}
          >
            Total Summary:
          </Texty>
          <Row className="w-50 d-flex justify-content-between">
            <Texty mode={"smooth"} style={{ fontWeight: "400", fontSize: 15 }}>
              SubTotal:
            </Texty>
            <span>${billDetail ? billDetail.sub_total : 0}</span>
          </Row>
          <Row className="w-50 d-flex justify-content-between">
            <Texty mode={"smooth"} style={{ fontWeight: "400", fontSize: 15 }}>
              Shipping Fee:
            </Texty>
            <span>${billDetail ? billDetail.total_shipping_cost : 0}</span>
          </Row>
          <Row className="w-50 d-flex justify-content-between">
            <Texty mode={"smooth"} style={{ fontWeight: "400", fontSize: 15 }}>
              Tax:
            </Texty>
            <span>${billDetail ? billDetail.tax : 0}</span>
          </Row>
          <Row className="w-50 d-flex justify-content-between">
            <Texty mode={"smooth"} style={{ fontWeight: "400", fontSize: 15 }}>
              Discount:
            </Texty>
            <span>{billDetail ? billDetail.discount : 0}%</span>
          </Row>
          <hr />
          <Row className="w-50 d-flex justify-content-between">
            <Texty mode={"smooth"} style={{ fontWeight: "400", fontSize: 15 }}>
              Total:
            </Texty>
            <span>${billDetail ? billDetail.total : 0}</span>
          </Row>
          <Button
            className="cancel-btn"
            onClick={() => message.success("order cancel succesfully")}
          >
            Cancel Order
          </Button>
        </div>
      </Modal>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    cart: state.cart.cart,
  };
};

export default connect(mapStateToProps)(index);
