import React, { useState, useEffect } from "react";
import HomeProfile from "../../CustomComponents/HomeProfile";
import ProfileEdit from "../../CustomComponents/ProfileEdit";
import ProfileUpgrade from "../../CustomComponents/ProfileUpgrade";
import ProfilePreferences from "../../CustomComponents/ProfilePreferences";
import ProfileChangePassword from "../../CustomComponents/ProfileChangePassword";
import "./index.scss";
import { connect } from "react-redux";
import { Col, Row } from "antd";
import { token } from "../../LocalForage/localForage";
import { getUserPosts } from "../../js/actions/Posts/posts.actions";
import { updateUserData, updateUser } from "../../js/actions/auth/updateUser";

function index(props) {
  const [isActive, setIsActive] = useState("profile");
  const [click, setClick] = useState(false);

  useEffect(() => {
    !props.user && props.getUserPosts(props.routeParams.name);
  }, []);
  return (
    <>
      <div className="container-fluid m-3 home-profile-photos">
        <Row className="p-3">
          <Col span={6}>
            <HomeProfile
              userType={props.userProfile ? props.userProfile.userType : ""}
              displayName={
                props.userProfile
                  ? props.userProfile.display_name
                  : props.user?.displayName
              }
              name={
                props.userProfile
                  ? props.userProfile.username
                  : props.user?.username
              }
              views={props.userProfile?.profileViews}
              photos={
                props.userPhotos.length === 0
                  ? 0
                  : props.userPhotos.Posts.length +
                    props.userPhotos.Tours.length
              }
              followers={
                props.userFollowers.length === 0
                  ? 0
                  : props.userFollowers.user_followed_by.length
              }
              following={
                props.userFollows.length === 0
                  ? 0
                  : props.userFollows.user_followed.length
              }
              image={
                props.user
                  ? props.user.profilePicture
                  : props.userProfile?.profilePicture
              }
              setClick={setClick}
              router={props.router}
              isVisitor={props.isVisitor}
              userID={props.user.userID}
            />
          </Col>

          <Col span={12}>
            <div className="d-flex flex-row justify-content-between align-items-center">
              <button
                onClick={() => setIsActive("profile")}
                className={
                  isActive === "profile"
                    ? "explore-button-active ml-2"
                    : "explore-button ml-2"
                }
              >
                <span
                  style={{ fontSize: "16px", fontWeight: 500 }}
                  className={
                    isActive === "profile"
                      ? "explore-text-active"
                      : "explore-text"
                  }
                >
                  Profile
                </span>
              </button>
              <button
                onClick={() => setIsActive("upgrade")}
                className={
                  isActive === "upgrade"
                    ? "explore-button-active ml-2"
                    : "explore-button ml-2"
                }
              >
                <span
                  style={{ fontSize: "16px", fontWeight: 500 }}
                  className={
                    isActive === "upgrade"
                      ? "explore-text-active"
                      : "explore-text"
                  }
                >
                  Upgrade
                </span>
              </button>
              <button
                onClick={() => setIsActive("preferences")}
                className={
                  isActive === "preferences"
                    ? "explore-button-active ml-2"
                    : "explore-button ml-2"
                }
              >
                <span
                  style={{ fontSize: "16px", fontWeight: 500 }}
                  className={
                    isActive === "preferences"
                      ? "explore-text-active"
                      : "explore-text"
                  }
                >
                  Preferences
                </span>
              </button>
              <button
                onClick={() => setIsActive("changepassword")}
                className={
                  isActive === "changepassword"
                    ? "explore-button-active ml-2"
                    : "explore-button ml-2"
                }
              >
                <span
                  style={{ fontSize: "16px", fontWeight: 500 }}
                  className={
                    isActive === "changepassword"
                      ? "explore-text-active"
                      : "explore-text"
                  }
                >
                  Change Password
                </span>
              </button>
            </div>
            <div className="mt-3">
              {isActive === "profile" && (
                <ProfileEdit
                  {...props}
                  updateUserName={(user) => props.updateUserData(user)}
                  updateUserProfile={(user) => props.updateUser(user)}
                  Active={(val) => setIsActive(val)}
                />
              )}
              {isActive === "upgrade" && (
                <ProfileUpgrade {...props} Active={(val) => setIsActive(val)} />
              )}
              {isActive === "preferences" && (
                <ProfilePreferences Active={(val) => setIsActive(val)} />
              )}
              {isActive === "changepassword" && (
                <ProfileChangePassword Active={(val) => setIsActive(val)} />
              )}
            </div>
          </Col>
          <Col className="" span={6} />
        </Row>
      </div>
      {/* small display settings */}
      <div className="sm-widht-100 sm-text-align sm-margin-top-15 lg-display-none">
        <button
          onClick={() => setIsActive("profile")}
          style={{
            width: "24%",
          }}
          className={
            isActive === "profile" ? "sm-tab-button-active" : "sm-tab-button"
          }
        >
          <span style={{ fontSize: "12px", fontWeight: 500 }}>Profile</span>
        </button>
        <button
          onClick={() => setIsActive("upgrade")}
          style={{
            width: "24%",
          }}
          className={
            isActive === "upgrade" ? "sm-tab-button-active" : "sm-tab-button"
          }
        >
          <span style={{ fontSize: "12px", fontWeight: 500 }}>Upgrade</span>
        </button>
        <button
          onClick={() => setIsActive("preferences")}
          style={{
            width: "24%",
          }}
          className={
            isActive === "preferences"
              ? "sm-tab-button-active"
              : "sm-tab-button"
          }
        >
          <span style={{ fontSize: "12px", fontWeight: 500 }}>Preferences</span>
        </button>
        <button
          onClick={() => setIsActive("changepassword")}
          style={{
            width: "24%",
          }}
          className={
            isActive === "changepassword"
              ? "sm-tab-button-active"
              : "sm-tab-button"
          }
        >
          <span style={{ fontSize: "12px", fontWeight: 500 }}>
            Change Password
          </span>
        </button>
        <div className="mt-3">
          {isActive === "profile" && (
            <ProfileEdit
              {...props}
              updateUserName={(user) => props.updateUserData(user)}
              updateUserProfile={(user) => props.updateUser(user)}
              Active={(val) => setIsActive(val)}
            />
          )}
          {isActive === "upgrade" && (
            <ProfileUpgrade {...props} Active={(val) => setIsActive(val)} />
          )}
          {isActive === "preferences" && (
            <ProfilePreferences Active={(val) => setIsActive(val)} />
          )}
          {isActive === "changepassword" && (
            <ProfileChangePassword Active={(val) => setIsActive(val)} />
          )}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    userPhotos: state.posts.userPhotos,
    userFollowers: state.posts.isVisitor
      ? state.followerProfile.userFollowers
      : state.posts.userFollowers,
    userFollows: state.posts.isVisitor
      ? state.followerProfile.userFollows
      : state.posts.userFollows,
    userProfile: state.auth.userProfile,
  };
};
const mapDispatchToProps = {
  getUserPosts,
  updateUserData,
  updateUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
