import React, { useEffect } from "react";
import "./index.scss";
import Divider from "../../components/Divider";
import Bullet from "../../components/Bullet";
import ImagePanorama from "../../Panolen/panorama/ImagePanorama";
import Viewer from "../../Panolen/viewer/Viewer";
export default function FroastiePro() {
  let panaromaref = null;
  const imgPath = process.env.PUBLIC_URL + "/images/";
  useEffect(() => {
    console.log(imgPath);
    const viewer = new Viewer({
      container: panaromaref,
      controlBar: false,
      cameraFov: 120,
      autoRotate: true,
      autoRotateSpeed: 0.2,
      dampingFactor: 0.1,
      autoRotateActivationDuration: 1000,
    });
    viewer.OrbitControls.noZoom = true;
    const panorama = new ImagePanorama(`${imgPath}VrProTop.png`);
    viewer.add(panorama);
  }, []);
  return (
    <div>
      <div
        style={{ width: "100%", height: "60vh" }}
        ref={(ref) => (panaromaref = ref)}
      />
      <h1
        className="image-heading heading centered font-weight-bold display-none"
        style={{ color: "#fff", top: "35%" }}
      >
        FROSTIE VR <span className="highlight-text">PRO</span>
        <br /> The Ultimate Solution for 360 Virtual Tours In Buisness!
      </h1>

      <div className="inner-container d-flex justify-content-center">
        <h1>World class Virtual Tour editor!</h1>
      </div>
      {/* <hr style={{ borderWidth: 0.5 }} /> */}
      <Divider />
      <div className="container-xxl mt-5 large-display-padding small-display-padding">
        <div className="row">
          <div className="col">
            <p className="main-text">
              With Frostie VR <span className="highlight-text">PRO</span> you
              can upload multiple panoramic photos, arrange and connect them,
              and create a stunning Virtual Tour, all that in a few minutes!
            </p>
            <p className="main-text">
              The editor supports the following features:
            </p>
            <Bullet text="Batch image uploads" />
            <Bullet text="360° and regular images" />
            <Bullet text="Fully fledged editing functions" />
            <Bullet text="Interactive hotspots" />
            <Bullet text="Walkthrough mode and automatic backlinks" />
            <Bullet text="Floor plans and maps" />
          </div>
          <div className="col-xxl">
            <img
              alt=""
              src={`${imgPath}VrProThumbnail.png`}
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </div>
      </div>
      <div className="button-container">
        <button className="button">
          <span
            className="large-display-font-size-sec small-display-font-size"
            style={{ color: "white", fontWeight: "bold" }}
          >
            Explore Sample Virtual Tour
          </span>
        </button>
      </div>
      <Divider />
      <div className="container-xxl mt-5 large-display-padding small-display-padding">
        <div className="row">
          <div className="col-xxl">
            <img
              alt=""
              src={`${imgPath}VrProRoom.png`}
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <div className="col">
            <h2 style={{ textAlign: "left" }} className="text-align">
              Unlimited Uploads
            </h2>
            <p
              className="text-align"
              style={{ fontSize: 25, textAlign: "left", lineHeight: 2 }}
            >
              Upload and share as many 360 photos & virtual tours as you need.
              With Frostie VR <span className="highlight-text">PRO</span> there
              are no monthly upload limits and no extra, hidden costs!
            </p>
            <h2
              style={{ textAlign: "left", marginTop: 60 }}
              className="text-align"
            >
              Unlisted & Private Tours
            </h2>
            <p
              style={{ fontSize: 25, textAlign: "left", lineHeight: 2 }}
              className="text-align "
            >
              Use Frostie VR <span className="highlight-text">PRO</span> to post
              unlisted or private tours and share them only with people you
              choose
            </p>
          </div>
        </div>
      </div>
      <Divider />
      <h1 className="d-flex justify-content-center w-100">There's More</h1>
      <Divider />
      <div className="bottom-container">
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            padding: 10,
          }}
        >
          <Bullet color="green" />
          <p style={{ margin: 0, color: "#757575" }}>
            <span style={{ fontSize: 25, fontWeight: "600", color: "black" }}>
              Free updates
            </span>
            - We always improve Frostie VR and add new features. All new
            features added to the PRO package will be included in your plan at
            no extra cost
          </p>
        </div>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            padding: 10,
          }}
        >
          <Bullet color="green" />
          <p style={{ margin: 0, color: "#757575" }}>
            <span style={{ fontSize: 25, fontWeight: "600", color: "black" }}>
              Premium Support
            </span>
            - We provide you with full support, including help integrating 360
            photos into your website.
          </p>
        </div>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            padding: 10,
          }}
        >
          <Bullet color="green" />
          <p style={{ margin: 0, color: "#757575" }}>
            <span style={{ fontSize: 25, fontWeight: "600", color: "black" }}>
              Links
            </span>{" "}
            - Drive traffic to your website by adding clickable links in post
            descriptions.
          </p>
        </div>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            padding: 10,
          }}
        >
          <Bullet color="green" />
          <p style={{ margin: 0, color: "#757575" }}>
            <span style={{ fontSize: 25, fontWeight: "600", color: "black" }}>
              No Ads
            </span>{" "}
            - Never see any ads on any of your posts and collections
          </p>
        </div>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
          }}
        ></div>
      </div>
      <div className="button-container">
        <button className="button-green">
          <span
            className="large-display-font-size-sec small-display-font-size"
            style={{ color: "white", fontWeight: "bold" }}
          >
            Get Frostie VR Pro starting at $12/month
          </span>
        </button>
      </div>
      <div style={{ marginBottom: "50px" }}>
        <Divider />
      </div>
    </div>
  );
}
