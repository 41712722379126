import React, { useEffect, useState } from "react";
import Post from "../../../CustomComponents/Post";
import "./index.scss";
import QueueAnim from "rc-queue-anim";
import moment from "moment";
import { connect } from "react-redux";
import { Empty, Button, Spin } from "antd";
import apiClient from "../../../Api Wrapper/Api";
import { BASE_URL_IMAGE, BASE_URL_WEBSITE } from "../../../constants/URL";
import { token } from "../../../LocalForage/localForage";

function TabToursBought(props) {
  const [isLoading, setLoading] = useState(true);
  const [tours, setTours] = useState({});

  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    apiClient
      .get(`purchase/tour/get`, { token: await token.getItem("@token") })
      .then((response) => {
        if (response && response.status === 200) {
          setTours(response.data);
        } else {
          setTours({});
        }
        setLoading(false);
      })
      .catch((error) => {
        setTours({});
        setLoading(false);
      });
  };
  return (
    <div className="main-container animated fadeInRight">
      <QueueAnim
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
        className="queue-simple"
      >
        {isLoading ? (
          <Spin
            style={{
              width: "100%",
              padding: 50,
            }}
          />
        ) : tours?.PurchaseTours?.length > 0 ? (
          tours.PurchaseTours.map((item) => {
            return (
              <div className="lg-post-w sm-post-w mt-2">
                <Post
                  key={item.id}
                  id={item.Tour.Tour_images[0].id}
                  postName={item.Tour.tour_name}
                  numberOfComments={item.Tour.Tour_images[0].Comments.length}
                  numberOfLikes={item.Tour.Tour_images[0].Likes.length}
                  numberOfView={item.Tour.Tour_images[0].Views.length}
                  Comments={item.Tour.Tour_images[0].Comments}
                  time={moment(item.Tour.createdAt).fromNow()}
                  handleClick={() => {
                    props.router.push({
                      pathname: `/public/tour/tid-${item.Tour.Post.id}/${props.userID}`,
                    });
                  }}
                  shareUrl={
                    BASE_URL_WEBSITE +
                    `/public/tour/tid-${item.Tour.Post.id}/${props.userID}`
                  }
                  profile={item.Tour.Post.User.profilePicture}
                  userID={props.userID}
                  userName={item.Tour.Post.User.display_name}
                  image={item.Tour.Tour_images[0].image}
                  boughtImage={true}
                />
              </div>
            );
          })
        ) : props.username === props.routeParams.name ? (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{
                height: 60,
              }}
              description={<span>There are no tours that you have bought</span>}
            />
          </div>
        ) : (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{
                height: 60,
              }}
              description={<span>There are no tours this user has bought</span>}
            />
          </div>
        )}
      </QueueAnim>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userID: state.auth.user.userID,
    username: state.auth.user.username,
    toursUserLike: state.posts.isVisitor
      ? state.followerProfile.toursUserLike
      : state.posts.toursUserLike,
    isVisitor: state.posts.isVisitor,
  };
};

export default connect(mapStateToProps)(TabToursBought);
