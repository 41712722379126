import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import "./MainCSS.css";
import Zoom from "react-reveal/Zoom";
import ImagePanorama from "../Panolen/panorama/ImagePanorama";
import Viewer from "../Panolen/viewer/Viewer";

function Support() {
  let panaromaref = null;
  useEffect(() => {
    const imgPath = process.env.PUBLIC_URL + "/images/support.jpg";
    const viewer = new Viewer({
      container: panaromaref,
      controlBar: false,
      cameraFov: 120,
      autoRotate: true,
      autoRotateSpeed: 0.2,
      dampingFactor: 0.1,
      autoRotateActivationDuration: 1000,
    });
    viewer.OrbitControls.noZoom = true;
    const panorama = new ImagePanorama(imgPath);
    viewer.add(panorama);
  }, []);
  return (
    <div>
      <div className="image-div">
        <div
          style={{ width: "100%", height: "60vh" }}
          ref={(ref) => (panaromaref = ref)}
        />
        <h1
          className="image-heading centered top-left"
          style={{ color: "#fff" }}
        >
          SUPPORT
        </h1>
        <div className="top-left-p display-none">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod
        </div>
      </div>

      <div className="row display-none">
        <div className="col-md-6 mt-5 center-block w-50">
          <div className="input-group mb-2">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faSearch} style={{ color: "gray" }} />
              </div>
            </div>
            <input
              type="text"
              className="form-control"
              id="inlineFormInputGroup"
              placeholder="Search"
            />
            <div className="input-group-append">
              <button className="btn btn-default border gray-button">
                Search
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-5 text-center">
        <div className="col-md-12">
          <h3 className="text-center font-weight-bold">Top Articles</h3>
          <Zoom>
            <div className="card m-15-px mt-5 top-articles">
              <div
                className="card-body"
                style={{ border: "1px solid transparent !important" }}
              >
                <h5 className="card-title">How To Shoot Panoramas</h5>
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </Zoom>
          <Zoom>
            <div className="card m-15-px mt-5 top-articles">
              <div className="card-body">
                <h5 className="card-title">
                  How To Shoot Panoramas{" "}
                  <span className="badge badge-success">PRO</span>
                </h5>
                <p className="card-text gray-color">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </Zoom>
          <Zoom>
            <div className="card m-15-px mt-5  top-articles">
              <div className="card-body">
                <h5 className="card-title">
                  How To Shoot Panoramas{" "}
                  <span className="badge badge-success">PRO</span>
                </h5>
                <p className="card-text gray-color">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </Zoom>
          <Zoom>
            <div className="card m-15-px mt-5  top-articles">
              <div className="card-body">
                <h5 className="card-title">How To Shoot Panoramas</h5>
                <p className="card-text gray-color">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </Zoom>
          <Zoom>
            <div className="card m-15-px mt-5 top-articles">
              <div className="card-body">
                <h5 className="card-title">How To Shoot Panoramas</h5>
                <p className="card-text gray-color">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </Zoom>
        </div>
      </div>
      <hr className="w-50 mt-5 thick-hr" />
      <div className="row mt-5 text-center small-screen-image-width">
        <div className="col-md-12">
          <h3 className="text-center font-weight-bold">
            Pro Account - Subscriptions & Billing
          </h3>
          <Zoom>
            <div className="card m-15-px mt-5  pro-articles">
              <div className="card-body">
                <h5 className="card-title">
                  How To Shoot Panoramas{" "}
                  <span className="badge badge-primary">PRO</span>
                </h5>
                <p className="card-text gray-color">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </Zoom>
          <Zoom>
            <div className="card m-15-px mt-5  pro-articles">
              <div className="card-body">
                <h5 className="card-title">How To Shoot Panoramas</h5>
                <p className="card-text gray-color">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </Zoom>
          <Zoom>
            <div className="card m-15-px mt-5 pro-articles">
              <div className="card-body">
                <h5 className="card-title">How To Shoot Panoramas</h5>
                <p className="card-text gray-color">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </Zoom>
        </div>
      </div>

      <hr className="w-50 mt-5 thick-hr" />
      <div className="row mt-5 text-center small-screen-image-width">
        <div className="col-md-12">
          <h3 className="text-center font-weight-bold">
            Business account features
          </h3>
          <Zoom>
            <div className="card m-15-px mt-5 pro-articles">
              <div className="card-body">
                <h5 className="card-title">
                  How To Shoot Panoramas{" "}
                  <span className="badge badge-primary">PRO</span>
                </h5>
                <p className="card-text gray-color">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </Zoom>
          <Zoom>
            <div className="card m-15-px mt-5 pro-articles">
              <div className="card-body">
                <h5 className="card-title">How To Shoot Panoramas</h5>
                <p className="card-text gray-color">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </Zoom>
          <Zoom>
            <div className="card m-15-px mt-5 pro-articles">
              <div className="card-body">
                <h5 className="card-title">How To Shoot Panoramas</h5>
                <p className="card-text gray-color">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </Zoom>
          <br />
          <br />
          <button className="orange-button">
            <span style={{ color: "#fff" }}>Load More...</span>
          </button>
        </div>
      </div>
      <hr className="w-50 mt-5 thick-hr" />
      {/* <div className="row mt-5 text-center small-screen-image-width">
        <div className="col-md-12">
          <h3 className="text-center font-weight-bold">Contact Us</h3>
          <p className="gray-color">Lorum Ipsum dolor sit amit...</p>
          <div className="form-group w-50 center-block">
            <textarea
              className="form-control"
              rows="5"
              placeholder="How can we help you?"
            ></textarea>
            <button className="mt-5 center-block pr-4 pl-4 btn btn-primary rounded-pill">
              Send
            </button>
          </div>
        </div>
      </div>
      <hr className="w-50 mt-5 thick-hr" /> */}
    </div>
  );
}

export default Support;
