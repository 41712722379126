import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import {
  reduxValidButton,
  reduxValidForm,
  reduxValidInput,
} from "redux-valid-form";

import authReducer from "./auth/auth.reducer";
import editorReducer from "./editor/editor.reducer";
import postsReducer from "./posts.reducer";
import hotspotReducer from "./hotspot/hotspot.reducer";
import tourReducer from "./tour/tour.reducer";
import cart from "./cart.reducer";
import textEditor from "./editor.reducer";
import followerProfile from "./followerProfile/followerProfile.reducer";

const allReducers = combineReducers({
  routing: routerReducer,
  editor: editorReducer,
  hotspot: hotspotReducer,
  auth: authReducer,
  posts: postsReducer,
  tour: tourReducer,
  cart: cart,
  label: textEditor,
  reduxValidButton,
  reduxValidForm,
  reduxValidInput,
  followerProfile,
});

export default allReducers;
