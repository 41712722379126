import React, { useState, useEffect } from "react";
import Home from "../Home";
import TabPhotos from "./TabsComponents/TabPhotos";
import Tabs from "./Tabs";
import Post from "../../CustomComponents/Post";
import TabTours from "./TabsComponents/TabTours";
import TabToursILike from "./TabsComponents/TabToursILike";
import TabPhotosILike from "./TabsComponents/TabPhotosILike";
import TabImagesBought from "./TabsComponents/TabImagesBought";
import TabToursBought from "./TabsComponents/TabToursBought";
import { connect } from "react-redux";
import { Col, Row, message, Spin } from "antd";
import "./index.scss";
import { setIsVisitor } from "../../js/actions/Posts/posts.actions";
import Api from "../../Api Wrapper/Api";
import HomeProfile from "../../CustomComponents/HomeProfile";
import { token } from "../../LocalForage/localForage";

function Photos(props) {
  const [activeTab, setActiveTab] = useState("photos");
  const [isLoad, setIsLoad] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [profilePicture, setProfilePicture] = useState();
  const [pageData, setPageData] = useState();
  const [posts, setPosts] = useState();
  const [tours, setTours] = useState();
  const [postLike, setPostLike] = useState();
  const [tourLike, setTourLike] = useState();
  const [click, setClick] = useState(false);

  const renderTab = () => {
    if (activeTab === "photos")
      return (
        <TabPhotos
          posts={posts}
          tours={tours}
          displayName={displayName}
          profilePicture={profilePicture}
          {...props}
        />
      );
    // else if (activeTab === "tours")
    //   return (
    //     <TabTours
    //       posts={tours}
    //       displayName={displayName}
    //       profilePicture={profilePicture}
    //       {...props}
    //     />
    //   );
    else if (activeTab === "BoughtImages")
      return <TabImagesBought {...props} />;
    else if (activeTab === "BoughtTours") return <TabToursBought {...props} />;
    else {
      return (
        <div>
          <TabPhotosILike
            posts={postLike}
            tours={tourLike}
            displayName={displayName}
            {...props}
          />
          {/* <TabToursILike
            posts={tourLike}
            displayName={displayName}
            {...props}
          /> */}
        </div>
      );
    }
  };
  useEffect(() => {
    onPageLoad();
  }, []);

  const onPageLoad = async () => {
    setActiveTab(props.routeParams.key);

    await Api.get(`/post/findPosts?username=${props.routeParams.name}`, {
      headers: { token: await token.getItem("@token") },
    })
      .then((res) => {
        if (res.status === 200) {
          setProfilePicture(res.data.profilePicture);
          setDisplayName(res.data.display_name);
          setPosts(res.data.Posts);
          setPageData(res.data);
          setTours(res.data.Tours);
          setTourLike(res.data.ToursILiked);
          setPostLike(res.data.ImagesILiked);
          profile_view_count();
          setIsLoad(true);
        } else {
          message.error(
            "There was an error fetching the post, please refresh or contact our administrator."
          );
        }
      })
      .catch((err) => {
        console.log(err);
        message.error(
          "There was an error fetching the post, please refresh or contact our administrator."
        );
      });
    if (props.routeParams.name !== props.user.username) {
      props.setIsVisitor(true);
    } else {
      props.setIsVisitor(false);
    }
  };

  const profile_view_count = async (id) => {
    if (props.routeParams.name !== props.user.username) {
      await Api.put(
        `/users/profile/view?username=${props.routeParams.name}`
      ).then((res) => {});
    }
  };
  return (
    <Row className="m-5 sm-tab-body-style">
      <Col className="home-profile-photos" span={6}>
        <HomeProfile
          userType={props.userProfile ? props.userProfile.userType : ""}
          name={pageData ? pageData.username : ""}
          displayName={pageData ? pageData.display_name : ""}
          views={pageData ? pageData.profileViews : 0}
          photos={pageData ? pageData.Posts.length + pageData.Tours.length : 0}
          followers={pageData ? pageData.user_followed_by.length : 0}
          following={pageData ? pageData.user_followed.length : 0}
          setClick={setClick}
          router={props.router}
          image={pageData ? pageData.profilePicture : ""}
          isVisitor={props.isVisitor}
          userID={props.userProfile ? props.userProfile.id : ""}
        />
      </Col>
      <Col span={window.screen.width > 762 ? 12 : 24}>
        <div className="sm-width-100">
          <div className="home-profile-none">
            <HomeProfile
              mobView={true}
              name={pageData ? pageData.username : ""}
              displayName={pageData ? pageData.display_name : ""}
              views={pageData ? pageData.profileViews : 0}
              photos={
                pageData ? pageData.Posts.length + pageData.Tours.length : 0
              }
              setClick={setClick}
              router={props.router}
              image={pageData ? pageData.profilePicture : ""}
              isVisitor={props.isVisitor}
              userID={props.userProfile ? props.userProfile.id : ""}
            />
          </div>
          <div className="w-100 mt-2">
            <div className="row">
              <Tabs
                tabName="Posts"
                onClick={() => {
                  props.router.push(`/profile/photos/${pageData.username}`);
                  setActiveTab("photos");
                }}
                active={activeTab === "photos"}
              />
              {/* <Tabs
                tabName="Tours"
                onClick={() => {
                  props.router.push(`/profile/tours/${pageData.username}`);
                  setActiveTab("tours");
                }}
                active={activeTab === "tours"}
              /> */}
              <Tabs
                tabName="Liked Posts"
                active={activeTab === "LikedPhotos"}
                onClick={() => {
                  props.router.push(
                    `/profile/LikedPhotos/${pageData.username}`
                  );
                  setActiveTab("LikedPhotos");
                }}
              />
              <Tabs
                active={activeTab === "BoughtImages"}
                tabName="Bought Images"
                onClick={() => {
                  props.router.push(
                    `/profile/BoughtImages/${pageData.username}`
                  );
                  setActiveTab("BoughtImages");
                }}
              />
              <Tabs
                active={activeTab === "BoughtTours"}
                tabName="Bought Tours"
                onClick={() => {
                  props.router.push(
                    `/profile/BoughtTours/${pageData.username}`
                  );
                  setActiveTab("BoughtTours");
                }}
              />
            </div>
            {isLoad ? (
              <div>{renderTab()}</div>
            ) : (
              <Spin
                style={{
                  width: "100%",
                  padding: 50,
                }}
              />
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
    isVisitor: state.posts.isVisitor,
    userProfile: state.auth.userProfile,
  };
};

const mapDispatchToProps = {
  setIsVisitor,
};
export default connect(mapStateToProps, mapDispatchToProps)(Photos);
