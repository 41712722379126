import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./MainCSS.css";
import {
  faSearch,
  faCommentAlt,
  faCalendarAlt,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import ImagePanorama from "../Panolen/panorama/ImagePanorama";
import Viewer from "../Panolen/viewer/Viewer";
import ReadMore from "../CustomComponents/ReadMore";

const Blog = () => {
  let panaromaref = null;
  const [isReadMore, setIsReadmore] = useState(false);
  const [isReadMoreSec, setIsReadmoreSec] = useState(false);
  const [isReadMoreThird, setIsReadmoreThird] = useState(false);
  useEffect(() => {
    const imgPath = process.env.PUBLIC_URL + "/images/blog.jpg";
    const viewer = new Viewer({
      container: panaromaref,
      controlBar: false,
      cameraFov: 120,
      autoRotate: true,
      autoRotateSpeed: 0.2,
      dampingFactor: 0.1,
      autoRotateActivationDuration: 1000,
    });
    viewer.OrbitControls.noZoom = true;
    const panorama = new ImagePanorama(imgPath);
    viewer.add(panorama);
  }, []);
  return (
    <div>
      <div className="image-div small-screen-width">
        <div
          style={{ width: "100%", height: "60vh" }}
          ref={(ref) => (panaromaref = ref)}
        />
        {/* <div className="top-left-1">
          <a className="btn btn-warning rounded-pill">Hi-Tech</a>
        </div> */}
        <div className="top-left-p mt-4">
          Make your 360°photos with any camera
          {/* <div className="top-left-p2">assurance situation</div> */}
          {/* <div className="row mt-3">
            <div className="col-md-2 display-none ">admin</div>
            <div className="col-md-1"></div>
            <div className="col-md-0 display-none">
              <FontAwesomeIcon icon={faCalendarAlt} style={{ color: "gray" }} />
              <span style={{ marginLeft: "5px" }}>jul/21/2020</span>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-0 display-none">
              <FontAwesomeIcon icon={faCommentAlt} style={{ color: "gray" }} />
              <span style={{ marginLeft: "5px" }}>01</span>
            </div>
            <div style={{ marginLeft: "30px" }}></div>
            <div className="col-md-0 display-none">
              <FontAwesomeIcon icon={faEye} style={{ color: "gray" }} />
              <span style={{ marginLeft: "5px" }}>301</span>
            </div>
          </div> */}
        </div>
      </div>

      <div className="row display-none">
        <div className="col-md-6 mt-5 center-block w-50">
          <div className="input-group mb-2">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faSearch} style={{ color: "gray" }} />
              </div>
            </div>
            <input
              type="text"
              className="form-control"
              id="inlineFormInputGroup"
              placeholder="Search"
            />
            <div className="input-group-append">
              <button className="btn btn-default border gray-button">
                Search
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-5 text-center blog-container">
        <div className="col-md-7 text-center">
          <div className="small-screen-image-width text-center sm-m-auto">
            <img className="img-fluid " src="/images/arrows.jpg"></img>
          </div>
          <br />

          <div className="row mt-4">
            <div className="col-md-12">
              <span className="gray-color">
                Add Contact Information to Virtual Tours & Generate Leads
              </span>
            </div>

            {/* <div className="row mt-1">
              <div style={{ marginLeft: "250px" }}></div>
              <div className="col-md-1">admin</div>
              <div className="col-md-1"></div>
              <div style={{ marginLeft: "15px" }}></div>
              <div className="col-md-0">
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  style={{ color: "gray" }}
                />
                jul/21/2020
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-0">
                <FontAwesomeIcon
                  icon={faCommentAlt}
                  style={{ color: "gray" }}
                />
                01
              </div>
              <div style={{ marginLeft: "50px" }}></div>

              <FontAwesomeIcon icon={faEye} style={{ color: "gray" }} />
              <div className="col-md-0">301</div>
              <div className="col-md-1"></div>
            </div> */}
          </div>
          <div className="col-md-12">
            <span className="gray-color">
              Add Contact Information to Virtual Tours & Generate Leads.Add
              Contact Information to Virtual Tours & Generate Leads.Add Contact
              Information to Virtual Tours & Generate Leads. Add Contact
              Information to Virtual Tours & Generate Leads.Add Contact
              Information to Virtual Tours & Generate Leads
            </span>
          </div>
          <div className="row mt-2">
            <div className="col-md-12">
              <div className="large-display-left-padding">
                <button
                  onClick={() => {
                    setIsReadmore(!isReadMore);
                  }}
                  className="orange-button-2"
                >
                  <span className="orange-text">
                    Read More
                    <i className="arrow right"></i>
                  </span>
                </button>
                {isReadMore && (
                  <ReadMore
                    image={"/images/arrows.jpg"}
                    text={
                      "Add Contact Information to Virtual Tours & Generate Leads.Add Contact Information to Virtual Tours & Generate Leads.Add Contact Information to Virtual Tours & Generate Leads. Add Contact Information to Virtual Tours & Generate Leads.Add Contact Information to Virtual Tours & Generate Leads"
                    }
                    isVisible={isReadMore}
                    onClose={(val) => setIsReadmore(val)}
                  />
                )}
              </div>
            </div>
          </div>

          <hr
            style={{
              borderTop: "1px dashed grey",
              width: "90%",
            }}
          />
        </div>

        <div className="col-md-1"></div>

        <div className="col-md-2">
          <div
            className="card ml-5 d-inline-block"
            style={{ width: "16rem", border: "1px solid transparent" }}
          >
            <img
              className="card-img-top"
              src="/images/boy2.png"
              alt="Card image cap"
            />

            <p
              className="card-text"
              style={{ textAlign: "left", fontSize: "16px" }}
            >
              Certainly listening no behaviour existence assurance situation.
            </p>
          </div>
          <br />
          <div
            className="card ml-5 d-inline-block"
            style={{ width: "16rem", border: "1px solid transparent" }}
          >
            <img
              className="card-img-top"
              src="/images/heart.jpg"
              alt="Card image cap"
            />

            <p
              className="card-text"
              style={{ textAlign: "left", fontSize: "16px" }}
            >
              Certainly listening no behaviour existence assurance situation.
            </p>
          </div>
          <div
            className="card ml-5 d-inline-block"
            style={{ width: "16rem", border: "1px solid transparent" }}
          >
            <img
              className="card-img-top"
              src="/images/heart.jpg"
              alt="Card image cap"
            />

            <p
              className="card-text"
              style={{ textAlign: "left", fontSize: "16px" }}
            >
              Certainly listening no behaviour existence assurance situation
            </p>
          </div>
        </div>
      </div>

      <div className="row mt-2 text-center blog-container">
        <div className="col-md-12">
          <img
            className="img-fluid lg-h-70per sm-h-40per"
            src="/images/mask.jpg"
            style={{ width: "100%", opacity: 0.6 }}
          />
          <div className="row mt-3">
            <div className="col-md-12">
              <button className="orange-button">
                <span style={{ color: "#fff" }}>Product</span>
              </button>
              {/* <a className="btn btn-warning rounded-pill">Product</a> */}
            </div>
          </div>
          <br />
          <br />
          <div className="col-md-12">
            <h4 className="gray-color">
              Add Contact Information to Virtual Tours & Generate Leads
            </h4>
          </div>

          <div className="row mt-2">
            <div className="col-md-4"></div>

            <div className="col-md-4">
              <span className="gray-color">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="display-none row mt-2">
        <div className="col-md-12">
          <div className="large-display-left-padding-sec small-display-left-padding-sec">
            <button
              onClick={() => {
                setIsReadmoreSec(!isReadMoreSec);
              }}
              className="orange-button-2"
            >
              <span className="orange-text">
                Read More
                <i className="arrow right"></i>
              </span>
            </button>
          </div>
          {isReadMoreSec && (
            <ReadMore
              image={"/images/mask.jpg"}
              text={
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
              }
              isVisible={isReadMoreSec}
              onClose={(val) => setIsReadmoreSec(val)}
            />
          )}
        </div>
      </div>
      <br />
      <br className="display-none-in-large-display" />
      <br className="display-none-in-large-display" />
      <hr
        className="display-none"
        style={{
          borderTop: "1px dashed grey",
          width: "90%",
        }}
      />
      <div className="row mt-5 text-center blog-container">
        <div className="col-md-2">
          <div
            className="card ml-5 d-inline-block"
            style={{ width: "16rem", border: "1px solid transparent" }}
          >
            <img
              className="card-img-top"
              src="/images/boy2.png"
              alt="Card image cap"
            />

            <p
              className="card-text"
              style={{ textAlign: "left", fontSize: "16px" }}
            >
              Certainly listening no behaviour existence assurance situation.
            </p>
          </div>
          <br />
          <div
            className="card ml-5 d-inline-block"
            style={{ width: "16rem", border: "1px solid transparent" }}
          >
            <img
              className="card-img-top"
              src="/images/heart.jpg"
              alt="Card image cap"
            />

            <p
              className="card-text"
              style={{ textAlign: "left", fontSize: "16px" }}
            >
              Certainly listening no behaviour existence assurance situation.
            </p>
          </div>
          <div
            className="card ml-5 d-inline-block"
            style={{ width: "16rem", border: "1px solid transparent" }}
          >
            <img
              className="card-img-top"
              src="/images/heart.jpg"
              alt="Card image cap"
            />

            <p
              className="card-text"
              style={{ textAlign: "left", fontSize: "16px" }}
            >
              Certainly listening no behaviour existence assurance situation
            </p>
          </div>
        </div>
        <div className="col-md-10">
          <div className="small-screen-image-width large-diplay-left-margin small-screen-margin small-screen-margin-top">
            <img className="img-fluid " src="/images/arrows.jpg"></img>
          </div>

          <div className="row mt-4">
            <div className="col-md-12">
              <button className="orange-button">
                <span style={{ color: "#fff" }}>Product</span>
              </button>
            </div>
          </div>

          <div className="row mt-4">
            <div
              style={{ width: "60%", marginLeft: "auto", marginRight: "auto" }}
            >
              <h4 className="gray-color">
                Add Contact Information to Virtual Tours & Generate Leads
              </h4>
              <span className="gray-color">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </span>
            </div>
          </div>
          {/* <div
            style={{
              paddingLeft: "200px",
              //   marginLeft: "400px",
              marginTop: "20px",
              width: "60%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <div></div>
          </div> */}

          <div className="row mt-2">
            <div className="col-md-12">
              <div className="large-display-left-padding">
                <button
                  onClick={() => {
                    setIsReadmoreThird(!isReadMoreThird);
                  }}
                  className="orange-button-2"
                >
                  <span className="orange-text">
                    Read More
                    <i className="arrow right"></i>
                  </span>
                </button>
              </div>
              {isReadMoreThird && (
                <ReadMore
                  image={"/images/arrows.jpg"}
                  text="Lorem Ipsum is simply dummy text of the printing and typesetting
                  industry. Lorem Ipsum has been the industry's standard dummy
                  text ever since the 1500s, when an unknown printer took a galley
                  of type and scrambled it to make a type specimen book. It has
                  survived not only five centuries, but also the leap into
                  electronic typesetting, remaining essentially unchanged. It was
                  popularised in the 1960s with the release of Letraset sheets
                  containing Lorem Ipsum passages, and more recently with desktop
                  publishing software like Aldus PageMaker including versions of
                  Lorem Ipsum."
                  Heading="Add Contact Information to Virtual Tours & Generate Leads"
                  isVisible={isReadMoreThird}
                  onClose={(val) => setIsReadmoreThird(val)}
                />
              )}
            </div>
          </div>

          <hr
            style={{
              borderTop: "1px dashed grey",
              width: "90%",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Blog;
