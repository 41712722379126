import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import { BASE_URL_WEBSITE } from "../constants/URL";
import "./MainCSS.css";
import ExploreCard from "./ExploreCard";
import { useEffect, useState, useRef } from "react";
import Api from "../Api Wrapper/Api";
import { Spin } from "antd";
import Viewer from "../Panolen/viewer/Viewer";
import ImagePanorama from "../Panolen/panorama/ImagePanorama";
import { connect } from "react-redux";
import moment from "moment";
function Explore(props) {
  let panaromaref = null;
  const [pageData, setPageData] = useState([]);
  const [mostLiked, setMostLiked] = useState([]);
  const [loading, setLoading] = useState(true);
  const [exploreTabs, setExploreTabs] = useState();
  const [likesLoading, setLikesLoading] = useState(false);
  let featuredDataCount = 0;
  // const [loadMorePhotos, setLoadMorePhotos] = useState(1);

  useEffect(() => {
    var str = props.router.params.key;

    setExploreTabs(str);
    onPageLoad(str);
  }, [props.router.params.key]);
  useEffect(() => {
    const image = "/images/explore.png";
    const viewer = new Viewer({
      container: panaromaref,
      controlBar: false,
      cameraFov: 120,
      autoRotate: true,
      autoRotateSpeed: 0.2,
      dampingFactor: 0.1,
      autoRotateActivationDuration: 1000,
    });
    viewer.OrbitControls.noZoom = true;
    const panorama = new ImagePanorama(image);
    viewer.add(panorama);
  }, []);

  const onPageLoad = (type) => {
    if (type === "likes") {
      setLikesLoading(true);
      Api.get("post/mostLiked").then((mostLikedResponse) => {
        if (mostLikedResponse.status === 200) {
          setMostLiked(mostLikedResponse.data);
          setLikesLoading(false);
        }
      });
    } else {
      setLoading(true);
      Api.get("post/findAll").then((res) => {
        if (res.status === 200) {
          setPageData(res.data);
          setLoading(false);
        }
      });
    }
  };
  // const loadMore = () => {
  //   if (exploreTabs === "photos") {
  //     const PhotosLength = pageData.filter((item) => item.Post.Tour === null)
  //       .length;
  //     PhotosLength > loadMorePhotos + 1
  //       ? setLoadMorePhotos(loadMorePhotos + 1)
  //       : setLoadMorePhotos(PhotosLength - 1);
  //   }
  // };
  return (
    <div>
      <div className="image-div">
        <div
          style={{ width: "100%", height: "50vh" }}
          ref={(ref) => (panaromaref = ref)}
        ></div>
        <h1
          className="image-heading heading centered font-weight-bold display-none"
          style={{ color: "#fff" }}
        >
          Lets Search 360<span>&#176;</span> Images
        </h1>
        <div
          className="heading-p centered"
          style={{
            width: window.screen.width > 762 ? "50%" : "75%",
            marginTop: window.screen.width > 762 ? 0 : 20,
          }}
        >
          <div
            className="input-group mb-2"
            style={{ opacity: 0.9, marginTop: "15px" }}
          >
            <div className="input-group-prepend">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faSearch} style={{ color: "gray" }} />
              </div>
            </div>
            <input
              type="text"
              className="form-control"
              id="inlineFormInputGroup"
              placeholder="Search"
              onChange={async (e) => {
                if (exploreTabs === "photos") {
                  setLoading(true);
                  await Api.get(
                    `post/findAll/?post_name=${e.target.value}`
                  ).then((res) => {
                    if (res.status === 200) {
                      setPageData(res.data);
                      setLoading(false);
                    }
                  });
                } else if (exploreTabs === "tour") {
                  setLoading(true);
                  await Api.get(
                    `post/findAll/?tour_name=${e.target.value}`
                  ).then((res) => {
                    if (res.status === 200) {
                      setPageData(res.data);
                      setLoading(false);
                    }
                  });
                }
              }}
            />
            <div className="input-group-append">
              <button className="btn btn-warning border gray-button">
                Search
              </button>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-md-6 mt-5 center-block w-50">
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <FontAwesomeIcon icon={faSearch} style={{ color: "gray" }} />
                </div>
              </div>
              <input
                type="text"
                className="form-control"
                id="inlineFormInputGroup"
                placeholder="Search"
              />
              <div className="input-group-append">
                <button className="btn btn-default border gray-button">
                  Search
                </button>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <div className="row display-none">
        <div className="col-md-6 mt-5 center-block w-50 text-center">
          <button
            onClick={() => {
              setExploreTabs("photos");
              props.router.push({
                pathname: `/explore/photos`,
              });
            }}
            className={
              exploreTabs === "photos"
                ? "explore-button-active"
                : "explore-button"
            }
          >
            <span
              style={{ fontSize: "16px", fontWeight: 500 }}
              className={
                exploreTabs === "photos"
                  ? "explore-text-active"
                  : "explore-text"
              }
            >
              Featured Photos
            </span>
          </button>
          <button
            onClick={() => {
              setExploreTabs("tour");
              props.router.push({
                pathname: `/explore/tour`,
              });
            }}
            className={
              exploreTabs === "tour"
                ? "explore-button-active ml-2"
                : "explore-button ml-2"
            }
          >
            <span
              style={{ fontSize: "16px", fontWeight: 500 }}
              className={
                exploreTabs === "tour" ? "explore-text-active" : "explore-text"
              }
            >
              Featured Tours
            </span>
          </button>
          <button
            onClick={() => {
              setExploreTabs("likes");
              props.router.push({
                pathname: `/explore/likes`,
              });
            }}
            className={
              exploreTabs === "likes"
                ? "explore-button-active ml-2"
                : "explore-button ml-2"
            }
          >
            <span
              style={{ fontSize: "16px", fontWeight: 500 }}
              className={
                exploreTabs === "likes" ? "explore-text-active" : "explore-text"
              }
            >
              Most Liked
            </span>
          </button>
        </div>
      </div>
      {/* Small display buttons */}
      <div className="sm-display-row display-none-in-large-display">
        <div className="sm-mt-10">
          <button
            onClick={() => {
              setExploreTabs("photos");
              props.router.push({
                pathname: `/explore/photos`,
              });
            }}
            style={{
              width: "24%",
            }}
            className={
              exploreTabs === "photos"
                ? "sm-explore-button-active"
                : "sm-explore-button"
            }
          >
            <span
              style={{
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              Featured Photos
            </span>
          </button>
          <button
            onClick={() => {
              setExploreTabs("tour");
              props.router.push({
                pathname: `/explore/tour`,
              });
            }}
            style={{
              width: "24%",
            }}
            className={
              exploreTabs === "tour"
                ? "sm-explore-button-active"
                : "sm-explore-button"
            }
          >
            <span
              style={{
                fontSize: "13px",
                fontWeight: 500,
                border: "1px solid white",
              }}
            >
              Featured Tours
            </span>
          </button>
          <button
            onClick={() => {
              setExploreTabs("likes");
              props.router.push({
                pathname: `/explore/likes`,
              });
            }}
            style={{
              width: "24%",
            }}
            className={
              exploreTabs === "likes"
                ? "sm-explore-button-active"
                : "sm-explore-button"
            }
          >
            <span
              style={{
                fontSize: "13px",
                fontWeight: 500,
                border: "1px solid white",
              }}
            >
              Most Liked
            </span>
          </button>
          {/* <button
            onClick={() => setExploreTabs("Experience")}
            style={{
              width: "24%",
            }}
            className={
              exploreTabs === "Experience"
                ? "sm-explore-button-active"
                : "sm-explore-button"
            }
          >
            <span
              style={{
                fontSize: "12.5px",
                fontWeight: 500,
                border: "1px solid white",
              }}
            >
              Experience
            </span>
          </button> */}
        </div>
      </div>
      {exploreTabs === "likes" ? (
        likesLoading ? (
          <Spin
            style={{
              width: "100%",
              padding: 50,
            }}
          />
        ) : (
          mostLiked && (
            <div>
              <div
                className="row"
                style={{
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                <div style={{ marginTop: "20px" }}>
                  {mostLiked.length > 0 ? (
                    mostLiked.map((item, index) => {
                      if (!item.Post[0].Tour) {
                        return (
                          <ExploreCard
                            key={item.Post[0].id}
                            profilePicture={item.profilePicture}
                            timeStamp={item.Post[0].createdAt}
                            likeCount={item.Post[0].Image.Likes.length}
                            likes={item.Post[0].Image.Likes}
                            viewCount={item.Post[0].Image.Views.length}
                            commentCount={item.Post[0].Image.Comments.length}
                            comments={item.Post[0].Image.Comments}
                            image={item.Post[0].Image.image}
                            image_id={item.Post[0].Image.id}
                            userName={item.display_name}
                            photoName={item.Post[0].name}
                            shareUrl={
                              BASE_URL_WEBSITE +
                              `/post-view/id-${item.Post[0].id}`
                            }
                            commentUser={props.user.displayName}
                            logedInUser={props.user.userID}
                            isTour={false}
                            postUserID={item.Post[0].uid}
                            onClick={() =>
                              props.router.push({
                                pathname: `/post-view/id-${item.Post[0].id}`,
                              })
                            }
                          />
                        );
                      } else {
                        return (
                          <ExploreCard
                            key={item.Post[0].id}
                            timeStamp={item.Post[0].createdAt}
                            profilePicture={item.profilePicture}
                            likeCount={
                              item.Post[0].Tour.Tour_images[0].Likes.length
                            }
                            likes={item.Post[0].Tour.Tour_images[0].Likes}
                            viewCount={
                              item.Post[0].Tour.Tour_images[0].Views.length
                            }
                            commentCount={
                              item.Post[0].Tour.Tour_images[0].Comments.length
                            }
                            comments={item.Post[0].Tour.Tour_images[0].Comments}
                            shareUrl={
                              BASE_URL_WEBSITE +
                              `/tour-view/tid-${item.Post[0].id}/${props.user.userID}`
                            }
                            commentUser={props.user.displayName}
                            logedInUser={props.user.userID}
                            image={item.Post[0].Tour.Tour_images[0].image}
                            image_id={item.Post[0].Tour.Tour_images[0].id}
                            index={item.Post[0].id}
                            userName={item.display_name}
                            photoName={item.Post[0].Tour.tour_name}
                            isTour={true}
                            postUserID={item.Post[0].uid}
                            onClick={() =>
                              props.router.push({
                                pathname: `/tour-view/tid-${item.Post[0].id}/${item.user_id}`,
                              })
                            }
                          />
                        );
                      }
                    })
                  ) : (
                    <>
                      There are no posts to show you. Would you like to post
                      one?
                    </>
                  )}
                </div>
              </div>
            </div>
          )
        )
      ) : loading ? (
        <Spin
          style={{
            width: "100%",
            padding: 50,
          }}
        />
      ) : pageData ? (
        <div>
          {exploreTabs === "photos" && (
            <div
              className="row"
              style={{
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <div style={{ marginTop: "20px" }}>
                <p style={{ display: "none" }}>{(featuredDataCount = 0)}</p>
                {pageData.length > 0 ? (
                  pageData.map((item, index) => {
                    let date = new Date();
                    let month = date.getMonth();
                    let feature = moment(item.Post.featuredTill)
                      .format("L")
                      .split("/")[0];

                    if (
                      item.Post.Image &&
                      (parseInt(feature) > parseInt(month) ||
                        item.Post.featuredTill !== "0")
                    ) {
                      featuredDataCount++;
                      return (
                        // index < loadMorePhotos && (
                        <ExploreCard
                          key={item.Post.id}
                          profilePicture={item.profilePicture}
                          timeStamp={item.Post.createdAt}
                          likeCount={item.Post.Image.Likes.length}
                          likes={item.Post.Image.Likes}
                          viewCount={item.Post.Image.Views.length}
                          commentCount={item.Post.Image.Comments.length}
                          comments={item.Post.Image.Comments}
                          image={item.Post.Image.image}
                          image_id={item.Post.Image.id}
                          userName={item.display_name}
                          photoName={item.Post.name}
                          shareUrl={
                            BASE_URL_WEBSITE + `/post-view/id-${item.Post.id}`
                          }
                          commentUser={props.user.displayName}
                          logedInUser={props.user.userID}
                          isTour={false}
                          postUserID={item.Post.uid}
                          onClick={() =>
                            props.router.push({
                              pathname: `/post-view/id-${item.Post.id}`,
                            })
                          }
                        />
                        // )
                      );
                    } else {
                      if (
                        featuredDataCount === 0 &&
                        index + 1 === pageData.length
                      ) {
                        return (
                          <div className="m-5">
                            There are no featured posts to show you. Would you
                            like to post one?
                          </div>
                        );
                      }
                    }
                  })
                ) : (
                  <>
                    There are no posts to show you. Would you like to post one?
                  </>
                )}
              </div>
            </div>
          )}

          {loading ? (
            <Spin
              style={{
                width: "100%",
                padding: 50,
              }}
            />
          ) : (
            exploreTabs === "tour" && (
              <div
                className="row"
                style={{
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                <div style={{ marginTop: "20px" }}>
                  <p style={{ display: "none" }}>{(featuredDataCount = 0)}</p>
                  {pageData.length > 0 ? (
                    pageData.map((item, index) => {
                      let date = new Date();
                      let month = date.getMonth();
                      let feature = moment(item.Post.featuredTill)
                        .format("L")
                        .split("/")[0];

                      if (
                        item.Post.Tour &&
                        (parseInt(feature) > parseInt(month) ||
                          item.Post.featuredTill !== "0")
                      ) {
                        featuredDataCount++;
                        return (
                          <ExploreCard
                            key={item.Post.id}
                            timeStamp={item.Post.createdAt}
                            profilePicture={item.profilePicture}
                            likeCount={
                              item.Post.Tour.Tour_images[0].Likes.length
                            }
                            likes={item.Post.Tour.Tour_images[0].Likes}
                            viewCount={
                              item.Post.Tour.Tour_images[0].Views.length
                            }
                            commentCount={
                              item.Post.Tour.Tour_images[0].Comments.length
                            }
                            comments={item.Post.Tour.Tour_images[0].Comments}
                            shareUrl={
                              BASE_URL_WEBSITE +
                              `/tour-view/tid-${item.Post.id}/${props.user.userID}`
                            }
                            commentUser={props.user.displayName}
                            logedInUser={props.user.userID}
                            image={item.Post.Tour.Tour_images[0].image}
                            image_id={item.Post.Tour.Tour_images[0].id}
                            index={item.Post.id}
                            userName={item.display_name}
                            photoName={item.Post.Tour.tour_name}
                            isTour={true}
                            postUserID={item.Post.uid}
                            onClick={() =>
                              props.router.push({
                                pathname: `/tour-view/tid-${item.Post.id}/${item.user_id}`,
                              })
                            }
                          />
                        );
                      } else {
                        if (
                          featuredDataCount === 0 &&
                          index + 1 === pageData.length
                        ) {
                          return (
                            <div className="m-5">
                              There are no featured tours to show you. Would you
                              like to post one?
                            </div>
                          );
                        }
                      }
                    })
                  ) : (
                    <>
                      There are no tours to show you. Would you like to post
                      one?
                    </>
                  )}
                </div>
              </div>
            )
          )}
        </div>
      ) : (
        <div>sdd</div>
      )}

      {/* <div
        onClick={loadMore}
        className="d-flex justify-content-center mt-2 mb-5"
      >
        <span className="btn btn-warning rounded-pill">Load More</span>
      </div> */}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    isVisitor: state.posts.isVisitor,
  };
};

export default connect(mapStateToProps, null)(Explore);
