import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Steps, Empty, Spin } from "antd";
import Texty from "rc-texty";
import "./index.scss";
import ProductApi from "../../Api Wrapper/ProductApi";
import Cart from "./Cart";
import DeliveryDetails from "./DeliveryDetails";
import Receipt from "./Receipt";

const { Step } = Steps;

function index(props) {
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = useState(true);
  const [shippingCost, setShippingCost] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState();
  const [deliveryAddress, setDeliveryAddress] = useState();
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    ProductApi.post("general/cartList", {
      customer_id: props.user.ecommerce_user_id,
    })
      .then((response) => {
        console.log(response);
        setCart(response.data.data);
        setLoading(false);
      })
      .catch((error) => {});
  }, []);

  const renderSteps = (step) => {
    if (step === 0) {
      return (
        <Cart
          cart={cart}
          nextStep={() => setActiveStep(1)}
          setCart={(cart) => setCart(cart)}
        />
      );
    } else if (step === 1) {
      return (
        <DeliveryDetails
          cart={cart}
          nextStep={() => setActiveStep(2)}
          setShippingCost={(cost) => setShippingCost(cost)}
          setPaymentMethod={(method) => setPaymentMethod(method)}
          setDeliveryAddress={(address) => setDeliveryAddress(address)}
          setCart={(cart) => setCart(cart)}
        />
      );
    } else if (step === 2) {
      return (
        <Receipt
          {...props}
          cart={cart}
          shippingCost={shippingCost}
          nextStep={() => setActiveStep(3)}
          paymentMethod={paymentMethod}
          deliveryAddress={deliveryAddress}
          setCart={(cart) => setCart(cart)}
        />
      );
    }
  };

  return (
    <div className="w-100 d-flex justify-content-center align-items-center">
      {loading ? (
        <Spin className="m-5" />
      ) : cart.length < 1 ? (
        <Empty
          className="m-5"
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 60,
          }}
          description={<span>Empty Cart</span>}
        ></Empty>
      ) : (
        <div className="w-100 p-5">
          <Texty
            mode={"smooth"}
            style={{
              fontSize: 30,
              fontWeight: "bold",
              marginTop: 30,
              marginBottom: 30,
            }}
          >
            CHECKOUT
          </Texty>
          <Steps
            direction={window.screen.width > 768 ? "horizontal" : "vertical"}
            current={activeStep}
            onChange={(step) => setActiveStep(step)}
          >
            <Step title="Cart Details" />
            <Step title="Delivery Details" />
            <Step title="Order Confirmation" />
          </Steps>
          <div className="w-100 d-flex justify-content-center">
            {renderSteps(activeStep)}
          </div>
        </div>
      )}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    cart: state.cart.cart,
  };
};

export default connect(mapStateToProps)(index);
