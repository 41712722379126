import axios from "axios";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOutAlt,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import "./MainCSS.css";
import Api from "../Api Wrapper/Api";
import { connect } from "react-redux";
import { login } from "../js/actions/auth/login";
import ValidateUsername from "../Validators/UserNameValidate";
import errors from "../constants/FormErrors";
import { token, currentUser } from "../LocalForage/localForage";
import { BASE_URL } from "../constants/URL";
import Cookies from "js-cookie";
import ImagePanorama from "../Panolen/panorama/ImagePanorama";
import Viewer from "../Panolen/viewer/Viewer";
function LoginScreen(props) {
  const [loginUsername, setLoginUsername] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [flash, setFlash] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const eye = <FontAwesomeIcon icon={faEye} />;
  const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;
  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };
  const HandleLogin = async () => {
    if (loginUsername === "") {
      setFlash(errors.EmptyUserName);
    } else if (loginPassword === "") {
      setFlash(errors.EmptyPassword);
    } else if (!ValidateUsername(loginUsername)) {
      setFlash(errors.UserNameCheck);
    } else if (loginPassword.length < 8) {
      setFlash(errors.PasswordCheck);
    } else {
      setLoading(true);
      await Api.post(`/users/login`, {
        username: loginUsername,
        password: loginPassword,
      }).then(async (response) => {
        if (response.data.success === 201 || response.status === 401) {
          setFlash(response.data.message);
          setLoading(false);
        } else if (response.status === 200) {
          props.login(response.data.token, response.data.user);
          await token.setItem("@token", response.data.token);
          Api.setHeader("token", response.data.token);
          await currentUser.setItem("@currentUser", response.data.user);
          const t = await token.getItem("@token");
          props.router.push(`/`);
        }
      });
    }
  };
  const GetFbLogedInUserData = async (user) => {
    {
      user = JSON.parse(user.slice(2));
      if (user.status === 200) {
        props.login(user.token, user.user);
        await token.setItem("@token", user.token);
        await currentUser.setItem("@currentUser", user.user);
        const t = await token.getItem("@token");
        props.router.push(`/home`);
      } else {
        setFlash(errors.LoginWithFacebookError);
      }
    }
  };
  let panaromaref = null;
  useEffect(() => {
    const user = Cookies.get("user");
    user !== undefined && GetFbLogedInUserData(user);
    const imgPath = process.env.PUBLIC_URL + "/images/login.jpg";
    const viewer = new Viewer({
      container: panaromaref,
      controlBar: false,
      cameraFov: 120,
      autoRotate: true,
      autoRotateSpeed: 0.2,
      dampingFactor: 0.1,
      autoRotateActivationDuration: 1000,
    });
    viewer.OrbitControls.noZoom = true;
    const panorama = new ImagePanorama(imgPath);
    viewer.add(panorama);
  }, []);
  return (
    <div className="row">
      <div className="col-md-5 image-div display-none">
        <div className="img-fluid h-100" ref={(ref) => (panaromaref = ref)} />
        <div className="centered heading image-heading">
          Welcome to Frostie VR
        </div>
        <div className="centered heading-p p-text">
          Procreate connections with 360° Virtual Reality.Be part of a
          futuristic community!
        </div>
        <div className="centered bottom bottom-text">
          Don't have account?{" "}
          <button
            onClick={() => props.router.push("/register")}
            className="orange-button mt-2"
          >
            <span style={{ color: "#fff" }}>SIGNUP</span>
          </button>
        </div>
      </div>
      <div className="col-md-7 width-login">
        <img id="logo" className="mt-5 center-block" src="/logo192.png"></img>
        <div className="mt-5 mb-4 w-75 vertical-border-orange center-block">
          <h4 className="p-2 text-capitalize font-weight-bolder">LOGIN</h4>
        </div>
        <div className="center-block form-group w-75">
          {flash && (
            <div className="alert alert-danger" role="alert">
              {flash}
            </div>
          )}
        </div>
        {/* <div className="center-block form-group w-75">
          {flash && (
            <div className="alert alert-danger" role="alert">
              {flash}
            </div>
          )}
        </div> */}

        <div className="center-block form-group w-75">
          <input
            value={loginUsername}
            className="custom-auth-text-field"
            placeholder="Username"
            onChange={(e) => setLoginUsername(e.target.value)}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingLeft: "0px",
          }}
          className="custom-auth-text-field center-block form-group w-75"
        >
          <input
            value={loginPassword}
            className="custom-auth-pass-field"
            type={passwordShown ? "text" : "password"}
            placeholder="Password"
            onChange={(e) => setLoginPassword(e.target.value)}
          />
          {passwordShown ? (
            <i
              onClick={togglePasswordVisiblity}
              className="d-flex align-items-center"
            >
              {eye}
            </i>
          ) : (
            <i
              onClick={togglePasswordVisiblity}
              className="d-flex align-items-center"
            >
              {eyeSlash}
            </i>
          )}
        </div>
        <div className="center-block form-group w-75 text-right">
          <button
            className="btn btn-lg btn-default gray-color"
            onClick={() => HandleLogin()}
          >
            LOGIN
            {loading ? (
              <div
                className="spinner-border spinner-border-sm ml-3"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            ) : undefined}
            <FontAwesomeIcon
              icon={faSignOutAlt}
              className="ml-2"
              style={{ color: "#ff7702" }}
            />
          </button>
        </div>

        <div className="center-block w-25 mb-3 mt-5 separator">Or</div>

        <div className="center-block form-group width-50 text-center">
          {/* <button
            className="bottom-text p-4 btn btn-block btn-primary"
            onClick={facebookLogin}
          >
            {" "}
            
          </button> */}
          <a
            href="http://localhost:8080/api/users/fb/login"
            className="bottom-text p-2 btn btn-block btn-primary"
          >
            SIGN-IN WITH FACEBOOK
          </a>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
  login,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
