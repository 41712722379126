import React, { useEffect, useState } from "react";
import {
  StarOutlined,
  HeartOutlined,
  ShareAltOutlined,
  FolderAddOutlined,
  HeartFilled,
  CreditCardOutlined,
} from "@ant-design/icons";

import { connect } from "react-redux";
import moment from "moment";
import CopyToClipboard from "react-copy-to-clipboard";

import VideoPanorama from "../../Panolen/panorama/VideoPanorama";

import { applyFilters } from "../../Helpers/filters";
import { Input, Spin, message, Result, Button } from "antd";
import "./index.scss";
import apiClient from "../../Api Wrapper/Api";
import { BASE_URL_IMAGE } from "../../constants/URL";
import Viewer from "../../Panolen/viewer/Viewer";
import ImagePanorama from "../../Panolen/panorama/ImagePanorama";
import { token } from "../../LocalForage/localForage";

class PostViewPublic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      post: "",
      loading: true,
      loadingPanaroma: true,
      isAuthorized: false,
    };
  }
  componentDidMount() {
    var str = this.props.router.params.id;
    var st = str.split("-", 2);
    this.requestNewPostData(st[1], this.props.router.params.uid);
  }

  requestNewPostData = async (id, uid) => {
    await apiClient
      .get(`/post/findPost?post_id=${id}`, {})
      .then(async (response) => {
        if (response && response.status === 200) {
          const Post = response.data;
          const t = await token.getItem("@token");
          apiClient.setHeaders({ token: t });
          apiClient
            .get(
              `/purchase/image/user/buy?uid=${uid}&image_id=${Post.Image.id}`
            )
            .then((res) => {
              if (res.status === 404 || res.status === 401) {
                this.setState({ isAuthorized: false, loading: false });
                message.error("You are not authorized to view this image.");
              } else {
                this.setState({
                  post: Post,
                  loading: false,
                  isAuthorized: true,
                });

                this.renderPanaroma(response.data);
              }
            });
        } else {
          message.error(
            "There was an error fetching the post, please refresh or contact our administrator."
          );
        }
      })
      .catch((err) => {
        console.log(err);
        message.error(
          "There was an error fetching the post, please refresh or contact our administrator."
        );
      });
  };

  renderPanaroma = (post) => {
    const viewer = new Viewer({
      container: this.panaromaref,
      autoHideInfospot: false,
      cameraFov: 120,
      // controlBar: false,
      // controlButtons: [],
    });
    this.myViewer = viewer;

    const ext = post.Image.image.split(".", 2);
    if (ext[1] === "png" || ext[1] === "jpeg" || ext[1] === "jpg") {
      const panorama = new ImagePanorama(BASE_URL_IMAGE + post.Image.image);
      viewer.add(panorama);
      this.myPanaroma = panorama;
    } else if (
      ext[1] === "mp4" ||
      ext[1] === "mkv" ||
      ext[1] === "gif" ||
      ext[1] === "webm"
    ) {
      const panorama = new VideoPanorama(BASE_URL_IMAGE + post.Image.image, {
        autoplay: true,
      });
      viewer.add(panorama);
      this.myPanaroma = panorama;
    }
    this.setState({ loadingPanaroma: false });
  };

  render() {
    const { post, loading, loadingPanaroma } = this.state;

    return (
      <div>
        {loading ? (
          <Spin
            style={{
              width: "100%",
              padding: 50,
            }}
          />
        ) : this.state.isAuthorized ? (
          <>
            {loadingPanaroma && (
              <Spin
                style={{
                  width: "100%",
                  padding: 50,
                }}
              />
            )}
            <div
              style={{
                height: window.innerHeight,
                width: window.innerWidth,
              }}
              ref={(ref) => (this.panaromaref = ref)}
            />
          </>
        ) : (
          <Result
            status="403"
            title="403"
            subTitle="Sorry, you are not authorized to access this page."
            extra={
              <Button
                onClick={() => this.props.router.push("/")}
                type="primary"
              >
                Back Home
              </Button>
            }
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    filterName: state.editor.filterName,
    intensity: state.editor.intensity,
    pitchLimit: state.editor.pitchLimit,
    zoomLimit: state.editor.zoomLimit,
    privacy: state.editor.privacy,
    description: state.editor.description,
  };
};
export default connect(mapStateToProps)(PostViewPublic);
