export const APPLY_IMAGE_IMAGE_FILTER = "IMAGE_APPLY_IMAGE_FILTER";
export const APPLY_IMAGE_IMAGE_FILTER_INTENSITY =
  "APPLY_IMAGE_IMAGE_FILTER_INTENSITY";
export const APPLY_MAX_PITCH = "APPLY_MAX_PITCH";
export const APPLY_ZOOM_LIMIT = "APPLY_ZOOM_LIMIT";
export const SET_PRIVACY = "SET_PRIVACY";
export const SET_DESCRIPTION = "SET_DESCRIPTION";
export const SET_PHOTONAME = "SET_PHOTONAME";
export const SET_YAW = "SET_YAW";

const filter = (filterName) => ({
  type: APPLY_IMAGE_IMAGE_FILTER,
  filterName: filterName,
});

export const applyFilter = (filterName) => (dispatch) => {
  dispatch(filter(filterName));
};

export const applyIntensity = (intensity) => (dispatch) => {
  dispatch({
    type: APPLY_IMAGE_IMAGE_FILTER_INTENSITY,
    intensity,
  });
};

export const applyMaxPitch = (pitchLimit) => (dispatch) => {
  dispatch({
    type: APPLY_MAX_PITCH,
    pitchLimit,
  });
};

export const applyZoomLimit = (zoomLimit) => (dispatch) => {
  dispatch({
    type: APPLY_ZOOM_LIMIT,
    zoomLimit,
  });
};

export const setDescription = (description) => (dispatch) => {
  dispatch({
    type: SET_DESCRIPTION,
    description,
  });
};

export const setPhotoName = (photoName) => (dispatch) => {
  dispatch({
    type: SET_PHOTONAME,
    photoName,
  });
};
export const setPrivacy = (privacy) => (dispatch) => {
  dispatch({
    type: SET_PRIVACY,
    privacy,
  });
};

export const setYaw = (yaw) => (dispatch) => {
  dispatch({
    type: SET_YAW,
    maxYaw: yaw,
  });
};
