const errors = {
  EmptyEmail: "Please enter email",
  EmptyPassword: "Please enter password",
  EmptyConfirmPasword: "Please enter confirm password",
  EmptyUserName: "Please enter username",
  EmptyFullName: "Please enter full name",
  InvalidEmail: "Email is not valid",
  PasswordCheck: "Password length should at least be 8",
  FullNameCheck: "Name should not contain any numbers or characters",
  UserNameCheck: "Username should only contains letters",
  ConfirmPasswordCheck: "Password and confirm password did not match",
  PostNameCheck: "Please Enter Post Name",
  TourImageMax: "Max Limit for one tour is 6",
  TourImageCheck: "Please select atleast on Image",
  HotspotURL: "Please Enter URL",
  HotspotCardText: "Please Enter Text of Card by clicking editing card button",
  HotspotPost: "Please select Post",
  ImagePurchaseCheck: "Please enter Price",
  ValidImageorVideoExt: "Please select a valid image or video.",
  unAuthorizedUserCheck: "Please Login first to interact with posts.",
  HotspotImageDimensions:
    "Supported image format is PNG and Dimensions are 64x64",
  LoginWithFacebookError:
    "Unable to log in with facebook,Please try again lator",
  PhoneValidation: "Phone # should be numbers and smaller than 14",
  unAuthorizedSubcriptionUserCheck: "Please Login first to subscribe plan",
};

export default errors;
