import { create } from "apisauce";
import { BASE_URL_PRODUCT } from "../constants/URL";

const apiClient = create({
  baseURL: BASE_URL_PRODUCT,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "x-app-id": "frostyVr",
  },
});

export default apiClient;
