import {
  SET_USER_POSTS,
  SET_USER_TOURS,
  SET_USER_FOLLOWERS,
  SET_USER_FOLLOWS,
  SET_USER_PHOTOS_LIKE,
  SET_USER_TOURS_LIKE,
  SET_VISITOR_NAME,
} from "../actions/Posts/posts.actions";

const postsReducer = (
  state = {
    isVisitor: false,
    userPhotos: [],
    photosUserLike: [],
    toursUserLike: [],
    userTours: [],
    userFollowers: [],
    userFollows: [],
  },
  action
) => {
  switch (action.type) {
    case SET_USER_POSTS:
      return Object.assign({}, state, {
        userPhotos: action.userPhotos,
      });
    case SET_USER_TOURS:
      return Object.assign({}, state, {
        userTours: action.userTours,
      });
    case SET_USER_FOLLOWERS:
      return Object.assign({}, state, {
        userFollowers: action.userFollowers,
      });
    case SET_USER_FOLLOWS:
      return Object.assign({}, state, {
        userFollows: action.userFollows,
      });
    case SET_USER_PHOTOS_LIKE:
      return Object.assign({}, state, {
        photosUserLike: action.photosUserLike,
      });
    case SET_USER_TOURS_LIKE:
      return Object.assign({}, state, {
        toursUserLike: action.toursUserLike,
      });
    case SET_VISITOR_NAME:
      return Object.assign({}, state, {
        isVisitor: action.isVisitor,
      });
    default:
      return state;
  }
};

export default postsReducer;
