import React, { useEffect, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Avatar, Dropdown, Menu, message } from "antd";
import {
  MenuOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  LogoutOutlined,
  SettingOutlined,
  InboxOutlined,
  SearchOutlined,
  BellOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import { isAuthorized } from "../js/actions/auth/token";
import "./MainCSS.css";
import { token, currentUser } from "../LocalForage/localForage";
import { logout } from "../js/actions/auth/logout";
import { BASE_URL_IMAGE } from "../constants/URL";
import apiClient from "../Api Wrapper/Api";

function AppNavbav(props) {
  useEffect(async () => {
    if (isAuthenticated) {
      apiClient
        .get("/notification/get", {
          headers: { token: await token.getItem("@token") },
        })
        .then((res) => {
          if (res.status === 200) {
            setNotificationCount(res.data.length);
          } else {
            message.error("something went wrong!");
          }
        })
        .catch((err) => {
          message.error("something went wrong!");
          console.log(err);
        });
    }
  }, []);

  const [notificationCount, setNotificationCount] = useState(0);
  const openNav = () => {
    document.getElementById("mySidenav").style.width =
      window.screen.width > 762 ? "500px" : "250px";
  };
  const { isAuthenticated } = props;

  const path = isAuthenticated
    ? [
        {
          path: `/home`,
          pathName: "Home",
        },
        {
          path: "/",
          pathName: "About",
        },
        {
          path: "/explore/photos",
          pathName: "Explore",
        },
        {
          path: "/blog",
          pathName: "Blog",
        },
        {
          path: "/pricing",
          pathName: "Pricing",
        },
        {
          path: "/support",
          pathName: "Support",
        },
        {
          path: "/froastie-vr-pro",
          pathName: "Frostie VR Pro",
        },
      ]
    : [
        {
          path: "/",
          pathName: "About",
        },
        {
          path: "/explore/photos",
          pathName: "Explore",
        },
        {
          path: "/blog",
          pathName: "Blog",
        },
        {
          path: "/pricing",
          pathName: "Pricing",
        },
        {
          path: "/support",
          pathName: "Support",
        },
        {
          path: "/login",
          pathName: "Login",
        },
        {
          path: "/register",
          pathName: "Register",
        },
      ];

  const menu = () => (
    <Menu>
      <Menu.Item
        onClick={() => {
          props.router.push(`/profile/photos/${props.user.username}`);
        }}
        key={1}
        style={{ fontWeight: "bold" }}
      >
        {props.user.displayName.charAt(0).toUpperCase() +
          props.user.displayName.slice(1)}
      </Menu.Item>
      <hr className="m-2" />
      <Menu.Item
        onClick={() => {
          console.log(
            props.router.push(`/edit-profile/${props.user.username}`)
          );
        }}
        key={2}
        style={{
          alignItems: "center",
          display: "flex",
          fontWeight: "500",
        }}
      >
        <SettingOutlined /> Account Setting
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          console.log(props.router.push(`/order`));
        }}
        key={3}
        style={{
          alignItems: "center",
          display: "flex",
          fontWeight: "500",
        }}
      >
        <InboxOutlined /> Your Orders
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          console.log(props.router.push(`/tracker`));
        }}
        key={4}
        style={{
          alignItems: "center",
          display: "flex",
          fontWeight: "500",
        }}
      >
        <SearchOutlined /> Track Order
      </Menu.Item>
      <hr className="m-1" />
      <Menu.Item
        onClick={async () => {
          await token.setItem("@token", "");
          await currentUser.setItem("@currentUser", "");
          props.logout();
        }}
        key={5}
      >
        <div
          style={{
            color: "rgb(248, 137, 18)",
            alignItems: "center",
            display: "flex",
            fontWeight: "500",
          }}
        >
          <LogoutOutlined style={{ marginRight: 8 }} />
          Sign Out
        </div>
      </Menu.Item>
    </Menu>
  );
  return (
    <Navbar className="navbar-expand" bg="light" variant="dark">
      <span className="mr-2">
        <a onClick={openNav}>
          <MenuOutlined style={{ color: "#ff7702", fontSize: "28px" }} />
        </a>
      </span>

      <Navbar.Brand
        className="small-display-logo large-display-logo"
        href="#home"
      >
        <img
          alt=""
          src="/logo192.png"
          width="100%"
          height="100%"
          className="d-inline-block align-top"
        />
      </Navbar.Brand>
      <Nav className="ml-auto display-none">
        {path.map((item, key) => {
          return (
            <Nav.Link
              key={key}
              onClick={() => {
                props.router.push(item.path);
              }}
            >
              <span
                style={{
                  color:
                    props.router.location.pathname === item.path
                      ? "rgb(247, 172, 86)"
                      : undefined,
                }}
              >
                {item.pathName === "Frostie VR Pro" ? (
                  <span>
                    Frostie VR <em className="highlight-text">PRO</em>
                  </span>
                ) : (
                  item.pathName
                )}
              </span>
            </Nav.Link>
          );
        })}

        {isAuthenticated && (
          <>
            <Nav.Link
              onClick={() => {
                props.router.push("/cart");
              }}
            >
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: 24,
                  color:
                    props.router.location.pathname === "/cart"
                      ? "rgb(247, 172, 86)"
                      : undefined,
                }}
              >
                <ShoppingCartOutlined />
              </span>
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                props.router.push("/notification");
              }}
            >
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: 24,
                  color:
                    props.router.location.pathname === "/notification"
                      ? "rgb(247, 172, 86)"
                      : undefined,
                }}
              >
                <BellOutlined />
                {notificationCount !== 0 && (
                  <p
                    style={{
                      margin: 0,
                      fontSize: 12,
                      color: "rgb(247, 172, 86)",
                    }}
                  >
                    {notificationCount}
                  </p>
                )}
              </span>
            </Nav.Link>
            <div
              style={{
                marginLeft: 18,
              }}
            >
              <Dropdown
                overlayStyle={{
                  width: 200,
                  paddingTop: 20,
                }}
                overlay={menu}
              >
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  {props.user.profilePicture ? (
                    <img
                      style={{ width: 45, height: 45, borderRadius: 25 }}
                      src={BASE_URL_IMAGE + props.user.profilePicture}
                    />
                  ) : (
                    <Avatar
                      style={{ display: "flex", justifyContent: "center" }}
                      size={45}
                      icon={props.user.displayName.charAt(0).toUpperCase()}
                    />
                  )}
                </a>
              </Dropdown>
            </div>
          </>
        )}
      </Nav>
    </Navbar>
  );
}

const mapDispatchToProps = {
  logout,
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    userID: state.auth.user.userID,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppNavbav);
