import React from "react";

export default function Bullet({ text, color, highlight }) {
  return (
    <div
      style={{
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        padding: 2,
      }}
    >
      <div
        style={{
          height: 10,
          width: 10,
          marginRight: 10,
          backgroundColor:
            color === "green" ? "rgb(129, 209, 53)" : "rgb(247, 172, 86)",
          borderRadius: 25,
        }}
      ></div>

      <p style={{ margin: 0, color: "#757575", fontWeight: "500" }}>{text}</p>
    </div>
  );
}
