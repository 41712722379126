import apiClient from "../../../Api Wrapper/Api";

export const SET_USER_POSTS = "SET_USER_POSTS";
export const SET_USER_PHOTOS_LIKE = "SET_USER_PHOTOS_LIKE";
export const SET_USER_TOURS_LIKE = "SET_USER_TOURS_LIKE";
export const SET_USER_TOURS = "SET_USER_TOURS";
export const SET_USER_FOLLOWERS = "SET_USER_FOLLOWERS";
export const SET_USER_FOLLOWS = "SET_USER_FOLLOWS";

export const getVisitorPost = (username, uid) => (dispatch) => {
  apiClient
    .get(`/post/findPosts?username=${username}&uid=${uid}`)

    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: SET_USER_POSTS,
          userPhotos: response.data,
        });
        dispatch({
          type: SET_USER_PHOTOS_LIKE,
          photosUserLike: response.data.ImagesILiked,
        });
        dispatch({
          type: SET_USER_TOURS_LIKE,
          toursUserLike: response.data.ToursILiked,
        });
        dispatch({
          type: SET_USER_TOURS,
          userTours: response.data,
        });
        dispatch({
          type: SET_USER_FOLLOWERS,
          userFollowers: response.data,
        });
        dispatch({
          type: SET_USER_FOLLOWS,
          userFollows: response.data,
        });
      } else {
        console.log("Error in fetching posts data in post action");
      }
    })
    .catch((error) => {
      console.log("Error in fetching posts data in post action" + error);
    });
};
