import React, { useEffect, useState } from "react";
import Post from "../../../CustomComponents/Post";
import "./index.scss";
import QueueAnim from "rc-queue-anim";
import moment from "moment";
import Texty from "rc-texty";
import { Empty, Spin, Button } from "antd";
import { connect } from "react-redux";
import { BASE_URL_WEBSITE } from "../../../constants/URL";

function TabPhotos(props) {
  const [posts, setPosts] = useState();
  const [order, setOrder] = useState();
  useEffect(() => {
    props.posts && setPosts(props.posts.concat(props.tours));
  }, [props?.posts]);
  function updateOrder(selectedOrder) {
    if (posts?.length > 1) {
      setOrder(selectedOrder);
      selectedOrder === "date" &&
        setPosts(
          posts.sort(function (a, b) {
            return new Date(b.createdAt) - new Date(a.createdAt);
          })
        );
      selectedOrder === "name" &&
        setPosts(
          posts.sort(function (a, b) {
            if (
              !a.Tour
                ? a.name.toLowerCase()
                : a.Tour.tour_name.toLowerCase() <
                  (!b.Tour
                    ? b.name.toLowerCase()
                    : b.Tour.tour_name.toLowerCase())
            ) {
              return -1;
            }
            if (
              !a.Tour
                ? a.name.toLowerCase()
                : a.Tour.tour_name.toLowerCase() >
                  (!b.Tour
                    ? b.name.toLowerCase()
                    : b.Tour.tour_name.toLowerCase())
            ) {
              return 1;
            }
            return 0;
          })
        );
      // selectedOrder === "likes" &&
      //   setPosts(
      //     posts.sort(function (a, b) {
      //       return (
      //         parseInt(b.Image.Likes.length) - parseInt(a.Image.Likes.length)
      //       );
      //     })
      //   );
      // selectedOrder === "views" &&
      //   setPosts(
      //     posts.sort(function (a, b) {
      //       return (
      //         parseInt(b.Image.Views.length) - parseInt(a.Image.Views.length)
      //       );
      //     })
      //   );
    }
  }
  return (
    <div className="main-container">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
        className="w-100  m-2"
      >
        <div className="gray-color lg-fs-16 sm-fs-13">Order by:</div>
        <div
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            color: order === "date" && "rgb(100, 100, 236)",
          }}
          className="gray-color ml-2 lg-fs-16 sm-fs-13"
          onClick={() => updateOrder("date")}
        >
          date
        </div>
        <div
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            color: order === "name" && "rgb(100, 100, 236)",
          }}
          className="gray-color ml-2 lg-fs-16 sm-fs-13"
          onClick={() => updateOrder("name")}
        >
          name
        </div>
        {/* <div
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            color: order === "likes" && "rgb(100, 100, 236)",
          }}
          className="gray-color ml-2 lg-fs-16 sm-fs-13"
          onClick={() => updateOrder("likes")}
        >
          likes
        </div>
        <div
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            color: order === "views" && "rgb(100, 100, 236)",
          }}
          className="gray-color ml-2 lg-fs-16 sm-fs-13"
          onClick={() => updateOrder("views")}
        >
          views
        </div> */}
      </div>
      {!props.isVisitor && (
        <Texty
          type="left"
          mode={"smooth"}
          style={{
            fontSize: 30,
            fontWeight: "bold",
            marginTop: 30,
            textAlign: "center",
          }}
        >
          YOUR UPLOADS
        </Texty>
      )}
      <QueueAnim
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
        className="queue-simple"
      >
        {!posts ? (
          <Spin
            style={{
              width: "100%",
              padding: 50,
            }}
          />
        ) : (
          <React.Fragment>
            {posts.length > 0 ? (
              posts.map((item, index) => {
                if (!item.Tour) {
                  return (
                    <div className="lg-post-w sm-post-w mt-2">
                      <Post
                        handleClick={() =>
                          props.router.push(`/post-view/id-${item.id}`)
                        }
                        key={item.id}
                        postID={item.id}
                        id={item.id}
                        postUserID={item.uid}
                        userID={props.userID}
                        postName={item.description}
                        commentUser={props.displayName}
                        userName={props.displayName ? props.displayName : ""}
                        numberOfComments={item.Image.Comments.length}
                        Comments={item.Image.Comments}
                        numberOfLikes={item.Image.Likes.length}
                        Likes={item.Image.Likes}
                        numberOfView={item.Image.Views.length}
                        time={moment(item.createdAt).fromNow()}
                        profile={props.profilePicture}
                        isTour={false}
                        image={item.Image.image}
                        isPurchaseable={item.Image.isPurchaseable}
                        isFeatured={item.featuredTill === "0" ? false : true}
                        isLiked={item.isLiked}
                        Liked={true}
                        LikedUserProfPic={true}
                        shareUrl={BASE_URL_WEBSITE + `/post-view/id-${item.id}`}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div className="lg-post-w sm-post-w mt-2">
                      <Post
                        handleClick={() =>
                          props.router.push(
                            `/tour-view/tid-${item.id}/${item.uid}`
                          )
                        }
                        postID={item.id}
                        key={item.id}
                        shareUrl={
                          BASE_URL_WEBSITE +
                          `/tour-view/tid-${item.id}/${item.uid}`
                        }
                        id={item.id}
                        userID={props.userID}
                        postUserID={item.uid}
                        postName={item.description}
                        numberOfComments={
                          item.Tour.Tour_images[0].Comments.length
                        }
                        Comments={item.Tour.Tour_images[0].Comments}
                        commentUser={props.displayName}
                        numberOfLikes={item.Tour.Tour_images[0].Likes.length}
                        Likes={item.Tour.Tour_images[0].Likes}
                        numberOfView={item.Tour.Tour_images[0].Views.length}
                        userName={props.displayName ? props.displayName : ""}
                        time={moment(item.createdAt).fromNow()}
                        profile={props.profilePicture}
                        isTour={true}
                        image={item.Tour.Tour_images[0].image}
                        isPurchaseable={item.Tour.isPurchaseable}
                        isFeatured={item.featuredTill === "0" ? false : true}
                        isLiked={item.Tour.Tour_images[0].isLiked}
                        Liked={true}
                        LikedUserProfPic={true}
                      />
                    </div>
                  );
                }
              })
            ) : (
              <React.Fragment>
                {props.username === props.routeParams.name ? (
                  <div
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <Empty
                      image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                      imageStyle={{
                        height: 60,
                      }}
                      description={
                        <span>There are no Posts that you have posted</span>
                      }
                    >
                      <Button
                        type="primary"
                        onClick={() => props.router.push("/single-image")}
                      >
                        Post Now
                      </Button>
                    </Empty>
                  </div>
                ) : (
                  <div
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <Empty
                      image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                      imageStyle={{
                        height: 60,
                      }}
                      description={<span>This User Has No Posts</span>}
                    ></Empty>
                  </div>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </QueueAnim>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    userID: state.auth.user.userID,
    username: state.auth.user.username,
    userPhotos: state.posts.isVisitor
      ? state.followerProfile.userPhotos
      : state.posts.userPhotos,
    isVisitor: state.posts.isVisitor,
  };
};

export default connect(mapStateToProps)(TabPhotos);
