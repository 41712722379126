import {
  APPLY_IMAGE_IMAGE_FILTER,
  APPLY_IMAGE_IMAGE_FILTER_INTENSITY,
  APPLY_MAX_PITCH,
  APPLY_ZOOM_LIMIT,
  SET_DESCRIPTION,
  SET_PRIVACY,
  SET_PHOTONAME,
  SET_YAW,
} from "../../actions/editor/filters";

const editorReducer = (
  state = {
    filterName: "NONE",
    intensity: 50,
    pitchLimit: 90,
    maxYaw: 180,
    zoomLimit: 100,
    privacy: "Public",
    description: "",
    photoName: "",
  },
  action
) => {
  switch (action.type) {
    case APPLY_IMAGE_IMAGE_FILTER:
      return Object.assign({}, state, {
        filterName: action.filterName,
      });
    case APPLY_IMAGE_IMAGE_FILTER_INTENSITY:
      return Object.assign({}, state, {
        intensity: action.intensity,
      });
    case APPLY_MAX_PITCH:
      return Object.assign({}, state, {
        pitchLimit: action.pitchLimit,
      });
    case APPLY_ZOOM_LIMIT:
      return Object.assign({}, state, {
        zoomLimit: action.zoomLimit,
      });
    case SET_DESCRIPTION:
      return Object.assign({}, state, {
        description: action.description,
      });
    case SET_PHOTONAME:
      return Object.assign({}, state, {
        photoName: action.photoName,
      });
    case SET_PRIVACY:
      return Object.assign({}, state, {
        privacy: action.privacy,
      });
    case SET_YAW:
      return Object.assign({}, state, {
        maxYaw: action.maxYaw,
      });
    default:
      return state;
  }
};

export default editorReducer;
