// export const BASE_URL = "http://159.203.125.201:8080/api";
// export const BASE_URL_IMAGE = "http://159.203.125.201:8080";
// export const BASE_URL_WEBSITE = "http://159.203.125.201/#";
// export const BASE_URL_PRODUCT = "http://therentalsapp.com/rentmoebackend/api";

export const BASE_URL = "http://localhost:8080/api";
export const BASE_URL_PRODUCT = "http://therentalsapp.com/rentmoebackend/api";
export const BASE_URL_IMAGE = "http://localhost:8080";
export const BASE_URL_WEBSITE = "http://localhost:3000/#";

// export const BASE_URL = "http://192.168.18.3:8080/api";
// export const BASE_URL_PRODUCT = "http://therentalsapp.com/rentmoebackend/api";
// export const BASE_URL_IMAGE = "http://192.168.18.3:8080";
// export const BASE_URL_WEBSITE = "http://192.168.18.3:3000/#";
