import React from "react";
import "../Screens/Home/index.scss";
import moment from "moment";
import { BASE_URL_IMAGE } from "../constants/URL";
import Avatar from "antd/lib/avatar/avatar";

export default function Activity({ activity }) {
  return (
    <div style={{ marginTop: 50 }}>
      <div style={{ marginLeft: 10 }}>
        <p className="font-weight-bold">Activity</p>
      </div>
      {activity.map((act, index) => (
        <div key={act.createdAt} className="activity_container">
          <div>
            {act.User.profilePicture ? (
              <img
                className="activity_image"
                alt=""
                src={BASE_URL_IMAGE + act.User.profilePicture}
              />
            ) : (
              <Avatar className="activity_image" size={50}>
                {act.commenter.charAt(0)}
              </Avatar>
            )}
          </div>
          <div className="activity_innerConatiner">
            <p className="m-0">{act.text}</p>
            <p className="dark_text m-0">
              {moment(act.createdAt).format("MMMM Do YYYY, h:mm")}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}
