import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
} from "@ant-design/icons";

function Footer(props) {
  return (
    <section id="footer" style={{ backgroundColor: "#e8ebfa" }}>
      <div className="row text-center text-xs-center text-sm-left text-md-left">
        <div className="center-block col-xs-12 col-sm-4 col-md-4 mt-4">
          {/* <br /> */}
          <br />
          <img
            alt=""
            src="/logo192.png"
            width="40%"
            height="9%"
            className="d-inline-block align-top"
          />{" "}
          <h6 className="gray-color mt-2">
            Procreate connections with 360° Virtual Reality.
          </h6>
          <br />
          <br />
          <a className="brand-links" href="">
            <FacebookOutlined style={{ color: "#ff7702", fontSize: "24px" }} />
          </a>
          <a className="brand-links ml-3" href="">
            <TwitterOutlined style={{ color: "#ff7702", fontSize: "24px" }} />
          </a>
          <a className="brand-links ml-3" href="">
            <InstagramOutlined style={{ color: "#ff7702", fontSize: "24px" }} />
          </a>
          <br />
          <br />
          <ul className="list-unstyled mt-4">
            <li onClick={() => props.router.push("/home")}>
              <a className="gray-color">Home</a>
            </li>
            <li onClick={() => props.router.push("/")}>
              <a className="gray-color">About</a>
            </li>
            <li>
              <a className="gray-color" href="#">
                FAQ
              </a>
            </li>
            <li>
              <a className="gray-color" href="#">
                Get Started
              </a>
            </li>
            <li>
              <a className="gray-color" href="#">
                Videos
              </a>
            </li>
          </ul>
          <br />
          {/* <br /> */}
        </div>
        <div className="col-xs-12 col-sm-4 col-md-4 mt-4">
          <br />
          <h3 className="font-weight-bold">Latest Posts</h3>
          <h5 className="gray-color mt-0">Lorum ipsum dolor sit amit.....</h5>
          <p className="text-muted mt-0">
            6th Jan, 2021 | by{" "}
            <a href="" className="brand-links">
              Chris
            </a>
          </p>
          <h6 className="mt-4 gray-color">Lorum ipsum dolor sit amit.....</h6>
          <h6 className="text-muted">
            6th Jan, 2021 | by{" "}
            <a href="" className="brand-links">
              Chris
            </a>
          </h6>
          <br />
          <br />
          <img
            style={{ opacity: "0.3" }}
            alt=""
            src="/logo192.png"
            width="60%"
            height="15%"
            className="d-inline-block align-top mt-4"
          />
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <h6
          className="gray-color "
          style={{
            fontSize: "16px",
          }}
        >
          ©Copyright 2020 <span style={{ color: "#ff7702" }}>FrostieVR</span> -
          Powered by SenaryBits
        </h6>
      </div>
      <br />
    </section>
  );
}

export default Footer;
