import React, { useEffect } from "react";
import { Row, Col, Button, message } from "antd";

import "./index.scss";
import Texty from "rc-texty";
import apiClient from "../../Api Wrapper/ProductApi";

export default function Receipt(props) {
  const calculateTotal = () => {
    let total = 0;
    props.cart.map((item) => {
      if (item.product_details) total = total + item.quantity * item.price;
    });
    return total;
  };
  const { cart } = props;
  let total = 0;

  const deliveryMethod = (action) => {
    switch (action) {
      case 12:
        return "normal_mail";
        break;
      case 15:
        return "DHL";
        break;
      case 20:
        return "UPS";
        break;
      case 25:
        return "Fedex";
        break;
      default:
        return "normal_mail";
        break;
    }
  };

  const confirmOrder = () => {
    const orderDetails = [];
    props.cart.map((item, index) => {
      orderDetails.push({
        product_id: item.product_id,
        shipping_address: props.deliveryAddress,
        return_address: props.deliveryAddress,
        quantity: item.quantity,
        price_per_unit: item.price,
        coupon: "",
        return_lat: 23,
        return_lng: 12,
        dispatch_from_address: "xyz B street",
        package_weight: 0,
        package_weight_unit: "gram",
        package_dimensions: {
          width: 32,
          height: 23,
          unit: "centimeter",
        },
        shipping_service_title: deliveryMethod(props.shippingCost),
        shipping_cost: props.shippingCost,
        currency_short_code: "USD",
      });
    });
    const orderForm = new FormData();
    orderForm.append("customer_id", props.cart[0].customer_id);
    orderForm.append("sub_total", calculateTotal());
    orderForm.append("shipping_cost", props.shippingCost);
    orderForm.append("total", calculateTotal() + props.shippingCost);
    orderForm.append("payment_status", 2);
    orderForm.append("payment_method", props.paymentMethod);
    orderForm.append("payment_customer_stripe_id", "cus_xyzq323");
    orderForm.append("tax", 0);
    orderForm.append("total_shipping_cost", props.shippingCost);
    orderForm.append("order_details", JSON.stringify(orderDetails));
    console.log(orderDetails);
    apiClient
      .post("/customer/order/submit", orderForm, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "x-app-id": "frostyVr",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          props.router.push("/order");
        } else {
          message.error("something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
        message.error("something went wrong");
      });
  };

  return (
    <div className="delivery-container cart-border">
      <Texty mode={"smooth"} style={{ fontWeight: "600" }}>
        Order Details
      </Texty>
      <Row className="justify-content-lg-between mt-3">
        <Col span={6}>
          <Texty mode={"smooth"} style={{ fontWeight: "600" }}>
            Product
          </Texty>
        </Col>
        <Col span={6}>
          <Texty mode={"smooth"} style={{ fontWeight: "600" }}>
            Price
          </Texty>
        </Col>
        <Col span={6}>
          <Texty mode={"smooth"} style={{ fontWeight: "600" }}>
            Quantity
          </Texty>
        </Col>
        <Col span={6}>
          <Texty mode={"smooth"} style={{ fontWeight: "600" }}>
            Total
          </Texty>
        </Col>
      </Row>
      <Row className="justify-content-lg-between mt-3">
        {cart.map((item, index) => {
          if (item.product_details) {
            total = total + item.quantity * item.price;
            return (
              <>
                <Col span={6}>
                  <img
                    src={item.product_details.product_media[0]._value}
                    style={{
                      width: 40,
                      height: 30,
                      borderRadius: 10,
                      marginRight: 10,
                    }}
                  />
                  {item.product_details.name}
                </Col>
                <Col span={6}>$ {item.price}</Col>
                <Col span={6}>
                  <span className="ml-2 mr-2">{item.quantity}</span>
                </Col>
                <Col span={6}>$ {item.quantity * item.price}</Col>
              </>
            );
          }
        })}
      </Row>
      <hr />
      <div className="d-flex mt-2 w-50 justify-content-between">
        <Texty mode={"smooth"} style={{ fontWeight: "600" }}>
          Sub Total:
        </Texty>
        <span> ${calculateTotal()}</span>
      </div>
      <div className="d-flex mt-2 w-50 justify-content-between">
        <Texty mode={"smooth"} style={{ fontWeight: "600" }}>
          Shipping Charges:
        </Texty>
        <span> ${props.shippingCost}</span>
      </div>
      <hr />
      <div className="d-flex mt-2 w-50 justify-content-between">
        <Texty mode={"smooth"} style={{ fontWeight: "600" }}>
          Total:
        </Texty>
        <span> ${calculateTotal() + props.shippingCost}</span>
      </div>
      <Button
        className="checkout-btn mt-3 mb-2"
        onClick={() => {
          confirmOrder();
        }}
      >
        Confirm Order
      </Button>
    </div>
  );
}
