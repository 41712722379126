import React, { useEffect, useState } from "react";
import "../Screens/Home/index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import QueueAnim from "rc-queue-anim";
import Texty from "rc-texty";
import { Empty, Spin, Button, Row, Col, Avatar, Input } from "antd";
import apiClient from "../Api Wrapper/Api";
import { getUserPosts } from "../js/actions/Posts/posts.actions";
import HomeProfile from "./HomeProfile";
import { BASE_URL_IMAGE } from "../constants/URL";
import Item from "antd/lib/list/Item";

const { Search } = Input;
function Following(props) {
  const [loading, setLoading] = useState(false);
  const [following, setFollowing] = useState();
  const [pageData, setPageData] = useState();
  const [click, setClick] = useState(false);
  useEffect(() => {
    LoadFollowing();
  }, []);
  const LoadFollowing = async () => {
    await apiClient
      .get(`/post/findPosts?username=${props.routeParams.name}`)
      .then((res) => {
        if (res.status === 200) {
          setPageData(res.data);
          setFollowing(res.data.user_followed);
        }
      });
  };
  const unFollow = (fid) => {
    setLoading(true);
    apiClient
      .post(`follower/unfollow?uid=${props.user.userID}&followed=${fid}`)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          window.location.reload();
        }
      });
  };

  const imgPath = process.env.PUBLIC_URL + "/images/";
  function onSearch(value) {
    setFollowing(
      following.filter((ele) => ele.display_name.search(value) > -1)
    );
  }
  return (
    <Row className="m-5">
      <Col span={6}>
        <HomeProfile
          userType={props.userProfile ? props.userProfile.userType : ""}
          name={pageData ? pageData.username : ""}
          displayName={pageData ? pageData.display_name : ""}
          views={pageData ? pageData.profileViews : 0}
          photos={pageData ? pageData.Posts.length + pageData.Tours.length : 0}
          followers={pageData ? pageData.user_followed_by.length : 0}
          following={pageData ? pageData.user_followed.length : 0}
          setClick={setClick}
          router={props.router}
          image={pageData ? pageData.profilePicture : ""}
          isVisitor={props.isVisitor}
          userID={props.userProfile ? props.userProfile.id : ""}
        />
      </Col>
      <Col span={12}>
        <div>
          <div className="d-flex flex-row justify-content-between align-items-center">
            <Texty
              type="left"
              mode={"smooth"}
              style={{ fontSize: 30, fontWeight: "bold", marginTop: 30 }}
            >
              PEOPLE YOU FOLLOW
            </Texty>
            <div style={{ marginTop: 30 }}>
              <Search
                placeholder="search following"
                onSearch={onSearch}
                style={{
                  width: 250,
                  backgroundColor: "grey",
                }}
              />
            </div>
          </div>
          {!following ? (
            <Spin
              style={{
                width: "100%",
                padding: 50,
              }}
            />
          ) : (
            <QueueAnim className="queue-simple">
              {following.length > 0 ? (
                following.map((item, index) => {
                  return (
                    <div
                      key={item.id}
                      className="d-flex flex-row justify-content-between align-items-center center-block width-90 pb-3"
                    >
                      <div className="d-flex flex-row mt-2 mb-2">
                        {item.profilePicture ? (
                          <img
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (props.user.username !== item.username) {
                                props.router.push({
                                  pathname: `/profile/photos/${item.username}`,
                                });
                              }
                            }}
                            className="activity_image"
                            alt=""
                            src={BASE_URL_IMAGE + item.profilePicture}
                          />
                        ) : (
                          <Avatar
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (props.user.username !== item.username) {
                                props.router.push({
                                  pathname: `/profile/photos/${item.username}`,
                                });
                              }
                            }}
                            className="activity_image"
                            size={50}
                          >
                            {item.username.charAt(0)}
                          </Avatar>
                        )}
                        <div className="activity_innerConatiner">
                          <p className="m-0">{item.display_name}</p>
                          <p className="dark_text m-0">
                            {item.user_followed_by.length} Followers
                          </p>
                        </div>
                      </div>
                      <div>
                        {!props.isVisitor && (
                          <button
                            onClick={() => unFollow(item.id)}
                            className="follow_button"
                          >
                            Unfollow
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })
              ) : (
                <Empty
                  image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                  imageStyle={{
                    height: 60,
                  }}
                  description={<span>You dont follow anyone</span>}
                ></Empty>
              )}
            </QueueAnim>
          )}
          {/* <div>
        <button className="load_button center-block">Load More...</button>
      </div> */}
        </div>
      </Col>
    </Row>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    isVisitor: state.posts.isVisitor,
    userProfile: state.auth.userProfile,
  };
};
const mapDispatchToProps = {
  getUserPosts,
};

export default connect(mapStateToProps, mapDispatchToProps)(Following);
