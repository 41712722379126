import React from "react";
import { useState } from "react";
import "../Screens/EditProfile/index.scss";
import apiClient from "../Api Wrapper/Api";
import { connect } from "react-redux";

function ProfilePreferences(props) {
  const [isCheckPref, setIsCheckPref] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [trackVisit, setTrackVisit] = useState(false);
  const [privacy, setPrivacy] = useState("");
  const [googleAna, setGoogleAna] = useState("");
  const preferences = [
    "In photos tab, show only posts not added to any other tour.",
    "Publicly show posts you like.",
    "Show the tours tab first, Photos tab second.",
    "Notify me when someone likes my post.",
    "Notify me when another user starts following me.",
  ];

  const handleClick = () =>
    apiClient
      .post(`users/addPref?uid=${props.user.userID}`, {
        photos_not_added_to_tour: isCheckPref[0],
        show_liked_posts_publically: isCheckPref[1],
        show_tours_tab_first: isCheckPref[2],
        notify_when_my_posts_got_liked: isCheckPref[3],
        notify_when_someone_followed_me: isCheckPref[4],
        default_privacy: privacy,
        google_analytics: googleAna,
        do_not_track_my_visit: trackVisit,
      })
      .then((response) => {
        if (response && response.data) {
          if (response && response.status === 200) {
            props.Active("changepassword");
          } else {
          }
        } else {
        }
      })
      .catch((error) => console.log(error));

  return (
    <div className="mt-5 sm-widht-90 sm-align-center">
      <div className="sm-mb-40">
        <h2>Preferences</h2>
        <p className="subheadtext">Profile Preferences:</p>
        <div className="sm-flex-start sm-width-90">
          {preferences.map((item, index) => (
            <div key={item} className="form-check">
              <input
                checked={isCheckPref[index]}
                className="form-check-input"
                type="checkbox"
                id="flexCheckDefault"
                onChange={(e) =>
                  setIsCheckPref(
                    isCheckPref.map((ele, id) =>
                      id === index ? e.target.checked : ele
                    )
                  )
                }
              />
              <label
                className="form-check-label subheadtext"
                htmlFor="flexCheckDefault"
              >
                {item}
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-3 sm-flex-start">
        <fieldset>
          <div className="form-group">
            <label className="subheadtext" htmlFor="disabledSelect">
              Defualt Privacy Settings:
              <span className="highlighttext">PRO</span>
            </label>
            <select
              style={{ fontStyle: "italic" }}
              className="form-control form-control-month helptext lg-widht-230 "
              id="disabledSelect"
              onChange={(event) => setPrivacy(event.target.value)}
            >
              <option>Public</option>
              <option>Private</option>
            </select>
          </div>
        </fieldset>
        <p style={{ fontStyle: "italic" }} className="helptext lg-widht-400 ">
          This setting will be applied to all uploaded files in the future. You
          can change it for individual photos and virtual tours
        </p>
      </div>
      <div className="mt-3 sm-flex-start">
        <fieldset>
          <div className="form-group">
            <label className="subheadtext" htmlFor="disabledSelect">
              Google Analytics:
              <span className="highlighttext">Buisness</span>
            </label>
            <select
              style={{ fontStyle: "italic" }}
              className="form-control form-control-month form-control-sm helptext lg-widht-300"
              id="disabledSelect"
              onChange={(event) => setGoogleAna(event.target.value)}
            >
              <option>Tracking ID (ex:UA-000000-03</option>
              <option>Tracking ID (ex:UA-000000-03</option>
            </select>
          </div>
        </fieldset>
        <div className="form-check">
          <input
            className="form-check-input "
            type="checkbox"
            checked={trackVisit}
            id="flexCheckDefault"
            onChange={(e) => setTrackVisit(e.target.checked)}
          />
          <label
            style={{ color: "#757575" }}
            className="form-check-label smalltext"
            htmlFor="flexCheckDefault"
          >
            Do not track my visit when I login.
          </label>
        </div>
        <p style={{ fontStyle: "italic" }} className="helptext">
          Learn more on Google Analytics Integration in{" "}
          <span style={{ color: "rgb(163, 196, 231)" }}>this article.</span>
        </p>
      </div>
      <button
        style={{ marginBottom: "10px" }}
        type="submit"
        className="btn btn-primary mt-4 lg-widht-100 sm-widht-260"
        onClick={handleClick}
      >
        Save Changes
      </button>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};
export default connect(mapStateToProps)(ProfilePreferences);
