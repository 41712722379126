import axios from "axios";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOutAlt,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import "./MainCSS.css";
import { connect } from "react-redux";
import { login } from "../js/actions/auth/login";
import Api from "../Api Wrapper/Api";
import ValidateEmail from "../Validators/EmailValidate";
import VlaidateFullName from "../Validators/FullNameValidate";
import VlaidateUserName from "../Validators/UserNameValidate";
import { token, currentUser } from "../LocalForage/localForage";
import ImagePanorama from "../Panolen/panorama/ImagePanorama";
import Viewer from "../Panolen/viewer/Viewer";

import errors from "../constants/FormErrors";

function Register(props) {
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerFullName, setRegisterFullName] = useState("");
  const [registerUserName, setregisterUserName] = useState("");
  const [registerAdress, setregisterAdress] = useState("");
  const [registerPhone, setregisterPhone] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [registerConfirmPassword, setRegisterConfirmPassword] = useState("");
  const [flash, setFlash] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const eye = <FontAwesomeIcon icon={faEye} />;
  const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;
  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };
  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };
  const reset = () => {};

  const register = async () => {
    const expression = /^[0-9\b]+$/;
    if (registerEmail === "") {
      setFlash(errors.EmptyEmail);
    } else if (registerFullName === "") {
      setFlash(errors.EmptyFullName);
    } else if (registerUserName === "") {
      setFlash(errors.EmptyUserName);
    } else if (registerPassword === "") {
      setFlash(errors.EmptyPassword);
    } else if (registerConfirmPassword === "") {
      setFlash(errors.EmptyConfirmPasword);
    } else if (!ValidateEmail(registerEmail)) {
      setFlash(errors.InvalidEmail);
    } else if (!VlaidateUserName(registerUserName)) {
      setFlash(errors.UserNameCheck);
    } else if (registerPassword.length < 8) {
      setFlash(errors.PasswordCheck);
    } else if (!VlaidateFullName(registerFullName)) {
      setFlash(errors.FullNameCheck);
    } else if (registerPassword != registerConfirmPassword) {
      setFlash(errors.ConfirmPasswordCheck);
    } else if (!expression.test(registerPhone) || registerPhone.length > 13) {
      setFlash(errors.PhoneValidation);
    } else {
      setLoading(true);
      await Api.post(`/users/signup`, {
        username: registerUserName,
        email: registerEmail,
        display_name: registerFullName,
        address: registerAdress,
        phone: registerPhone,
        password: registerPassword,
      })
        .then(async (response) => {
          console.log(response);
          if (response.status === 201 || response.status === 409) {
            setFlash(
              response.data.message.username
                ? response.data.message.username[0]
                : response.data.message
            );
            setLoading(true);
          } else if (response.status === 200) {
            props.login(response.data.token, response.data.user);
            await token.setItem("@token", response.data.token);
            await currentUser.setItem("@currentUser", response.data.user);
            const t = await token.getItem("@token");
            props.router.push(`/`);
            // props.login(response.data.token, response.data.user.dislayName);
            // props.router.push("/");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  let panaromaref = null;
  useEffect(() => {
    const imgPath = process.env.PUBLIC_URL + "/images/register.png";
    const viewer = new Viewer({
      container: panaromaref,
      controlBar: false,
      cameraFov: 120,
      autoRotate: true,
      autoRotateSpeed: 0.2,
      dampingFactor: 0.1,
      autoRotateActivationDuration: 1000,
    });
    viewer.OrbitControls.noZoom = true;
    const panorama = new ImagePanorama(imgPath);
    viewer.add(panorama);
  }, []);
  return (
    <div className="row">
      <div className="col-md-5 image-div display-none">
        <div className="img-fluid h-100" ref={(ref) => (panaromaref = ref)} />
        <div className="centered heading image-heading">
          Welcome to Frostie VR
        </div>
        <div className="centered heading-p p-text">
          Procreate connections with 360° Virtual Reality.Be part of a
          futuristic community!
        </div>
        <div className="centered bottom bottom-text">
          Already have account?{" "}
          <button
            onClick={() => props.router.push("/login")}
            className="orange-button mt-3"
          >
            <span style={{ color: "#fff" }}>LOGIN</span>
          </button>
        </div>
      </div>
      <div className="col-md-7 width-login">
        <img id="logo" className="mt-5 center-block" src="/logo192.png"></img>
        <div className="mt-5 mb-4 w-75 vertical-border-orange center-block">
          <h4 className="p-2 text-capitalize font-weight-bolder">SIGN UP</h4>
        </div>

        <div className="center-block form-group w-75">
          {flash && (
            <div className="alert alert-danger" role="alert">
              {flash}
            </div>
          )}
        </div>
        <div className="center-block form-group w-75">
          <i
            className="fa fa-fw fa-info-circle d-inline"
            style={{
              fontSize: "15px",
              color: "grey",
              marginTop: "2px",
              marginRight: "2px",
            }}
          />
          <span
            className="text-muted"
            style={{
              fontSize: window.screen.width > 768 ? "14px" : "12px",
              fontWeight: 200,
              marginLeft: "3px",
            }}
          >
            Remember this, as you will need your username during loging in
          </span>
          <input
            className="custom-auth-text-field"
            placeholder="User Name"
            onChange={(e) => {
              setregisterUserName(e.target.value);
              reset();
            }}
          />
        </div>
        <div className="center-block form-group w-75">
          <input
            className="custom-auth-text-field"
            placeholder="Full Name"
            onChange={(e) => {
              setRegisterFullName(e.target.value);
              reset();
            }}
          />
        </div>
        <div className="center-block form-group w-75">
          <input
            className="custom-auth-text-field"
            placeholder="Email"
            onChange={(e) => setRegisterEmail(e.target.value)}
          />
        </div>

        {/* <div className="center-block form-group w-75">
          <input
            className="custom-auth-text-field"
            placeholder="Username"
            onChange={(e) => {
              setRegisterUsername(e.target.value);
              reset();
            }}
          />
        </div> */}
        <div className="center-block form-group w-75">
          <input
            className="custom-auth-text-field"
            placeholder="Address"
            onChange={(e) => setregisterAdress(e.target.value)}
          />
        </div>
        <div className="center-block form-group w-75">
          <input
            className="custom-auth-text-field"
            placeholder="Phone #"
            onChange={(e) => setregisterPhone(e.target.value)}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingLeft: "0px",
          }}
          className="custom-auth-text-field center-block form-group w-75"
        >
          <input
            className="custom-auth-pass-field"
            type={passwordShown ? "text" : "password"}
            placeholder="Password"
            onChange={(e) => setRegisterPassword(e.target.value)}
          />
          {passwordShown ? (
            <i
              onClick={togglePasswordVisiblity}
              className="d-flex align-items-center"
            >
              {eye}
            </i>
          ) : (
            <i
              onClick={togglePasswordVisiblity}
              className="d-flex align-items-center"
            >
              {eyeSlash}
            </i>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingLeft: "0px",
          }}
          className="custom-auth-text-field center-block form-group w-75"
        >
          <input
            className="custom-auth-pass-field"
            type={confirmPasswordShown ? "text" : "password"}
            placeholder="Confirm Password"
            onChange={(e) => setRegisterConfirmPassword(e.target.value)}
          />
          {confirmPasswordShown ? (
            <i
              onClick={toggleConfirmPasswordVisiblity}
              className="d-flex align-items-center"
            >
              {eye}
            </i>
          ) : (
            <i
              onClick={toggleConfirmPasswordVisiblity}
              className="d-flex align-items-center"
            >
              {eyeSlash}
            </i>
          )}
        </div>
        {/* <div className="center-block form-group w-75 text-right">
          <button
            className="btn btn-lg btn-default p-0 gray-color"
            onClick={register}
          >
            SIGN IN
            <FontAwesomeIcon
              icon={faSignOutAlt}
              className="ml-3"
              style={{ color: "#ff7702" }}
            />
          </button>
        </div>

        <div className="center-block w-25 mt-4 mb-4 separator">Or</div> */}

        <div className="center-block form-group w-50 text-center">
          <button
            className="p-4 btn mb-2 btn-block btn-primary w-100"
            onClick={() => register()}
          >
            Register
            {loading ? (
              <div
                className="spinner-border spinner-border-sm ml-3"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            ) : undefined}
          </button>
          <span
            className="text-muted"
            style={{ fontSize: 15, fontWeight: 200 }}
          >
            By joining Frostie VR, you are agreeing to the{" "}
            <a href="#">Terms & Privacy Policy</a>
          </span>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  login,
};
export default connect(null, mapDispatchToProps)(Register);
