export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const UPDATE_USER = "UPDATE_USER";

export const updateUser = (user) => (dispatch) => {
  dispatch({ type: SET_USER_PROFILE, user });
};

export const updateUserData = (user) => (dispatch) => {
  dispatch({ type: UPDATE_USER, user });
};
