import {
  SET_IMAGES,
  DELETE_IMAGE,
  RESET_IMAGES,
  SET_NAME,
  SET_DESC,
  SET_TOUR,
} from "../../actions/tour/tour";

const tourReducer = (
  state = {
    name: "",
    description: "",
    images: [],
    tour: [],
  },
  action
) => {
  switch (action.type) {
    case SET_IMAGES:
      return Object.assign({}, state, {
        images: [...state.images, action.image],
      });
    case SET_TOUR:
      return Object.assign({}, state, {
        tour: action.tour,
      });
    case DELETE_IMAGE:
      const newImages = state.images.filter((item) => item != action.image);
      return Object.assign({}, state, {
        images: [...newImages],
      });
    case SET_NAME:
      return Object.assign({}, state, {
        name: action.name,
      });
    case SET_DESC:
      return Object.assign({}, state, {
        description: action.desc,
      });
    case RESET_IMAGES:
      return Object.assign({}, state, {
        images: [],
      });
    default:
      return state;
  }
};

export default tourReducer;
