import React from "react";

export default function Divider() {
  return (
    <div
      className="d-flex justify-content-center"
      style={{
        width: "70%",
        height: 1,
        alignSelf: "center",
        display: "block",
        backgroundColor: "rgb(202, 202, 202)",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    ></div>
  );
}
