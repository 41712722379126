import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import "./MainCSS.css";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { message } from "antd";
import ImagePanorama from "../Panolen/panorama/ImagePanorama";
import Viewer from "../Panolen/viewer/Viewer";
import errors from "../constants/FormErrors";

function Pricing(props) {
  const [billing, setBilling] = useState("Monthly");
  let panaromaref = null;
  useEffect(() => {
    const imgPath = process.env.PUBLIC_URL + "/images/support.jpg";
    const viewer = new Viewer({
      container: panaromaref,
      controlBar: false,
      cameraFov: 120,
      autoRotate: true,
      autoRotateSpeed: 0.2,
      dampingFactor: 0.1,
      autoRotateActivationDuration: 1000,
    });
    viewer.OrbitControls.noZoom = true;
    const panorama = new ImagePanorama(imgPath);
    viewer.add(panorama);
  }, []);
  return (
    <div>
      <div className="image-div">
        <div
          style={{ width: "100%", height: "60vh" }}
          ref={(ref) => (panaromaref = ref)}
        />
        <h1
          style={{ color: "#fff" }}
          className="image-heading heading centered font-weight-bold white display-none"
        >
          Choose the right plan for you!
        </h1>
        <h1
          style={{ color: "#fff" }}
          className="heading centered font-weight-bold white small-screen-sec-image-width small-display-padding-bottom display-none-in-large-display"
        >
          Choose the right plan for you!
        </h1>
        <div className="heading-p centered mt-3 small-screen-sec-image-width ">
          Frostie VR is the ideal solution for companies that use 360 content
          and virtual tours in their business, for professional panoramic
          photographers and for anyone who wants to share and enjoy great 360°
          content!
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mt-5 center-block w-50 text-center display-none">
          <button
            className={
              billing === "Monthly" ? "pricing-button-active" : "pricing-button"
            }
            onClick={() => setBilling("Monthly")}
          >
            <span
              className={
                billing === "Monthly" ? "pricing-text-active" : "pricing-text"
              }
            >
              Monthly
            </span>
          </button>
          <button
            className={
              billing === "Yearly" ? "pricing-button-active" : "pricing-button"
            }
            style={{ marginLeft: "10px" }}
            onClick={() => setBilling("Yearly")}
          >
            <span
              className={
                billing === "Yearly" ? "pricing-text-active" : "pricing-text"
              }
            >
              Yearly
            </span>
          </button>
        </div>
        <div className="mt-5 small-display-left-padding-sec small-screen-width display-none-in-large-display">
          <button
            className={
              billing === "Monthly"
                ? "sm-pricing-button-active"
                : "sm-pricing-button"
            }
            onClick={() => setBilling("Monthly")}
          >
            <span
              className={
                billing === "Monthly" ? "pricing-text-active" : "pricing-text"
              }
            >
              Monthly
            </span>
          </button>
          <button
            className={
              billing === "Yearly"
                ? "sm-pricing-button-active"
                : "sm-pricing-button"
            }
            style={{ marginLeft: "10px" }}
            onClick={() => setBilling("Yearly")}
          >
            <span
              className={
                billing === "Yearly" ? "pricing-text-active" : "pricing-text"
              }
            >
              Yearly
            </span>
          </button>
        </div>
      </div>

      <div className="row mt-5 w-100 text-center center-block display-column">
        <div
          style={{
            // padding: "10px",
            // border: "1px solid grey",
            borderRadius: "18px",
          }}
          className="large-display-padding"
        >
          <div
            className="large-display-width about-social-handler small-display-width-in-px card border border-warning pricing-table-1"
            style={{
              marginTop: "20px",
              backgroundColor: "#fff",
            }}
          >
            <div className="card-body">
              <h5 className="card-title h3 text-warning">BASIC</h5>
              <span className="h1">
                <sup className="h4">$</sup>0
              </span>
              <span> / month</span> <br />
              <h2>
                <span
                  className="badge badge-warning"
                  style={{
                    fontWeight: "normal",
                    fontSize: "16px",
                    padding: "10px",
                    width: "100px",
                  }}
                >
                  GO FREE
                </span>
              </h2>
              <br />
              <div>
                <ul
                  style={{
                    textAlign: "center",
                    listStyleType: "none",
                    padding: "10px",
                  }}
                >
                  <li>100 Public Uploads Per Month</li>
                  <hr />
                  <li>Retouch & Improve Images</li>
                  <hr />
                  <li>Level Correction</li>
                  <hr />
                  <li>Easily Share & Embed</li>
                  <hr />
                  <li>Virtual Reality</li>
                  <hr />
                  <li>Tiny Planet Editor</li>
                  <hr />
                  <li>basic Support</li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className="card border about-social-handler large-display-width small-display-width-in-px small-display-left-padding border-success large-display-left-margin mt-3 pricing-table-1"
            style={{ backgroundColor: "#fff", color: "rgb(129, 209, 53)" }}
          >
            <div
              className="card-body"
              style={{ cursor: "pointer" }}
              onClick={() =>
                props.isAuthenticated
                  ? props.router.push({
                      pathname: `/edit-profile/${props.user.username}`,
                      // state: { tab: "Business" },
                    })
                  : message.error(errors.unAuthorizedSubcriptionUserCheck)
              }
            >
              <h5 className="card-title h3 text-success">PRO</h5>
              <span className="h1">
                <sup className="h4">$</sup>
                {billing === "Monthly" ? 12 : 144}
              </span>
              <span> / month</span>
              <h2>
                <span
                  className="badge badge-success"
                  style={{
                    fontWeight: "normal",
                    fontSize: "16px",
                    padding: "10px",
                    width: "100px",
                  }}
                >
                  GO PRO
                </span>
              </h2>
              <br />
              <div>
                <ul
                  style={{
                    textAlign: "center",
                    listStyleType: "none",
                    padding: "10px",
                  }}
                >
                  <li>Unlimited Tours & Posts</li>
                  <hr />
                  <li>Branding: Your Logo</li>
                  <hr />
                  <li>Virtual Tour Editor</li>
                  <hr />
                  <li>Unlisted & Private Tours</li>
                  <hr />
                  <li>Custom Images & Fonts</li>
                  <hr />
                  <li>Duplicate Tours</li>
                  <hr />
                  <li>HD Embedds With VR</li>
                  <hr />
                  <li>No Ads</li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className="card border about-social-handler large-display-width small-display-width-in-px small-display-left-padding border-primary large-display-left-margin mt-2 pricing-table-1"
            style={{ backgroundColor: "#fff", color: "#4a90e2" }}
          >
            <div
              className="card-body"
              style={{ cursor: "pointer" }}
              onClick={() =>
                props.isAuthenticated
                  ? props.router.push({
                      pathname: `/edit-profile/${props.user.username}`,
                      // state: { tab: "Business" },
                    })
                  : message.error(errors.unAuthorizedSubcriptionUserCheck)
              }
            >
              <h5 className="card-title h3 text-primary">BUSINESS</h5>
              <span className="h1">
                <sup className="h4">$</sup>
                {billing === "Monthly" ? 36 : 432}
              </span>
              <span> / month</span>
              <h2>
                <span
                  className="badge badge-primary"
                  style={{
                    fontWeight: "normal",
                    fontSize: "16px",
                    padding: "10px",
                    width: "150px",
                  }}
                >
                  GO BUSINESS
                </span>
              </h2>
              <br />
              <div>
                <ul
                  style={{
                    textAlign: "center",
                    listStyleType: "none",
                    padding: "10px",
                  }}
                >
                  <li>Custom Domains with SSL</li>
                  <hr />
                  <li>Branding: Multiple Logos</li>
                  <hr />
                  <li>Password Protected Tours</li>
                  <hr />
                  <li>Detailed Analytics</li>
                  <hr />
                  <li>Landing Page Editor</li>
                  <hr />
                  <li>Whiteslisted Social Posting</li>
                  <hr />
                  <li>+ everything in PRO & BASIC</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div
          className="mb-5 mt-2"
          style={{
            textAlign: "left",
            width: "90%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <h5>Frostie VR Subscription Options</h5>
          <p className="gray-color">
            Frostie VR is a platform that gives creators the freedom of
            expressing themselves by using 360° virtual tours. It helps in
            growing business and brings value by uploading, sharing, and
            browsing other’s work. Professions content creators can use this
            platform to target a wider audience. Give your clients a vivid
            experience of 360°tours and build-up engagements. Upgrade your
            account and enjoy the whole experience. Manage and grow your online
            business with our subscription plans. Free users can have access to
            every selling product and have the liberty to purchase any
            photography and products. They can also upload 50 photographs per
            month. If you can step up your game to PRO user package is perfect
            for you. PRO users have the privileges of no ads, audio hotspots,
            video uploads, background audio, narrator audio, and the authority
            to sell products and photos. Major additional advantages in such a
            reasonable price. Lastly, if you’re more of a captain and interested
            in catching big fish try the Business user package. The plan gives
            you all the privileges of Free and Pro users but with
            password-protected tours and the ability to sell the experience. You
            have a monthly or yearly subscription according to your desire.
            Subscription can be cancelled anytime and you would not have any
            damage to your account.
          </p>
          <p className="gray-color">All prices are in USD.</p>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps, null)(Pricing);
