import React from "react";
import "./index.scss";

export default function Tabs({ tabName, active, onClick }) {
  return (
    <>
      <button
        style={{ marginTop: "3px" }}
        className={
          active
            ? "explore-button-active sm-display-none"
            : "explore-button sm-display-none"
        }
        onClick={() => onClick()}
      >
        <span
          style={{ fontSize: "16px", fontWeight: 500 }}
          className={active ? "explore-text-active" : "explore-text"}
        >
          {tabName}
        </span>
      </button>
      <button
        style={{
          width: "20%",
        }}
        className={
          active
            ? "sm-tab-button-active lg-display-none"
            : "sm-tab-button lg-display-none"
        }
        onClick={() => onClick()}
      >
        <span
          style={{
            fontSize: "12px",
            fontWeight: 500,
          }}
        >
          {tabName}
        </span>
      </button>
    </>
  );
}
