import React, { Component } from "react";

// CSS Here
import "./index.scss";

// Third party library imports for components
import {
  InboxOutlined,
  DownOutlined,
  UploadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Upload,
  Slider,
  Row,
  Col,
  Radio,
  Popconfirm,
  Button,
  Checkbox,
  Space,
  Dropdown,
  Menu,
  Modal,
  message,
  Spin,
  Input,
  InputNumber,
  Tooltip,
} from "antd";
import { TextureLoader, Vector3 } from "three";
import { token } from "../../LocalForage/localForage";
// Panaroma Viewer Imports (Panolens.js)
import ImagePanorama from "../../Panolen/panorama/ImagePanorama";
import VideoPanorama from "../../Panolen/panorama/VideoPanorama";
import Viewer from "../../Panolen/viewer/Viewer";
import Infospot from "../../Panolen/infospot/Infospot";
import { DataImage } from "../../Panolen/DataImage";
import LittlePlanet from "../../Panolen/panorama/ImageLittlePlanet";

// Helper Methods
import { applyFilters } from "../../Helpers/filters";
import Base64ToFile from "../../Helpers/Base64ToFile";
import ValidateImageorVideo from "../../Helpers/Validator";
import Api from "../../../src/Api Wrapper/Api";
import ProductApi from "../../../src/Api Wrapper/ProductApi";
import errors from "../../constants/FormErrors";
import { BASE_URL_IMAGE } from "../../constants/URL";

// Icons import
import {
  faExclamationTriangle,
  faImage,
  faExclamationCircle,
  faTenge,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//hotspot Image
import dollar from "../../Images/Dollar2.png";
// Redux Imports
import { connect } from "react-redux";
import {
  applyFilter,
  applyIntensity,
  applyZoomLimit,
  applyMaxPitch,
  setDescription,
  setPhotoName,
  setPrivacy,
  setYaw,
} from "../../js/actions/editor/filters";
import {
  addHotspot,
  deleteHotspot,
  setActivehotspot,
  resetHotspot,
  RESET_HOTSPOT,
  addAudioHotspot,
  deleteAudioHotspot,
  resetAudioHotspot,
} from "../../js/actions/hotspot/hotspot";
import { resetLabel } from "../../js/actions/textEditor/editor";
import html2canvas from "html2canvas";
import * as htmlToImage from "html-to-image";
import { toPng } from "html-to-image";
import TextArea from "antd/lib/input/TextArea";
import TextEditor from "../../CustomComponents/TextEditor";
const { Dragger } = Upload;

class SingleImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: "",
      video: "",
      hotspotPrevID: "",
      hotspotImage: DataImage.Info,
      hotspotLabel: "",
      hotspotScale: 100,
      hotspotRotation: 0,
      isHoverOnly: true,
      opacity: 100,
      loading: false,
      dynamicPitch: 0,
      dynamicYaw: 0,
      hotspotProcessing: false,
      hotspots: [],
      posts: [],
      filters: ["NONE", "COOLER", "GREYSCALE", "BLOOM", "BLUR", "WATER"],
      isActive: "photo",
      addedHotspots: [],
      activeHotspot: "",
      action: "No action",
      url: "",
      isModalVisible: false,
      isCardVisible: false,
      isBuyModalVisible: false,
      isBuyModalSecVisible: false,
      actionID: "",
      actionImage: "",
      actionImageName: "",
      cardLinkLabel: "",
      cardImageUrl: "",
      cardUrl: "",
      cardText: "",
      currentHotspot: "",
      AccountNumber: 0,
      AccountTitle: "",
      isPurchaseable: false,
      isProductModal: false,
      purchasePrice: 0,
      isImage: true,
      snapshot: "",
      falseState: false,
      mediaType: "3D",
      productName: "",
      productDetail: "",
      productImages: [],
      productPrice: 0,
      isProductImage: false,
      productCategory: "NONE",
      loadCategories: "",
      productCategoryID: 1,
      productQuantity: 1,
      productClassification: 1,
      productType: 1,
      productFeatureImage: "",
      initialViewEditing: false,
      initialViewHotspot: "",
      initialViewX: 0,
      initialViewY: 0,
      initialViewZ: 0,
      hotspotAudioFile: "",
      audioHotspots: [],
      spriteImageProcessing: false,
      productID: 0,
      hotspotEditing: true,
      isMuteAdded: false,
      isEditingMode: false,
      editorData: "",
      textImageProcessing: false,
      textLabel: "label",
      position: "3D",
      isFloor: false,
      defaultHotspot: "",
      hotspotLabelStyle:
        "padding-left: 10px; padding-right: 10px; border-radius: 10px; margin-bottom: 15px;",
    };
  }

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  handleHotspotImage = async (file, info) => {
    const { status } = info.file;
    let preview = "";
    var ff = this;
    if (!file.url && !file.preview && status === "error") {
      preview = await this.getBase64(file.originFileObj);
      var img = new Image();
      const extention = file.name.split(".");
      img.onload = function () {
        if (
          img.width > 64 ||
          img.height > 64 ||
          extention[extention.length - 1] !== "png"
        ) {
          message.info({
            content: errors.HotspotImageDimensions,
            style: {
              marginTop: 100,
            },
          });
        } else {
          ff.setState({ hotspotImage: preview });
          ff.changeHotspotImage(preview);
        }
      };
      img.src = preview;
    }
  };

  handlePreview = async (file, info) => {
    const { status } = info.file;

    let preview = "";
    if (!file.url && !file.preview && status === "error") {
      if (ValidateImageorVideo(file.name) === "image") {
        preview = await this.getBase64(file.originFileObj);
        this.setState({ image: preview, isImage: true, snapshot: preview });
        this.createImagePanorama();
      } else if (ValidateImageorVideo(file.name) === "video") {
        preview = await this.getBase64(file.originFileObj);
        this.setState({ video: preview, isImage: false });
        this.createVideoPanorama();
      } else {
        message.error("Please select a valid image or video.");
      }
    }
  };
  handleProductPreview = async (file, info) => {
    const { status } = info.file;
    let preview = "";
    if (status === "error") {
      preview = await this.getBase64(file.originFileObj);
      this.setState({
        productImages: [...this.state.productImages, preview],
        isProductImage: true,
      });
    }
  };

  onSubmit = async () => {
    console.log("audio");
    console.log(this.props.audioHotspot);
    console.log("regular");
    console.log(this.props.hotspot);
    if (this.props.photoName === "") {
      return this.messages(errors.PostNameCheck);
    }
    this.setState({ isSubmitting: true });
    this.props.hotspot.forEach((item) => {
      delete item.hotspotID;
    });
    this.props.audioHotspot.forEach((item) => {
      delete item.hotspotID;
    });
    const form = new FormData();
    if (this.state.isEditingMode) {
      form.append("description", this.props.description);
      form.append("privacy", this.props.privacy);
      form.append("filterName", this.props.filterName);
      form.append("intensity", this.props.intensity);
      form.append("zoomLimit", this.props.zoomLimit);
      form.append("pitchLimit", this.props.pitchLimit);
      form.append("name", this.props.photoName);
      form.append("isPurchaseable", this.state.isPurchaseable);
      form.append("purchasePrice", this.state.purchasePrice);
      form.append("user_acc_num", this.state.AccountNumber);
      form.append("user_acc_title", this.state.AccountTitle);
      form.append("hotspot", JSON.stringify(this.props.hotspot));
      form.append("maxYaw", this.props.maxYaw);
      form.append("initialView", 0);
      form.append("initialViewX", this.state.initialViewX);
      form.append("initialViewY", this.state.initialViewY);
      form.append("initialViewZ", this.state.initialViewZ);
      if (this.state.audioFile.name) {
        form.append("audio_one", this.state.audioFile.originFileObj);
      }
      if (this.state.narratorAudio.name) {
        form.append("audio_two", this.state.narratorAudio.originFileObj);
      }
      Api.put(
        `/post/update?post_id=${this.props.router.params.id}&image_id=${this.state.editorData.Image.id}`,
        form,
        {
          headers: { token: await token.getItem("@token") },
        }
      )
        .then((res) => {
          if (res.status === 200) {
            this.props.router.push(
              `/post-view/id-${this.props.router.params.id}`
            );
          }
          this.props.resetHotspot();
          console.log(res);
        })
        .catch((err) => {
          message.error("something went wrong");
          console.log(err);
        });
      if (this.props.audioHotspot.length > 0) {
        this.props.audioHotspot.map((item) => {
          item.post_id = this.props.router.params.id;
        });
        this.props.audioHotspot.map(async (item) => {
          const hotspotFrom = new FormData();
          hotspotFrom.append("audio", item.audio);
          hotspotFrom.append("post_id", item.post_id);
          hotspotFrom.append("image", item.image);
          hotspotFrom.append("url", item.url);
          hotspotFrom.append("opacity", item.opacity);
          hotspotFrom.append("scale", item.scale);
          hotspotFrom.append("rotation", item.rotation);
          hotspotFrom.append("label", item.label);
          hotspotFrom.append("isFloor", false);
          hotspotFrom.append("isHover", item.isHover);
          hotspotFrom.append("x", item.x);
          hotspotFrom.append("y", item.y);
          hotspotFrom.append("z", item.z);
          hotspotFrom.append("action", item.action);
          hotspotFrom.append("cardText", item.cardText);
          hotspotFrom.append("cardImageUrl", item.cardImageUrl);
          hotspotFrom.append("cardLinkLabel", item.cardLinkLabel);
          hotspotFrom.append("cardUrl", item.cardUrl);
          hotspotFrom.append("child_post_id", item.id);
          await Api.put("/hotspot/audio/add", hotspotFrom)
            .then((res) => {
              console.log("res.data");
              console.log(res.data);
            })
            .catch((err) => {
              console.log("err");
              console.log(err);
            });
        });
      }

      this.props.resetAudioHotspot();
    }

    if (!this.state.isEditingMode) {
      if (this.state.isImage) {
        const fileExt = this.state.image.split(";", 2);
        const extention = fileExt[0].split("/", 2);
        const file = Base64ToFile(this.state.image, `test.${extention[1]}`);
        form.append("type", "3D");
        form.append("image", file);
      } else {
        const fileExt = this.state.video.split(";", 2);
        const extention = fileExt[0].split("/", 2);
        const file = Base64ToFile(this.state.video, `test.${extention[1]}`);
        form.append("type", "video");
        form.append("image", file);
      }
      // form.append("uid", this.props.user.userID);
      form.append("description", this.props.description);
      form.append("privacy", this.props.privacy);
      form.append("filterName", this.props.filterName);
      form.append("intensity", this.props.intensity);
      form.append("zoomLimit", this.props.zoomLimit);
      form.append("pitchLimit", this.props.pitchLimit);
      form.append("name", this.props.photoName);
      form.append("isPurchaseable", this.state.isPurchaseable);
      form.append("purchasePrice", this.state.purchasePrice);
      form.append("user_acc_num", this.state.AccountNumber);
      form.append("user_acc_title", this.state.AccountTitle);
      form.append("hotspot", JSON.stringify(this.props.hotspot));
      form.append("maxYaw", this.props.maxYaw);
      form.append("initialView", 0);
      form.append("initialViewX", this.state.initialViewX);
      form.append("initialViewY", this.state.initialViewY);
      form.append("initialViewZ", this.state.initialViewZ);
      if (this.state.audioFile) {
        form.append("audio_one", this.state.audioFile.originFileObj);
      }
      if (this.state.narratorAudio) {
        form.append("audio_two", this.state.narratorAudio.originFileObj);
      }

      await Api.post(`/image/add`, form).then(async (response) => {
        if (response.status === 200) {
          this.setState({ loading: true, isSubmitting: false });
          this.props.resetHotspot();
          this.props.audioHotspot.map((item) => {
            item.post_id = response.data.post.id;
          });
          this.props.audioHotspot.map(async (item) => {
            const hotspotFrom = new FormData();
            hotspotFrom.append("audio", item.audio);
            hotspotFrom.append("post_id", item.post_id);
            hotspotFrom.append("image", item.image);
            hotspotFrom.append("url", item.url);
            hotspotFrom.append("opacity", item.opacity);
            hotspotFrom.append("scale", item.scale);
            hotspotFrom.append("rotation", item.rotation);
            hotspotFrom.append("label", item.label);
            hotspotFrom.append("isFloor", false);
            hotspotFrom.append("isHover", item.isHover);
            hotspotFrom.append("x", item.x);
            hotspotFrom.append("y", item.y);
            hotspotFrom.append("z", item.z);
            hotspotFrom.append("action", item.action);
            hotspotFrom.append("cardText", item.cardText);
            hotspotFrom.append("cardImageUrl", item.cardImageUrl);
            hotspotFrom.append("cardLinkLabel", item.cardLinkLabel);
            hotspotFrom.append("cardUrl", item.cardUrl);
            hotspotFrom.append("child_post_id", item.id);
            await Api.put("/hotspot/audio/add", hotspotFrom)
              .then((res) => {
                console.log("res.data");
                console.log(res.data);
              })
              .catch((err) => {
                console.log("err");
                console.log(err);
              });
          });

          this.props.router.push(`/post-view/id-${response.data.post.id}`);
          this.props.resetAudioHotspot();
        } else {
          console.log(response);
          this.setState({ isSubmitting: false });
        }
      });
    }
  };
  convertImgToBase64(url, callback, outputFormat) {
    var canvas = document.createElement("CANVAS");
    var ctx = canvas.getContext("2d");
    var img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = function () {
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL(outputFormat || "image/png");
      callback.call(this, dataURL);
      // Clean up
      canvas = null;
    };
    img.src = url;
  }
  createImagePanorama() {
    const viewer = new Viewer({
      container: this.panaromaref,
      autoHideInfospot: false,
      dampingFactor: 0.01,
      //   rotateSpeed: -5,
      cameraFov: 90,
      momentum: true,
    });
    // console.log("viewer");
    // console.log(viewer);
    // viewer.OrbitControls.momentumDampingFactor = 0.8;
    // viewer.OrbitControls.maxZoom = 50;
    // viewer.OrbitControls.minZoom = 90;
    // viewer.tweenControlCenter(new Vector3 (-4204.25, 123.08, 2684.97), 0);
    const panorama = new ImagePanorama(this.state.image);
    const littlePlanet = new LittlePlanet(this.state.image);
    viewer.add(panorama);
    viewer.add(littlePlanet);
    //initial view
    // viewer.tweenControlCenter(new Vector3(-4204.25, 123.08, 2684.97), 0);

    this.myViewer = viewer;
    this.myPanorama = panorama;
    this.myTinyPlanet = littlePlanet;
  }

  createVideoPanorama() {
    const viewer = new Viewer({
      container: this.panaromaref,
      autoHideInfospot: false,
      cameraFov: 120,
    });
    const panorama = new VideoPanorama(this.state.video, {
      autoplay: true,
      muted: false,
    });
    viewer.add(panorama);
    this.myViewer = viewer;
    this.myPanorama = panorama;
    var controlItemCube = {
      style: {
        backgroundImageColor: "red",
        backgroundImage:
          "url(https://cdn1.bbcode0.com/uploads/2021/4/4/31d399a01caf44c898a6381a26e42495-full.png)",
        // "url(https://cdn1.bbcode0.com/uploads/2021/4/4/c8ca2162e114ec3eff76d4cda9113877-full.png)",
      },

      onTap: () => this.muteVideoFunction(),
    };
    this.myViewer.appendControlItem(controlItemCube);
  }
  muteVideoFunction = () => {
    if (this.myPanorama.isVideoMuted()) {
      this.myPanorama.unmuteVideo();
    } else {
      this.myPanorama.muteVideo();
    }
  };
  componentDidMount() {
    this.getPosts();
    this.getCategories();
    this.getEditPost();
  }
  getEditPost = async () => {
    if (this.props.router.params.id !== "post") {
      Api.get(`/post/findPost?post_id=${this.props.router.params.id}`, {
        headers: { token: await token.getItem("@token") },
      })
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data);
            this.props.applyFilter(res.data.Image.filterName);
            this.props.setPhotoName(res.data.name);
            this.props.setDescription(res.data.description);
            this.props.applyIntensity(res.data.Image.intensity);
            this.props.applyMaxPitch(res.data.Image.pitchLimit);
            this.props.setYaw(res.data.Image.maxYaw);
            this.props.applyZoomLimit(res.data.Image.zoomLimit);
            this.setState({
              editorData: res.data,
              isEditingMode: true,
              image: res.data.Image.image,
              audioFile: res.data.Image.audio_one,
              narratorAudio: res.data.Image.audio_two,
              snapshot: BASE_URL_IMAGE + res.data.Image.image,
              isPurchaseable: res.data.Image.isPurchaseable,
              purchasePrice: res.data.Image.purchasePrice,
            });
            if (ValidateImageorVideo(res.data.Image.image) === "image") {
              this.setState({ isImage: true });
              const viewer = new Viewer({
                container: this.panaromaref,
                autoHideInfospot: false,
                dampingFactor: 0.01,
                //   rotateSpeed: -5,
                cameraFov: 90,
                momentum: true,
              });
              // console.log("viewer");
              // console.log(viewer);
              // viewer.OrbitControls.momentumDampingFactor = 0.8;
              // viewer.OrbitControls.maxZoom = 50;
              // viewer.OrbitControls.minZoom = 90;
              // viewer.tweenControlCenter(new Vector3 (-4204.25, 123.08, 2684.97), 0);
              const panorama = new ImagePanorama(
                BASE_URL_IMAGE + res.data.Image.image
              );
              const littlePlanet = new LittlePlanet(
                BASE_URL_IMAGE + res.data.Image.image
              );
              viewer.add(panorama);
              viewer.add(littlePlanet);
              //initial view
              // viewer.tweenControlCenter(new Vector3(-4204.25, 123.08, 2684.97), 0);

              this.myViewer = viewer;
              this.myPanorama = panorama;
              this.myTinyPlanet = littlePlanet;
            } else if (ValidateImageorVideo(res.data.Image.image) === "video") {
              this.setState({ isImage: false });
              const viewer = new Viewer({
                container: this.panaromaref,
                autoHideInfospot: false,
                cameraFov: 120,
              });
              const panorama = new VideoPanorama(
                BASE_URL_IMAGE + res.data.Image.image,
                {
                  autoplay: true,
                  muted: false,
                }
              );
              viewer.add(panorama);
              this.myViewer = viewer;
              this.myPanorama = panorama;
              var controlItemCube = {
                style: {
                  backgroundImageColor: "red",
                  backgroundImage:
                    "url(https://cdn1.bbcode0.com/uploads/2021/4/4/31d399a01caf44c898a6381a26e42495-full.png)",
                  // "url(https://cdn1.bbcode0.com/uploads/2021/4/4/c8ca2162e114ec3eff76d4cda9113877-full.png)",
                },

                onTap: () => this.muteVideoFunction(),
              };
              this.myViewer.appendControlItem(controlItemCube);
            }
            if (res.data.hotspot_post.length > 0) {
              res.data.hotspot_post.map((item) => {
                var infospot2 = new Infospot(
                  item.scale * 6,
                  BASE_URL_IMAGE + item.image
                );
                infospot2.position.set(item.x, item.y, item.z);
                infospot2.show();
                if (item.label.split("style", 2)[0] !== "") {
                  const div = document.createElement("div");
                  div.style =
                    item.label.split("style", 2)[1] +
                    "padding-left: 10px; padding-right: 10px; border-radius: 10px; margin-bottom: 15px;";
                  div.innerHTML = `<div>${
                    item.label.split("style", 2)[0]
                  }</div>`;

                  infospot2.addHoverElement(div);
                }
                infospot2.material.rotation = (Math.PI / 180) * item.rotation;
                infospot2.material.opacity = item.opacity / 100;
                this.convertImgToBase64(
                  BASE_URL_IMAGE + item.image,
                  (base64Image) => {
                    this.setState({
                      hotspotImage: base64Image,
                      hotspotAudioFile: item.audio,
                    });
                    if (item.audio) {
                      const hotspot = {
                        hotspotID: infospot2.id,
                        x: item.x,
                        y: item.y,
                        z: item.z,
                        image: base64Image,
                        scale: item.scale,
                        rotation: item.rotation,
                        opacity: item.opacity,
                        label: item.label,
                        isHover: item.isHover,
                        id: item.hotspot_with,
                        action: item.action,
                        url: item.url,
                        isFloor: false,
                        cardText: item.cardText,
                        cardImageUrl: item.cardImageUrl,
                        cardLinkLabel: item.cardLinkLabel,
                        cardUrl: item.cardUrl,
                        audio: item.audio,
                        productId: item.productID,
                      };
                      this.props.addAudioHotspot(hotspot);
                      this.myPanorama.add(infospot2);
                      infospot2.addEventListener("click", (e) => {
                        this.activateHotspot(e.target);
                      });
                    } else {
                      const hotspot = {
                        hotspotID: infospot2.id,
                        x: item.x,
                        y: item.y,
                        z: item.z,
                        image: base64Image,
                        scale: item.scale,
                        rotation: item.rotation,
                        opacity: item.opacity,
                        label: item.label,
                        isHover: item.isHover,
                        id: item.hotspot_with,
                        action: item.action,
                        url: item.url,
                        isFloor: false,
                        cardText: item.cardText,
                        cardImageUrl: item.cardImageUrl,
                        cardLinkLabel: item.cardLinkLabel,
                        cardUrl: item.cardUrl,

                        productId: item.productID,
                      };
                      this.props.addHotspot(hotspot);
                      this.myPanorama.add(infospot2);
                      infospot2.addEventListener("click", (e) => {
                        this.activateHotspot(e.target);
                      });
                    }
                  }
                );
                Api.delete(`/post/hotspot/delete?hid=${item.id}`).then(
                  (res) => {
                    console.log(res.data);
                  }
                );
              });
            }
          } else {
            message.error("something went wrong");
          }
        })
        .catch((err) => {
          message.error("something went wrong");
          console.log(err);
        });
    }
  };
  getPosts = async () => {
    await Api.get(`post/findPosts?username=${this.props.user.username}`).then(
      (res) => {
        if (res.status === 200) {
          this.setState({ posts: res.data.Posts });
        }
      }
    );
  };

  changeHotspotImage = (image) => {
    if (this.state.isFloor) {
      this.setState({ isFloor: false });
      // var ff = this;
      // var img = document.createElement("img");
      // img.style = "transform: perspective(900px) rotateX(60deg) scale(0.7);";
      // img.src = image;
      // img.className = "floor-hotspot";
      // document.querySelector("#mySidenav").appendChild(img);
      // ff.setState({ defaultHotspot: image });

      // htmlToImage
      //   .toPng(document.querySelector(".floor-hotspot"))
      //   .then(function (dataUrl) {
      //     var image = new Image();
      //     image.src = dataUrl;
      //     ff.setState({ hotspotImage: dataUrl });
      //     var Texture = new TextureLoader();
      //     Texture.load(dataUrl, (texture) => {
      //       ff.state.activeHotspot.material.map = texture;
      //     });

      //     document.querySelector("#mySidenav").removeChild(img);
      //   })
      //   .catch(function (error) {
      //     console.error("oops, something went wrong!", error);
      //   });
    }
    var Texture = new TextureLoader();
    var loadedTexture = Texture.load(image);
    this.state.activeHotspot.material.map = loadedTexture;
  };

  createHotspot = (cursorLocation = this.myViewer.getCurrentPosition()) => {
    var infospot2 = new Infospot(500, this.state.hotspotImage);
    infospot2.position.set(
      cursorLocation.x,
      cursorLocation.y,
      cursorLocation.z
    );

    this.myViewer.tweenControlCenter(
      new Vector3(
        cursorLocation.x,
        parseInt(cursorLocation.y),
        parseInt(cursorLocation.z)
      ),
      0
    );

    infospot2.show();
    // var c = infospot2.getContainer();
    // console.log(infospot2);
    this.setState({
      addedHotspots: [...this.state.addedHotspots, infospot2],
    });

    infospot2.addEventListener("click", (e) => {
      this.activateHotspot(e.target);
    });

    this.myPanorama.add(infospot2);
    this.setState({
      isActive: "addon",
      activeHotspot: infospot2,
      // });
    });
  };
  floorHotspot = () => {
    var ff = this;
    var img = document.createElement("img");
    img.style = "transform: perspective(900px) rotateX(70deg) scale(1);";
    img.src = this.state.hotspotImage;
    img.className = "floor-hotspot";
    document.body.appendChild(img);
    ff.setState({ defaultHotspot: this.state.hotspotImage });
    htmlToImage
      .toPng(document.querySelector(".floor-hotspot"))
      .then(function (dataUrl) {
        var image = new Image();
        image.src = dataUrl;
        ff.setState({ hotspotImage: dataUrl });
        var Texture = new TextureLoader();
        Texture.load(dataUrl, (texture) => {
          ff.state.activeHotspot.material.map = texture;

          document.body.removeChild(img);
        });
        // var loadedTexture = Texture.load(dataUrl);
        // document.querySelector("#mySidenav").appendChild(image);
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  };
  activateHotspot = (activeHotspot) => {
    this.setState({
      hotspotEditing: !this.state.hotspotEditing,
      activeHotspot: activeHotspot,
      isActive: "addon",
    });
    const hotspot = this.props.hotspot.find(
      (x) => x.hotspotID === activeHotspot.id
    );
    const audioHotspot = this.props.audioHotspot.find(
      (x) => x.hotspotID === activeHotspot.id
    );
    if (
      hotspot !== undefined &&
      hotspot.hotspotID === activeHotspot.id &&
      !hotspot.audio
    ) {
      this.setState({
        hotspotImage: hotspot.image,
        hotspotScale: hotspot.scale,
        hotspotRotation: hotspot.rotation,
        opacity: hotspot.opacity,
        hotspotLabel: hotspot.label,
        action: hotspot.action,
        isHover: hotspot.isHover,
        url: hotspot.url,
        cardText: hotspot.cardText,
        cardImageUrl: hotspot.cardImageUrl,
        cardLinkLabel: hotspot.cardLinkLabel,
        cardUrl: hotspot.cardUrl,
        hotspotAudioFile: "",
        isFloor: hotspot.isFloor,
      });
    } else if (
      audioHotspot !== undefined &&
      audioHotspot.hotspotID === activeHotspot.id &&
      audioHotspot.audio
    ) {
      this.setState({
        hotspotImage: audioHotspot.image,
        hotspotScale: audioHotspot.scale,
        hotspotRotation: audioHotspot.rotation,
        opacity: audioHotspot.opacity,
        hotspotLabel: audioHotspot.label,
        action: audioHotspot.action,
        isHover: audioHotspot.isHover,
        url: audioHotspot.url,
        cardText: audioHotspot.cardText,
        cardImageUrl: audioHotspot.cardImageUrl,
        cardLinkLabel: audioHotspot.cardLinkLabel,
        cardUrl: audioHotspot.cardUrl,
        hotspotAudioFile: audioHotspot.audio,
        isFloor: audioHotspot.isFloor,
      });
    }
    this.props.setActivehotspot(activeHotspot);
  };

  hotspotLabel_Scale = (
    label = "",
    scale = this.state.hotspotScale * 5,
    style = this.state.hotspotLabelStyle,
    image = this.state.hotspotImage
  ) => {
    if (label) {
      console.log(label);
      var string = label + "style" + style;
      this.setState({ hotspotLabel: string, hotspotLabelStyle: style });
    }
    const positions = this.state.activeHotspot.position;
    this.props.deleteHotspot(this.state.activeHotspot.id);
    this.props.deleteAudioHotspot(this.state.activeHotspot.id);

    this.state.activeHotspot.dispose();
    const div = document.createElement("div");
    var labelStr = label.split("style")[0];
    div.style =
      style +
      "padding-left: 10px; padding-right: 10px; border-radius: 10px; margin-bottom: 15px;";
    div.innerHTML = `<div>${labelStr}</div>`;
    // console.log(div);
    var infospot2 = new Infospot(scale, image);
    if (label != "") {
      infospot2.addHoverElement(div);
    }
    if (this.state.spriteImageProcessing) {
      infospot2.addEventListener("click", (e) => {
        this.activateSprite(e.target);
      });
    } else {
      infospot2.addEventListener("click", (e) => {
        this.activateHotspot(e.target);
      });
    }
    infospot2.position.set(positions.x, positions.y, positions.z);
    infospot2.show();
    // this.state.isHoverOnly
    //   ? infospot2.unlockHoverElement()
    //   :
    infospot2.lockHoverElement();
    infospot2.material.opacity = this.state.opacity / 100;
    infospot2.material.rotation = (Math.PI / 180) * this.state.hotspotRotation;
    this.setState({ activeHotspot: infospot2 });

    this.myPanorama.add(infospot2);
    this.myViewer.add(this.myPanorama);
  };

  // async componentDidMount() {
  //   await Api.get(`post/findPosts?uid=${this.props.user.userID}`).then(
  //     (res) => {
  //       if (res.status === 200) {
  //         this.setState({ posts: res.data.Posts, postsLoading: false });
  //       } else {
  //         this.setState({ posts: [], postsLoading: false });
  //       }
  //     }
  //   );
  // }
  createSprite = (cursorLocation = this.myViewer.getCurrentPosition()) => {
    var infospot2 = new Infospot(400, this.state.hotspotImage);
    infospot2.position.set(
      cursorLocation.x,
      cursorLocation.y,
      cursorLocation.z
    );

    this.myViewer.tweenControlCenter(
      new Vector3(
        cursorLocation.x,
        parseInt(cursorLocation.y),
        parseInt(cursorLocation.z)
      ),
      0
    );

    infospot2.show();
    this.setState({ addedHotspots: [...this.state.addedHotspots, infospot2] });

    infospot2.addEventListener("click", (e) => {
      this.activateSprite(e.target);
    });
    infospot2.addEventListener("touchstart", (e) => {
      this.activateSprite(e.target);
    });

    this.myPanorama.add(infospot2);
    this.setState({
      isActive: "addon",
      activeHotspot: infospot2,
    });
  };

  activateSprite = (activeHotspot) => {
    this.setState({
      hotspotEditing: !this.state.hotspotEditing,
      activeHotspot: activeHotspot,
      isActive: "addon",
      spriteImageProcessing: true,
    });
    const hotspot = this.props.hotspot.find(
      (x) => x.hotspotID === activeHotspot.id
    );
    const audioHotspot = this.state.audioHotspots.find(
      (x) => x.hotspotID === activeHotspot.id
    );
    if (
      hotspot !== undefined &&
      hotspot.hotspotID === activeHotspot.id &&
      !hotspot.audio
    ) {
      this.setState({
        hotspotImage: hotspot.image,
        hotspotScale: hotspot.scale,
        hotspotRotation: hotspot.rotation,
        opacity: hotspot.opacity,
        hotspotLabel: hotspot.label,
        action: hotspot.action,
        isHover: hotspot.isHover,
        url: hotspot.url,
        cardText: hotspot.cardText,
        cardImageUrl: hotspot.cardImageUrl,
        cardLinkLabel: hotspot.cardLinkLabel,
        cardUrl: hotspot.cardUrl,
        hotspotAudioFile: "",
      });
    } else if (
      audioHotspot !== undefined &&
      audioHotspot.hotspotID === activeHotspot.id &&
      audioHotspot.audio
    ) {
      this.setState({
        hotspotImage: audioHotspot.image,
        hotspotScale: audioHotspot.scale,
        hotspotRotation: audioHotspot.rotation,
        opacity: audioHotspot.opacity,
        hotspotLabel: audioHotspot.label,
        action: audioHotspot.action,
        isHover: audioHotspot.isHover,
        url: audioHotspot.url,
        cardText: audioHotspot.cardText,
        cardImageUrl: audioHotspot.cardImageUrl,
        cardLinkLabel: audioHotspot.cardLinkLabel,
        cardUrl: audioHotspot.cardUrl,
        hotspotAudioFile: audioHotspot.audio,
      });
    }
    this.props.setActivehotspot(activeHotspot);
  };

  createTextSprite = (cursorLocation = this.myViewer.getCurrentPosition()) => {
    var ff = this;
    const div = document.createElement("p");
    div.style =
      "background-color: rgba(0,0,0,0.5);color:white;padding-left: 10px; padding-right: 10px; border-radius: 10px;display:inline-block;font-size:24px";
    div.innerText = `${this.state.textLabel}`;
    div.className = "text-label";
    document.querySelector("#mySidenav ").appendChild(div);

    html2canvas(document.querySelector(".text-label"), {
      backgroundColor: "transparent",
    }).then(function (canvas) {
      var base64image = canvas.toDataURL("image/png");
      var infospot2 = new Infospot(400, base64image);
      infospot2.position.set(
        cursorLocation.x,
        cursorLocation.y,
        cursorLocation.z
      );

      ff.myViewer.tweenControlCenter(
        new Vector3(
          cursorLocation.x,
          parseInt(cursorLocation.y),
          parseInt(cursorLocation.z)
        ),
        0
      );

      infospot2.show();
      ff.setState({ addedHotspots: [...ff.state.addedHotspots, infospot2] });

      infospot2.addEventListener("click", (e) => {
        ff.activateText(e.target);
      });

      ff.myPanorama.add(infospot2);
      ff.setState({
        isActive: "addon",
        activeHotspot: infospot2,
        hotspotImage: base64image,
      });
      document.querySelector("#mySidenav").removeChild(div);
    });
  };
  activateText = (activeHotspot) => {
    this.setState({
      hotspotEditing: !this.state.hotspotEditing,
      activeHotspot: activeHotspot,
      isActive: "addon",
      textImageProcessing: true,
    });
    const hotspot = this.props.hotspot.find(
      (x) => x.hotspotID === activeHotspot.id
    );
    const audioHotspot = this.state.audioHotspots.find(
      (x) => x.hotspotID === activeHotspot.id
    );
    if (
      hotspot !== undefined &&
      hotspot.hotspotID === activeHotspot.id &&
      !hotspot.audio
    ) {
      this.setState({
        hotspotImage: hotspot.image,
        hotspotScale: hotspot.scale,
        hotspotRotation: hotspot.rotation,
        opacity: hotspot.opacity,
        hotspotLabel: hotspot.label,
        action: hotspot.action,
        isHover: hotspot.isHover,
        url: hotspot.url,
        cardText: hotspot.cardText,
        cardImageUrl: hotspot.cardImageUrl,
        cardLinkLabel: hotspot.cardLinkLabel,
        cardUrl: hotspot.cardUrl,
        hotspotAudioFile: "",
        textLabel: hotspot.textLabel,
      });
    } else if (
      audioHotspot !== undefined &&
      audioHotspot.hotspotID === activeHotspot.id &&
      audioHotspot.audio
    ) {
      this.setState({
        hotspotImage: audioHotspot.image,
        hotspotScale: audioHotspot.scale,
        hotspotRotation: audioHotspot.rotation,
        opacity: audioHotspot.opacity,
        hotspotLabel: audioHotspot.label,
        action: audioHotspot.action,
        isHover: audioHotspot.isHover,
        url: audioHotspot.url,
        cardText: audioHotspot.cardText,
        cardImageUrl: audioHotspot.cardImageUrl,
        cardLinkLabel: audioHotspot.cardLinkLabel,
        cardUrl: audioHotspot.cardUrl,
        hotspotAudioFile: audioHotspot.audio,
      });
    }
    this.props.setActivehotspot(activeHotspot);
  };

  changeTextLabel = (label) => {
    console.log(this.props.htmlLabel);
    var vp = document.getElementById("viewportMeta").getAttribute("content");
    if (screen.width < 768) {
      document
        .getElementById("viewportMeta")
        .setAttribute("content", "width=800px");
      console.log(document.getElementById("viewport"));
    }
    const positions = this.state.activeHotspot.position;
    this.props.deleteHotspot(this.state.activeHotspot.id);
    this.setState({ textLabel: this.props.htmlLabel.label });
    this.state.activeHotspot.dispose();
    var ff = this;
    const div = document.createElement("p");
    div.style =
      this.props.htmlLabel.style +
      "padding-left: 10px; padding-right: 10px; border-radius: 10px;display:inline-block; font-variant:normal;";
    div.innerText = `${
      this.props.htmlLabel.label ? this.props.htmlLabel.label : "label"
    }`;
    div.className = "empty";
    console.log(div);
    document.body.appendChild(div);

    // console.log(div);
    html2canvas(document.querySelector(".empty"), {
      backgroundColor: "transparent",
      scale: 2,
    }).then(function (canvas) {
      var base64image = canvas.toDataURL("image/png");
      var infospot2 = new Infospot(400, base64image);
      infospot2.addEventListener("click", (e) => {
        ff.activateText(e.target);
      });

      infospot2.position.set(positions.x, positions.y, positions.z);
      infospot2.show();

      infospot2.material.opacity = ff.state.opacity / 100;
      infospot2.material.rotation = (Math.PI / 180) * ff.state.hotspotRotation;
      ff.setState({ activeHotspot: infospot2, hotspotImage: base64image });

      ff.myPanorama.add(infospot2);
      if (screen.width < 1024) {
        document.getElementById("viewportMeta").setAttribute("content", vp);
      }
      document.body.removeChild(div);
    });
  };
  messages = (checkMessage) => {
    message.info({
      content: checkMessage,
      style: {
        marginTop: 100,
      },
    });
  };

  getCategories = async () => {
    await ProductApi.get("/general/subclassifications-with-categories").then(
      (res) => {
        if (res.status === 200) {
          this.setState({ loadCategories: res.data.data.categories_data.data });
        }
      }
    );
  };

  actionChecks = () => {
    switch (this.state.action) {
      case "No action":
        return true;
        break;
      case "Open URL":
        if (this.state.url === "") {
          this.messages(errors.HotspotURL);
          return false;
        } else {
          return true;
        }
        break;
      case "Sell Product":
        return true;
        break;
      case "Go to another post":
        if (
          this.state.actionImage === "" ||
          this.state.actionImageName === ""
        ) {
          this.messages(errors.HotspotPost);
          return false;
        } else {
          return true;
        }
        break;
      case "Open Card":
        if (this.state.cardText === "") {
          this.messages(errors.HotspotCardText);
          return false;
        } else {
          return true;
        }
        break;
    }
  };
  handleProduct = async () => {
    this.setState({ isProductModal: false });
    const form = new FormData();
    form.append("category_id", this.state.productCategoryID);
    form.append("added_by", this.props.user.ecommerce_user_id);
    form.append("classification_id", this.state.productClassification);
    form.append("pages", 1);
    form.append("p_type", "sell");

    ProductApi.post("/product/add", form)
      .then(async (res) => {
        if (res.status === 200) {
          console.log(res.data);
          const image1 =
            "https://i.picsum.photos/id/1059/7360/4912.jpg?hmac=vVWk1qyiXN_VgPhpNqFm3yl2HUPW6fHqYOjTHYO2bHQ";
          const image2 =
            "https://i.picsum.photos/id/1070/5472/3648.jpg?hmac=oFxAwLeGJmas45_yf5NdpeQzexAF-tMVL6q9JwvSuo0";
          const productForm = new FormData();
          productForm.append("id", res.data.data.id);
          productForm.append("category_id", res.data.data.category_id);
          productForm.append("name", this.state.productName);
          productForm.append("description", this.state.productDetail);
          productForm.append("quantity", this.state.productQuantity);
          productForm.append(
            "product_images",
            JSON.stringify([image1, image2])
          );
          productForm.append(
            "classification_id",
            res.data.data.classification_id
          );
          productForm.append(
            "price",
            JSON.stringify([{ price: this.state.productPrice }])
          );
          productForm.append("pages", 1);
          productForm.append("p_type", "sell");
          productForm.append("added_by", res.data.data.added_by);
          fetch("http://therentalsapp.com/rentmoebackend/api/product/update", {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "x-app-id": "frostyVr",
              // "Content-Type": "multipart/form-data",
            },
            method: "POST",
            body: productForm,
          })
            .then(async (res) => {
              const response = await res.json();
              console.log(response);
              console.log("response.data.id");
              console.log(response.data.id);
              this.setState({ productID: response.data.id });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  takesnapshot = () => {
    const ff = this;
    html2canvas(document.getElementById("myPanoramaSingleImage")).then(
      function (canvas) {
        var base64image = canvas.toDataURL("image/png");
        ff.setState({ snapshot: base64image });
      }
    );
  };
  render() {
    const menu = () => (
      <Menu
        defaultValue={this.state.action}
        onClick={(e) => {
          this.setState({ action: e.key });
        }}
      >
        <Menu.Item style={{ color: "black" }} key="No action">
          No action
        </Menu.Item>
        <Menu.Item
          disabled={this.state.posts.length < 1}
          style={{ color: "black" }}
          key="Go to another post"
          onClick={() => this.setState({ isModalVisible: true })}
        >
          Go to another post
        </Menu.Item>
        <Menu.Item style={{ color: "black" }} key="Open Card">
          Open Card
        </Menu.Item>
        <Menu.Item style={{ color: "black" }} key="Open URL">
          Open URL
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            this.setState({ hotspotImage: DataImage.Dollar });
            this.changeHotspotImage(DataImage.Dollar);
          }}
          style={{ color: "black" }}
          key="Sell Product"
        >
          Sell Product
        </Menu.Item>
      </Menu>
    );

    const productCategorymenu = () => {
      return (
        <Menu
          style={{ backgroundColor: "#757575" }}
          onClick={(e) => {
            this.setState({ productCategory: e.key });
          }}
        >
          {this.state.loadCategories.map((item) => (
            <Menu.Item
              style={{ color: "#4a90e2" }}
              key={item.name}
              onClick={() => {
                this.setState({ productCategoryID: item.id });
              }}
            >
              {item.name}
            </Menu.Item>
          ))}
        </Menu>
      );
    };

    return (
      <div style={{ marginBottom: 50 }}>
        <button
          style={{ width: 100 }}
          type="submit"
          onClick={() => this.onSubmit()}
          className="post-button-mobile"
          disabled={this.state.isSubmitting}
        >
          {this.state.isSubmitting ? <Spin color="black" /> : "Post It"}
        </button>
        <div className="editor_container m-0">
          <div className="row m-0 row-height">
            <div
              style={applyFilters(this.props.filterName, this.props.intensity)}
              className="col-sm-9 image-single m-0 p-0"
            >
              {this.state.image || this.state.video ? (
                <>
                  <div
                    onTouchEnd={(e) => {
                      if (!this.state.hotspotEditing) {
                        const currPosition = this.myViewer.getCurrentPosition();
                        this.state.activeHotspot.position.set(
                          currPosition.x,
                          currPosition.y,
                          currPosition.z
                        );
                      }
                      if (this.state.initialViewEditing) {
                        const currPosition = this.myViewer.getCurrentPosition();
                        this.state.initialViewHotspot.position.set(
                          currPosition.x,
                          currPosition.y,
                          currPosition.z
                        );
                        this.setState({
                          initialViewX: currPosition.x,
                          initialViewY: currPosition.y,
                          initialViewZ: currPosition.z,
                        });
                      }
                    }}
                    onMouseMove={(e) => {
                      // console.log(e);
                      if (!this.state.hotspotEditing) {
                        const currPosition = this.myViewer.getCurrentPosition();
                        this.state.activeHotspot.position.set(
                          currPosition.x,
                          currPosition.y,
                          currPosition.z
                        );
                      }
                      if (this.state.initialViewEditing) {
                        const currPosition = this.myViewer.getCurrentPosition();
                        this.state.initialViewHotspot.position.set(
                          currPosition.x,
                          currPosition.y,
                          currPosition.z
                        );
                        this.setState({
                          initialViewX: currPosition.x,
                          initialViewY: currPosition.y,
                          initialViewZ: currPosition.z,
                        });
                      }
                    }}
                    id="myPanoramaSingleImage"
                    // onClick={(e) => {
                    //   if (this.state.hotspotEditing) {
                    //     const currPosition = this.myViewer.getCurrentPosition();
                    //     this.state.activeHotspot.position.set(
                    //       currPosition.x,
                    //       currPosition.y,
                    //       currPosition.z
                    //     );
                    //     //floor(undertaker)
                    //     // this.state.activeHotspot.translateElement(50, 80);
                    //   }
                    //   if (this.state.initialViewEditing) {
                    //     const currPosition = this.myViewer.getCurrentPosition();
                    //     this.state.initialViewHotspot.position.set(
                    //       currPosition.x,
                    //       currPosition.y,
                    //       currPosition.z
                    //     );
                    //     this.setState({
                    //       initialViewX: currPosition.x,
                    //       initialViewY: currPosition.y,
                    //       initialViewZ: currPosition.z,
                    //     });
                    //     //floor(undertaker)
                    //     // this.state.activeHotspot.translateElement(50, 80);
                    //   }
                    // }}
                    ref={(ref) => (this.panaromaref = ref)}
                  />
                  {this.state.hotspotProcessing && (
                    <p className="hotspot-help-text">
                      click anywhere on image to add hotspot
                    </p>
                  )}
                </>
              ) : (
                <div className="overlay-single-image">
                  <Dragger
                    style={{ backgroundColor: "transparent" }}
                    onChange={async (info) =>
                      this.handlePreview(info.file, info)
                    }
                    multiple={false}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p
                      style={{ color: "white", fontSize: 25 }}
                      className="ant-upload-text "
                    >
                      Click or drag Image/Video to this area to upload
                    </p>
                  </Dragger>
                </div>
              )}
            </div>
            <div className="col-sm-3 editor-single">
              <div className="m-0">
                <div className="row">
                  <div
                    className={
                      this.state.isActive === "photo"
                        ? "editor-button-active col-sm"
                        : "editor-button col-sm"
                    }
                  >
                    <div
                      className="align-items-center d-flex justify-content-center"
                      onClick={() => {
                        if (this.state.isActive !== "addon") {
                          this.myViewer.setPanorama(this.myPanorama);
                          this.setState({ isActive: "photo" });
                        }
                      }}
                    >
                      PHOTO
                    </div>
                  </div>
                  <div
                    className={
                      this.state.isActive === "addon"
                        ? "editor-button-active col-sm"
                        : "editor-button col-sm"
                    }
                  >
                    <div className="align-items-center d-flex justify-content-center">
                      ADDON
                    </div>
                  </div>
                  <div
                    className={
                      this.state.isActive === "planet"
                        ? "editor-button-active col-sm"
                        : "editor-button col-sm"
                    }
                  >
                    {this.state.isImage && (
                      <div
                        className="align-items-center d-flex justify-content-center"
                        onClick={() => {
                          if (this.state.isActive !== "addon") {
                            this.myViewer.setPanorama(this.myTinyPlanet);
                            this.setState({ isActive: "planet" });
                          }
                        }}
                      >
                        TINY PLANET
                      </div>
                    )}
                  </div>
                  <div className="col-sm editor_container">
                    {this.state.isActive !== "addon" && (
                      <button
                        style={{ width: 100 }}
                        type="submit"
                        onClick={() => this.onSubmit()}
                        className="post-button display-none"
                        disabled={this.state.isSubmitting}
                      >
                        {this.state.isSubmitting ? (
                          <Spin
                            style={{
                              width: "100%",
                              padding: 50,
                            }}
                            color="black"
                          />
                        ) : this.state.isEditingMode ? (
                          "Save It"
                        ) : (
                          "Post It"
                        )}
                        {/* {!this.state.loading && "POST IT!"}
                        {this.state.loading ? (
                          <div
                            className="spinner-border spinner-border-sm ml-1"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : undefined} */}
                      </button>
                    )}
                  </div>
                </div>
                <div className="mt-2">
                  {this.state.isActive === "photo" && (
                    <div className="mb-5 scrollable">
                      <p className="on-mobile">scrollup</p>
                      <p className="text-white font-weight-normal p-0 m-0">
                        Heading
                      </p>
                      <div className="d-flex flex-row align-items-center p-0 m-0">
                        {this.state.initialViewHotspot && (
                          <>
                            <span
                              style={{
                                color: "white",
                                marginRight: 10,
                                fontSize: 15,
                              }}
                            >
                              X {this.state.initialViewX}
                            </span>
                            <span
                              style={{
                                color: "#4a90e2",
                                marginRight: 10,
                                fontSize: 15,
                              }}
                            >
                              x
                            </span>
                            <span style={{ color: "white", fontSize: 15 }}>
                              Y {this.state.initialViewY}
                            </span>
                          </>
                        )}
                        <Button
                          className="change-hotspot-btn"
                          onClick={() => {
                            var infospot2 = new Infospot(
                              500,
                              DataImage.FullscreenEnter
                            );
                            const currPosition = this.myViewer.getCurrentPosition();
                            infospot2.position.set(
                              currPosition.x,
                              currPosition.y,
                              currPosition.z
                            );
                            infospot2.show();
                            this.myViewer.tweenControlCenter(
                              new Vector3(
                                currPosition.x,
                                parseInt(currPosition.y),
                                parseInt(currPosition.z)
                              ),
                              0
                            );
                            infospot2.addEventListener("click", (e) => {
                              this.setState({
                                initialViewEditing: !this.state
                                  .initialViewEditing,
                                initialViewHotspot: infospot2,
                              });
                            });
                            this.myPanorama.add(infospot2);
                            const element = document.querySelector(
                              ".change-hotspot-btn"
                            );
                            element.style.display = "none";
                          }}
                        >
                          Add Initial View
                        </Button>
                        <img
                          src={DataImage.FullscreenEnter}
                          style={{
                            width: 50,
                            height: 50,
                            marginBottom: 10,
                            paddingTop: 10,
                          }}
                        />
                      </div>
                      <div className="addons">
                        <div
                          className="addons align-items-center addonbtn justify-content-center"
                          onClick={() => {
                            this.setState({ spriteImageProcessing: true });
                            this.createSprite();
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faImage}
                            style={{ marginRight: 8, fontSize: 20 }}
                          />
                          Image
                        </div>
                        <div
                          onClick={() => {
                            this.setState({ textImageProcessing: true });
                            this.createTextSprite();
                          }}
                          className="addons align-items-center addonbtn justify-content-center"
                        >
                          <FontAwesomeIcon
                            icon={faTenge}
                            style={{ marginRight: 8, fontSize: 20 }}
                          />
                          Text
                        </div>
                        <div
                          className="addons align-items-center addonbtn justify-content-center"
                          onClick={() => {
                            this.createHotspot();
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faExclamationCircle}
                            style={{ marginRight: 8, fontSize: 20 }}
                          />
                          Hotspot
                        </div>
                      </div>
                      <div></div>
                      <Modal
                        title="Terms and conditions"
                        width="40%"
                        visible={this.state.isBuyModalVisible}
                        centered={true}
                        closable={false}
                        okText="Accept"
                        bodyStyle={{
                          padding: 0,
                          paddingLeft: 10,
                        }}
                        onOk={() => {
                          this.setState({
                            isBuyModalVisible: false,
                            isBuyModalSecVisible: true,
                          });
                        }}
                        onCancel={() =>
                          this.setState({
                            isBuyModalVisible: false,
                            purchasePrice: 0,
                            AccountNumber: 0,
                            AccountTitle: "",
                            isPurchaseable: false,
                          })
                        }
                      >
                        <div className="ml-2">
                          {/* <p className="m-0 font-weight-bold">
                            Terms and Condition*
                          </p> */}
                          <ul>
                            <li>
                              Contrary to popular belief, Lorem Ipsum is not
                              simply random text.
                            </li>
                            <li>
                              Contrary to popular belief, Lorem Ipsum is not
                              simply random text.
                            </li>
                            <li>
                              Contrary to popular belief, Lorem Ipsum is not
                              simply random text.
                            </li>
                            <li>
                              Contrary to popular belief, Lorem Ipsum is not
                              simply random text.
                            </li>
                            <li>
                              Contrary to popular belief, Lorem Ipsum is not
                              simply random text.
                            </li>
                          </ul>
                        </div>
                      </Modal>
                      <Modal
                        title="Sale Image"
                        width="40%"
                        visible={this.state.isBuyModalSecVisible}
                        centered={true}
                        closable={false}
                        okText="Save"
                        bodyStyle={{
                          padding: 0,
                          paddingLeft: 10,
                        }}
                        onOk={() => {
                          if (this.state.purchasePrice === 0) {
                            this.messages(errors.ImagePurchaseCheck);
                          } else {
                            this.setState({
                              isBuyModalSecVisible: false,
                            });
                          }
                        }}
                        onCancel={() =>
                          this.setState({
                            isBuyModalSecVisible: false,
                            purchasePrice: 0,
                            AccountNumber: 0,
                            AccountTitle: "",
                            isPurchaseable: false,
                          })
                        }
                      >
                        <div className="ml-2">
                          <br />
                          <span style={{ fontSize: 15, fontWeight: "bold" }}>
                            Sale Price Per Month (USD $)
                          </span>
                          <br />
                          <InputNumber
                            style={{ width: "50%", marginBottom: 10 }}
                            placeholder="Picture price per month"
                            value={this.state.purchasePrice}
                            onChange={(event) =>
                              this.setState({
                                purchasePrice: event,
                              })
                            }
                          />
                          <br />
                          <span style={{ fontSize: 15, fontWeight: "bold" }}>
                            Account Number
                          </span>
                          <br />
                          <InputNumber
                            style={{ width: "50%", marginBottom: 10 }}
                            placeholder="Account Number"
                            value={this.state.AccountNumber}
                            onChange={(event) =>
                              this.setState({
                                AccountNumber: event,
                              })
                            }
                          />
                          <br />
                          <span style={{ fontSize: 15, fontWeight: "bold" }}>
                            Account Title
                          </span>
                          <br />
                          <Input
                            style={{ width: "50%", marginBottom: 10 }}
                            placeholder="Account Title"
                            value={this.state.AccountTitle}
                            onChange={(value) => {
                              this.setState({
                                AccountTitle: value.target.value,
                              });
                            }}
                          />
                        </div>
                      </Modal>
                      <p className="text-help">
                        Click/tap on addons to select and edit
                      </p>
                      <div className="d-flex flex-row  align-items-center">
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          style={{
                            marginRight: 10,
                            fontSize: 40,
                            color: "yellow",
                          }}
                        />
                        <div>
                          <p className="m-0 warning-text">
                            Hotspots Custom images and audio can be shared
                            publicly only with VR Pro.
                          </p>
                          <p className="m-0 warning-text">Learn More</p>
                        </div>
                      </div>
                      <div className="mt-4">
                        <p
                          className="font-weight-light"
                          style={{ color: "white", fontSize: 18 }}
                        >
                          Photo Name
                        </p>
                        <input
                          type="text"
                          onChange={(value) => {
                            this.setState({ photoName: value.target.value });
                            this.props.setPhotoName(value.target.value);
                          }}
                          defaultValue={this.props.photoName}
                          placeholder=""
                          className="p-2 photo-name"
                        />
                        <p
                          className="font-weight-light mt-3"
                          style={{ color: "white", fontSize: 18 }}
                        >
                          Description
                        </p>
                        <textarea
                          onChange={(value) => {
                            this.setState({ description: value.target.value });
                            this.props.setDescription(value.target.value);
                          }}
                          defaultValue={this.props.description}
                          placeholder="C & H"
                          className="p-2 discription"
                        />
                      </div>

                      <p className="text-white font-weight-normal mt-3">Tour</p>
                      <p className="text-white font-weight-normal mt-3 mb-1">
                        Privacy
                      </p>
                      <select
                        className="privacy"
                        defaultValue="public"
                        onChange={(value) => {
                          this.setState({ privacy: value.target.value });
                          this.props.setPrivacy(value.target.value);
                        }}
                      >
                        <option className="options" value="public">
                          &#xf0ac; Public
                        </option>
                        <option
                          className="options disable"
                          value="unlisted"
                          disabled
                        >
                          &#xf070; Unlisted
                        </option>
                        <option
                          className="options disable"
                          value="private"
                          disabled
                        >
                          &#xf023; Private
                        </option>
                      </select>

                      <p className="text-white font-weight-normal mt-5">
                        Thumbnail
                      </p>
                      {this.state.image && (
                        <div className="d-flex flex-row">
                          <img
                            className="skew-effect"
                            style={{
                              width: 100,
                              height: 100,
                            }}
                            src={
                              process.env.PUBLIC_URL + `/HotspotImages/4.png`
                            }
                          />
                          {/* <Button
                            onClick={() => this.takesnapshot()}
                            className="change-hotspot-btn"
                          >
                            Take snapshot
                          </Button> */}
                        </div>
                      )}
                      <p className="text-white font-weight-normal mt-3">
                        Filters
                      </p>
                      <Radio.Group
                        buttonStyle={"solid"}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                        value={this.props.filterName}
                      >
                        {this.state.filters.map((item) => (
                          <Radio.Button
                            key={item}
                            style={{
                              marginRight: 10,
                              marginBottom: 10,
                              height: 40,
                              width: 110,
                              fontSize: "small",
                              justifyContent: "center",
                              alignItems: "center",
                              display: "flex",
                            }}
                            value={item}
                            onChange={(event) => {
                              this.props.applyFilter(event.target.value);
                              this.setState({ filter: event.target.value });
                            }}
                          >
                            {item}
                          </Radio.Button>
                        ))}
                      </Radio.Group>
                      <p className="text-white font-weight-normal mt-3">
                        Filter Intensity
                      </p>
                      <Row>
                        <Col span={20}>
                          <Slider
                            min={0}
                            max={100}
                            defaultValue={this.props.intensity}
                            onChange={(value) => {
                              this.props.applyIntensity(value);
                              this.setState({ inputValue: value });
                            }}
                            value={
                              typeof this.props.intensity === "number"
                                ? this.props.intensity
                                : 0
                            }
                          />
                        </Col>
                        <Col style={{ color: "#4a90e2" }} span={4}>
                          <p className="ml-3"> {this.props.intensity}%</p>
                        </Col>
                      </Row>
                      <p className="text-white font-weight-normal mt-3">
                        Pitch
                      </p>
                      <Row>
                        <Col span={20}>
                          <Slider
                            min={1}
                            max={90}
                            defaultValue={this.props.pitchLimit}
                            onChange={(value) => {
                              this.myViewer.OrbitControls.minAzimuthAngle = -(
                                value *
                                (Math.PI / 180)
                              );
                              this.myViewer.OrbitControls.maxAzimuthAngle =
                                value * (Math.PI / 180);
                              this.props.applyMaxPitch(value);
                            }}
                            value={
                              typeof this.props.pitchLimit === "number"
                                ? this.props.pitchLimit
                                : 0
                            }
                          />
                        </Col>
                        <Col style={{ color: "#4a90e2" }} span={4}>
                          <p className="ml-3"> {this.props.pitchLimit} ˚</p>
                        </Col>
                      </Row>
                      <p className="text-white font-weight-normal mt-3">Yaw</p>
                      <Row>
                        <Col span={20}>
                          <Slider
                            min={1}
                            max={180}
                            defaultValue={this.props.maxYaw}
                            onChange={(value) => {
                              this.myViewer.OrbitControls.minPolarAngle =
                                value * (Math.PI / 180);
                              this.myViewer.OrbitControls.maxPolarAngle =
                                value * 2 * (Math.PI / 180);
                              this.props.setYaw(value);
                            }}
                            value={
                              typeof this.props.maxYaw === "number"
                                ? this.props.maxYaw
                                : 0
                            }
                          />
                        </Col>
                        <Col style={{ color: "#4a90e2" }} span={4}>
                          <p className="ml-3"> {this.props.maxYaw} ˚</p>
                        </Col>
                      </Row>
                      <p className="text-white font-weight-normal mt-3">
                        Zoom Limit
                      </p>
                      <Row>
                        <Col span={20}>
                          <Slider
                            min={100}
                            max={150}
                            defaultValue={this.props.zoomLimit}
                            onChange={(value) => {
                              this.myViewer.OrbitControls.minFov = 100;
                              this.props.applyZoomLimit(value);
                            }}
                            value={
                              typeof this.props.zoomLimit === "number"
                                ? this.props.zoomLimit
                                : 0
                            }
                          />
                        </Col>
                        <Col style={{ color: "#4a90e2" }} span={4}>
                          <p className="ml-3"> {this.props.zoomLimit}</p>
                        </Col>
                      </Row>
                      <p className="text-white font-weight-normal mt-3">
                        Background Audio
                      </p>
                      <div>
                        <Upload
                          style={{ width: "100%" }}
                          multiple={false}
                          showUploadList={false}
                          onChange={(info) => {
                            this.setState({
                              audioFile: info.file,
                            });
                          }}
                        >
                          <Button
                            style={{
                              border: "none",
                              backgroundColor: "rgb(248, 137, 18)",
                              color: "white",
                              width: "100%",
                            }}
                            icon={<UploadOutlined style={{ color: "white" }} />}
                          >
                            Click to {this.state.audioFile ? "Edit" : "Upload"}
                          </Button>
                        </Upload>
                      </div>
                      {this.state.audioFile && (
                        <p className="text-white">
                          Background Audio:{" "}
                          {!this.state.audioFile.name
                            ? this.state.audioFile.split("/", 3)[2]
                            : this.state.audioFile.name}
                        </p>
                      )}
                      <p className="text-white font-weight-normal mt-3">
                        Narrator Audio
                      </p>
                      <Upload
                        multiple={false}
                        showUploadList={false}
                        onChange={(info) => {
                          this.setState({
                            narratorAudio: info.file,
                          });
                        }}
                      >
                        <Button
                          style={{
                            border: "none",
                            backgroundColor: "rgb(248, 137, 18)",
                            color: "white",
                            width: "100%",
                          }}
                          icon={<UploadOutlined style={{ color: "white" }} />}
                        >
                          Click to{" "}
                          {this.state.narratorAudio ? "Edit" : "Upload"}
                        </Button>
                      </Upload>
                      {this.state.narratorAudio && (
                        <p className="text-white">
                          Narrator Audio:{" "}
                          {!this.state.narratorAudio.name
                            ? this.state.narratorAudio.split("/", 3)[2]
                            : this.state.narratorAudio.name}
                        </p>
                      )}
                      {this.state.isPurchaseable && (
                        <>
                          <br />
                          <span
                            style={{
                              color: "#fff",
                              fontSize: 15,
                            }}
                          >
                            Sale Price Per Month ({this.state.purchasePrice} $)
                          </span>
                          <br />
                          <span
                            style={{
                              cursor: "pointer",
                              color: "#4a90e2",
                              textDecorationLine: "underline",
                              fontSize: 15,
                            }}
                            onClick={() =>
                              this.setState({
                                isBuyModalSecVisible: true,
                              })
                            }
                          >
                            Edit Details
                          </span>
                        </>
                      )}
                      <div
                        className=" addonbtn "
                        style={{
                          margin: 0,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "50%",
                          marginTop: "20px",
                          marginLeft: "auto",
                          marginRight: "auto",
                          marginBottom: "10px",
                        }}
                        onClick={() => {
                          this.setState({
                            isPurchaseable: !this.state.isPurchaseable,
                            isBuyModalVisible: true,
                          });
                        }}
                      >
                        <span>For Sale?</span>
                      </div>
                    </div>
                  )}
                  {/* Addons */}
                  {this.state.isActive === "addon" && (
                    <div className="mb-5 scrollable">
                      {!this.state.textImageProcessing && (
                        <>
                          <p className="text-white m-0 pb-2 ">Icon</p>
                          <Row>
                            <Col span={6}>
                              <div className="hotspot-image-viewer">
                                <img
                                  className="hotspot-image"
                                  style={{
                                    width: this.state.hotspotScale,
                                    height: this.state.hotspotScale,
                                    opacity: this.state.opacity / 100,
                                    transform: `rotate(${
                                      this.state.hotspotRotation < 0
                                        ? this.state.hotspotRotation * -1 +
                                          "deg"
                                        : -this.state.hotspotRotation + "deg"
                                    })`,
                                  }}
                                  src={this.state.hotspotImage}
                                />
                              </div>
                            </Col>
                            <Col span={10}>
                              <div
                                style={{
                                  display: "flex",
                                  height: 80,
                                  width: 100,
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Button
                                  onClick={() => {
                                    this.setState({
                                      isHotspotImageModal: true,
                                    });
                                  }}
                                  className="change-hotspot-btn"
                                >
                                  Change Image
                                </Button>
                                <Modal
                                  title="Select Image"
                                  width="35%"
                                  centered={true}
                                  footer={null}
                                  closable={true}
                                  bodyStyle={{
                                    backgroundColor: "#1E1E1E",
                                  }}
                                  visible={this.state.isHotspotImageModal}
                                  onOk={() => {
                                    this.setState({
                                      isHotspotImageModal: false,
                                    });
                                  }}
                                  onCancel={() => {
                                    this.setState({
                                      isHotspotImageModal: false,
                                    });
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      flexWrap: "wrap",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    {Array.apply(null, Array(57)).map(
                                      (item, index) => (
                                        <img
                                          id="Img1"
                                          onClick={async (e) => {
                                            var c = document.createElement(
                                              "canvas"
                                            );
                                            var img = e.target;
                                            c.height = img.naturalHeight;
                                            c.width = img.naturalWidth;
                                            var ctx = c.getContext("2d");
                                            ctx.drawImage(
                                              img,
                                              0,
                                              0,
                                              c.width,
                                              c.height
                                            );
                                            var base64String = c.toDataURL();
                                            this.changeHotspotImage(
                                              base64String
                                            );
                                            this.setState({
                                              hotspotImage: base64String,
                                              isHotspotImageModal: false,
                                            });
                                          }}
                                          key={index}
                                          style={{
                                            width: 70,
                                            height: 70,
                                            padding: 10,
                                          }}
                                          src={
                                            process.env.PUBLIC_URL +
                                            `/HotspotImages/${index + 1}.png`
                                          }
                                        />
                                      )
                                    )}
                                  </div>
                                  <Upload
                                    showUploadList={false}
                                    onChange={async (info) =>
                                      this.handleHotspotImage(info.file, info)
                                    }
                                  >
                                    <Button
                                      style={{
                                        backgroundColor: "rgb(248, 137, 18)",
                                        margin: 25,
                                        marginLeft: 0,
                                        color: "white",
                                        border: "none",
                                      }}
                                    >
                                      Upload Custom
                                    </Button>
                                  </Upload>
                                </Modal>
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                      <p className="text-white m-0 pb-2 mt-4">Appearance</p>
                      {!this.state.textImageProcessing && (
                        <Row>
                          <Col span={4}>
                            <p className="dark-text">Size</p>
                          </Col>
                          <Col span={16}>
                            <Slider
                              min={1}
                              max={100}
                              onChange={(value) => {
                                this.setState({ hotspotScale: value });
                                this.hotspotLabel_Scale(
                                  this.state.hotspotLabel,
                                  value * 5
                                );
                              }}
                              // defaultValue={50}
                              value={this.state.hotspotScale}
                            />
                          </Col>
                          <Col style={{ color: "#4a90e2" }} span={4}>
                            <p className="ml-3">{this.state.hotspotScale}%</p>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col span={4}>
                          <p className="dark-text">Rotation</p>
                        </Col>
                        <Col span={16}>
                          <Slider
                            min={-180}
                            max={180}
                            onChange={(value) => {
                              this.setState({ hotspotRotation: value });
                              this.state.activeHotspot.material.rotation =
                                (Math.PI / 180) * value;
                            }}
                            value={this.state.hotspotRotation}
                          />
                        </Col>
                        <Col style={{ color: "#4a90e2" }} span={4}>
                          <p className="ml-3">{this.state.hotspotRotation}'</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={4}>
                          <p className="dark-text">Opacity</p>
                        </Col>
                        <Col span={16}>
                          <Slider
                            min={0}
                            max={100}
                            onChange={(value) => {
                              this.setState({ opacity: value });
                              this.state.activeHotspot.material.opacity =
                                value / 100;
                            }}
                            value={this.state.opacity}
                          />
                        </Col>
                        <Col style={{ color: "#4a90e2" }} span={4}>
                          <p className="ml-3">{this.state.opacity}%</p>
                        </Col>
                      </Row>

                      {this.state.textImageProcessing && (
                        <div>
                          <TextEditor isText={true} />
                          <Button
                            style={{
                              marginBottom: 10,
                              backgroundColor: "rgb(248, 137, 18)",
                              color: "white",
                              width: "100%",
                              border: "none",
                            }}
                            onClick={() => {
                              this.changeTextLabel();
                            }}
                          >
                            Apply Changes
                          </Button>
                        </div>
                      )}

                      {!this.state.spriteImageProcessing &&
                        !this.state.textImageProcessing && (
                          <>
                            <p className="text-white m-0 pb-2">Position</p>
                            <Checkbox
                              style={{
                                height: "30px",
                                lineHeight: "30px",
                                color: "#757575",
                              }}
                              checked={this.state.isFloor}
                              onChange={(e) => {
                                this.setState({ isFloor: e.target.checked });
                                if (e.target.checked) {
                                  this.floorHotspot();
                                } else {
                                  this.setState({
                                    hotspotImage: this.state.defaultHotspot,
                                  });
                                  var Texture = new TextureLoader();
                                  var loadedTexture = Texture.load(
                                    this.state.defaultHotspot
                                  );
                                  this.state.activeHotspot.material.map = loadedTexture;
                                }
                              }}
                            >
                              Floor
                            </Checkbox>

                            <p className="text-white m-0 pb-2 pt-2">Label</p>
                            <TextEditor
                              changeLabel={(label, style) =>
                                this.hotspotLabel_Scale(label, 500, style)
                              }
                              defaultLabel={
                                this.state.hotspotLabel.split("style", 2)[0]
                              }
                            />
                            {/* <input
                              type="text"
                              onChange={(e) => {
                                this.setState({ hotspotLabel: e.target.value });

                                this.hotspotLabel_Scale(
                                  e.target.value,
                                  this.state.hotspotScale * 5
                                );
                              }}
                              defaultValue={this.state.hotspotLabel}
                              placeholder=""
                              className="p-2 photo-name"
                            /> */}
                            <Row>
                              <Checkbox
                                className="dark-text"
                                style={{ fontSize: 12 }}
                                onChange={(e) => {
                                  this.setState({
                                    isHoverOnly: e.target.checked,
                                  });
                                  e.target.checked
                                    ? this.state.activeHotspot.unlockHoverElement()
                                    : this.state.activeHotspot.lockHoverElement();
                                }}
                                defaultChecked={this.state.isHover}
                              >
                                Hover Only
                              </Checkbox>
                            </Row>

                            <p className="text-white m-0 mt-2 pb-2">
                              Click Action
                            </p>
                            <Space wrap>
                              <Dropdown overlay={menu()}>
                                <Button
                                  style={{
                                    backgroundColor: "#757575",
                                    border: "none",
                                    color: "white",
                                    width: 350,
                                    textAlign: "start",
                                  }}
                                >
                                  {this.state.action} <DownOutlined />
                                </Button>
                              </Dropdown>
                            </Space>

                            {this.state.action === "Open URL" && (
                              <input
                                type="text"
                                // defaultValue={
                                //   props.ActiveHotspot ? props.ActiveHotspot.url : ""
                                // }
                                onChange={(e) => {
                                  this.setState({ url: e.target.value });
                                }}
                                defaultValue={this.state.url}
                                placeholder="Enter URL"
                                className="mt-2 p-2 photo-name"
                              />
                            )}
                            {this.state.action === "Open Card" && (
                              <>
                                <button
                                  onClick={() =>
                                    this.setState({ isCardVisible: true })
                                  }
                                  type="submit"
                                  className="card-button"
                                >
                                  Edit Card
                                </button>
                                <div className="d-flex flex-row  align-items-center mt-2">
                                  <FontAwesomeIcon
                                    icon={faExclamationTriangle}
                                    style={{
                                      marginRight: 10,
                                      color: "yellow",
                                    }}
                                  />
                                  <div>
                                    <p className="m-0 warning-text">
                                      Interactive cards are not supported in VR
                                      headsets.
                                    </p>
                                  </div>
                                </div>
                              </>
                            )}
                            {this.state.action === "Sell Product" && (
                              <>
                                <button
                                  onClick={() =>
                                    this.setState({ isProductModal: true })
                                  }
                                  type="submit"
                                  className="card-button"
                                >
                                  Edit Product
                                </button>
                                <div className="d-flex flex-row  align-items-center mt-2">
                                  <FontAwesomeIcon
                                    icon={faExclamationTriangle}
                                    style={{
                                      marginRight: 10,
                                      color: "yellow",
                                    }}
                                  />
                                  <div>
                                    <p className="m-0 warning-text">
                                      Interactive products are not supported in
                                      VR headsets.
                                    </p>
                                  </div>
                                </div>
                              </>
                            )}
                            {this.state.action === "Go to another post" && (
                              <div className="d-flex flex-row mt-2">
                                {this.state.mediaType === "3D" ? (
                                  <img
                                    style={{
                                      width: 60,
                                      height: 60,
                                      marginRight: 5,
                                    }}
                                    src={`${BASE_URL_IMAGE}${this.state.actionImage}`}
                                  />
                                ) : (
                                  <video
                                    style={{
                                      width: 60,
                                      height: 60,
                                      marginRight: 5,
                                    }}
                                    src={`${BASE_URL_IMAGE}${this.state.actionImage}`}
                                  />
                                )}
                                <div>
                                  <p className="post-desc-text m-0">
                                    {this.state.actionImageName}
                                  </p>
                                  <p
                                    className="btn-change-post"
                                    onClick={() => {
                                      this.setState({ isModalVisible: true });
                                    }}
                                  >
                                    Change Post
                                  </p>
                                </div>
                              </div>
                            )}
                            <Modal
                              title="Card Content"
                              width="40%"
                              visible={this.state.isCardVisible}
                              centered={true}
                              closable={false}
                              okText="Save"
                              bodyStyle={{
                                padding: 0,
                                paddingLeft: 10,
                              }}
                              onOk={() =>
                                this.setState({ isCardVisible: false })
                              }
                              onCancel={() =>
                                this.setState({
                                  isCardVisible: false,
                                  action: "No action",
                                })
                              }
                            >
                              <Row>
                                <Col span={4}>Media</Col>
                                <Col span={20}>
                                  <input
                                    type="text"
                                    onChange={(e) => {
                                      this.setState({
                                        cardImageUrl: e.target.value,
                                      });
                                    }}
                                    defaultValue={this.state.cardImageUrl}
                                    placeholder="Image URL"
                                    className="card-fields"
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col span={4}>Text</Col>
                                <Col span={20}>
                                  <input
                                    type="text"
                                    defaultValue={this.state.cardText}
                                    style={{ height: 200 }}
                                    onChange={(e) => {
                                      this.setState({
                                        cardText: e.target.value,
                                      });
                                    }}
                                    className="card-fields"
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col span={4}>Link Label</Col>
                                <Col span={20}>
                                  <input
                                    type="text"
                                    defaultValue={this.state.cardLinkLabel}
                                    onChange={(e) => {
                                      this.setState({
                                        cardLinkLabel: e.target.value,
                                      });
                                    }}
                                    className="card-fields"
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col span={4}>Link URL</Col>
                                <Col span={20}>
                                  <input
                                    type="text"
                                    defaultValue={this.state.cardUrl}
                                    onChange={(e) => {
                                      this.setState({
                                        cardUrl: e.target.value,
                                      });
                                    }}
                                    placeholder="http://"
                                    className="card-fields"
                                  />
                                </Col>
                              </Row>
                            </Modal>
                            <Modal
                              title="Product Details"
                              width="40%"
                              visible={this.state.isProductModal}
                              centered={true}
                              closable={false}
                              okText="Save"
                              bodyStyle={{
                                padding: 0,
                                paddingLeft: 10,
                              }}
                              onOk={() => this.handleProduct()}
                              onCancel={() => {
                                // this.changeHotspotImage(this.state.hotspotImage);
                                this.setState({
                                  isProductModal: false,
                                  productCategoryID: 0,
                                  productName: "",
                                  productDetail: "",
                                  productPrice: 0,
                                  productImages: [],
                                  isProductImage: false,
                                  action: "No action",
                                });
                              }}
                            >
                              <Row style={{ padding: 10 }}>
                                <Col
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  span={4}
                                >
                                  Product Name
                                </Col>
                                <Col span={20}>
                                  <input
                                    style={{ borderRadius: 10 }}
                                    type="text"
                                    onChange={(e) => {
                                      this.setState({
                                        productName: e.target.value,
                                      });
                                    }}
                                    defaultValue={this.state.productName}
                                    placeholder="Product Name"
                                    className="card-fields"
                                  />
                                </Col>
                              </Row>
                              <Row style={{ padding: 10 }}>
                                <Col
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  span={4}
                                >
                                  Classification
                                </Col>
                                <Col span={20}>
                                  <Radio.Group
                                    onChange={(e) =>
                                      this.setState({
                                        productClassification: e.target.value,
                                      })
                                    }
                                    value={this.state.productClassification}
                                  >
                                    <Radio
                                      style={{
                                        height: "30px",
                                        lineHeight: "30px",
                                        color: "#757575",
                                      }}
                                      value={1}
                                    >
                                      product
                                    </Radio>
                                    <Radio
                                      style={{
                                        height: "30px",
                                        lineHeight: "30px",
                                        color: "#757575",
                                      }}
                                      value={2}
                                    >
                                      service
                                    </Radio>
                                    <Radio
                                      style={{
                                        height: "30px",
                                        lineHeight: "30px",
                                        color: "#757575",
                                      }}
                                      value={3}
                                    >
                                      property
                                    </Radio>
                                    <Radio
                                      style={{
                                        height: "30px",
                                        lineHeight: "30px",
                                        color: "#757575",
                                      }}
                                      value={4}
                                    >
                                      vehicle
                                    </Radio>
                                  </Radio.Group>
                                </Col>
                              </Row>
                              <Row style={{ padding: 10 }}>
                                <Col
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  span={4}
                                >
                                  Category
                                </Col>
                                <Col span={20}>
                                  <Space wrap>
                                    <Dropdown overlay={productCategorymenu()}>
                                      <Button
                                        style={{
                                          backgroundColor: "#757575",
                                          border: "none",
                                          color: "white",
                                          width: "100%",
                                          borderRadius: 10,
                                          textAlign: "start",
                                        }}
                                      >
                                        {this.state.productCategory}{" "}
                                        <DownOutlined />
                                      </Button>
                                    </Dropdown>
                                  </Space>
                                </Col>
                              </Row>
                              <Row style={{ padding: 10 }}>
                                <Col
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  span={4}
                                >
                                  Product Detail
                                </Col>
                                <Col span={20}>
                                  <TextArea
                                    style={{ borderRadius: 10 }}
                                    type="text"
                                    defaultValue={this.state.productDetail}
                                    onChange={(e) => {
                                      this.setState({
                                        productDetail: e.target.value,
                                      });
                                    }}
                                    className="card-fields"
                                  />
                                </Col>
                              </Row>
                              <Row style={{ padding: 10 }}>
                                <Col
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  span={4}
                                >
                                  <div>
                                    Product Images
                                    <br />
                                    <span
                                      style={{ fontSize: 10, color: "grey" }}
                                    >
                                      select feature image
                                    </span>
                                  </div>
                                </Col>
                                <Col span={20}>
                                  {!this.state.isProductImage ? (
                                    <Dragger
                                      maxCount={4}
                                      style={{ borderRadius: 10 }}
                                      onChange={async (info) =>
                                        this.handleProductPreview(
                                          info.file,
                                          info
                                        )
                                      }
                                      multiple={true}
                                    >
                                      <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                      </p>
                                      <p className="ant-upload-text ">
                                        Click or drag Image/Video to this area
                                        to upload
                                      </p>
                                      <p className="ant-upload-hint">
                                        Support for a single or bulk upload.
                                        Strictly prohibit from uploading company
                                        data or other band files.Max Image
                                        Support is 4.
                                      </p>
                                    </Dragger>
                                  ) : (
                                    this.state.productImages.map(
                                      (item, index) => {
                                        return (
                                          <Radio.Group
                                            onChange={(e) =>
                                              this.setState({
                                                productFeatureImage:
                                                  e.target.value,
                                              })
                                            }
                                            value={
                                              this.state.productFeatureImage
                                            }
                                          >
                                            <Radio
                                              style={{
                                                lineHeight: "30px",
                                                color: "#757575",
                                              }}
                                              value={item}
                                            >
                                              <img
                                                key={index}
                                                src={item}
                                                style={{
                                                  width: 100,
                                                  height: 100,
                                                  borderRadius: 20,
                                                  padding: 10,
                                                }}
                                              />
                                              <DeleteOutlined
                                                size={24}
                                                style={{
                                                  color: "red",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                  const newArr = this.state.productImages.filter(
                                                    (value) => item !== value
                                                  );
                                                  this.setState({
                                                    productImages: newArr,
                                                  });
                                                  if (
                                                    this.state.productImages
                                                      .length <= 1
                                                  ) {
                                                    this.setState({
                                                      isProductImage: false,
                                                    });
                                                  }
                                                }}
                                              />
                                            </Radio>
                                          </Radio.Group>
                                        );
                                      }
                                    )
                                  )}
                                </Col>
                              </Row>
                              <Row style={{ padding: 10 }}>
                                <Col
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  span={4}
                                >
                                  Product Price
                                </Col>
                                <Col span={20}>
                                  <InputNumber
                                    style={{
                                      width: "50%",
                                      marginBottom: 10,
                                      borderRadius: 10,
                                    }}
                                    placeholder="product price"
                                    value={this.state.productPrice}
                                    onChange={(event) =>
                                      this.setState({
                                        productPrice: event,
                                      })
                                    }
                                  />
                                </Col>
                              </Row>
                              <Row style={{ padding: 10 }}>
                                <Col
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  span={4}
                                >
                                  Product Quantity
                                </Col>
                                <Col span={20}>
                                  <InputNumber
                                    style={{
                                      width: "50%",
                                      marginBottom: 10,
                                      borderRadius: 10,
                                    }}
                                    placeholder="product price"
                                    value={this.state.productQuantity}
                                    onChange={(event) =>
                                      this.setState({
                                        productQuantity: event,
                                      })
                                    }
                                  />
                                </Col>
                              </Row>
                            </Modal>
                            <Modal
                              width="100%"
                              title="Select Post"
                              onCancel={() =>
                                this.setState({
                                  isModalVisible: false,
                                  action: "No action",
                                })
                              }
                              onOk={() => {
                                if (
                                  this.state.actionImage === "" ||
                                  this.state.actionImageName === ""
                                ) {
                                  this.messages(errors.HotspotPost);
                                } else {
                                  this.setState({ isModalVisible: false });
                                }
                              }}
                              visible={this.state.isModalVisible}
                              centered={true}
                              closable={false}
                              bodyStyle={{
                                backgroundColor: "#757575",
                                height: "100%",
                                padding: 5,
                              }}
                            >
                              <div className="modal-container d-flex flex-row">
                                {this.state.posts && this.state.posts.length > 0
                                  ? this.state.posts.map((item, index) => {
                                      return item.type === "3D" ? (
                                        <div key={item.Image.image}>
                                          <div
                                            className="select-posts ml-1"
                                            onClick={() => {
                                              this.setState({
                                                mediaType: "3D",
                                                actionID: item.id,
                                                actionImage: item.Image.image,
                                                actionImageName: item.name,
                                                isModalVisible: false,
                                              });
                                            }}
                                          >
                                            <img
                                              style={{
                                                width: 140,
                                                height: 120,
                                              }}
                                              src={
                                                BASE_URL_IMAGE +
                                                item.Image.image
                                              }
                                            />
                                            <p className={"post-desc"}>
                                              {item.name}
                                            </p>
                                          </div>
                                          {index % 2 === 0 ? <br /> : undefined}
                                        </div>
                                      ) : (
                                        <div key={item.Image.image}>
                                          <div
                                            className="select-posts ml-1"
                                            onClick={() => {
                                              this.setState({
                                                mediaType: "video",
                                                actionID: item.id,
                                                actionImage: item.Image.image,
                                                actionImageName: item.name,
                                                isModalVisible: false,
                                              });
                                            }}
                                          >
                                            <video
                                              style={{
                                                width: 140,
                                                height: 120,
                                              }}
                                              src={
                                                BASE_URL_IMAGE +
                                                item.Image.image
                                              }
                                            />
                                            <p className={"post-desc"}>
                                              {item.name}
                                            </p>
                                          </div>
                                          {index % 2 === 0 ? <br /> : undefined}
                                        </div>
                                      );
                                    })
                                  : undefined}
                              </div>
                            </Modal>

                            <p className="dark-text pt-2">
                              Select what happens when user clicks or taps on
                              the hotspot.
                            </p>
                            <p className="text-white m-0 pb-2">Settings</p>
                            <p className="text-white mt-3">Add Audio</p>
                            <div className="mb-3">
                              <Upload
                                multiple={false}
                                showUploadList={false}
                                onChange={(info) => {
                                  this.setState({
                                    hotspotAudioFile: info.file.originFileObj,
                                  });
                                }}
                              >
                                <Tooltip title="The Audio will work with this hotspot only">
                                  <Button
                                    style={{
                                      border: "none",
                                      backgroundColor: "rgb(248, 137, 18)",
                                      color: "white",
                                      width: "100%",
                                    }}
                                    icon={
                                      <UploadOutlined
                                        style={{ color: "white" }}
                                      />
                                    }
                                  >
                                    Click to{" "}
                                    {this.state.hotspotAudioFile
                                      ? "Edit"
                                      : "Upload"}
                                  </Button>
                                </Tooltip>
                              </Upload>

                              {this.state.hotspotAudioFile && (
                                <p className="text-white">
                                  Audio:{" "}
                                  {!this.state.hotspotAudioFile.name
                                    ? this.state.hotspotAudioFile.split(
                                        "/",
                                        3
                                      )[2]
                                    : this.state.hotspotAudioFile.name}
                                </p>
                              )}
                            </div>
                          </>
                        )}
                      <button
                        style={{ marginRight: 10 }}
                        type="submit"
                        onClick={() => {
                          this.props.resetLabel();
                          if (this.actionChecks()) {
                            if (this.state.hotspotAudioFile) {
                              this.props.deleteAudioHotspot(
                                this.state.activeHotspot.id
                              );
                              const hotspot = {
                                hotspotID: this.state.activeHotspot.id,
                                x: this.state.activeHotspot.position.x,
                                y: this.state.activeHotspot.position.y,
                                z: this.state.activeHotspot.position.z,
                                image: this.state.hotspotImage,
                                scale: this.state.hotspotScale,
                                rotation: this.state.hotspotRotation,
                                opacity: this.state.opacity,
                                label: this.state.hotspotLabel,
                                isHover: this.state.isHoverOnly,
                                id: this.state.actionID,
                                action: this.state.action,
                                url: this.state.url,
                                isFloor: this.state.isFloor,
                                cardText: this.state.cardText,
                                cardImageUrl: this.state.cardImageUrl,
                                cardLinkLabel: this.state.cardLinkLabel,
                                cardUrl: this.state.cardUrl,
                                audio: this.state.hotspotAudioFile,
                                productId: this.state.productID,
                              };
                              this.props.addAudioHotspot(hotspot);
                              this.setState({
                                isActive: "photo",
                                hotspotEditing: true,
                                hotspotImage: DataImage.Info,
                                hotspotScale: 50,
                                hotspotRotation: 0,
                                opacity: 100,
                                hotspotLabel: "",
                                action: "No action",
                                isHover: true,
                                url: "",
                                cardText: "",
                                cardImageUrl: "",
                                cardLinkLabel: "",
                                cardUrl: "",
                                hotspotAudioFile: "",
                                audioHotspots: [
                                  ...this.state.audioHotspots,
                                  hotspot,
                                ],
                                spriteImageProcessing: false,
                                isFloor: false,
                                textImageProcessing: false,
                              });
                            } else {
                              this.props.deleteHotspot(
                                this.state.activeHotspot.id
                              );
                              const hotspot = {
                                hotspotID: this.state.activeHotspot.id,
                                x: this.state.activeHotspot.position.x,
                                y: this.state.activeHotspot.position.y,
                                z: this.state.activeHotspot.position.z,
                                image: this.state.hotspotImage,
                                scale: this.state.hotspotScale,
                                rotation: this.state.hotspotRotation,
                                opacity: this.state.opacity,
                                label: this.state.hotspotLabel,
                                isHover: this.state.isHoverOnly,
                                id: this.state.actionID,
                                action: this.state.action,
                                url: this.state.url,
                                isFloor: this.state.isFloor,
                                cardText: this.state.cardText,
                                cardImageUrl: this.state.cardImageUrl,
                                cardLinkLabel: this.state.cardLinkLabel,
                                cardUrl: this.state.cardUrl,
                                productId: this.state.productID,
                                textLabel: this.state.textLabel,
                              };
                              this.props.addHotspot(hotspot);
                              this.setState({
                                isActive: "photo",
                                hotspotEditing: true,
                                hotspotImage: DataImage.Info,
                                hotspotScale: 50,
                                hotspotRotation: 0,
                                opacity: 100,
                                hotspotLabel: "",
                                action: "No action",
                                isHover: true,
                                url: "",
                                cardText: "",
                                cardImageUrl: "",
                                cardLinkLabel: "",
                                cardUrl: "",
                                spriteImageProcessing: false,
                                isFloor: false,
                                textImageProcessing: false,
                              });
                            }
                          }
                        }}
                        className="post-button w-100"
                      >
                        Save
                        {this.state.spriteImageProcessing ||
                        this.state.textImageProcessing
                          ? this.state.textImageProcessing
                            ? " Text"
                            : " Image"
                          : " Hotspot"}
                      </button>
                      {!this.state.textImageProcessing && (
                        <button
                          onClick={() => {
                            if (this.state.hotspotAudioFile) {
                              this.props.deleteAudioHotspot(
                                this.state.activeHotspot.id
                              );
                              const hotspot = {
                                hotspotID: this.state.activeHotspot.id,
                                x: this.state.activeHotspot.position.x,
                                y: this.state.activeHotspot.position.y,
                                z: this.state.activeHotspot.position.z,
                                image: this.state.hotspotImage,
                                scale: this.state.hotspotScale,
                                rotation: this.state.hotspotRotation,
                                opacity: this.state.opacity,
                                label: this.state.hotspotLabel,
                                isHover: this.state.isHoverOnly,
                                id: this.state.actionID,
                                action: "No action",
                                url: this.state.url,
                                isFloor: this.state.isFloor,
                                cardText: this.state.cardText,
                                cardImageUrl: this.state.cardImageUrl,
                                cardLinkLabel: this.state.cardLinkLabel,
                                cardUrl: this.state.cardUrl,
                                audio: this.state.hotspotAudioFile,
                                productId: this.state.productID,
                              };
                              this.props.addAudioHotspot(hotspot);
                              this.setState({
                                isActive: "photo",
                                hotspotEditing: true,
                                hotspotImage: DataImage.Info,
                                hotspotScale: 50,
                                hotspotRotation: 0,
                                opacity: 100,
                                hotspotLabel: "",
                                action: "No action",
                                isHover: true,
                                url: "",
                                cardText: "",
                                cardImageUrl: "",
                                cardLinkLabel: "",
                                cardUrl: "",
                                hotspotAudioFile: "",
                                audioHotspots: [
                                  ...this.state.audioHotspots,
                                  hotspot,
                                ],
                                spriteImageProcessing: false,
                                isFloor: false,
                                textImageProcessing: false,
                              });
                            } else {
                              this.props.deleteHotspot(
                                this.state.activeHotspot.id
                              );
                              const hotspot = {
                                hotspotID: this.state.activeHotspot.id,
                                x: this.state.activeHotspot.position.x,
                                y: this.state.activeHotspot.position.y,
                                z: this.state.activeHotspot.position.z,
                                image: this.state.hotspotImage,
                                scale: this.state.hotspotScale,
                                rotation: this.state.hotspotRotation,
                                opacity: this.state.opacity,
                                label: this.state.hotspotLabel,
                                isHover: this.state.isHoverOnly,
                                id: this.state.actionID,
                                action: "No action",
                                url: this.state.url,
                                isFloor: this.state.isFloor,
                                cardText: this.state.cardText,
                                cardImageUrl: this.state.cardImageUrl,
                                cardLinkLabel: this.state.cardLinkLabel,
                                cardUrl: this.state.cardUrl,
                                productId: this.state.productID,
                              };
                              this.props.addHotspot(hotspot);
                              this.setState({
                                isActive: "photo",
                                action: "No action",
                                hotspotEditing: true,
                                hotspotImage: DataImage.Info,
                                hotspotScale: 50,
                                hotspotRotation: 0,
                                opacity: 100,
                                hotspotLabel: "",
                                isHover: true,
                                url: "",
                                cardText: "",
                                cardImageUrl: "",
                                cardLinkLabel: "",
                                cardUrl: "",
                                hotspotAudioFile: "",
                                spriteImageProcessing: false,
                                isFloor: false,
                                textImageProcessing: false,
                              });
                            }
                          }}
                          style={{
                            width: 100,
                            backgroundColor: "#4a90e2",
                            borderColor: "#4a90e2",
                            marginRight: 10,
                          }}
                          className="post-button w-100"
                        >
                          Reset
                        </button>
                      )}
                      <Popconfirm
                        className="w-100 d-flex justify-content-center mt-2"
                        title="Are you sure to delete this hotspot?"
                        onConfirm={() => {
                          this.state.activeHotspot.dispose();
                          this.setState({ isActive: "photo" });
                          this.props.deleteHotspot(this.state.activeHotspot.id);
                        }}
                        // onCancel={() => message.error("Click on No")}
                        okText="Yes"
                        cancelText="No"
                      >
                        <a href="#" style={{ color: "red", paddingBottom: 10 }}>
                          Delete
                        </a>
                      </Popconfirm>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setActivehotspot,
  resetHotspot,
  setPhotoName,
  setDescription,
  setPrivacy,
  applyIntensity,
  applyMaxPitch,
  setYaw,
  applyFilter,
  applyZoomLimit,
  addHotspot,
  deleteHotspot,
  deleteAudioHotspot,
  addAudioHotspot,
  resetLabel,
  resetAudioHotspot,
};

const mapStateToProps = (state) => {
  return {
    filterName: state.editor.filterName,
    intensity: state.editor.intensity,
    pitchLimit: state.editor.pitchLimit,
    zoomLimit: state.editor.zoomLimit,
    maxYaw: state.editor.maxYaw,
    privacy: state.editor.privacy,
    description: state.editor.description,
    photoName: state.editor.photoName,
    hotspot: state.hotspot.hotspots,
    audioHotspot: state.hotspot.audioHotspots,
    label: state.hotspot.label,
    user: state.auth.user,
    ActiveHotspot: state.hotspot.ActiveHotspot,
    // Revamped hotspots
    panoView: state.hotspot.panoView,
    user: state.auth.user,
    htmlLabel: state.label.label,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleImage);
//
