const videoExtentions = ["mp4", "mkv", "gif", "webm"];
const imageExtentions = ["png", "jpeg", "jpg", "exr"];

export default function validateImageorVideo(filename) {
  const extention = filename.split(".");
  const isVideo = videoExtentions.find(
    (x) => x === extention[extention.length - 1]
  );
  const isImage = imageExtentions.find(
    (x) => x === extention[extention.length - 1]
  );
  if (isVideo) return "video";
  else if (isImage) return "image";
  else return "inValid";
}
