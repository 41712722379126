import React, { useEffect, useState } from "react";
import "../Screens/Home/index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import QueueAnim from "rc-queue-anim";
import Texty from "rc-texty";
import { Empty, Spin, Button, Col, Row, message, Avatar, Input } from "antd";
import Api from "../Api Wrapper/Api";
import { getUserPosts } from "../js/actions/Posts/posts.actions";
import HomeProfile from "../CustomComponents/HomeProfile";
import { BASE_URL_IMAGE } from "../constants/URL";

const { Search } = Input;
function Followers(props) {
  useEffect(() => {
    LoadFollowers();
  }, []);
  const [followers, setFollowers] = useState();
  const imgPath = process.env.PUBLIC_URL + "/images/";
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState();
  const [click, setClick] = useState(false);

  const LoadFollowers = async () => {
    await Api.get(`/post/findPosts?username=${props.routeParams.name}`).then(
      (res) => {
        if (res.status === 200) {
          setPageData(res.data);
          setFollowers(res.data.user_followed_by);
        }
      }
    );
  };

  const unFollow = (fid) => {
    setLoading(true);
    Api.post(`follower/remove?uid=${props.user.userID}&follower=${fid}`).then(
      (res) => {
        if (res.status === 200) {
          setLoading(false);
          window.location.reload();
        }
      }
    );
  };

  const Followers = ({ name, noFollowers, id, username, profilePicture }) => (
    <div className="d-flex flex-row justify-content-between align-items-center center-block width-90 pb-3">
      <div style={{ cursor: "pointer" }} className="d-flex flex-row mt-2 mb-2">
        {profilePicture ? (
          <img
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (props.user.username !== username) {
                props.router.push({
                  pathname: `/profile/photos/${username}`,
                });
              }
            }}
            className="activity_image"
            alt=""
            src={BASE_URL_IMAGE + profilePicture}
          />
        ) : (
          <Avatar
            onClick={() => {
              if (props.user.username !== username) {
                props.router.push({
                  pathname: `/profile/photos/${username}`,
                });
              }
            }}
            className="activity_image"
            size={50}
          >
            {username.charAt(0)}
          </Avatar>
        )}

        <div className="activity_innerConatiner">
          <p className="m-0">{name}</p>
          <p className="dark_text m-0">{noFollowers} Followers</p>
        </div>
      </div>
      <div>
        {!props.isVisitor && (
          <button onClick={() => unFollow(id)} className="follow_button">
            Remove
          </button>
        )}
      </div>
    </div>
  );
  function onSearch(value) {
    setFollowers(
      followers.filter((ele) => ele.display_name.search(value) > -1)
    );
  }
  return (
    <Row className="m-5">
      <Col span={6}>
        <HomeProfile
          userType={props.userProfile ? props.userProfile.userType : ""}
          name={pageData ? pageData.username : ""}
          displayName={pageData ? pageData.display_name : ""}
          views={pageData ? pageData.profileViews : 0}
          photos={pageData ? pageData.Posts.length + pageData.Tours.length : 0}
          followers={pageData ? pageData.user_followed_by.length : 0}
          following={pageData ? pageData.user_followed.length : 0}
          setClick={setClick}
          router={props.router}
          image={pageData ? pageData.profilePicture : ""}
          isVisitor={props.isVisitor}
          userID={props.userProfile ? props.userProfile.id : ""}
        />
      </Col>
      <Col span={12}>
        <div>
          <>
            <div className="d-flex flex-row justify-content-between align-items-center">
              <Texty
                type="left"
                mode={"smooth"}
                style={{ fontSize: 30, fontWeight: "bold", marginTop: 30 }}
              >
                YOUR FOLLOWERS
              </Texty>
              <div style={{ marginTop: 30 }}>
                <Search
                  placeholder="search followers"
                  onSearch={onSearch}
                  style={{ width: 250 }}
                  loading
                />
              </div>
            </div>
            {!followers ? (
              <Spin
                style={{
                  width: "100%",
                  padding: 50,
                }}
              />
            ) : (
              <QueueAnim className="queue-simple">
                {followers.length > 0 ? (
                  followers.map((item, index) => {
                    return (
                      <Followers
                        key={item.id}
                        id={item.id}
                        name={item.display_name}
                        username={item.username}
                        noFollowers={item.user_followed_by.length}
                        profilePicture={item.profilePicture}
                      />
                    );
                  })
                ) : (
                  <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{
                      height: 60,
                    }}
                    description={<span>You have 0 followers</span>}
                  ></Empty>
                )}
              </QueueAnim>
            )}
          </>
        </div>
      </Col>
    </Row>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    isVisitor: state.posts.isVisitor,
    userProfile: state.auth.userProfile,
  };
};
const mapDispatchToProps = {
  getUserPosts,
};
export default connect(mapStateToProps, mapDispatchToProps)(Followers);
