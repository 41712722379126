import React, { useState } from "react";
import { BgColorsOutlined } from "@ant-design/icons";
import { InputNumber } from "antd";
import { ChromePicker } from "react-color";
import { connect } from "react-redux";
import { setLabel } from "../js/actions/textEditor/editor";

function TextEditor(props) {
  const [isBold, setIsBold] = useState(false);
  const [fontSize, setFontSize] = useState(16);
  const [pickBackColor, setPickBackColor] = useState(false);
  const [pickTextColor, setPickTextColor] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("white");
  const [textColor, setTextColor] = useState("black");
  const [label, setLabel] = useState("label");

  const createLabelHTML = (
    value = label,
    backgroundColor = backgroundColor,
    textColor = textColor,
    fontSize = fontSize,
    isBold = isBold
  ) => {
    var style = `background-color:${backgroundColor}; color:${textColor}; font-size:${fontSize}px; font-weight:${
      isBold ? "bold" : "normal"
    } ;`;
    props.setLabel({
      style: style,
      label: value,
    });
    if (!props.isText) {
      props.changeLabel(value, style);
    }
  };

  return (
    <div
      style={{ backgroundColor: "#1d1d1d", borderRadius: 20, marginBottom: 20 }}
    >
      <div
        style={{
          backgroundColor: "black",
          borderTopRightRadius: 20,
          borderTopLeftRadius: 20,
          padding: 5,
          display: "flex",
          flexDirection: "row",
        }}
        className="align-items-center justify-content-around"
      >
        {/* <span style={{ color: "white", fontSize: 15, cursor: "pointer" }}>
          T
        </span> */}
        <span
          style={{
            color: "white",
            fontSize: 15,
            cursor: "pointer",
            padding: 8,
            backgroundColor: pickTextColor ? "#1d1d1d" : "black",
          }}
        >
          <span
            style={{ color: textColor === "black" ? "white" : textColor }}
            onClick={() => setPickTextColor(!pickTextColor)}
          >
            A
          </span>
          {pickTextColor ? (
            <div style={{ position: "absolute", zIndex: 2 }}>
              <div
                style={{
                  width: "250px",
                  height: "250px",
                  position: "fixed",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                }}
              >
                <ChromePicker
                  disableAlpha={true}
                  color={textColor}
                  onChangeComplete={(color) => {
                    setTextColor(color.hex);
                    createLabelHTML(
                      label,
                      backgroundColor,
                      color.hex,
                      fontSize,
                      isBold
                    );
                  }}
                />
              </div>
            </div>
          ) : undefined}
        </span>
        <span
          style={{
            color: "white",
            fontSize: 15,
            cursor: "pointer",
            padding: 8,
            backgroundColor: pickBackColor ? "#1d1d1d" : "black",
          }}
        >
          <BgColorsOutlined
            onClick={() => setPickBackColor(!pickBackColor)}
            style={{ fontSize: 16, cursor: "pointer", color: backgroundColor }}
          />
          {pickBackColor ? (
            <div style={{ position: "absolute", zIndex: 2 }}>
              <div
                style={{
                  width: "250px",
                  height: "250px",
                  position: "fixed",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                }}
              >
                <ChromePicker
                  disableAlpha={true}
                  color={backgroundColor}
                  onChangeComplete={(color) => {
                    setBackgroundColor(color.hex);
                    createLabelHTML(
                      label,
                      color.hex,
                      textColor,
                      fontSize,
                      isBold
                    );
                  }}
                />
              </div>
            </div>
          ) : undefined}
        </span>
        <span
          onClick={() => {
            setIsBold(!isBold);
            createLabelHTML(
              label,
              backgroundColor,
              textColor,
              fontSize,
              !isBold
            );
          }}
          style={{
            color: "white",
            fontSize: 15,
            cursor: "pointer",
            padding: 8,
            backgroundColor: isBold ? "#1d1d1d" : "black",
          }}
        >
          B
        </span>

        <span style={{ color: "white", fontSize: 15 }}>
          <InputNumber
            value={fontSize}
            onChange={(e) => {
              setFontSize(e);
              createLabelHTML(label, backgroundColor, textColor, e, isBold);
            }}
            style={{
              //   width: 60,
              backgroundColor: "#1d1d1d",
              border: "none",
              color: "white",
              marginRight: 5,
            }}
          />
          px
        </span>
      </div>
      <div>
        <textarea
          defaultValue={props.defaultLabel}
          style={{
            backgroundColor: "#757575",
            borderBottomLeftRadius: 20,
            borderBottomRightRadius: 20,
            padding: 10,
            width: "100%",
            color: "white",
            border: "1px solid #757575",
            borderTop: "none",
            outline: "none",
            fontSize: 16,
            fontWeight: isBold ? "bold" : "normal",
          }}
          onChange={(e) => {
            setLabel(e.target.value);
            createLabelHTML(
              e.target.value,
              backgroundColor,
              textColor,
              fontSize,
              isBold
            );
          }}
        />
      </div>
    </div>
  );
}
const mapDispatchToProps = {
  setLabel,
};

export default connect(null, mapDispatchToProps)(TextEditor);
