import React, { useState } from "react";
import { Row, Col, Button, Input, Radio } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import CreditCardInput from "react-credit-card-input";
import "./index.scss";

export default function DeliveryDetails(props) {
  const [paymentMethod, setPaymentMethod] = useState("cod");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [deliveryService, setDeliveryService] = useState("normal_mail");
  const [cardNumber, setCardNumber] = useState("");
  const [cvc, setCVC] = useState("");
  const [expiry, setExpiry] = useState("");
  const calculateDeliveryCost = () => {
    switch (deliveryService) {
      case "normal_mail":
        return 12;
        break;
      case "DHL":
        return 15;
        break;
      case "UPS":
        return 20;
        break;
      case "Fedex":
        return 25;
        break;
      default:
        return 12;
        break;
    }
  };
  const calculateTotal = () => {
    let total = 0;
    props.cart.map((item) => {
      if (item.product_details) total = total + item.quantity * item.price;
    });
    return total;
  };
  const { cart } = props;
  let total = 0;

  //   {
  //     "product_id":1029,
  //     "shipping_address":"22 B street",
  //     "return_address":"22 B street",
  //     "quantity":3,
  //     "price_per_unit":247,
  //     "coupon":"",
  //     "return_lat":23,
  //     "return_lng":12,
  //     "dispatch_from_address":"xyz B street",
  //     "package_weight":22,
  //     "package_weight_unit":"gram",
  //     "package_dimensions":{
  //        "width":32,
  //        "height":23,
  //        "unit":"centimeter"
  //     },
  //     "shipping_service_title":"normal_mail",
  //     "shipping_cost":12,
  //     "currency_short_code":"USD"
  //  },
  return (
    <div className="delivery-container cart-border">
      <label>Address: </label>
      <Input
        style={{ height: window.screen.width > 768 ? undefined : "100px" }}
        onChange={(e) => {
          setDeliveryAddress(e.target.value);
        }}
        placeholder="Enter your complete address ... "
      />

      <label className="mt-3 mb-2">Payment Method: </label>
      <br />
      <Radio.Group
        onChange={(event) => {
          setPaymentMethod(event.target.value);
        }}
        defaultValue="cod"
      >
        <Radio.Button value="cod">Cash on Delivery</Radio.Button>
        <Radio.Button
          style={{ marginTop: window.screen.width > 768 ? undefined : "10px" }}
          value="credit"
        >
          Credit / Debit Card
        </Radio.Button>
      </Radio.Group>
      {paymentMethod === "credit" ? (
        <div className="mt-3">
          <CreditCardInput
            cardNumberInputProps={{
              value: cardNumber,
              onChange: (value) => setCardNumber(value.target.value),
            }}
            cardExpiryInputProps={{
              value: expiry,
              onChange: (value) => setExpiry(value.target.value),
            }}
            cardCVCInputProps={{
              value: cvc,
              onChange: (value) => setCVC(value.target.value),
            }}
          />
        </div>
      ) : undefined}
      <br />
      <label className="mb-2 mt-2">Delivery Service: </label>
      <br />
      <Radio.Group
        onChange={(event) => {
          setDeliveryService(event.target.value);
        }}
        defaultValue="normal_mail"
      >
        <Radio.Button value="normal_mail">Mail</Radio.Button>
        <Radio.Button
          style={{
            marginLeft: window.screen.width > 768 ? undefined : "10px",
          }}
          value="DHL"
        >
          DHL
        </Radio.Button>
        <Radio.Button
          style={{
            marginLeft: window.screen.width > 768 ? undefined : "10px",
          }}
          value="Fedex"
        >
          Fedex
        </Radio.Button>
        <Radio.Button
          style={{
            marginTop: window.screen.width > 768 ? undefined : "10px",
          }}
          value="UPS"
        >
          UPS
        </Radio.Button>
      </Radio.Group>
      <br />
      <label className="mt-3 font-weight-bold">
        Shipping Cost:
        <label className="font-weight-light">
          &emsp;{calculateDeliveryCost()} $
        </label>
      </label>
      <Button
        className="d-flex justify-content-center align-self-center align-items-center mt-2"
        style={{ width: "100%" }}
        type="primary"
        onClick={() => {
          props.setShippingCost(calculateDeliveryCost());
          props.setPaymentMethod(paymentMethod);
          props.setDeliveryAddress(deliveryAddress);
          props.nextStep();
        }}
      >
        Proceed To Confirmation
      </Button>
    </div>
  );
}
