import React, { useState } from "react";
import "../Screens/EditProfile/index.scss";
import PaymentCard from "../CustomComponents/PaymentCard";
import { connect } from "react-redux";
import { CreditCardOutlined } from "@ant-design/icons";
import apiClient from "../Api Wrapper/Api";
import { message, notification, Select, Spin } from "antd";
import { updateUser } from "../js/actions/auth/updateUser";

function ProfileUpgraded(props) {
  const [name, setName] = useState("Muhammad Ahsan");
  const [cardNum, setCardNum] = useState("4242424242424242");
  const [expDateMonth, setExpDateMonth] = useState(2);
  const [expDateYear, setExpDateYear] = useState(2022);
  const [cvc, setCvc] = useState(314);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectPayment, setSelectPayment] = useState([
    {
      type: "PRO",
      duration: "year",
      value: "144",
      selected: false,
    },
    {
      type: "Business",
      duration: "year",
      value: "432",
      selected: false,
    },
    {
      type: "PRO",
      duration: "month",
      value: "16",
      selected: false,
    },
    {
      type: "Business",
      duration: "month",
      value: "48",
      selected: false,
    },
  ]);

  const PaymentSelection = (t) => {
    var tempArr = [...selectPayment];
    tempArr.map((item) => {
      if (item.selected) {
        item.selected = false;
      }
    });
    tempArr[t].selected = true;
    setSelectPayment(tempArr);
  };

  const handleClick = () => {
    setLoading(true);
    const selectedPlan = selectPayment.filter(
      (item) => item.selected === true
    )[0];
    if (selectedPlan) {
      apiClient
        .post(`users/card/token`, {
          number: cardNum,
          exp_month: expDateMonth,
          exp_year: expDateYear,
          cvc: cvc,
        })
        .then((response) => {
          if (response && response.status === 200) {
            apiClient
              .post(`users/card/charges/deduct`, {
                amount: selectedPlan.value,
                token_id: response.data,
              })
              .then((tokenResponse) => {
                if (tokenResponse && tokenResponse.status === 200) {
                  apiClient
                    .post(`/payment/add?uid=${props.user.userID}`, {
                      planType: selectedPlan.type,
                      planDuration: selectedPlan.duration,
                      userType: selectedPlan.type,
                      charge_token: tokenResponse.data.id,
                      amount: selectedPlan.value,
                      type: "account",
                    })
                    .then((response) => {
                      setLoading(false);
                      notification.success({
                        message:
                          "Congratulations! Now you are a " +
                          selectedPlan.type +
                          " user, we welcome you to our " +
                          selectedPlan.type +
                          " family.",
                      });
                      props.updateUser(response.data);
                      props.router.push("/home");
                    })
                    .catch((error) => {
                      setLoading(false);
                      message.error(
                        "We were unable to complete the transaction, please try again later."
                      );
                    });
                } else {
                  setLoading(false);
                  message.error(
                    "We were unable to complete the transaction, please try again later."
                  );
                }
              })
              .catch((error) => {
                setLoading(false);
                message.error(
                  "We were unable to complete the transaction, please try again later."
                );
              });
          } else {
            setLoading(false);
            message.error(
              "We were unable to complete the transaction, please check your credit card details."
            );
          }
        })
        .catch((error) => setLoading(false));
    } else {
      setLoading(false);
      message.error(
        "Please select a plan on which you would like to subscribe to?"
      );
    }
  };
  return (
    <div className="mt-5 sm-widht-90 sm-align-center">
      <div>
        <h2 className="text-center">Upgrade to Pro or Business</h2>
        <p className="text-center subheadtext">
          We accept Credit Card payments for all subscriptions and Paypal
          Payments
        </p>
      </div>
      <div>
        <p className="heading">Credit card payment</p>
        <p className="subheadtext">Select Plan:</p>
        <div className="container">
          <div className="row m-0">
            <div className="col-sm">
              <div onClick={() => PaymentSelection(0)}>
                <PaymentCard
                  type="PRO"
                  duration="year"
                  value="144"
                  installments="12"
                  selected={selectPayment[0].selected}
                />
              </div>
              <div onClick={() => PaymentSelection(1)}>
                <PaymentCard
                  type="Business"
                  duration="year"
                  value="432"
                  installments="36"
                  selected={selectPayment[1].selected}
                />
              </div>
            </div>
            <div className="col-sm">
              <div onClick={() => PaymentSelection(2)}>
                <PaymentCard
                  selected={selectPayment[2].selected}
                  type="PRO"
                  duration="month"
                  value="16"
                />
              </div>
              <div onClick={() => PaymentSelection(3)}>
                <PaymentCard
                  selected={selectPayment[3].selected}
                  type="Business"
                  duration="month"
                  value="48"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3 sm-widht-90">
        <p className="heading">Credit Card / Debit Car Info:</p>
        <form>
          <div className="form-group">
            <label className="subheadtext" htmlFor="exampleInputEmail1">
              Name on Card:
            </label>
            <input
              value={name}
              type="text"
              className="form-control payment-form"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder=""
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label className="subheadtext" htmlFor="exampleInputEmail1">
              Card Number:
            </label>
            <input
              value={cardNum}
              style={{ fontStyle: "italic" }}
              type="text"
              maxLength={12}
              className="form-control payment-form helptext"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="ex 1234 4567 8912 3456"
              onChange={(e) => setCardNum(e.target.value)}
            />
          </div>
          {/* justify-content-between */}
          <div className="d-flex flex-row align-items-center sm-widht-100">
            <div className="form-group">
              <label
                className="subheadtext"
                htmlFor="exampleFormControlSelect1"
              >
                Expiration Date:
              </label>
              <br />
              <Select
                className="sm-widht-200-px lg-widht-100"
                value={expDateMonth}
                placeholder="Select Month"
              >
                <Select.Option value={1}>Januray</Select.Option>
                <Select.Option value={2}>February</Select.Option>
                <Select.Option value={3}>March</Select.Option>
                <Select.Option value={4}>April</Select.Option>
                <Select.Option value={5}>May</Select.Option>
                <Select.Option value={6}>June</Select.Option>
                <Select.Option value={7}>July</Select.Option>
                <Select.Option value={8}>August</Select.Option>
                <Select.Option value={9}>September</Select.Option>
                <Select.Option value={10}>October</Select.Option>
                <Select.Option value={11}>November</Select.Option>
                <Select.Option value={12}>December</Select.Option>
              </Select>
            </div>
            <div className="form-group mt-4 pl-3">
              <Select
                className="sm-widht-200-px lg-widht-100"
                value={expDateYear}
                placeholder="Select Year"
              >
                <Select.Option value={2021}>2021</Select.Option>
                <Select.Option value={2022}>2022</Select.Option>
                <Select.Option value={2023}>2023</Select.Option>
                <Select.Option value={2024}>2024</Select.Option>
                <Select.Option value={2025}>2025</Select.Option>
                <Select.Option value={2026}>2026</Select.Option>
                <Select.Option value={2027}>2027</Select.Option>
                <Select.Option value={2028}>2028</Select.Option>
                <Select.Option value={2029}>2029</Select.Option>
                <Select.Option value={2030}>2030</Select.Option>
              </Select>
            </div>
            <div className="form-group ml-5">
              <label className="subheadtext" htmlFor="exampleInputEmail1">
                CVC:
              </label>
              <span className="display-row">
                <input
                  value={cvc}
                  style={{ fontStyle: "italic" }}
                  type="text"
                  maxLength={3}
                  className="form-control cvc-form helptext"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="000"
                  onChange={(e) => setCvc(e.target.value)}
                />
                <CreditCardOutlined
                  className="ml-2 lg-display-none"
                  style={{
                    fontSize: 35,
                    color: "rgb(202, 202, 202)",
                  }}
                />
              </span>
            </div>
            <div className="ml-2 mt-2 sm-display-none">
              <CreditCardOutlined
                style={{
                  fontSize: 35,
                  marginTop: 3,
                  color: "rgb(202, 202, 202)",
                }}
              />
            </div>
          </div>
        </form>
      </div>
      {showSuccessMessage && (
        <div style={{ color: "green" }}>
          We've successfully added your Card Info !
        </div>
      )}
      <button
        style={{ marginBottom: "10px" }}
        type="submit"
        disabled={loading}
        className="btn btn-primary center-block mt-4 lg-widht-100 sm-widht-260"
        onClick={handleClick}
      >
        {loading && (
          <Spin style={{ color: "#fff", width: "100%", pading: 50 }} />
        )}
        &ensp; Buy Subscription With Card
      </button>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = {
  updateUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileUpgraded);
