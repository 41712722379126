import React, { useEffect, useState } from "react";
import moment from "moment";
import { Empty, Spin, Button, message } from "antd";
import Home from "../Home";
import "./index.scss";
import Post from "../../CustomComponents/Post";
import { connect } from "react-redux";
import apiClient from "../../Api Wrapper/Api";
import {
  getUserPosts,
  setIsVisitor,
} from "../../js/actions/Posts/posts.actions";
import { BASE_URL_WEBSITE } from "../../constants/URL";
import Slide from "react-reveal/Slide";
import { token } from "../../LocalForage/localForage";

function index(props) {
  const [timelinePost, setTimelinePost] = useState([]);
  const [loading, setLoading] = useState(true);

  const LoadTimeLine = async () => {
    return apiClient
      .get(`post/findFollowersPosts`, {
        headers: { token: await token.getItem("@token") },
      })
      .then((response) => {
        if (response && response.data) {
          if (response.status === 200) {
            setTimelinePost(response.data);
            setLoading(false);
          } else {
            message.error("something went wrong");
            setTimelinePost([]);
            setLoading(false);
          }
        }
      });
  };

  useEffect(() => {
    LoadTimeLine();
    props.getUserPosts(props.user.username);
    props.setIsVisitor(false);
  }, []);

  return (
    <Home {...props} {...{ timeline: true }}>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {loading ? (
          <Spin
            style={{
              width: "100%",
              padding: 50,
            }}
          />
        ) : timelinePost.length > 0 ? (
          timelinePost.map((post) => {
            return (
              <div className="lg-post-w sm-post-w mt-2">
                <Slide top>
                  <Post
                    key={post.Posts.id}
                    postID={post.Posts.id}
                    image={
                      post.Posts && post.Posts.Tour !== null
                        ? post.Posts.Tour.Tour_images[0].image
                        : post.Posts.Image.image
                    }
                    isPurchaseable={
                      post.Posts.Tour
                        ? post.Posts.Tour.isPurchaseable
                        : post.Posts.Image.isPurchaseable
                    }
                    userID={props.user.userID}
                    postUserID={post.Posts.uid}
                    commentUser={props.user.displayName}
                    userName={post.display_name}
                    handleClick={() =>
                      post.Posts.Tour !== null
                        ? props.router.push({
                            pathname: `/tour-view/tid-${post.Posts.id}/${post.Posts.uid}`,
                          })
                        : props.router.push({
                            pathname: `/post-view/id-${post.Posts.id}`,
                          })
                    }
                    shareUrl={
                      post.Posts.Tour !== null
                        ? BASE_URL_WEBSITE +
                          `/tour-view/tid-${post.Posts.id}/${props.user.userID}`
                        : BASE_URL_WEBSITE + `/post-view/id-${post.Posts.id}`
                    }
                    profile={post.profilePicture}
                    postName={post.Posts.name}
                    numberOfComments={post.Posts.Image.Comments.length}
                    Comments={post.Posts.Image.Comments}
                    numberOfLikes={post.Posts.Image.Likes.length}
                    Likes={post.Posts.Image.Likes}
                    numberOfView={post.Posts.Image.Views.length}
                    time={moment(post.Posts.createdAt).fromNow()}
                    id={post.Posts.Image.id}
                    isTour={post.Posts.Tour === null ? false : true}
                    isFeatured={post.Posts.featuredTill === "0" ? false : true}
                    isLiked={
                      post.Posts.Tour === null
                        ? post.Posts.Image.isLiked
                        : post.Posts.Tour.Tour_images[
                            post.Posts.Tour.Tour_images.length - 1
                          ].isLiked
                    }
                    LikedUserProfPic={true}
                  />
                </Slide>
              </div>
            );
          })
        ) : (
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 60,
            }}
            description={<span>No posts to show.</span>}
          >
            <Button
              type="primary"
              onClick={() => props.router.push("/explore/photos")}
            >
              Explore Posts
            </Button>
          </Empty>
        )}
      </div>
    </Home>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    isVisitor: state.posts.isVisitor,
  };
};
const mapDispatchToProps = {
  getUserPosts,
  setIsVisitor,
};
export default connect(mapStateToProps, mapDispatchToProps)(index);
