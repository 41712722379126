import React from "react";
import { connect } from "react-redux";
import { Vector3 } from "three";
import { applyFilters } from "../../Helpers/filters";
import { Row, Col, Button, Spin, message, Carousel, Result } from "antd";
import "./index.scss";
import apiClient from "../../Api Wrapper/Api";
import ProductApi from "../../Api Wrapper/ProductApi";
import { BASE_URL_IMAGE } from "../../constants/URL";
import Viewer from "../../Panolen/viewer/Viewer";
import ImagePanorama from "../../Panolen/panorama/ImagePanorama";
import VideoPanorama from "../../Panolen/panorama/VideoPanorama";
import productImage from "../../Images/product.jpg";
import productImage1 from "../../Images/product1.jpg";
import Infospot from "../../Panolen/infospot/Infospot";
import Modal from "antd/lib/modal/Modal";
import LittlePlanet from "../../Panolen/panorama/ImageLittlePlanet";
import { CONTROLS, MODES } from "../../Panolen/Constants";
import { token } from "../../LocalForage/localForage";

class TourView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      value: "",
      submitting: false,
      post: "",
      current: "",
      loading: true,
      isLiked: false,
      likesCounter: 0,
      isShareModal: false,
      viewsCounter: 0,
      isFollowed: false,
      isTour: false,
      isFeatured: false,
      loadingPanaroma: true,
      allScenes: [],
      mode: "photo",
      currentImageIndex: 0,
      postID: 0,
      currentMode: MODES.NORMAL,
      currentControl: CONTROLS.ORBIT,
      narratorAudioLevel: 1,
      backgroundMusicLevel: 1,
      showProductModal: false,
      productDescription: "",
      productName: "",
      productPrice: 0,
      productQuantity: 0,
      isCollapse: true,

      isAuthorized: false,
    };
  }
  componentDidMount() {
    var str = this.props.router.params.id;
    var st = str.split("-", 2);
    this.setState({ postID: st[1] });
    this.requestNewPostData(st[1], this.props.router.params.uid);
  }

  componentWillUnmount() {
    this.resetAudio();
  }

  requestNewPostData = async (id, uid) => {
    // this.setState({ isLoading: true });
    await apiClient
      .get(`/tour/findTour?post_id=${id}&uid=${uid}`)
      .then(async (response) => {
        if (response && response.status === 200) {
          const Post = response.data;
          // const firstTour = Post.Tour.Tour_images[0];
          // this.playPostAudios(firstTour.audio_one, firstTour.audio_two);
          const t = await token.getItem("@token");
          apiClient.setHeaders({ token: t });
          apiClient
            .get(`/purchase/tour/user/buy?tour_id=${Post.Tour.id}`)
            .then((res) => {
              if (res.status === 404 || res.status === 401) {
                this.setState({ isAuthorized: false, loading: false });
                message.error("You are not authorized to view this Tour.");
              } else {
                this.setState({
                  isAuthorized: true,
                  post: Post,
                  likesCounter: Post.Tour.Tour_images[0].Likes.length,
                  viewsCounter: Post.Tour.Tour_images[0].Views.length,
                  isFollowed: Post.isFollowed,
                  isLiked: Post.isLiked,
                  comments: Post.Tour.Tour_images[0].Comments,
                  loading: false,
                });
                if (this.state.firstDone) {
                  this.render2ndPanaromas(response.data);
                } else {
                  this.renderPanaroma(response.data);
                }
              }
            });
        } else {
          message.error(
            "There was an error fetching the post, please refresh or contact our administrator."
          );
        }
      });
  };

  resetAudio = () => {
    if (this.audioPlayer) {
      this.audioPlayer.pause();
      this.audioPlayer.removeEventListener("ended", () =>
        this.setState({
          playingAudio: false,
        })
      );
    }
    if (this.narratorAudioPlayer) {
      this.narratorAudioPlayer.pause();
      this.narratorAudioPlayer.removeEventListener("ended", () =>
        this.setState({
          playingNarratorAudio: false,
        })
      );
    }
  };

  playPostAudios = (audio_one, audio_two) => {
    this.resetAudio();
    if (audio_one) {
      const audio = new Audio(BASE_URL_IMAGE + audio_one);
      this.audioPlayer = audio;
      audio.play();
      audio.addEventListener("ended", () => this.setState({ play: false }));
      this.setState({
        playingAudio: true,
        narratorAudioLevel: 1,
        backgroundMusicLevel: 1,
      });
    }
    if (audio_two) {
      const audio = new Audio(BASE_URL_IMAGE + audio_two);
      this.narratorAudioPlayer = audio;
      audio.play();
      audio.addEventListener("ended", () => this.setState({ play: false }));
      this.setState({
        playingNarratorAudio: true,
        narratorAudioLevel: 1,
        backgroundMusicLevel: 1,
      });
    }
  };

  shiftingPanorama = (post, item, infoSpot) => {
    console.log(item, post, infoSpot);
    infoSpot.unlockHoverElement();
    const index = post.Tour.Tour_images.findIndex((x) => x.id === item);
    let currentPost = post.Tour.Tour_images[index];
    this.playPostAudios(currentPost.audio_one, currentPost.audio_two);
    this.setState({ currentImageIndex: index });
    this.myViewer.setPanorama(this.myPanaroma[index]);
  };

  renderPanaroma = (post) => {
    const viewer = new Viewer({
      container: this.panaromaref,
      autoHideInfospot: false,
      //   dampingFactor: 0.7,
      //   rotateSpeed: -5,
      //   momentum: true,
      cameraFov: 90,
      // controlBar: false,
      // controlButtons: [],
    });
    const panorama = [];
    const littlePlanet = [];
    for (var v = 0; v < post.Tour.Tour_images.length; v++) {
      const ext = post.Tour.Tour_images[v].image.split(".", 2);
      if (ext[1] === "png" || ext[1] === "jpeg" || ext[1] === "jpg") {
        panorama[v] = new ImagePanorama(
          BASE_URL_IMAGE + post.Tour.Tour_images[v].image
        );
        // littlePlanet[v] = new LittlePlanet(
        //   BASE_URL_IMAGE + post.Tour.Tour_images[v].image
        // );
      } else if (
        ext[1] === "mp4" ||
        ext[1] === "mkv" ||
        ext[1] === "gif" ||
        ext[1] === "webm"
      ) {
        panorama[v] = new VideoPanorama(
          BASE_URL_IMAGE + post.Tour.Tour_images[v].image,
          { autoplay: true }
        );
      }

      post.Tour.Tour_images[v].hotspot_image.map((item) => {
        let infoSpot = new Infospot(
          item.ImageHotspot.scale * 5,
          BASE_URL_IMAGE + item.ImageHotspot.image
        );
        infoSpot.position.set(
          item.ImageHotspot.x,
          item.ImageHotspot.y,
          item.ImageHotspot.z
        );
        infoSpot.material.rotation =
          (Math.PI / 180) * item.ImageHotspot.rotation;

        infoSpot.addHoverText(item.ImageHotspot.label);
        if (item.ImageHotspot.audio) {
          infoSpot.addEventListener("click", (e) => {
            let audio = new Audio(BASE_URL_IMAGE + item.ImageHotspot.audio);
            audio.play();
          });
        }
        if (item.ImageHotspot.action === "No action") {
          // Show hover label only
        } else if (item.ImageHotspot.action === "Go to another post") {
          //   let newScene = new ImagePanorama(BASE_URL_IMAGE + item.image);
          //   viewer.add(newScene);
          infoSpot.addEventListener("click", (e) =>
            this.shiftingPanorama(
              post,
              item.ImageHotspot.hotspot_with,
              infoSpot
            )
          );
        } else if (item.ImageHotspot.action === "Open URL") {
          // Open the url for the user
          infoSpot.addEventListener("click", (e) => {
            window.open(item.ImageHotspot.url, "_blank");
          });
        } else if (item.ImageHotspot.action === "Open Card") {
          infoSpot.addEventListener("click", (e) => {
            this.setState({
              activeHotspot: item.ImageHotspot,
              showModal: true,
            });
          });
        } else if (item.ImageHotspot.action === "Sell Product") {
          ProductApi.post(
            `http://therentalsapp.com/rentmoebackend/api/general/product-details?product_id=${item.ImageHotspot.product_id}`
          )
            .then((res) => {
              this.setState({
                productDescription: res.data.data.description,
                productName: res.data.data.name,
                productPrice: res.data.data.price[0].price,
                productQuantity: res.data.data.quantity,
              });
              console.log(res.data.data.price[0].price);
            })
            .catch((err) => {
              console.log(err);
            });
          infoSpot.addEventListener("click", (e) => {
            this.setState({ activeHotspot: item, showProductModal: true });
          });
        }
        panorama[v].add(infoSpot);
      });
      viewer.add(panorama[v]);
      // viewer.add(littlePlanet[v]);
      // panorama[v].addEventListener("enter-fade-start", function () {
      viewer.tweenControlCenter(
        new Vector3(
          post.Tour.Tour_images[v].initialViewX,
          post.Tour.Tour_images[v].initialViewY,
          post.Tour.Tour_images[v].initialViewZ
        ),
        0
      );
      // });
    }

    this.myViewer = viewer;
    // const littlePlanet = new LittlePlanet(BASE_URL_IMAGE + post.Image.image);
    // viewer.add(littlePlanet);

    this.myPanaroma = panorama;
    this.myViewer.setPanorama(this.myPanaroma[0]);
    this.myLittlePlanet = littlePlanet;

    this.setState({ loadingPanaroma: false });
  };

  render() {
    const { post, loading, loadingPanaroma } = this.state;
    return (
      <div>
        {loading ? (
          <Spin
            style={{
              width: "100%",
              padding: 50,
            }}
          />
        ) : !this.state.isAuthorized ? (
          <Result
            status="403"
            title="403"
            subTitle="Sorry, you are not authorized to access this page."
            extra={
              <Button
                onClick={() => this.props.router.push("/")}
                type="primary"
              >
                Back Home
              </Button>
            }
          />
        ) : (
          <div className="row m-0 p-0">
            <React.Fragment>
              <div
                style={applyFilters(
                  post.Tour.Tour_images[this.state.currentImageIndex]
                    .filterName,
                  post.Tour.Tour_images[this.state.currentImageIndex].intensity
                )}
                className="col-md-9 m-0 p-0 panoroma-container"
              >
                {loadingPanaroma && (
                  <Spin
                    style={{
                      width: "100%",
                      padding: 50,
                    }}
                  />
                )}
                <div
                  style={{
                    height: window.innerHeight,
                    width: window.innerWidth,
                  }}
                  id="myPanoramaTourView"
                  ref={(ref) => (this.panaromaref = ref)}
                />
                {/* <div className="m-0 slider-container-tour display-none">
                {post.Tour.Tour_images.map((item, index) => {
                  const ext = item.image.split(".", 2);
                  return ext[1] === "png" ||
                    ext[1] === "jpeg" ||
                    ext[1] === "jpg" ? (
                    <img
                      key={item.id}
                      onClick={() => {
                        this.setState({
                          currentImageIndex: index,
                          isActive: "photo",
                        });
                        this.resetAudio();
                        this.playPostAudios(
                          post.Tour.Tour_images[index].audio_one,
                          post.Tour.Tour_images[index].audio_two
                        );
                        this.myViewer.setPanorama(this.myPanaroma[index]);
                      }}
                      className={
                        this.state.currentImageIndex === index
                          ? "slider-images-selected"
                          : "slider-images"
                      }
                      data-content={this.state.description}
                      src={BASE_URL_IMAGE + item.image}
                    />
                  ) : (
                    <video
                      key={item.id}
                      onClick={() => {
                        this.setState({
                          currentImageIndex: index,
                          isActive: "photo",
                        });
                        this.myViewer.setPanorama(this.myPanaroma[index]);
                      }}
                      className={
                        this.state.currentImageIndex === index
                          ? "slider-images-selected"
                          : "slider-images"
                      }
                      data-content={this.state.description}
                      src={BASE_URL_IMAGE + item.image}
                    />
                  );
                })}
              </div> */}
              </div>
              {/* <div className="m-0 slider-container-tour mobile-view-slider">
              {post.Tour.Tour_images.map((item, index) => {
                const ext = item.image.split(".", 2);
                return ext[1] === "png" ||
                  ext[1] === "jpeg" ||
                  ext[1] === "jpg" ? (
                  <img
                    key={item.id}
                    onClick={() => {
                      this.setState({
                        currentImageIndex: index,
                        isActive: "photo",
                      });
                      this.resetAudio();
                      this.playPostAudios(
                        post.Tour.Tour_images[index].audio_one,
                        post.Tour.Tour_images[index].audio_two
                      );
                      this.myViewer.setPanorama(this.myPanaroma[index]);
                    }}
                    className={
                      this.state.currentImageIndex === index
                        ? "slider-images-selected"
                        : "slider-images"
                    }
                    data-content={this.state.description}
                    src={BASE_URL_IMAGE + item.image}
                  />
                ) : (
                  <video
                    key={item.id}
                    onClick={() => {
                      this.setState({
                        currentImageIndex: index,
                        isActive: "photo",
                      });
                      this.myViewer.setPanorama(this.myPanaroma[index]);
                    }}
                    className={
                      this.state.currentImageIndex === index
                        ? "slider-images-selected"
                        : "slider-images"
                    }
                    data-content={this.state.description}
                    src={BASE_URL_IMAGE + item.image}
                  />
                );
              })}
            </div> */}
              {this.state.activeHotspot ? (
                <Modal
                  title="Card"
                  width="100%"
                  visible={this.state.showModal}
                  onCancel={() => this.setState({ showModal: false })}
                >
                  <iframe
                    src={this.state.activeHotspot.cardImageUrl}
                    height={"100%"}
                    width={"100%"}
                  />
                  <p>{this.state.activeHotspot.cardText}</p>
                  <a href={this.state.activeHotspot.cardUrl} target="_blank">
                    {this.state.activeHotspot.cardLinkLabel}
                  </a>
                </Modal>
              ) : undefined}
              {this.state.activeHotspot ? (
                <Modal
                  title="Product"
                  centered={true}
                  width="50%"
                  footer={null}
                  bodyStyle={{ height: 600 }}
                  visible={this.state.showProductModal}
                  onOk={() => this.setState({ showProductModal: false })}
                  onCancel={() => this.setState({ showProductModal: false })}
                >
                  <Row style={{ height: 500 }}>
                    <Col span={12}>
                      <Carousel
                        ref={(ref) => {
                          this.carosalRef = ref;
                        }}
                        dots={false}
                      >
                        <img
                          src={productImage}
                          style={{
                            height: 100,
                            width: 100,
                            borderRadius: 10,
                            marginRight: 5,
                            marginBottom: 5,
                          }}
                        />
                        <img
                          src={productImage1}
                          style={{
                            height: 100,
                            width: 100,
                            borderRadius: 10,
                            marginRight: 5,
                            marginBottom: 5,
                          }}
                        />
                      </Carousel>
                      <div className="d-flex flex-row justify-content-center mt-2">
                        <img
                          src={productImage}
                          className="slider-product"
                          onClick={() => this.carosalRef.goTo(0)}
                          style={{
                            height: 50,
                            width: 50,
                            borderRadius: 5,
                            marginRight: 5,
                            marginBottom: 5,
                          }}
                        />
                        <img
                          src={productImage1}
                          onClick={() => this.carosalRef.goTo(1)}
                          className="active-slider-product"
                          style={{
                            height: 50,
                            width: 50,
                            borderRadius: 5,
                            marginRight: 5,
                            marginBottom: 5,
                          }}
                        />
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="product-modal-side-menu">
                        <p
                          className="m-0"
                          style={{ fontWeight: "bold", fontSize: 25 }}
                        >
                          {this.state.productName}
                        </p>
                        <hr className="border-product" />
                        <p
                          className="m-0 mt-2"
                          style={{ fontWeight: "bold", fontSize: 18 }}
                        >
                          ${this.state.productPrice}.00
                        </p>
                        <p className="m-0 mt-2" style={{ fontWeight: "bold" }}>
                          Description:
                        </p>
                        <p className="m-0 w-75">
                          {this.state.productDescription}
                        </p>
                        <div className="d-flex flex-row mt-4 ">
                          <p
                            className="m-0 mr-2"
                            style={{ fontWeight: "bold" }}
                          >
                            Status:
                          </p>
                          <p>
                            {this.state.productQuantity > 0
                              ? " In Stock"
                              : " Out Of Stock"}
                          </p>
                          {/* <InputNumber
                            className="m-0 ml-2"
                            style={{ fontWeight: "bold" }}
                            value={1}
                          /> */}
                        </div>
                        <Button
                          onClick={() => {
                            if (this.state.productQuantity > 0) {
                              this.props.addToCart({
                                image: productImage,
                                name: this.state.productName,
                                Price: this.state.productPrice,
                                Quantity: 1,
                                AvailableQuantity: this.state.productQuantity,
                                Total: this.state.productPrice,
                              });
                              message.success("added to cart succesfully");
                            } else {
                              message.error("out of stock");
                            }
                          }}
                          style={{ width: "75%", marginTop: 10 }}
                        >
                          Add to Cart
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Modal>
              ) : undefined}
            </React.Fragment>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,

    filterName: state.editor.filterName,
    intensity: state.editor.intensity,
    pitchLimit: state.editor.pitchLimit,
    zoomLimit: state.editor.zoomLimit,
    privacy: state.editor.privacy,
    description: state.editor.description,
  };
};
export default connect(mapStateToProps)(TourView);
