import React, { useEffect, useState } from "react";
import "./index.scss";
import Texty from "rc-texty";
import apiClient from "../../Api Wrapper/Api";

import {
  LikeOutlined,
  CheckOutlined,
  CommentOutlined,
} from "@ant-design/icons";
import { Empty, message, Spin } from "antd";
import { token } from "../../LocalForage/localForage";

export default function index(props) {
  useEffect(async () => {
    setLoading(true);
    apiClient
      .get("/notification/get", {
        headers: { token: await token.getItem("@token") },
      })
      .then((res) => {
        if (res.status === 200) {
          setNotifications(res.data.reverse());
          setLoading(false);
        } else {
          message.error("something went wrong!");
        }
      })
      .catch((err) => {
        message.error("something went wrong!");
        console.log(err.err);
      });
  }, []);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);

  return (
    <div className="notifi-container">
      <Texty
        mode={"smooth"}
        style={{
          fontSize: 30,
          fontWeight: "bold",
          marginTop: 30,
          marginBottom: 30,
        }}
      >
        Notifications
      </Texty>
      {loading ? (
        <Spin className="m-5" />
      ) : notifications.length > 0 ? (
        notifications.map((item) => (
          <div
            key={item.id}
            className="notifications"
            onClick={() => {
              if (item.text.includes("followed")) {
                props.router.push(`/profile/photos/${item.username}`);
              }
              if (
                item.text.includes("liked") ||
                item.text.includes("commented")
              ) {
                if (item.isTour) {
                  props.router.push(
                    `/tour-view/tid-${item.post_id}/${item.uid}/`
                  );
                } else {
                  props.router.push(`/post-view/id-${item.post_id}`);
                }
              }
            }}
            style={{
              color: item.text.includes("liked")
                ? "#4a90e2"
                : item.text.includes("followed")
                ? "rgb(248, 137, 18)"
                : "rgb(129, 209, 53)",
            }}
          >
            {item.text}
            {item.text.includes("liked") ? (
              <LikeOutlined />
            ) : item.text.includes("followed") ? (
              <CheckOutlined />
            ) : (
              <CommentOutlined />
            )}
          </div>
        ))
      ) : (
        <Empty
          className="mb-5"
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 60,
          }}
          description={<span>No Notifications</span>}
        ></Empty>
      )}
    </div>
  );
}
