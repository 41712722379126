import React, { useState } from "react";
import { Modal } from "antd";
export default function ReadMore(props) {
  const [modalVisible, setModalVisible] = useState(props.isVisible);
  return (
    <div>
      <Modal
        title={props.Heading ? props.Heading : "Content"}
        centered={true}
        width="50%"
        footer={null}
        // bodyStyle={{ height: 500 }}
        visible={modalVisible}
        onOk={() => {
          setModalVisible(false);
          props.onClose && props.onClose(false);
        }}
        onCancel={() => {
          props.onClose && props.onClose(false);
          setModalVisible(false);
        }}
      >
        <div className="d-flex flex-row">
          <img
            style={{ width: 300, height: 300, borderRadius: 20 }}
            src={props.image}
          />
          <p style={{ marginLeft: 20, marginTop: 10 }}>{props.text}</p>
        </div>
      </Modal>
    </div>
  );
}
