import React from "react";
import "../Screens/EditProfile/index.scss";

export default function PaymentCard(props) {
  return (
    <div className={props.selected ? "card_container" : "dark_card_container"}>
      <div
        className={props.selected ? "inner_container" : "dark_inner_container"}
      >
        <p
          className="text-center pt-2 font-weight-bold"
          style={{ color: "white" }}
        >
          {props.type}
          {props.duration === "month" && (
            <span className="smalltext"> (month)</span>
          )}
        </p>
      </div>
      <p
        className="text-center pt-2"
        style={{
          color: props.selected ? "rgb(129, 209, 53)" : "rgb(183, 183, 183)",
        }}
      >
        ${props.value}/{props.duration}{" "}
        {props.duration === "year" && (
          <span className="smalltext"> (${props.installments}/m)</span>
        )}
      </p>
    </div>
  );
}
