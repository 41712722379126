import localForage from "localforage";

var currentUser = localForage.createInstance({
  name: "@currentUser",
});

var token = localForage.createInstance({
  name: "@token",
});

export { token, currentUser };
