import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AppNav from "../../../components/appNavBar";
import Footer from "../../../components/footer";
import SideBar from "../../../components/sideBar";
import jwt_decode from "jwt-decode";

import { loginError, login } from "../../../js/actions/auth/login";
import { updateUser } from "../../actions/auth/updateUser";
import { token, currentUser } from "../../../LocalForage/localForage";
import { isAuthorized } from "../../actions/auth/token";
import apiClient from "../../../Api Wrapper/Api";

class AppPrivate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: false,
    };
    props.isAuthorized(props.token);
  }
  async componentDidMount() {
    const userToken = await token.getItem("@token");
    const user = await currentUser.getItem("@currentUser");
    if (userToken) {
      var decodedToken = jwt_decode(userToken);
      var dateNow = new Date();
      if (decodedToken.exp * 1000 < dateNow.getTime()) {
        await token.setItem("@token", "");
        await currentUser.setItem("@currentUser", "");
        this.props.loginError("authentication failed");
      } else {
        apiClient.setHeader("token", userToken);
        apiClient.get(`users/get?uid=${user.userID}`).then((response) => {
          if (response && response.status === 200) {
            this.props.updateUser(response.data);
          }
        });
        this.props.login(userToken, user);
      }
    } else {
      this.props.loginError("authentication failed");
    }

    const { isAuthenticated } = this.props;
    if (!isAuthenticated) this.props.router.goBack();
  }
  render() {
    const { isAuthenticated, children } = this.props;
    return (
      <div>
        {isAuthenticated && (
          <div>
            <AppNav {...this.props} />

            <SideBar {...this.props} />
            <div
              onClick={() => {
                document.getElementById("mySidenav").style.width = "0";
              }}
            >
              {children}
            </div>
            <Footer {...this.props} />
          </div>
        )}
      </div>
    );
  }
}

const matchDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      isAuthorized,
      loginError,
      login,
      updateUser,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    token: state.auth.token,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, matchDispatchToProps)(AppPrivate);
