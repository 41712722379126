export const ADD_HOTSPOT = "ADD_HOTSPOT";
export const ADD_AUDIO_HOTSPOT = "ADD_AUDIO_HOTSPOT";
export const UPDATE_HOTSPOT = "UPDATE_HOTSPOT";
export const ADD_HOTSPOT_LABEL = "ADD_HOTSPOT_LABEL";
export const SET_IMAGE = "SET_IMAGE";
export const SET_DESC = "SET_DESC";
export const SET_ACTIVE_HOTSPOT = "SET_ACTIVE_HOTSPOT";
export const RESET_HOTSPOT = "RESET_HOTSPOT";
export const RESET_AUDIO_HOTSPOT = "RESET_AUDIO_HOTSPOT";
export const DELETE_HOTSPOT = "DELETE_HOTSPOT";
export const DELETE_AUDIO_HOTSPOT = "DELETE_AUDIO_HOTSPOT";

// ************ REVAMPED PANO ***********
export const SET_PANO_VIEW = "SET_PANO_VIEW";
export const SET_CURRENT_PANO = "SET_CURRENT_PANO";

export const addHotspot = (hotspot) => (dispatch) => {
  dispatch({
    type: ADD_HOTSPOT,
    hotspot,
  });
};
export const addAudioHotspot = (hotspot) => (dispatch) => {
  dispatch({
    type: ADD_AUDIO_HOTSPOT,
    hotspot,
  });
};
export const deleteHotspot = (id) => (dispatch) => {
  dispatch({
    type: DELETE_HOTSPOT,
    id,
  });
};
export const deleteAudioHotspot = (id) => (dispatch) => {
  dispatch({
    type: DELETE_AUDIO_HOTSPOT,
    id,
  });
};

export const resetHotspot = () => (dispatch) => {
  dispatch({
    type: RESET_HOTSPOT,
  });
};
export const resetAudioHotspot = () => (dispatch) => {
  dispatch({
    type: RESET_AUDIO_HOTSPOT,
  });
};

export const updateHotspot = (hid, hotspot) => (dispatch) => {
  dispatch({
    type: UPDATE_HOTSPOT,
    hid,
    hotspot,
  });
};
export const addHotspotLabel = (hotspotlabel) => (dispatch) => {
  dispatch({
    type: ADD_HOTSPOT_LABEL,
    hotspotlabel,
  });
};
export const setImage = (image) => (dispatch) => {
  dispatch({
    type: SET_IMAGE,
    image,
  });
};
export const setDesc = (desc) => (dispatch) => {
  dispatch({
    type: SET_DESC,
    desc,
  });
};
export const setActivehotspot = (hotspot) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_HOTSPOT,
    hotspot,
  });
};

// *************** REVAMPED HOTSPOTS FUNC ***********
export const setPanoView = (panoView) => (dispatch) => {
  dispatch({
    type: SET_PANO_VIEW,
    panoView,
  });
};

export const setCurrentPanaroma = (currentPano) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_PANO,
    currentPano,
  });
};
