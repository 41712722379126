import React, { useEffect, Component } from "react";
import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
} from "@ant-design/icons";
import "./MainCSS.css";
import { Carousel } from "antd";
import VerticalSlider from "./vertical-slider";
import ImagePanorama from "../Panolen/panorama/ImagePanorama";
import Viewer from "../Panolen/viewer/Viewer";
import image from "../Images/360.jpg";
import ReadMore from "../CustomComponents/ReadMore";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSmall: window.matchMedia("(max-width: 768px)").matches,
      isLarge: window.matchMedia("(min-width: 992px)").matches,
      isReadMore: false,
      isReadWorld: false,
    };
  }
  componentDidMount() {
    const imgPath = process.env.PUBLIC_URL + "/images/";
    const viewer = new Viewer({
      container: this.panaromaref,
      controlBar: false,
      cameraFov: 120,
      autoRotate: true,
      autoRotateSpeed: 0.2,
      dampingFactor: 0.1,
      autoRotateActivationDuration: 1000,
    });
    viewer.OrbitControls.noZoom = true;
    const panorama = new ImagePanorama(image);
    viewer.add(panorama);
  }
  render() {
    const imgPath = process.env.PUBLIC_URL + "/images/";
    const contentStyle = {
      height: "160px",
      color: "#fff",
      lineHeight: "160px",
      textAlign: "center",
      background: "#d3d3d3 ",
      width: "50%",
      marginLeft: "25%",
      borderRadius: 25,
      marginTop: 20,
      display: "flex",
      textAlign: "center",
      justifyContent: "center",
      paddingLeft: 20,
      paddingRight: 20,
    };
    return (
      <div>
        <div>
          <div className="image-div">
            <div
              style={{ width: "100%", height: "60vh" }}
              ref={(ref) => (this.panaromaref = ref)}
            />
            <h1
              className="image-heading heading centered font-weight-bold display-none"
              style={{ color: "#fff", top: "40%" }}
            >
              <em style={{ color: "rgb(129, 209, 53)" }}> FROSTIE VR</em>
              <p style={{ fontSize: 17, marginTop: 5, fontWeight: "500" }}>
                Procreate connections with 360° Virtual Reality
              </p>
            </h1>
          </div>
        </div>
        <div className="about-first-container">
          <div className="small-display-logo large-display-logo">
            <img
              alt=""
              src="/logo192.png"
              width="100%"
              className="d-inline-block align-top mt-1 lg-h-20per "
            />
          </div>
          <div>
            <h2>360° Virtual Tours Made Easy</h2>

            <p>Create • Edit • Share</p>
            <p>
              Upload 360 VR photos. Create easy Virtual Tours. Share it
              anywhere.
            </p>
          </div>
          <div>
            <a className="brand-links" href="">
              <FacebookOutlined className="about-social-handler" />
            </a>
            <a className={this.state.isLarge && "brand-links ml-3"} href="">
              <TwitterOutlined className="about-social-handler" />
            </a>
            <a className={this.state.isLarge && "brand-links ml-3"} href="">
              <InstagramOutlined className="about-social-handler" />
            </a>
          </div>
        </div>

        <div className="about-Sec-container">
          <br />
          <div className="about-Sec-container-div">
            <img
              alt=""
              src={`${imgPath}pexels-alex-1732414.jpg`}
              style={{ width: "100%", borderRadius: "22px", height: "100%" }}
            />
          </div>
          <div className="about-Sec-container-text-div">
            <br />
            <h2 style={{ fontWeight: "bolder" }}>
              Make your content more interactive with a 360° virtual tour
            </h2>
            <p style={{ fontSize: "14.5px" }} className="gray-color">
              <span style={{ fontWeight: "bold" }}>
                Wave goodbye to old-fashioned photographs
              </span>{" "}
              and create a more innovative experience for your audience. Frostie
              VR is determined to give an experience of a competent and reliable
              360° player. Showcase your content with a 360° virtual tour and
              bring value to your content with the help of Frostie. Making a
              connection is very important when it comes to marketing or
              interacting with the audience. In this era of social media, the
              connection is only a few seconds away and Frostie 360° virtual
              tour will help you to be more noticeable than others. It is all
              about perspective, market your business with a wider perspective
              with more details and make an impression in a jiffy. In a world
              where everyone is a photographer, a virtual tour can grab the
              viewer’s attention seamlessly. Frostie VR provides a platform for
              creating, sharing, editing, and viewing your 360° photograph.
            </p>
            <button
              onClick={() =>
                this.setState({ isReadMore: !this.state.isReadMore })
              }
              className="orange-button"
            >
              <span
                style={{ color: "white", fontWeight: "bold", fontSize: "16px" }}
              >
                READ MORE
              </span>
              {this.state.isReadMore && (
                <ReadMore
                  image={`${imgPath}pexels-alex-1732414.jpg`}
                  heading="Make your content more interactive with a 360° virtual tour"
                  text=" Wave goodbye to old-fashioned photographs and create a more innovative experience for your audience. Frostie VR is determined to give an experience of a competent and reliable 360° player. Showcase your content with a 360° virtual tour and bring value to your content with the help of Frostie. Making a connection is very important when it comes to marketing or interacting with the audience. In this era of social media, the connection is only a few seconds away and Frostie 360° virtual tour will help you to be more noticeable than others. It is all about perspective, market your business with a wider perspective with more details and make an impression in a jiffy. In a world where everyone is a photographer, a virtual tour can grab the viewer’s attention seamlessly. Frostie VR provides a platform for creating, sharing, editing, and viewing your 360° photograph."
                  isVisible={this.state.isReadMore}
                />
              )}
            </button>
          </div>
        </div>

        <div
          style={{
            width: "95%",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "100px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{ width: "65%", marginRight: "auto", marginLeft: "20px" }}
          >
            <img
              alt=""
              src={`${imgPath}pexels-curtis-adams-3773570.jpg`}
              className="small-image-one-screen-height large-image-one-screen-height"
              style={{ width: "95%", borderRadius: "22px" }}
            />
          </div>
          <div
            style={{
              width: "30%",
              marginLeft: "auto",
              marginRight: "20px",
              textAlign: "center",
            }}
          >
            <br />
            <br />
            <img
              alt=""
              src={`${imgPath}pexels-sound-on-3761262.jpg`}
              className="small-image-two-screen-height large-image-two-screen-height"
              style={{
                width: "100%",
                borderRadius: "22px",
                marginBottom: "20px",
              }}
            />
            <h5 style={{ margin: 0 }}>Project 1</h5>
            {/* <p style={{ fontSize: "16px" }} className="gray-color">
              Read more
            </p> */}
          </div>
        </div>

        <div
          className="display-column"
          style={{
            marginTop: "100px",
            width: "90%",
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
            justifyContent: "space-between",
            textAlign: "center",
          }}
        >
          <div
            className="small-screen-margin small-screen-width w-75"
            style={{
              textAlign: "left",
            }}
          >
            <br />
            <br />
            <h4>
              <b>Accommodate 360° tours into Your Profile</b>
            </h4>
            <br />
            <p style={{ fontSize: "14.5px" }} className="gray-color">
              <span style={{ fontWeight: "bold" }}>
                Make your Profile look more aesthetic and appealing with 360
                photography.
              </span>{" "}
              Be one of those few people who fuel the trendy fire in cold winter
              and benefit from the progressive warmth and the bright light it
              has to offer. Being one of the pioneers in technology especially
              aids in the engagement growth of your young audience because they
              catch up with technology so fast. Introducing something new that
              is going to replace traditional photography to any audience will
              leave an impression and it is highly unlikely to forget that brand
              quickly. Digital marketing is getting progressively competitive.
              More than a billion people are active users of daily internet
              browsing. If you want to target a wider audience then 360° is a
              perfect tool for that. What could be better than having a whole
              perspective? Users will be able to rotate in any direction.
              e-commerce websites can improve the audience engagement process
              with 360°virtual tours. People will spend more time on your
              website than others if shopping is only a click away in an
              interactive digital environment. There will be lesser returned
              goods because shoppers will have the confidence of purchasing the
              right product for them. 360° virtual tours will extensively help
              in a variety of businesses. Touring agencies will hugely benefit
              by showcasing the 360° view of the landscapes, museums, historical
              places, cultural festivals. People will develop trust in your
              brand by digitally having a tour and they will be looking forward
              to the whole journey. The value in your business will only
              increase. Airlines, hotels, aquariums, museums, real estate, or
              any other business that can think of, will create more worth to
              any brand identity. Art websites can achieve that sleek aesthetic
              look which artists and curators are always craving. Integrating
              360° to any website will only be fruitful and there is no
              downside. Frostie supports smooth shareability that comes in handy
              while integrating panorama to your website.
            </p>
            <br />
            <h4>
              <b>Your 360° Images on Mobile</b>
            </h4>
            <p style={{ fontSize: "14.5px" }} className="gray-color">
              <span style={{ fontWeight: "bold" }}>
                More than half of the population uses smartphones
              </span>{" "}
              more than laptops or any other personal computer. The number of
              cell phone users and 24/7 connectivity demands to market your
              content or product on these devices (smartphones) more than
              computers. Lucky for your Frostie VR lets you experience your
              360°panorama with ease on cell phones as well and enhance user
              engagement. Your smartphone experience will be as amusing as the
              big screen. Imagine scrolling through social media as usual and in
              those dull phones on an Instagram or a Facebook feed, you stumble
              upon a virtual tour of a magnificent landscape. You move around
              your phone to have a full 360° experience. While you are engaging
              with this beautiful scenery your heart wants you to visit this
              place. Imagine a trademark of a hotel or an airline is stuck in
              your mind because of this experience. Next time you plan to visit
              the same landscape you will automatically think of those same
              airlines or that hotel. Isn’t it a good tool to have? visualize
              yourself browsing a supermarket virtual tour and this vivid
              experience makes you want to just grab those things. What if I
              told you that those goods are only a tap away to be delivered to
              your home while sitting on a couch. Frostie not only gives a 360°
              experience it also provides you with an e-commerce service. So
              much on a single photograph, it is true what they say that “A
              picture speaks a thousand words.”
            </p>
            <br />
            <br />
            <h4>
              <b>Conquer the world of Virtual Reality</b>
            </h4>
            <br />
            <p style={{ fontSize: "14.5px" }} className="gray-color">
              The number of VR users is accelerating rapidly. There are millions
              of people who have already started to use VR. This billion-dollar
              industry is estimated to grow tremendously merely in a few years.
              The content creators and all sorts of industries will lean on
              creating content for these virtual headsets for maintaining their
              business. Frostie 360° virtual tour is compatible with a variety
              of virtual headsets. Frostie makes virtual reality easy so you
              won’t be left out in any trend.
            </p>
            <br />
          </div>
          <div className="about-sec-img-div">
            <img
              alt=""
              src={`${imgPath}Editor.png`}
              className="about-sec-img"
            />
          </div>
        </div>

        <div
          style={{
            marginTop: "100px",
            display: "flex",
            width: "80%",
            marginRight: "auto",
            marginLeft: "auto",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <div className="large-screen-width small-screen-width">
            <h2>
              <b>What People Say</b>
            </h2>
          </div>
        </div>

        <Carousel autoplay>
          <div>
            <div style={contentStyle}>
              <p className="gray-color" style={{ fontSize: "16px" }}>
                “Virtual reality is the 'ultimate empathy machine.' These
                experiences are more than documentaries. They're opportunities
                to walk a mile in someone else's shoes.”
              </p>
              <p style={{ fontSize: "16px" }}>
                - <span style={{ color: "#ff7702" }}>Chris Milk</span>
              </p>
            </div>
          </div>
          <div>
            <div style={contentStyle}>
              <p className="gray-color" style={{ fontSize: "16px" }}>
                “Photography is a kind of virtual reality, and it helps if you
                can create the illusion of being in an interesting world.”
              </p>
              <p style={{ fontSize: "16px" }}>
                - <span style={{ color: "#ff7702" }}>Steven Pinker</span>
              </p>
            </div>
          </div>
          <div>
            <div style={contentStyle}>
              <p className="gray-color" style={{ fontSize: "16px" }}>
                “Virtual reality is a technology that could actually allow you
                to connect on a real human level, soul-to-soul, regardless of
                where you are in the world.”
              </p>
              <p style={{ fontSize: "16px" }}>
                - <span style={{ color: "#ff7702" }}>Chris Milk</span>
              </p>
            </div>
          </div>
          <div>
            <div style={contentStyle}>
              <p className="gray-color" style={{ fontSize: "16px" }}>
                “Virtual reality is inevitably going to become mainstream - it's
                only a question of how good it needs to be before the mainstream
                is willing to use it.”
              </p>
              <p style={{ fontSize: "16px" }}>
                - <span style={{ color: "#ff7702" }}>Palmer Luckey</span>
              </p>
            </div>
          </div>
          <div>
            <div style={contentStyle}>
              <p className="gray-color" style={{ fontSize: "16px" }}>
                “Obviously, virtual reality is where I've placed my bet about
                the future and where the excitement is going. At this point, I
                could say it's almost a lock. It's going to be magical - it is
                magical - and great things are coming from that. Along the way,
                I was focused on the first-person shooters. I said we should go
                do something on mobile.”
              </p>
              <p style={{ fontSize: "16px" }}>
                - <span style={{ color: "#ff7702" }}>John Carmack</span>
              </p>
            </div>
          </div>
        </Carousel>

        <div
          className="large-screen-sec-image-width small-screen-sec-image-width"
          style={{
            marginTop: "100px",
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
          }}
        >
          <div style={{ marginLeft: "auto" }}>
            <img
              alt=""
              src={`${imgPath}worldwide.png`}
              style={{ width: "100%", height: "80%", borderRadius: "22px" }}
            />
          </div>
          <br />
          <div
            style={{ width: "70%", marginRight: "auto", marginLeft: "auto" }}
          >
            <p style={{ fontSize: "16px" }} className="gray-color">
              <span style={{ fontWeight: "bold" }}>
                Be part of a creative community.
              </span>{" "}
              Virtually connect to the people having as interest as you. Keep
              track of the 360° world and what people are up to. Fuel your
              creative mind with motivation by browsing fresh new perspectives
              and techniques every day. Frostie 360° virtual tour allows you to
              publish your 360 panoramas and connect you to more creators.
            </p>
            <button
              onClick={() => {
                this.setState({ isReadWorld: !this.state.isReadWorld });
              }}
              className="orange-button"
            >
              <span
                style={{ color: "white", fontWeight: "bold", fontSize: "16px" }}
              >
                READ MORE
              </span>
              {this.state.isReadWorld && (
                <ReadMore
                  image={`${imgPath}worldwide.png`}
                  heading="Join a World Wide community
                  "
                  text={
                    " Be part of a creative community. Virtually connect to the people having as interest as you. Keep track of the 360° world and what people are up to. Fuel your creative mind with motivation by browsing fresh new perspectives and techniques every day.  Frostie 360° virtual tour allows you to publish your 360 panoramas and connect you to more creators."
                  }
                  isVisible={this.state.isReadWorld}
                />
              )}
            </button>
          </div>
        </div>

        <div style={{ marginTop: "50px", textAlign: "center" }}>
          <h6 style={{ margin: 0 }}>
            <b>E-mail</b>
          </h6>
          <p
            className="gray-color m-0"
            style={{ fontSize: "16px", marginTop: "5px" }}
          >
            contact@frostievr.com
          </p>
          <br />
          <h6 style={{ margin: 0 }}>
            <b>Telephone</b>
          </h6>
          <p
            className="gray-color m-0"
            style={{ fontSize: "16px", marginTop: "5px" }}
          >
            (786)299-2120
          </p>
          <br />
          <h6 style={{ margin: 0 }}>
            <b>Office Address</b>
          </h6>
          <p
            className="gray-color m-0 mb-5"
            style={{ fontSize: "16px", marginTop: "5px" }}
          >
            Riverside Drive Reading, CA 96001
          </p>
        </div>
      </div>
    );
  }
}

export default About;
