import React from "react";
import Post from "../../../CustomComponents/Post";
import "./index.scss";
import QueueAnim from "rc-queue-anim";
import moment from "moment";
import Texty from "rc-texty";
import { connect } from "react-redux";
import { Empty, Button, Spin } from "antd";

function TabToursILike(props) {
  return (
    <div className="main-container animated fadeInRight">
      <QueueAnim
        style={{
          display: "flex",
          // width: "100%",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
        className="queue-simple"
      >
        {!props.posts ? (
          <Spin
            style={{
              width: "100%",
              padding: 50,
            }}
          />
        ) : (
          <React.Fragment>
            {props.posts
              ? props.posts.map((item) =>
                  item.Posts.map((post) => (
                    <div className="lg-post-w sm-post-w mt-2">
                      <Post
                        key={post.id}
                        id={post.id}
                        postID={item.id}
                        postName={post.description}
                        userID={props.userID}
                        numberOfComments={
                          post.Tour.Tour_images[0].Comments.length
                        }
                        Comments={post.Tour.Tour_images[0].Comments}
                        commentUser={props.displayName}
                        numberOfLikes={post.Tour.Tour_images[0].Likes.length}
                        Likes={post.Tour.Tour_images[0].Likes}
                        numberOfView={post.Tour.Tour_images[0].Views.length}
                        userName={props.displayName}
                        time={moment(post.createdAt).fromNow()}
                        handleClick={() =>
                          props.router.push({
                            pathname: `/tour-view/tid-${post.id}/${post.uid}`,
                          })
                        }
                        profile={item.profilePicture}
                        isTour={true}
                        userName={item.display_name}
                        image={post.Tour.Tour_images[0].image}
                        isPurchaseable={post.Tour.isPurchaseable}
                        isFeatured={post.featuredTill === "0" ? false : true}
                        isLiked={true}
                        LikedUserProfPic={true}
                      />
                    </div>
                  ))
                )
              : undefined}
          </React.Fragment>
        )}
      </QueueAnim>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userID: state.auth.user.userID,
  };
};

export default connect(mapStateToProps)(TabToursILike);
